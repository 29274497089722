import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { getAdminDetails } from "../../redux/admin/admin.actions";

export function GetAdminDetailsApiCall(adminId) {
  return (dispatch) => {
    dispatch(getAdminDetails(null, true));

    const url = `${URL_CONSTANTS.GET_ADMIN_DETAILS}${adminId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(getAdminDetails(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(getAdminDetails(null, false));
      });
  };
}