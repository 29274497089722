import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { userPropertyEditDetails } from "../../redux/userProperty/userProperty.actions";

export function UserPropertyEditDetailsApiCall(propertyId) {
  return (dispatch) => {
    dispatch(userPropertyEditDetails(null, true));

    const url = `${URL_CONSTANTS.USER_PROPERTY_EDIT_DETAILS}${propertyId}`;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(userPropertyEditDetails(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(userPropertyEditDetails(error, false));
      });
  };
}