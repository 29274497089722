import { createSelector } from "reselect";

const selectSearch = (state) => state.search;

export const getTopTenStates = createSelector(
  [selectSearch],
  (search) => search.topTenStates
);

export const getTopTenCities = createSelector(
  [selectSearch],
  (search) => search.topTenCities
);

export const getTopLocalities = createSelector(
  [selectSearch],
  (search) => search.topLocalities
);

export const getPropertySearchList = createSelector(
  [selectSearch],
  (search) => search.propertySearchList
);
