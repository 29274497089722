import FileActionTypes from './file.types';

const initialFileState = {
  uploadFile: {
    data: null,
    loader: false
  },
  removeFile: {
    data: null,
    loader: false
  },
  removeAdFile: {
    data: null,
    loader: false
  },
  toast: {
    message: '',
    appearance: ''
  }
};

const fileReducer = (state = initialFileState, action) => {
  switch(action.type) {
    case FileActionTypes.UPLOAD_FILE:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case FileActionTypes.REMOVE_FILE:
      return {
        ...state,
        removeFile: {
          ...state.removeFile,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case FileActionTypes.REMOVE_AD_FILE:
      return {
        ...state,
        removeAdFile: {
          ...state.removeAdFile,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case FileActionTypes.TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          message: action.payload.message,
          appearance: action.payload.appearance
        }
      };
    default:
      return state;
  }
};

export default fileReducer;