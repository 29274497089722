import { createSelector } from "reselect";

const selectToken = (state) => state.token;

export const getAdminToken = createSelector(
  [selectToken],
  (token) => token.adminToken
);

export const getUserToken = createSelector(
  [selectToken],
  (token) => token.userToken
);