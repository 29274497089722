import { postRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adminLogin } from "../../redux/persistantData/persistantData.actions";
import { setAdminToken } from '../../redux/token/token.actions';

export function LoginApiCall(data, toastView) {
  return (dispatch) => {
    dispatch(adminLogin(null, true));

    const url = URL_CONSTANTS.ADMIN_LOGIN;

    postRequest(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(setAdminToken(res?.data?.data?.token));
          dispatch(adminLogin(res.data.data, false));
          toastView("Login success", 'success');
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message, 'error');
        dispatch(adminLogin(null, false));
      });
  };
}
