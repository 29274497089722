import axios from "axios";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { store } from "../../redux/store";

export function FileUploadUserApiCall(file, onUploadProgress) {
  const state = store.getState();
  const authToken = state.token.userToken;
  let formData = new FormData();

  formData.append("file", file);
  return axios.post(URL_CONSTANTS.FILE_UPLOAD_USER, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${authToken}`,
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json"
    },
    onUploadProgress
  });
}