import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { allPropertyTypes } from "../../redux/property/property.actions";

export function AllPropertyTypesApiCall() {
  return (dispatch) => {
    const url = URL_CONSTANTS.FILTER_ALL_TYPES_PROPERTY;

    postRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(allPropertyTypes(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(allPropertyTypes(null, false));
      });
  };
}
