import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { getAdminRoles } from "../../redux/admin/admin.actions";

export function GetAdminRolesApiCall() {
  return (dispatch) => {
    dispatch(getAdminRoles(null, true));

    const url = URL_CONSTANTS.GET_ADMIN_ROLES;

    getRequestHeader(url)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          dispatch(getAdminRoles(res?.data?.data, false));
        }
      })
      .catch((error) => {
        dispatch(getAdminRoles(null, false));
      });
  };
}