import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import PropertyCardDetailed from "../../components/Cards/PropertyCardDetailed.component";
import Loader from '../../components/Loader/Loader.component';
import MiniAdvertisement from "../../components/Ads/MiniAdvertisement.component";
import { UserAdDetailsApiCall } from '../../apiCalls/User/UserAdDetailsApiCall';
import { OtherSalesPropertiesApiCall } from '../../apiCalls/Property/OtherSalesPropertiesApiCall';
import { getOtherSalesProperties } from '../../redux/property/property.selector';
import { userAdDetailsState, showInterestState } from '../../redux/user/user.selector';
import NoDataIMG from '../../assets/img/no-data2.jpg';
import "./BuySale.styles.scss";

const BankAuction = () => {
  const dispatch = useDispatch();
  const [defaultData, setDefaultData] = useState(['', '', '', '', '', '', '', '']);
  const otherSalesProperties = useSelector(getOtherSalesProperties);
  const adDetails = useSelector(userAdDetailsState);
  const [infiniteProperties, setInfiniteProperties] = useState([]);
  const [pageNumber, setPagenumber] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const showInterest = useSelector(showInterestState);

  useEffect(() => {
    dispatch(UserAdDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (otherSalesProperties.loader) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {

        if (pageNumber < (totalPages)) {
          setPagenumber(old => old + 1)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [otherSalesProperties.loader, pageNumber, totalPages]);

  useEffect(() => {
    if (otherSalesProperties?.data?.data?.length > 0) {
      if (pageNumber === 1) {
        setInfiniteProperties(otherSalesProperties.data?.data);
        settotalPages(otherSalesProperties.data?.total_page_count);

      } else {
        setInfiniteProperties(old => [
          ...old, ...otherSalesProperties.data?.data
        ]);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherSalesProperties]);

  useEffect(() => {
    dispatch(OtherSalesPropertiesApiCall(pageNumber, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <div className="container-fluid bank-auction-outer">
      <Loader visible={showInterest.loader} />
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs-outer">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">Properties Buy-Sale</Typography>
      </Breadcrumbs>

      <MiniAdvertisement
        data1={adDetails?.data?.Buy_Sale_Page_Slot_1}
        data2={adDetails?.data?.Buy_Sale_Page_Slot_2}
        data3={adDetails?.data?.Buy_Sale_Page_Slot_3}
      />

      <h2>Properties Buy-Sale</h2>

      {infiniteProperties?.length === 0 ? (
        <div className="results-empty container">
          <img src={NoDataIMG} alt=" " />
          <h1>No Properties Found...</h1>
        </div>
      ) : (
        <div className="row cards-wrapper">
          <div className="col-12 col-lg-10">
            <div className="row">
              {infiniteProperties?.map((item, index) => (
                infiniteProperties.length === index + 1 ?
                  <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3" ref={lastElementRef}>
                    <PropertyCardDetailed section="buy-sale" data={item} />
                  </div> : <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3">
                    <PropertyCardDetailed section="buy-sale" data={item} />
                  </div>
              ))}
            </div>
          </div>
          <div className="col-2 empty-class"></div>
        </div>
      )}
    </div>
  );
};

export default BankAuction;
