import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SingleBedIcon from "@material-ui/icons/SingleBed";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ExploreIcon from "@material-ui/icons/Explore";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import InsideLoader from "../ContentLoader/InsideLoader.component";
import { ShowInterestApiCall } from '../../apiCalls/User/ShowInterestApiCall';
import { getUserToken } from '../../redux/token/token.selector';
import { savePropertyLink } from '../../redux/user/user.actions';
import NoFile from "../../assets/img/no-file.png";
import { getCategoryByPropertyType } from '../../utils/CustomFunctions';
import "./PropertyCardDetailed.styles.scss";

export default function PropertyCardDetailed({ section, data }) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const userToken = useSelector(getUserToken);
  let history = useHistory();

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const showInterest = (propertyId) => {
    if (userToken) {
      dispatch(ShowInterestApiCall(propertyId, toastView));
    } else {
      history.push('/login');
    }
  }

  const handleViewDetails = (link) => {
    if(!userToken) {
      dispatch(savePropertyLink(link, false));
    }
  }

  return (
    <div className={`property-card-detailed ${section === "buy-sale" ? 'buysale-card':''}`}>
      {data === '' ? (
        <InsideLoader />
      ) : (
        <>
          <div className="image-wrapper">
            {
              data?.images && data?.images?.length ? (
                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}${data?.images[0]}`} alt="" />
              ) : (
                <img alt="Property" src={NoFile} />
              )
            }
          </div>
          <div className="details-wrapper pl-3 pr-3 pt-3">
            {section === "buy-sale" ? (
              <div>
                <h2>₹ {data?.price}</h2>
                <p>
                  <HomeWorkIcon /> {data?.category_name ? getCategoryByPropertyType(data?.type_name, data.category_name) : '-'}
                </p>
                <p>
                  <LocationOnIcon /> {data?.locality_name || '-'},&nbsp; {data?.city_name}
                </p>
                {
                  data?.face ? (
                    <p>
                      <ExploreIcon /> Facing: {data?.face || '-'}
                    </p>
                  ) : ('')
                }
              </div>
            ) : (
              <div>
                <h2>
                  <span>Reserved Price: </span> ₹ {data?.price || '-'}
                </h2>

                <h4 className="mt-3 text-capitalize">{data?.institution || '-'}</h4>
                <p>
                  <HomeWorkIcon /> {data?.category_name ? getCategoryByPropertyType(data?.type_name, data.category_name) : '-'}
                </p>
                <p className="text-capitalize">
                  <LocationOnIcon /> {data?.locality_name || '-'},&nbsp; {data?.city_name}
                </p>

                <p>
                  <DateRangeIcon /> Auction Date: {''}
                  {
                    data?.auction_start_date ? <span>{data?.auction_start_date || '-'}</span> : <span class="coming-soon-label"> Coming Soon!!</span>
                  }
                </p>
              </div>
            )}

            <div className="extra-details-wrapper d-flex">
              {data?.bhk ? (
                <p>
                  <SingleBedIcon /> {data?.bhk}&nbsp;BHK Flat
                </p>
              ) : ('')}
              <p>
                <AspectRatioIcon /> {data?.property_area} {data?.property_area_unit || '-'}
              </p>
            </div>
          </div>

          <div className="button-wrapper p-3 pt-0 d-flex">
            {section === "buy-sale" ? (
              <Link
                to={userToken ? `buy-sale-property-details/${data?.property_id}` : 'login'}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" color="default" onClick={() => handleViewDetails(`buy-sale-property-details/${data?.property_id}`)}>
                  View Details
                </Button>
              </Link>
            ) : (
              <Link
                to={userToken ? `auction-property-details/${data?.property_id}` : 'login'}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" color="default" onClick={() => handleViewDetails(`auction-property-details/${data?.property_id}`)}>
                  View Details
                </Button>
              </Link>
            )}

            <Button
              variant="contained"
              color={section === "buy-sale" ? "secondary" : "primary"}
              onClick={() => showInterest(data?.property_uniqueid)}
            >
              Contact Us
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
