import { nanoid } from 'nanoid';

const downloadsData = [
  {
    id: nanoid(),
    name: '1. SARFAESI Act, 2002 Manual in English',
    link: '/doc/SARFAESI-MANUAL-PDF.pdf',
    type: 'PDF File'
  },
  {
    id: nanoid(),
    name: '2. SARFAESI Act, Amendments 2016',
    link: '/doc/SARFAESI-ACT-2016-Amendments.pdf',
    type: 'PDF File'
  },
  {
    id: nanoid(),
    name: '3. DRT Act  Manaul in English',
    link: '/doc/DRT-Act.pdf',
    type: 'PDF File'
  },
  {
    id: nanoid(),
    name: '4. Pamphlet/e-Brochure of SARFAESIAUCTIONS.COM webportal',
    link: '/doc/E-Brochure.pptx',
    type: 'PPT File'
  },
  {
    id: nanoid(),
    name: '5. Video clip on working of SARFAESIAUCTONS.COM in English',
    link: 'https://www.youtube.com/channel/UCz_12M3Z8pIlM-KxZYSen4A',
    type: 'Audio File'
  },
  {
    id: nanoid(),
    name: "6. List of Resolution Agents/Enforcement Agencies/ Recovery Agents - Statewise as per  Banks' empanelment",
    link: '/doc/recovery-agent-list-07-12-2019.pdf',
    type: 'Xle File'
  },
  {
    id: nanoid(),
    name: '7. List of Assets Reconstructons Companies as per RBI',
    link: '/doc/List-of-ARCs-as-per-RBI.xlsx',
    type: 'Xle File'
  },
  {
    id: nanoid(),
    name: "8. List of Authorised Valuers - Statewise",
    link: '/doc/Property-valuers.doc',
    type: 'Word File'
  },
  {
    id: nanoid(),
    name: '9. List of Non-Banking Finance Companies as per RBI',
    link: 'https://rbi.org.in/Scripts/BS_NBFCList.aspx',
    type: 'Xle File'
  },
  {
    id: nanoid(),
    name: "10. List of Housing Finance Companies as per RBI",
    link: '/doc/List-of-HFCs.xlsx',
    type: 'Xle File'
  },
  {
    id: nanoid(),
    name: '11. List of Detective Agencies as per RBI',
    link: '/doc/detective-agencies-of-bank.pdf',
    type: 'PDF File'
  },
  {
    id: nanoid(),
    name: "12. List of Major Realestate Builders in India",
    link: '/doc/Top-Realestate-Developers.xlsx',
    type: 'Xle File'
  },
  {
    id: nanoid(),
    name: '13. Satewise Land area measurement table',
    link: '/doc/landmeasurement.xlsx',
    type: 'Xle File'
  },
  {
    id: nanoid(),
    name: '14. Model Tenancy Act approved on 02.06.2021',
    link: '/doc/Model-Tenancy-Act-02062020.pdf',
    type: 'PDF File'
  }
];

export { downloadsData };