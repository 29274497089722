import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const addEndUserState = createSelector(
  [selectUser],
  (user) => user.addEndUser 
);

export const resendOTPState = createSelector(
  [selectUser],
  (user) => user.resendOTP
);

export const showInterestState = createSelector(
  [selectUser],
  (user) => user.showInterest
);

export const verifyOTPState = createSelector(
  [selectUser],
  (user) => user.verifyOTP
);

export const propertyDetailsForUser = createSelector(
  [selectUser],
  (user) => user.propertyDetailsForUser
);

export const bankAuctionProperties = createSelector(
  [selectUser],
  (user) => user.bankAuctionProperties
);

export const getResetPasswordState = createSelector(
  [selectUser],
  (user) => user.resetPassword
);

export const userAdDetailsState = createSelector(
  [selectUser],
  (user) => user.userAdDetails
);

export const getSavePropertyLink = createSelector(
  [selectUser],
  (user) => user.savePropertyLink
);