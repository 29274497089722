import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { updateProperty, propertyAddSuccess } from "../../redux/adminProperty/adminProperty.actions";

export function UpdatePropertyApiCall(data, toastView) {
  return (dispatch) => {
    dispatch(updateProperty(null, true));
    dispatch(propertyAddSuccess(false));

    const url = URL_CONSTANTS.UPDATE_PROPERTY;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView('Property Updation Successful', 'success');
          dispatch(propertyAddSuccess(true));
          dispatch(updateProperty(res?.data?.data?.data, false));
        }
      })
      .catch((error) => {
        dispatch(updateProperty(null, false));
        dispatch(propertyAddSuccess(false));
      });
  };
}