import {
  RESIDENTIAL_CATEGORIES,
  AGRICULTURAL_CATEGORIES,
  INDUSTRIAL_CATEGORIES,
  COMMERCIAL_CATEGORIES
} from './JsonData/Categories';

export function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// For getting new category name by property type and old category name
export const getCategoryByPropertyType = (propertyType, categoryName) => {
  if (propertyType === "Residential") {
    return RESIDENTIAL_CATEGORIES[categoryName];
  } else if (propertyType === "Industrial") {
    return INDUSTRIAL_CATEGORIES[categoryName];
  } else if (propertyType === "Agricultural land") {
    return AGRICULTURAL_CATEGORIES[categoryName];
  } else if (propertyType === "Commercial") {
    return COMMERCIAL_CATEGORIES[categoryName];
  } else {
    return categoryName;
  }
}

// Url search params after filtering null, undefined and ""
export const filteredSearchParams = (queryObj) => {
  let query = queryObj;
  for(let param in query) {
    if(query[param] === undefined
      || query[param] === null 
      || query[param] === ""
    ) {    
      delete query[param];
    }
  }
  return new URLSearchParams(query);
}