const UserActionTypes = {
  ADD_END_USER: "ADD_END_USER",
  RESEND_OTP: "RESEND_OTP",
  SHOW_INTEREST: "SHOW_INTEREST",
  VERIFY_OTP: "VERIFY_OTP",
  RESET_PASSWORD: "RESET_PASSWORD",
  PROPERTY_DETAILS_FOR_USER: "PROPERTY_DETAILS_FOR_USER",
  BANK_AUCTION_PROPERTIES: "BANK_AUCTION_PROPERTIES",
  USER_AD_DETAILS: "USER_AD_DETAILS",
  SAVE_PROPERTY_LINK: "SAVE_PROPERTY_LINK"
};

export default UserActionTypes;