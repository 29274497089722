import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from 'react-bootstrap/Modal';
import InfoIcon from '@material-ui/icons/Info';
import NoFile from "../../assets/img/no-file.png";
import { PropertyDetailsForUserApiCall } from '../../apiCalls/User/PropertyDetailsForUser';
import { getUserToken } from '../../redux/token/token.selector';
import './PropertyCard.styles.scss';

export default function PropertyCard({ data, handleEdit, handleDelete, ref }) {
  const dispatch = useDispatch();
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const userToken = useSelector(getUserToken);

  const deleteToggled = () => setIsVisibleDeleteModal(true);
  const onDeleteModalClose = () => setIsVisibleDeleteModal(false);

  const viewPropertyDetails = () => {
    if (userToken) {
      dispatch(PropertyDetailsForUserApiCall(data?.property_id));
    }
  }

  return (
    <div className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card-user pl-0 pr-0" ref={ref}>
      <div className="image-wrapper">
        <img src={data?.images?.length > 0 ? `${process.env.REACT_APP_IMAGE_BASE_URL}${data?.images[0]}` : NoFile} alt="Property" />
      </div>
      <div className="details-section mt-3">
        <p><span>Property Id : </span>{data?.property_uniqueid}</p>
        <p><span>Borrower Name : </span>{data?.title}</p>
        <p><span>Area : </span>{data?.property_area}</p>
        <p><span>Face : </span>{data?.face}</p>
        <p><span>Reserve Price : </span>{data?.price}</p>
        <p><span>Locality : </span>{data?.locality_name}</p>
        <p><span>City : </span>{data?.city_name}</p>
      </div>
      <div className="actions">
        <Button className="view-more-btn ml-2" variant="contained" color="default" onClick={() => handleEdit(data)}>
          <EditIcon className="edit" />Edit
        </Button>
        <Tooltip title="View More">
          <Link
            to={userToken ? `buy-sale-property-details/${data?.property_id}` : 'login'}
            style={{ textDecoration: "none" }}
          >
            <IconButton
              className="view-icon"
              color="primary"
              onClick={viewPropertyDetails}
            >
              <OpenInNewIcon />
            </IconButton>
          </Link>  
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="secondary"
            onClick={deleteToggled}
          >
            <DeleteForeverIcon className="delete-icon" />
          </IconButton>
        </Tooltip>
      </div>

      <Modal size="lg" show={isVisibleDeleteModal} onHide={onDeleteModalClose} className="delete-modal-outer">
        <Modal.Header closeButton>
          <Modal.Title>
            <InfoIcon />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure to delete this property ?</h5>
          <p>This action is irreversible.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onDeleteModalClose}>
            Cancel
          </Button>
          <Button variant="danger" style={{ color: 'red' }} onClick={() => {
            handleDelete(data);
            onDeleteModalClose();  
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}