import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Modals.styles.scss';

const LogoutModal = ({
  showLogoutModal,
  setShowLogoutModal,
  handleLogout
}) => (
  <Modal
    show={showLogoutModal}
    centered
  >
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Modal.Title className="logout-modal-head">
        You are about to logout... Are you sure?
      </Modal.Title>
      <div className="modal-buttons-container">
        <Button
          variant="secondary"
          className="logout-modal-button"
          onClick={() => setShowLogoutModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </div>
  </Modal>
);

export default LogoutModal;