import { createSelector } from "reselect";

const selectAdminProperty = (state) => state.adminProperty;

export const getpropertyOtherAuctionList = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.propertyOtherAuctionList
);

export const addPropertyState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.addProperty
);

export const bankAuctionPropertyList = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.bankAuctionPropertyList
);

export const propertyApprovalState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.propertyApproval
);

export const propertyUnApprovalState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.propertyUnApproval
);

export const updateProperty = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.updateProperty
);

export const removeProperty = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.removeProperty
);

export const dashboardSummary = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.dashboardSummary
);

export const editPropertyState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.editProperty
);

export const adminPropertyDetailsState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.adminPropertyDetails
);

export const adminPropertySearchState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.adminPropertySearch
);

export const adminBankSearchState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.adminBankSearch
);

export const propertyAddSuccessState = createSelector(
  [selectAdminProperty],
  (adminProperty) => adminProperty.propertyAddSuccess
);