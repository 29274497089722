import * as React from "react";
import Box from "@mui/material/Box";
import Popup from "@mui/material/Modal";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 2,
  p: 4,
};

export default function Modal({
  children,
  open,
  handleOpen,
  handleClose,
  width,
}) {
  const dimensions = useWindowDimensions();
  return (
    <Popup open={open} onClose={handleClose} >
      <Box
        sx={style}
        width={dimensions.width > 500 ? width : "95%"}
        height={dimensions.width > 1024 ? "450px" : "80%"}
        overflow={"auto"}
      >
        <Box>{children}</Box>
      </Box>
    </Popup>
  );
}
