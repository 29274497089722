import { combineReducers } from "redux";
import adminReducer from "./admin/admin.reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import searchReducer from "./search/search.reducer";
import propertyReducer from "./property/property.reducer";
import adminPropertyReducer from "./adminProperty/adminProperty.reducer";
import fileReducer from "./file/file.reducer";
import userReducer from "./user/user.reducer";
import tokenReducer from "./token/token.reducer";
import userPropertyReducer from "./userProperty/userProperty.reducer";
import persistantDataReducer from "./persistantData/persistantData.reducer";
import reportReducer from "./report/report.reducer";
import institutionReducer from "./institution/institution.reducer";
import { RESET_STORE } from "./actionTypes";
import categoryReducer from "./category/category.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["token", "persistantData"],
};

const appReducer = combineReducers({
  admin: adminReducer,
  search: searchReducer,
  property: propertyReducer,
  adminProperty: adminPropertyReducer,
  file: fileReducer,
  user: userReducer,
  token: tokenReducer,
  userProperty: userPropertyReducer,
  persistantData: persistantDataReducer,
  report: reportReducer,
  institution: institutionReducer,
  category: categoryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
