import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertyUploadedList } from "../../redux/userProperty/userProperty.actions";

export function PropertyUploadedListApiCall(pageNo, pageSize) {
  return (dispatch) => {
    dispatch(propertyUploadedList(null, true));

    const url = `${URL_CONSTANTS.PROPERTY_UPLOADED_LIST}page_number=${pageNo}&page_size=${pageSize}`;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertyUploadedList(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertyUploadedList(null, false));
      });
  };
}