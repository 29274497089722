import React from "react";
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import {
  INSTAGRAM_ICON,
  FACEBOOK_ICON,
  LINKEDIN_ICON,
  YOUTUBE_ICON,
  WHATSAPP_ICON
} from '../../constants/iconConstants';
import './Footer.styles.scss';

export default function Footer() {
  const { addToast } = useToasts();

  return (
    <footer className="footer">
      <div className="footer-primary">
        <div className="container">
          {/* <img src={Logo} alt="logo" className="logo" /> */}
          <div className="row d-flex justify-content-between">
            <div className="col-sm-12 col-md-12 col-lg-3 footer-widget footer-widget-about">
              <div className="footer-widget__content">
                <p>
                  SARFAESIAUCTIONS.COM is India’s  online composite property web-portal for auction, buy-sale, To-let & 
                  SARFAESI NPA Resolutions through software application for legalized activities as per guidelines laid down by RBI & 
                  other statutory bodies. We provide a digital platform-cum-market place with globally accessible to all genuine buyers 
                  of property of any nature.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-6 footer-widget footer-widget-nav footer-link-nav px-3">
              <h6 className="footer-widget__title">Links</h6>
              <div className="footer-widget__content">
                <div className="row">
                  <div className="col-xl-2 col-lg-3 col-md-7 col-sm-6 col-5">
                    <ul className="list-unstyled mr-4">
                      <li><a href="/">Home</a></li>
                      <li><Link to="/about-us">About Us</Link></li>
                      <li><Link to="/disclaimer">Disclaimer</Link></li>
                      <li><Link to="/downloads">Downloads</Link></li>
                      <li><Link to="/faq">FAQ</Link></li>
                      <li><Link to="/architects">Architects</Link></li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-6">
                    <ul className="list-unstyled mr-4">
                      <li>
                        <Link to='/our-services'>
                          Our Services
                        </Link>
                      </li>
                      <li><Link to="/property-valuers">Property Valuers</Link></li>
                      <li><a href="/doc/channel-partner.docx" target="_blank" rel="noreferrer">Channel Partners</a></li>
                      <li><a href="#">Financial Partners</a></li>
                      <li><Link to="/knowledge-hub">Knowledge Hub</Link></li>
                      <li><Link to='/contact-us'>Contact Us</Link></li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-7 col-sm-12 col-5">
                    <ul className="list-unstyled mr-4">
                      <li>
                        <Link to='/privacy-policy'>
                          Privacy Policy
                        </Link>
                      </li>
                      <li><Link to="/property-lawyers">Property Lawyers</Link></li>
                      <li><Link to="/vastu-consultants">Vastu Consultants</Link></li>
                      <li><Link to="/business-enquiries">Business Enquiries</Link></li>
                      <li><Link to="/business-associates">Business Associates</Link></li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-6">
                    <ul className="list-unstyled">
                      <li><a href="#">Allied Business Associates</a></li>
                      <li>
                        <Link to="buy-sale">
                          Other Properties Buy-Sale
                        </Link>
                      </li>
                      <li><Link to="/property-dealers">Property Dealers/Brokers</Link></li>
                      <li>
                        <Link
                          to={{
                            pathname: "/bank-auction-properties",
                            state: {
                              title: "Bank Auction Properties"
                            }
                          }}
                        >
                          Bank Auction Properties
                        </Link>
                      </li>
                      <li onClick={() => addToast('This option available for bank officials only', { appearance: 'error', autoDismiss: true })}>
                        <a href="#">
                          Sarfaesi NPA Solutions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3 footer-widget footer-widget-nav">
              <h6 className="footer-widget__title">Contact Us</h6>
              <div className="footer-widget__content">
                <nav>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#" className='d-flex'>
                        <PhoneIcon style={{ fill: "#0075ff" }} className="mr-2" />
                        <div className='d-flex flex-column'>
                          <span>9990051189</span>
                          <span className='mt-2'>9318343435</span>
                          <span className='mt-2'>8218291267</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@sarfaesiauctions.com">
                        <EmailIcon style={{ fill: "#0075ff" }} className="mr-2" /> info@sarfaesiauctions.com
                      </a>
                    </li>
                    <p>Follow us on</p>
                    <ul className="social-icons d-flex list-unstyled mb-0 mr-30">
                      <li>
                        <a href="https://www.facebook.com/Sarfaesiauctions/">
                          <img src={FACEBOOK_ICON.default} width="22" height="22" style={{ borderRadius: 3 }} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/sarfaesi-auctions">
                          <img src={LINKEDIN_ICON.default} width="22" height="22" style={{ borderRadius: 3 }} />
                        </a>
                      </li>
                      <li>
                        <a href="https://youtube.com/channel/UCz_12M3Z8pIlM-KxZYSen4A">
                          <img src={YOUTUBE_ICON.default} width="22" height="22" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/invites/contact/?i=xdmppn6mbkzo&utm_content=chk39hw">
                          <img src={INSTAGRAM_ICON.default} width="22" height="22" />
                        </a>
                      </li>
                      <li>
                        <a href="https://wa.me/message/DF5GJRRJF3WFM1">
                          <img src={WHATSAPP_ICON.default} width="25" height="25" />
                        </a>
                      </li>
                    </ul>
                  </ul>
                </nav>
              </div>
              <div className="foooter-ads blink">
                <Link to="/advertise-with-us">Advertise with Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-secondary">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-6 col-md-5 col-lg-6">
              <div className="footer__copyrights pl-5">
                <span className="copyright-credit"
                >&copy; 2021 Sahni Corporation. All Rights Reserved
                </span>
              </div>
            </div>
            <div className="col-sm-6 text-right col-md-5 col-lg-6">
              <div className="footer__copyrights pr-5">
                {/* <span className="development-credit">
                  Designed and Developed by Haeres Technologies
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}