import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adminsByRole } from "../../redux/admin/admin.actions";

export function GetAdminsByRoleApiCall(roleId) {
  return (dispatch) => {
    dispatch(adminsByRole(null, true));

    const url = `${URL_CONSTANTS.GET_ADMINS_BY_ROLE}${roleId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(adminsByRole(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(adminsByRole(null, false));
      });
  };
}
