import { nanoid } from 'nanoid';
import { PROFILE } from '../../constants/iconConstants';

const vastuConsultantsData = [
  {
    id: nanoid(),
    city: 'JAIPUR',
    image: PROFILE,
    data: [
      { id: nanoid(), label: 'Name', value: '' },
      { id: nanoid(), label: 'Address for contact', value: '' },
      { id: nanoid(), label: 'Mobile No.', value: '' },
      { id: nanoid(), label: 'Email id', value: '' },
      { id: nanoid(), label: 'Brief Details', value: '' }
    ]
  },
  {
    id: nanoid(),
    city: 'DELHI',
    image: PROFILE,
    data: [
      { id: nanoid(), label: 'Name', value: '' },
      { id: nanoid(), label: 'Address for contact', value: '' },
      { id: nanoid(), label: 'Mobile No.', value: '' },
      { id: nanoid(), label: 'Email id', value: '' },
      { id: nanoid(), label: 'Brief Details', value: '' }
    ]
  }
];

export { vastuConsultantsData };