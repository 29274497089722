import './FAQ.styles.scss';

const FAQ = () => (
    <div className="faq-container container">
        <h1>FAQ</h1>
        <h3><span className="h3-subhead">FOR BUYER</span></h3>
        <h4>What is E-auction?</h4>
        <p>
            It is a non-conventional method of auction conducted electronically through E-Auction sale software. Banks
            or Auctioneers are used to sell their properties through online using internet & software technology. In
            case of property, Banks or DRTs forward auction is concered where the buyer bids on the seller's product and
            the prices of the item are increased by a fixed incremental amount during the auction.
        </p>
        <h4>Who is Bidder?</h4>
        <p>
            Any executive of a company/government dept. or an individual who is willing to participate in bid for online
            Auction is called as Bidder. By participating in an Auction, the Bidder commits himself to buy the property
            during auction process at prices submitted by him.
        </p>
        <h4>Whether confidentiality is maintained for the Auction Data?</h4>
        <p>
            Every Auction is a highly confidential event conducted between the Auctioneer/Bank and a set of qualified
            Bidders on the Bank/DRT Auctions platform. No outsider can view any aspect of an Auction event without
            proper authentication by Auctioneer. Qualified Bidder can only view details of his own bids being made
            online.All data like property specifications, pricing, Bidders' information and bid data are all highly
            confidential and available to respective Auctioneers only.
        </p>
        <h4>How to get a password and login id, if it is forgotten?</h4>
        <p>
            At the auction portal thee is forgot password option available, just click on "Forgot Password" link
            provided on Login page. You will be required to provide your registered email id or Mobile number and OTP
            will be sent to Bidder registered Mobile number. After entering the OTP Bidder can generate their new
            Password.
        </p>
        <h4>What is the minimum period of bidding a property?</h4>
        <p>
            If it is a Bank/Institution property under SARFAESI Act, the statutory notice period plus 15 days from
            closure of auction. If it is individual’s property ,10 days from closure of auction.
        </p>
        <h4>Whether Title due-diligence is required?</h4>
        <p>
            Usually, if a bank auctions a property, the title will be always proper. However, an auction notice
            typically mentions a clause where the bank absolves itself of any surprises it may not be aware of. This
            could be an unexpected third-party claim or any dues of the owner.There may be disputes related to the
            property and if you land into trouble, the bank does not take any responsibility for the same. “The title
            and mortgage documents must be thoroughly checked in, as any defect in them would be creating obstacles in
            claiming the ownership. It is advised to make enquiries regarding the disturbances, the claims and the
            rights of the previous owner or any other entity, and any finance liabilities before bidding. “Buyers should
            also avail the services of a lawyer to get the due diligence done.
        </p>
        <h4>Is it necessary to cofirm possession of the property ?</h4>
        <p>
            In many cases, while auctioning an immovable property such as a plot, house or apartment, banks have only
            legal documents or say symbolic possession of the property. The bank doesn’t evict the occupants and it
            becomes the responsibility of the new buyers to evict the tenants and claim the possession. It can be very
            difficult to get property vacated from its current occupants. It is better to ensure that there are no
            pre-occupied properties before bidding. “In the case of independent plots, it is crucial to take physical
            possession and not rely completely on symbolic possession issued by banks,.Since a bank auctioning a
            property is not considered a supplier of goods or services, you cannot take the bank to the court, if things
            go wrong. In contrast, you can file a case against a property developer if that’s where you buy a new
            property.
        </p>
        <h4>What are the risks?</h4>
        <p>
            Banks use legal terms “as is where is" and “whatever there is" when auctioning properties. These terms mean
            that the bank is selling the property based on its current physical and legal conditions, including any
            encumbrances.It means the responsibility of dealing with any problems that arise later lies with the buyer
            once the property is auctioned.
        </p>
        <h4>Is it really auctioned at cheaper price?</h4>
        <p>
            The terms and conditions for the properties that Banks are auctioned convey the risks that buyers need to be
            aware of. Besides selling the property on “as is where is basis", the banks have also states that the
            payment of all statutory and non-statutory dues, taxes, charges, fees and so on will be the sole
            responsibility of the bidder. So if the previous owner has not paid dues on the property, the winning bidder
            will need to clear them.Such dues could end up neutralizing the benefit of lower prices. Some of these
            charges also attract interest penalty if they are not paid on time. Besides, you may also need to spend
            extra amount on repairs & maintenance of the property.
        </p>
        <h4>What is payment of EMD, remaining amount etc. pay in period for auction property?</h4>
        <p>
            Before participating in the auction, banks usually ask for 10% of the reserve price as earnest or deposit
            money (EMD).The money is reimbursed if you lose the bid. For winners, the payment deadlines are tight.
            According to SBI’s terms and conditions, the winner will need to deposit 25% of the sale price, after
            adjusting for the earnest money, by the next working day of winning an auction. The balance 75% is to be
            paid within 15 days of winning the auction. If you fail to meet any of the deadlines or give up the flat,
            you will forfeit the deposit.Since home loans, typically, take a bit of time or are difficult to materialize
            in such cases, you will need to arrange adequate money within the bank deadlines.Banks stick to a strict
            payment structure as they are in a hurry to recover the loan amount, and want only serious buyers to
            participate.
        </p>
        <h4>What is to do with tenants, if an auctioned property is rented out to tenants?</h4>
        <p>
            Normally, a tenant has no information about that property has been auctioned. Auction usually means an end
            to the lease agreement as well. This does not mean that the tenant needs to vacate the premises with
            immediate effect. The new owner is still required to furnish a notice to the tenants, intimating about the
            termination of the lease and giving them adequate time (up to 90 days) to move out.
        </p>
        <h4>What are Bank Auction Properties?</h4>
        <p>
            The properties repossessed by banks are regularly sold off at rates 20-30 per cent lower than the prevailing
            market rate through auctions. A bank auction can be uplifting, but a very tedious way of grabbing a sale.
            Under the SARFAESI Act (Securitisation and Reconstruction of Financial Assets and Securities Interest
            Enforcement Act, 2002), banks are permitted to sell off repossessed or stressed properties to recover their
            dues or losses.
        </p>
        <h4>When the properties seized by Bank?</h4>
        <p>
            These properties are seized by banks after the lenders have made several defaults in making EMI payments.
        </p>
        <h4>Bank auction properties are auctioned on less than market value?</h4>
        <p>
            They are also sold at an attractive prices, as banks sell foreclosed properties solely to recover the
            remaining principal and interest amount.
        </p>
        <h4>Whate are key documents to be checked in?</h4>
        <p>
            While purchasing residential bank auctioned properties, the interested bidder should engage in a legal title
            search of the property to ensure the validity of key documents such as the original sales deed and
            non-encumbrance certificate, for the avoidance of disputes at later stage. Banks can auction both
            under-construction and ready-to-move-in properties, including flats, plots, and individual houses as well.
        </p>
        <h4>A physical inspection of property under auctionis necessary?</h4>
        <p>
            A physical inspection of the auctioned property is advisable. Banks usually provide a date for inspection,
            and on that date, the buyer could visit the site to inspect the property physically. In the case of
            e-auctions, the same can be done by applying to the bank official concerned. Purchasing properties where the
            bank has physical ownership is fine. If it's just a symbolic possession, you may have to wait a little
            longer for possession. Make sure you get physical property ownership as soon as you complete the payment to
            prevent any legal disputes.
        </p>
        <h4>How can I purchase property through MSTC portal?</h4>
        <p>
            You have to do registration at MSTC portal, authorization of documents from MSTC or other auctioneer site.
            attach interested properties, pay pre-Bid EMD required against property, participate in auction (bidding).
            Bid reject/accept by bank decision email to H1 bidder will be sent.
            <a href="https://www.mstcecommerce.com/auctionhome/ibapi/faq.html">Upload KYC documents- To click on the
                link</a>
        </p>
        <h4>Can I participate in the auction with spouse name or any other family member?</h4>
        <p>
            Yes, you may participate with family member or any other person. Please register under group of individuals
            after OTP validation during registration. Group of two members and more will authorize a lead member of
            group. The lead member will register at the portal and add name of other member in his registration profile.
            All the group members shall authorize lead member in format available on website and upload on the portal.
        </p>
        <h4>Can I do multiple registration in MSTC portal?</h4>
        <p>
            Yes. Registration can be done using unique e-mail ID and mobile number.
        </p>
        <h4>Is there any fee for registration?</h4>
        <p>
            No, at present there is no fee for such registration.
        </p>
        <h4>From where I can get details of property?</h4>
        <p>
            Property details are available at website <a href="https://www.ibapi.in"> ibapi.in</a> or eauction portal as mentioned in Eauction
            notice of the bank. The details of property fetched to MSTC website one day prior to schedule date of
            auction. Bidder may add the property ID in 'Interested property' option through their login.
        </p>
        <h4>How to make payment of EMD?</h4>
        <p>
            You have to generate a fresh NEFT challan for each transaction and the amount should be paid via NEFT only.
            Please do not reuse any Challan or use a challan which was generated 72 hours ago.
        </p>
        <h4>Can I withdraw the property becase of Court order or any other reason?</h4>
        <p>
            If the property has already shifted to <a href="https://www.mstcecommerce.com">mstcecommerce.com</a> portal and the auction is yet to start then
            please logon to <a href="https://www.mstcecommerce.com/auctionhome/ibapi">mstcecommerce.com/auctionhome/ibapi</a> as AO using the AO user id and password provided by
            IBAPI. On successful login go to 'Withdraw Property', choose the required property and withdraw it using
            your DSC. Only concerned AO of the respective property can do it. In case the property is still at
            <a href="https://www.ibapi.in"> ibapi.in</a> portal please login to <a href="https://www.ibapi.in">ibapi.in</a> and do the needful.
        </p>
        <h4>After conclusion of bidding, how to deposit remaining amount?</h4>
        <p>
            After conclusion of the auction you have to login at MSTC portal. Approve the bid received on the property.
            After this the 10% EMD amount deposited by bidder will get transferred to the account associated with the
            property and the bidder will get a sale confirmation mail from system in which the instruction regarding
            payment of the balance amount will be detailed. The bidder is supposed to follow the instruction by
            depositing the balance amount in a timely fashion in the account associated with the property.
        </p>
        <h4>Is there any timeline for rejection or approval of the property under auction?</h4>
        <p>
            There is no time-line to approve or reject property. However, feed your decision at the earliest. System
            locks pre-bid amount of all the bidders and release after decision through AO login at MSTC portal. In case
            of approval, system will retail pre-bid amount of H1 bidder and forwarded to bank account associated with
            property and returned to all non H1 bidder in their EMD ledger. In case of rejection, returned to all
            bidders.
        </p>
        <h4>Any TDS deduction if applicable on sale of property?</h4>
        <p>
            As per provision of 194O of IT act, TDS amount of 1% of sale value will be deducted by auctioneer/bank and
            deposited with Income Tax Dept. against PAN of Seller. 25% discount is given till 31st March, 2021. Hence
            effective TDS rate is 0.75% of sale value. 0.75% of total bid value is deducted by MSTC or Auctioneer and
            rest amount will forward to BANK. Differential amount is TDS amount u/s 194O of IT act.
        </p>
        <h4>What clauses are to be checked in before buying a property through auction?</h4>
        <p>
            Ensure that you receive from the bank two essential documents, one is the certificate of indemnity, and the
            other is a protective penalty clause against the owner. The indemnity clause would help shield you from the
            owner's possibility of potential lawsuits as you are protected by the penalty clause against the seller
            opting out of the deal. A buyer must check the property's title and mortgage documents, litigation fees,
            statutory dues, unpaid electricity bills, municipal dues, or any other attached taxes. If an
        </p>
        <h4>What is a Tripartite Agreement?</h4>
        <p>
            If you have to buy a property that has already been constructed and ready to move-in, any agreement will
            normally include only two parties – the buyer and the seller. This agreement is a critical element of
            seamlessly securing a bridge loan and thus, the tripartite agreement in India is a legal contract or
            agreement that is entered into between buyers, banks, and sellers. It is mostly needed when a buyer wants to
            apply for a home loan to buy a property that is in an under-construction condition or built in at the time
            of loan processing. In some cases, the buyer may wish to buy a property that is under construction and to
            get home loan for the purchase, may opt to have such agreement. In such a case, the agreement shall be
            between all the three parties ( the buyer, the seller, and the bank), this agreement is known as a
            tripartite agreement.
        </p>
    </div>
);
export default FAQ;