import { createSelector } from "reselect";

const selectUserProperty = (state) => state.userProperty;

export const userPropertyAdding = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.userPropertyAdding
);

export const favouriteList = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.favouriteList
);

export const propertyUploadedList = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.propertyUploadedList
);

export const userPropertyUpdate = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.userPropertyUpdate
);

export const fileUploadUser = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.fileUploadUser
);

export const fileRemoveUser = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.fileRemoveUser
);

export const propertyRemoveUser = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.propertyRemoveUser
);

export const propertyFavouriteUser = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.propertyFavouriteUser
);

export const userPropertyEditDetails = createSelector(
  [selectUserProperty],
  (userProperty) => userProperty.userPropertyEditDetails
);