import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimension from '../../utils/CustomHooks/useWindowDimension';
import { propertyValuersData } from './PropertyValuers.data';
import { RUPEE, VALUERS } from '../../constants/iconConstants';
import './PropertyValuers.styles.scss';

const PropertyValuers = () => {
  const { height, width } = useWindowDimension();
  const useStyles = makeStyles({
    root: {
      marginTop: 35
    },
    head: {
      backgroundColor: 'black'
    },
    title: {
      fontWeight: 'bold',
      fontSize: width > 576 ? '16px' : '14px',
      color: 'white',
      textAlign: 'center'
    },
    last: {
      fontWeight: 'bold',
      fontSize: width > 576 ? '16px' : '14px',
      textAlign: 'center'
    },
    cell: {
      fontSize: width > 576 ? '16px' : '14px',
      textAlign: 'center'
    }
  });
  const classes = useStyles();

  return (
    <div className="property-valuers-container">
      <img
        src={VALUERS.default}
        className="valuers-image"
        alt=""
        width="130px"
        height="100px"
      />
      <h1>BANK APPROVED PROPERTY VALUERS</h1>
      <p>
        The value of land in India, especially in urban regions, has grown exponentially over the past two decades, on the back of the 
        terms like ‘land scarcity’ and ‘space crunch’. Such has been the demand for land that its values have continued to show 
        appreciation, in spite of a multi-year slowdown in the real estate market in India, which has been aggravated by the 
        Coronavirus pandemic and its fallout on human lives and the economy. In fact, the impact on value appreciation on new 
        property has also been negligible as reflected in numbers below.
      </p>
      <h3>Annual property price growth: City-wise break-up</h3>
      <div className="row table-wrap">
        <div className="col-xl-8">
          <TableContainer component={Paper} className={classes.root}>
            <Table>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell className={classes.title}>City</TableCell>
                  <TableCell className={classes.title}>Average price as on June 30, 2021 (in Rs per sq ft)</TableCell>
                  <TableCell className={classes.title}>Annual growth in %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {propertyValuersData.map((dat) => (
                  <TableRow key={dat.id}>
                    <TableCell className={dat.city === "National average" ? classes.last : classes.cell} component="th" scope="row">
                      {dat.city}
                    </TableCell>
                    <TableCell className={dat.city === "National average" ? classes.last : classes.cell}>{dat.avg}</TableCell>
                    <TableCell className={dat.city === "National average" ? classes.last : classes.cell}>{dat.growth}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <span>Source: Real Insight: Q2 2021</span>
        </div>
      </div>
      <p>
        Real estate or property valuation is a process that determines the economic value/market value of a real estate investment. The capitalization 
        rate is a key metric for valuing an income-producing property. Net operating income (NOI) measures an income-producing property's profitability 
        before adding costs for financing and taxes. The two key real estate valuation methods include discounting future NOI and the gross income 
        multiplier model. On the downside, because the property markets are less liquid and transparent than the stock market, it can be difficult 
        to obtain the necessary information.
      </p>
      <h3>Important points to remember during land valuation:</h3>
      <h5>Age of the property:</h5>
      <p>
        Age is another factor that impacts the worth of a property. An old construction would cost much less than a new construction in the same location.
      </p>
      <h5>Builder brand:</h5>
      <p>
        In case of a building, the brand of the developer will also have an impact on the pricing. A project by a well-known developer 
        will, for instance, cost more than a project by comparatively less-known developer.
      </p>
      <h5>Quality of Construction:</h5>
      <p>
        The quality of construction of the building standing on the land is very crucial aspect.. In the same locality, a building will 
        cost much more if premium materials have been used to build it, as compared to a building constructed using average quality 
        materials, even if there is no age difference between the two buildings. Do note that you have to hire technical experts to 
        gauge the construction quality, as you may lack the expertise to do so on your own.
      </p>
      <h5>Location of the property:</h5>
      <p>
        Location has  the biggest role, in determining the worth of land. The scale of development in a locality, decides its worth or the lack thereof. 
        This is why a large land parcel in a developing locality would fetch much less money than a tiny piece of land in a well-developed area. 
      </p>
      <h3>METHODS TO CALCULATE PROPERTY VALUE IN INDIA</h3>
      <ul>
        <li>Comparative Method</li>
        <li>Development Method</li>
        <li>Land & Building Method</li>
        <li>Belting Method</li>
        <li>Guidance Value Method</li>
      </ul>
      <h3>OTHER FACTORS THAT DECIDES A PROPERTY VALUE</h3>
      <ul>
        <li>Location</li>
        <li>Shape</li>
        <li>Size</li>
        <li>Level</li>
        <li>Frontage</li>
        <li>Legal Issues</li>
        <li>Parking Space</li>
        <li>Vastu Compliance</li>
        <li>Facing</li>
      </ul>
      <h3>Govt/Bank Approved Valuers</h3>
      <form noValidate autoComplete="off" className="w-100">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <TextField
              fullWidth
              required
              id="standard-basic"
              label="Name of Property Valuer" 
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <TextField
              fullWidth
              id="standard-basic"
              label="Place" 
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <TextField
              fullWidth
              id="standard-basic"
              label="Empaneled Banks/NBFC/HFCs" 
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <TextField
              fullWidth
              required
              id="standard-basic"
              label="Mobile Nos." 
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <TextField
              fullWidth
              required
              id="standard-basic"
              label="Email id" 
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <TextField
              fullWidth
              id="standard-basic"
              label="Website" 
            />
          </div>
          <div className="col-12">
            <TextField
              fullWidth
              id="standard-basic"
              multiline
              rows={4}
              label="Brief details (Not more than 150 words)" 
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PropertyValuers;