import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { getPropertySummary } from '../../redux/property/property.selector';
import './PropertyStats.styles.scss';


export default function PropertyStats() {
  const propertySummary = useSelector(getPropertySummary);

  return (
    <div class="stats-wrapper d-flex text-center">
      <div className="stats-item">
        {
          propertySummary.data?.bank_auction_count > 0 ? (
            <CountUp start={0} end={propertySummary.data?.bank_auction_count ?? 0} delay={1} redraw={true} separator="," suffix="+" />
          ) : (
            <p>
              <MinimizeIcon />
            </p>
          )
        }
        <Link
          to={{
            pathname: "bank-auction-properties",
            state: {
              title: "Bank Auction Properties"
            }
          }}
          style={{ textDecoration: "none" }}
        >
          <p>Bank Auction Properties</p>
        </Link>
      </div>
      <div className="stats-item">
        {
          propertySummary.data?.live_auction_count > 0 ? (
            <CountUp start={0} end={propertySummary.data?.live_auction_count ?? 0} delay={1} redraw={true} separator="," suffix="+" />
          ) : (
            <p>
              <MinimizeIcon />
            </p>
          )
        }
        <Link
          to={{
            pathname: "bank-auction-properties",
            state: {
              title: "Live Auction Properties"
            }
          }}
          style={{ textDecoration: "none" }}
        >
          <p>Live Auction Properties</p>
        </Link>
      </div>
      <div className="stats-item">
        {
          propertySummary.data?.upcoming_auction_count > 0 ? (
            <CountUp start={0} end={propertySummary.data?.upcoming_auction_count ?? 0} delay={1} redraw={true} separator="," suffix="+" />
          ) : (
            <p>
              <MinimizeIcon />
            </p>
          )
        }
        <Link
          to={{
            pathname: "bank-auction-properties",
            state: {
              title: "Upcoming Auction Properties"
            }
          }}
          style={{ textDecoration: "none" }}
        >
          <p>Upcoming Auction Properties</p>
        </Link>
      </div>
      <div className="stats-item">
        {
          propertySummary.data?.agent_prop_count > 0 ? (
            <CountUp start={0} end={propertySummary.data?.agent_prop_count ?? 0} delay={1} redraw={true} separator="," suffix="+" />
          ) : (
            <p>
              <MinimizeIcon />
            </p>
          )
        }
        <Link
          to={{
            pathname: "buy-sale"
          }}
          style={{ textDecoration: "none" }}
        >
          <p>Owner Properties</p>
        </Link>
      </div>
      {/* <div className="stats-item">
        <CountUp start={0} end={3500} delay={1} redraw={true} separator="," suffix="+" />
        <p>Agents Properties</p>
      </div> */}
    </div>
  );
}