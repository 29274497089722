import SearchActionTypes from "./search.types";

const initialSearchState = {
  topTenStates: {
    data: null,
    loader: false
  },
  topTenCities: {
    data: null,
    loader: false
  },
  topLocalities: {
    data: null,
    loader: false
  },
  propertySearchList: {
    data: null,
    loader: false
  }
};

const searchReducer = (state = initialSearchState, action) => {
  switch (action.type) {
    case SearchActionTypes.TOP_TEN_STATES:
      return {
        ...state,
        topTenStates: {
          ...state.topTenStates,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case SearchActionTypes.TOP_TEN_CITIES:
      return {
        ...state,
        topTenCities: {
          ...state.topTenCities,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case SearchActionTypes.TOP_LOCALITIES:
      return {
        ...state,
        topLocalities: {
          ...state.topLocalities,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case SearchActionTypes.PROPERTY_SEARCH_LIST:
      return {
        ...state,
        propertySearchList: {
          ...state.propertySearchList,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default searchReducer;
