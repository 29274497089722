import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertyUnApproval } from "../../redux/adminProperty/adminProperty.actions";

export function PropertyUnApprovalApiCall(propertyId, toastView, approvalSuccess) {
  return (dispatch) => {
    dispatch(propertyUnApproval(null, true));

    const url = `${URL_CONSTANTS.PROPERTY_UNAPPROVAL}${propertyId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Property Unapproval Success", 'success');
          dispatch(propertyUnApproval(res.data.data, false));
          approvalSuccess();
        }
      })
      .catch((error) => {
        dispatch(propertyUnApproval(null, false));
      });
  };
}