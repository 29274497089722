import UserPropertyActionTypes from "./userProperty.types";

const initialUserPropertyState = {
  userPropertyAdding: {
    data: null,
    loader: false
  },
  favouriteList: {
    data: null,
    loader: false
  },
  propertyUploadedList: {
    data: null,
    loader: false
  },
  userPropertyUpdate: {
    data: null,
    loader: false
  },
  fileUploadUser: {
    data: null,
    loader: false
  },
  fileRemoveUser: {
    data: null,
    loader: false
  },
  propertyRemoveUser: {
    data: null,
    loader: false
  },
  propertyFavouriteUser: {
    data: null,
    loader: false
  },
  userPropertyEditDetails: {
    data: null,
    loader: false
  }
};

const userPropertyReducer = (state = initialUserPropertyState, action) => {
  switch(action.type) {
    case UserPropertyActionTypes.USER_PROPERTY_ADDING:
      return {
        ...state,
        userPropertyAdding: {
          ...state.userPropertyAdding,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.FAVOURITE_LIST:
      return {
        ...state,
        favouriteList: {
          ...state.favouriteList,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.PROPERTY_UPLOADED_LIST:
      return {
        ...state,
        propertyUploadedList: {
          ...state.propertyUploadedList,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.USER_PROPERTY_UPDATE:
      return {
        ...state,
        userPropertyUpdate: {
          ...state.userPropertyUpdate,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.FILE_UPLOAD_USER:
      return {
        ...state,
        fileUploadUser: {
          ...state.fileUploadUser,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.FILE_REMOVE_USER:
      return {
        ...state,
        fileRemoveUser: {
          ...state.fileRemoveUser,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.PROPERTY_REMOVE_USER:
      return {
        ...state,
        propertyRemoveUser: {
          ...state.propertyRemoveUser,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.PROPERTY_FAVOURITE_USER:
      return {
        ...state,
        propertyFavoriteUser: {
          ...state.propertyFavouriteUser,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserPropertyActionTypes.USER_PROPERTY_EDIT_DETAILS:
      return {
        ...state,
        userPropertyEditDetails: {
          ...state.userPropertyEditDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default userPropertyReducer;