import CategoryActionTypes from "./category.types";

const initialCategoryState = {
  CategoryList: [],
};

const categoryReducer = (state = initialCategoryState, action) => {
  switch (action.type) {
    case CategoryActionTypes.GET_CATEGORY_BY_ID:
      return {
        ...state,
        CategoryList: action.payload, 
      };
    default:
      return state;
  }
};

export default categoryReducer;
