import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { otherSalesProperties } from "../../redux/property/property.actions";

export function OtherSalesPropertiesApiCall(pageNo, pageSize) {
  return (dispatch) => {
    dispatch(otherSalesProperties(null, true));

    const url = `${URL_CONSTANTS.OTHER_SALES_PROPERTIES}${pageNo}&page_size=${pageSize}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(otherSalesProperties(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(otherSalesProperties(null, false));
      });
  };
}
