import { useSelector } from "react-redux";
import {
  WALLET,
  LEFT_ARROW,
  PROPERTY,
  LOGOUT,
  SETTINGS,
  ACCOUNT_SETTINGS,
  HOME,
} from "../../constants/iconConstants";
import { adminLoginState } from "../../redux/persistantData/persistantData.selector";
import "./Sidebar.styles.scss";

const Sidebar = ({
  selectedTab,
  handleTabChange,
  expanded,
  setExpanded,
  setShowLogoutModal,
}) => {
  const adminLogin = useSelector(adminLoginState);

  return (
    <div
      className={`sidebar-container ${
        expanded ? "expanded-sidebar" : "shrinked-sidebar"
      }`}
    >
      <div>
        <div className="top-tab">
          <img
            src={WALLET.default}
            alt=""
            className="img-icon"
            onClick={() => setExpanded(true)}
          />
          {expanded && (
            <>
              <div className="top-tab-text-wrap">
                <p className="auction-heading">Auction Property</p>
                <p className="super-admin">
                  {adminLogin.data?.user_name} ({adminLogin.data?.user_role})
                </p>
              </div>
              <img
                src={LEFT_ARROW.default}
                alt=""
                className="img-icon"
                onClick={() => setExpanded(false)}
              />
            </>
          )}
        </div>
        <div className="middle-tabs">
          {adminLogin?.data?.privileges?.external_user !== true && (
            <>
              <div
                className={`each-tab ${
                  selectedTab === "dashboard" && "tab-selection"
                }`}
                onClick={() => handleTabChange("dashboard")}
              >
                <img src={HOME.default} alt="" className="img-icon" />
                {expanded && <p className="middle-tab-text">Dashboard</p>}
              </div>
              {adminLogin?.data?.privileges?.is_OH === 1 && (
                <div
                  className={`each-tab ${
                    selectedTab === "advertisement" && "tab-selection"
                  }`}
                  onClick={() => handleTabChange("advertisement")}
                >
                  <img src={PROPERTY.default} alt="" className="img-icon" />
                  {expanded && <p className="middle-tab-text">Advertisement</p>}
                </div>
              )}
            </>
          )}
          {adminLogin?.data?.privileges?.is_OH === 1 && (
                <div
                className={`each-tab ${
                  selectedTab === "property" && "tab-selection"
                }`}
                onClick={() => handleTabChange("property")}
              >
                <img src={PROPERTY.default} alt="" className="img-icon" />
                {expanded && <p className="middle-tab-text">Properties</p>}
              </div>
              )}
          
          <div
            className={`each-tab ${
              selectedTab === "bankAuctionProperty" && "tab-selection"
            }`}
            onClick={() => handleTabChange("bankAuctionProperty")}
          >
            <img src={PROPERTY.default} alt="" className="img-icon" />
            {expanded && (
              <p className="middle-tab-text">Bank Auction Properties</p>
            )}
          </div>

          {/* Property upload */}
          <div
            className={`each-tab ${
              selectedTab === "property-upload" && "tab-selection"
            }`}
            onClick={() => handleTabChange("property-upload")}
          >
            <img src={PROPERTY.default} alt="" className="img-icon" />
            {expanded && <p className="middle-tab-text">Property Upload</p>}
          </div>

          {/* Configuration */}
          {adminLogin?.data?.privileges?.external_user !== true && (
            <div
              className={`each-tab ${
                selectedTab === "configuration" && "tab-selection"
              }`}
              onClick={() => handleTabChange("configuration")}
            >
              <img src={SETTINGS.default} alt="" className="img-icon" />
              {expanded && <p className="middle-tab-text">Configuration</p>}
            </div>
          )}

          {adminLogin?.data?.privileges?.external_user !== true && (
            <div
              className={`each-tab ${
                selectedTab === "userReport" && "tab-selection"
              }`}
              onClick={() => handleTabChange("userReport")}
            >
              <img src={SETTINGS.default} alt="" className="img-icon" />
              {expanded && <p className="middle-tab-text">User Report</p>}
            </div>
          )}
        </div>
      </div>
      <div className="bottom-tabs">
        <div
          className={`each-tab ${
            selectedTab === "settings" && "tab-selection"
          }`}
          onClick={() => handleTabChange("settings")}
        >
          <img src={ACCOUNT_SETTINGS.default} alt="" className="img-icon" />
          {expanded && <p className="middle-tab-text">Account Settings</p>}
        </div>
        <div className="each-tab" onClick={() => setShowLogoutModal(true)}>
          <img src={LOGOUT.default} alt="" className="img-icon" />
          {expanded && <p className="middle-tab-text">Logout</p>}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
