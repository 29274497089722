import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";

export function DownloadPropertyTemplateApiCall(templateType, setLoader) {
    const url = `${URL_CONSTANTS.DOWNLOAD_PROPERTY_TEMPLATE}/${templateType}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.status === 200) {
          const base64String=`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`;
          let a = document.createElement('a');
          a.href = base64String;
          a.download = `${templateType}_template.xlsx`;
          a.click();
        }
        setLoader(null);
      })
      .catch((error) => {
        setLoader(null);
      });
}