import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { VASTU } from '../../constants/iconConstants';
import { vastuConsultantsData } from './VastuConsultants.data';
import './VastuConsultants.styles.scss';

const useStyles = makeStyles({
  root: {
    marginTop: 40,
    marginBottom: 50
  }
});

const VastuConsultants = () => {
  const classes = useStyles();

  return (
    <div className="vastu-container container">
      <img
        src={VASTU.default}
        className="vastu-image"
        alt=""
        height="130px"
      />
      <p>
        Vastu shastra is a traditional Indian system of architecture originating in India. Texts from the Indian subcontinent describe principles of design, 
        layout, measurements, ground preparation, space arrangement, and spatial geometry. Vastu Shastras incorporate traditional Hindu and Buddhist beliefs. 
        Vastu shastra is a traditional Indian system of architecture originating in India. Texts from the Indian subcontinent describe principles of design, 
        layout, measurements, ground preparation, space arrangement, and spatial geometry. Vastu Shastras incorporate traditional Hindu and Buddhist beliefs. 
        Vaastu is a science of structures or the creation of harmonious energy field in a structure is called Vaastu. It is an art of placing through which a 
        balance between negative & positive energies in the structure is maintained. All with structure building it also includes soil inspection, roads curing, 
        facing of the plots, water, surrounding environment, directions and much more.
      </p>
      <div className="row">
        <div className="col-xl-10">
          <h3>Vastu Consultants in your Location</h3>
          {vastuConsultantsData.map(item => (
            <div key={item.id}>
              <h5>{item?.city}</h5>
              <TableContainer component={Paper} className={classes.root}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <img
                          src={item.image.default}
                          alt=""
                          height="100px"
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    {item.data.map(dat => (
                      <TableRow key={dat.id}>
                        <TableCell component="th" scope="row">
                          {dat.label}
                        </TableCell>
                        <TableCell>{dat.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VastuConsultants;