import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Typography } from "@mui/material";
import Modal from "../core/Model";
import { useState } from "react";
import SearchData from "./SearchData";
import SearchFilter from "../../components/SearchFilter/SearchFilter.component";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";
import useModalStore from "../Store/model";

const Filter = () => {
  const Dimensions = useWindowDimensions();
  const { open, handleOpen, handleClose } = useModalStore((state) => ({
    open: state.open,
    handleOpen: state.handleOpen,
    handleClose: state.handleClose,
  }));
  return (
    <>
      <Box width="100%" display={"flex"} justifyContent={"center"}>
        <Button
          variant="outlined"
          size="large"
          sx={{
            height: "60px",
            borderRadius: "16px",
            border: "1px solid #ccc",
            color: "#000",
            textAlign: "left",
            display: "flex",
            width: Dimensions.width > 450 ? "600px" : "320px",
            "& .MuiButton-endIcon": {
              width: "auto",
              float: "right",
              position: "relative",
              right: "0%",
            },
          }}
          endIcon={
            <SearchIcon
              sx={{
                position: "relative",
                right: 0,
                left: "auto",
                color: "#000 !important",
                fontSize: "30px",
              }}
              color="#000"
            />
          }
          onClick={handleOpen}
        >
          <Typography
            textAlign={"left"}
            variant="h6"
            width={"100%"}
            color={"#000"}
            textTransform={"capitalize"}
            fontSize={"18px"}
          >
            Search
          </Typography>
        </Button>
      </Box>
      {open && (
        <Modal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          width={"80%"}
        >
          <SearchData />
        </Modal>
      )}
    </>
  );
};
export default Filter;
