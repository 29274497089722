import InstitutionActionTypes from "./institution.types";

export const searchInstitutionAction = (data) => ({
  type: InstitutionActionTypes.SEARCH_INSTITUTION,
  payload: data,
});

export const addInstitutionAction = (data, loader) => ({
  type: InstitutionActionTypes.ADD_INSTITUTION,
  payload: {
    data,
    loader,
  },
});

export const editInstitutionAction = (data, loader) => ({
  type: InstitutionActionTypes.EDIT_INSTITUTION,
  payload: {
    data,
    loader,
  },
});

export const allInstitutionAction = (data) => ({
  type: InstitutionActionTypes.ALL_INSTITUTION,
  payload: data,
});

export const getAllInstitutionAction = (data) => ({
  type: InstitutionActionTypes.GET_ALL_INSTITUTION,
  payload: data,
});
