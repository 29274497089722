import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonIcon from "@material-ui/icons/Person";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Logo from "../../assets/img/logo.svg";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";
import { getUserToken } from "../../redux/token/token.selector";
import { userLoginState } from "../../redux/persistantData/persistantData.selector";
import { setUserToken } from "../../redux/token/token.actions";
import { userLogin } from "../../redux/persistantData/persistantData.actions";
import {
  favouriteList,
  propertyUploadedList,
} from "../../redux/userProperty/userProperty.actions";
import { propertyDetailsForUser } from "../../redux/user/user.actions";
import "./Navbar.styles.scss";
import { Box } from "@mui/material";

const Navbar = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const userToken = useSelector(getUserToken);
  const userDetails = useSelector(userLoginState);
  const toggler = useRef(null);
  const { addToast } = useToasts();
  const { height, width } = useWindowDimensions();
  const navbarMenus = [
    {
      id: 0,
      title: "Home",
      link: "/",
      isDropdown: false,
    },
    {
      id: 1,
      title: "About Us",
      link: "/about-us",
      isDropdown: false,
    },
    {
      id: 2,
      title: "Bank Auction Properties",
      link: "/bank-auction-properties",
      isDropdown: false,
    },
    {
      id: 3,
      title: "Properties Buy-Sale",
      link: "/buy-sale",
      isDropdown: false,
    },
    {
      id: 4,
      title: "SARFAESI NPA Solutions",
      link: "#",
      isDropdown: false,
      onClick: () =>
        addToast("This option available for bank officials only", {
          appearance: "error",
          autoDismiss: true,
        }),
    },
    {
      id: 5,
      title: "Contact Us",
      link: "/contact-us",
      isDropdown: false,
    },
    {
      id: 6,
      title: userToken ? "Log Out" : "Sign in",
      link: "/login",
      isDropdown: false,
    },
    {
      id: 7,
      title: "Post a Property",
      link: "/my-profile",
      isDropdown: false,
    },
  ];

  const handleLogout = () => {
    handleClose();
    handleNavClose();
    dispatch(userLogin(null, false));
    dispatch(setUserToken(null));
    dispatch(propertyDetailsForUser(null, false));
    dispatch(favouriteList(null, false));
    dispatch(propertyUploadedList(null, false));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavClose = () => {
    let element = document.getElementById("navbarSupportedContent");
    if (width < 992 && element.classList.contains("show")) {
      toggler.current.click();
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark stroke">
      <div className="container-fluid">
        <button
          ref={toggler}
          className="navbar-toggler collapsed button-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* <Box sx={{ display: "flex", alignContent: "center", height: "100%"  }} > */}
          <Link
            to="/"
            className=""
            style={{
              display: "flex",
              position: "absolute",
              top: "0px",
              height: "100%",
              left: "50px",
              display: "flex",
              alignItems:"center"
            }}
            onClick={handleNavClose}
          >
            <img src={Logo} alt="Logo"  style={{height:"50px"}}/>
          </Link>
        {/* </Box> */}

        {!location.pathname?.includes("/my-profile") &&
          !location.pathname?.includes("/login") &&
          !location.pathname?.includes("/signup") && (
              <Link
                className="nav-link active post-property-btn-1"
                onClick={handleNavClose}
                to={{
                  pathname: "/my-profile",
                  state: {
                    title: "Bank Auction Properties",
                  },
                }}
              >
                Post a Property
              </Link>

          )}

        <div
          className="collapse navbar-collapse navbar-menu-container"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 menus-wrapper">
            {navbarMenus.map((menu) => (
              <>
                {menu.isDropdown ? (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle menu-nav"
                      href="/"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      onClick={handleNavClose}
                    >
                      {menu.title}
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {menu.items.map((item) => (
                        <div key={item.id}>
                          <li>
                            <a
                              className="dropdown-item"
                              href="/"
                              onClick={handleNavClose}
                            >
                              {item.name}
                            </a>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li
                    className={
                      menu.id === 0 ? "section-main nav-item" : "nav-item"
                    }
                  >
                    {menu.id !== 0 ? (
                      menu.id === 2 ? (
                        <Link
                          onClick={handleNavClose}
                          className="nav-link active menu-nav"
                          to={{
                            pathname: menu.link,
                            state: {
                              title: "Bank Auction Properties",
                            },
                          }}
                        >
                          {menu.title}
                        </Link>
                      ) : menu.id === 6 && userToken ? (
                        <p className="nav-link active menu-nav p-0 m-0 ml-3 user-profile">
                          <Avatar onClick={handleClick}>
                            <AccountCircleIcon />
                          </Avatar>
                          <Menu
                            className="avatar-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className="avatar-dropdown"
                              onClick={handleClose}
                              style={{ fontWeight: 600 }}
                            >
                              <PersonIcon /> {userDetails?.data?.user_name}
                            </MenuItem>
                            <MenuItem
                              className="avatar-dropdown"
                              onClick={handleClose}
                            >
                              <Link
                                className="nav-link active menu-nav"
                                to={{
                                  pathname: "/my-profile",
                                }}
                                style={{ padding: "0", color: "black" }}
                              >
                                <RecentActorsIcon />
                                My Profile
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="avatar-dropdown"
                              onClick={handleClose}
                            >
                              <Link
                                className="nav-link active menu-nav"
                                to={{
                                  pathname: "/edit-profile",
                                }}
                                style={{ padding: "0", color: "black" }}
                              >
                                <RecentActorsIcon />
                                Edit Profile
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="avatar-dropdown"
                              onClick={handleLogout}
                            >
                              <ExitToAppIcon />
                              Logout
                            </MenuItem>
                          </Menu>
                        </p>
                      ) : menu.id === 7 ? (
                        <Link
                          onClick={handleNavClose}
                          className="nav-link active post-property-btn"
                          to={{
                            pathname: menu.link,
                            state: {
                              title: "Bank Auction Properties",
                            },
                          }}
                        >
                          {menu.title}
                        </Link>
                      ) : (
                        <Link
                          className="nav-link active menu-nav"
                          to={menu.link}
                          onClick={() => {
                            handleNavClose();
                            if (menu?.onClick) {
                              menu?.onClick();
                            }
                          }}
                        >
                          {menu.title}
                        </Link>
                      )
                    ) : (
                      <></>
                      // <Link to="/" onClick={handleNavClose}>
                      //   <img src={Logo} alt="Logo" />
                      // </Link>
                    )}
                  </li>
                )}
              </>
            ))}
          </ul>
          <div className="user-details">
            {userDetails?.data?.user_name ? (
              <MenuItem className="avatar-dropdown" style={{ fontWeight: 600 }}>
                Hi,&nbsp;{" "}
                <span className="secondary-dark">
                  {" "}
                  {userDetails?.data?.user_name}
                </span>
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem className="avatar-dropdown">
              <Link
                className="nav-link active menu-nav"
                to={{
                  pathname: "/my-profile",
                }}
                style={{ padding: "0", color: "black" }}
                onClick={handleNavClose}
              >
                My Profile
              </Link>
            </MenuItem>
            <MenuItem className="avatar-dropdown">
              <Link
                className="nav-link active menu-nav"
                to={{
                  pathname: "/edit-profile",
                }}
                style={{ padding: "0", color: "black" }}
                onClick={handleNavClose}
              >
                Edit Profile
              </Link>
            </MenuItem>
            {userDetails?.data?.user_name && (
              <MenuItem className="avatar-dropdown" onClick={handleLogout}>
                Logout
              </MenuItem>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
