import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { downloadsData } from './Downloads.data';
import './Downloads.styles.scss';
import { auto } from '@popperjs/core';

const useStyles = makeStyles({
  root: {
    marginTop: 40,
    marginBottom: 30
  }
});

const Downloads = () => {
  const classes = useStyles();

  return (
    <div className="container downloads-container">
      <div className="row">
        <div className="col-xl-10">
          <h1>Downloads</h1>
          <TableContainer component={Paper} className={classes.root}>
            <Table>
              <TableBody>
                {downloadsData.map((dat) => (
                  <TableRow key={dat.id}>
                    <TableCell component="th" scope="row">
                      {dat.name}
                    </TableCell>
                    <TableCell>
                      <a href={dat.link} target="_blank" rel="noreferrer">
                        {dat.type}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p>
            Note: By clicking to these download links, we consider you have agreed to our terms & conditions of privacy policy as well as you have well undertsood our 
            disclaimer policy. These downloads will be permitted to only subscribed members.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Downloads;