import { createSelector } from "reselect";

const selectAdmin = (state) => state.admin;

export const adminsByRole = createSelector(
  [selectAdmin],
  (admin) => admin.adminsByRole
);

export const getAdminDetails = createSelector(
  [selectAdmin],
  (admin) => admin.getAdminDetails
);

export const getAdminRoles = createSelector(
  [selectAdmin],
  (admin) => admin.getAdminRoles
);

export const createAdmin = createSelector(
  [selectAdmin],
  (admin) => admin.createAdmin
);

export const changeAdminPassword = createSelector(
  [selectAdmin],
  (admin) => admin.changeAdminPassword
);

export const updateAdminRole = createSelector(
  [selectAdmin],
  (admin) => admin.updateAdminRole
);

export const removeAdmin = createSelector(
  [selectAdmin],
  (admin) => admin.removeAdmin
);

export const adminResetPasswordState = createSelector(
  [selectAdmin],
  (admin) => admin.adminResetPassword
);

export const adminResendOtpState = createSelector(
  [selectAdmin],
  (admin) => admin.adminResendOtp
);

export const adDetailsState = createSelector(
  [selectAdmin],
  (admin) => admin.adDetails
);

export const adChangeState = createSelector(
  [selectAdmin],
  (admin) => admin.adChange
);