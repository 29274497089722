import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NoFile from "../../assets/img/no-file.png";
import "./ImageCarousal.styles.scss";

export default function ImageCarousal({ data }) {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="property-list-carousal">
      {data?.length > 0 && <h1 className="numbering">{index + 1} / {data?.length}</h1>}
      <Carousel
        fade
        nextIcon={<NavigateNextIcon />}
        prevIcon={<NavigateBeforeIcon />}
        index={index}
        onSelect={handleSelect}
        indicators={true}
        touch={true}
      >
        {data?.map((item) => (
          <Carousel.Item key={item}>
            {data?.length > 0 ? (
              <img alt="Property" src={`${process.env.REACT_APP_IMAGE_BASE_URL}${item}`} />
            ) : (<img alt="Property" src={NoFile} />)}
          </Carousel.Item>
        ))}
        {!data?.length ? (
          <Carousel.Item>
            <img alt="Property" src={NoFile} />
          </Carousel.Item>
        ) : ('')}
        {/* <Carousel.Item>
          <img alt="Property" src={Property2} />
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Property" src={Property3} />
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
}
