import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adDetails } from "../../redux/admin/admin.actions";

export function AdDetailsApiCall() {
  return (dispatch) => {
    dispatch(adDetails(null, true));

    const url = URL_CONSTANTS.AD_DETAILS;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(adDetails(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(adDetails(null, false));
      });
  };
}