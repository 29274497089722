import axios from "axios";
import { store } from "../redux/store";
import { setAdminToken, setUserToken } from '../redux/token/token.actions';
import { adminLogin, userLogin } from '../redux/persistantData/persistantData.actions';
import { toast } from '../redux/file/file.actions';

export const getRequest = async (url) => {
  return axios.get(url).then((res) => {
    return res;
  });
};

export const getRequestHeader = async (url) => {
  const state = store.getState();
  const authToken = await state.token.adminToken;

  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${authToken}`
    }
  }).then((res) => {
    return res;
  }).catch((err) => {
    if (err.response.status === 401) {
      store.dispatch(setAdminToken(null));
      store.dispatch(adminLogin(null, false));
      store.dispatch(toast(err?.response?.data?.message, 'warning'));
    } else {
      store.dispatch(toast(err?.response?.data?.message, 'error'));
    }
    return err;
  });
};

export const getRequestHeaderUser = async (url) => {
  const state = store.getState();
  const authToken = await state.token.userToken;
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${authToken}`
    }
  }).then((res) => {
    return res;
  }).catch((err) => {
    if (err.response.status === 401) {
      store.dispatch(setUserToken(null));
      store.dispatch(userLogin(null, false));
      store.dispatch(toast(err?.response?.data?.message, 'warning'));
    } else {
      store.dispatch(toast(err?.response?.data?.message, 'error'));
    }
    return err;
  });
};

export const postRequest = async (url, data) => {
  return axios.post(url, data).then((res) => {
    return res;
  });
};

export const postRequestHeader = async (url, data) => {
  const state = store.getState();
  const authToken = await state.token.adminToken;
  return axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${authToken}`
    }
  }).then((res) => {
    return res;
  })
  .catch((err) => {
    if (err.response.status === 401) {
      store.dispatch(setAdminToken(null));
      store.dispatch(adminLogin(null, false));
      store.dispatch(toast(err?.response?.data?.message, 'warning'));
    } else {
      store.dispatch(toast(err?.response?.data?.message, 'error'));
    }
    return err;
  });
};

export const postRequestHeaderUser = async (url, data) => {
  const state = store.getState();
  const authToken = await state.token.userToken;
  return axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${authToken}`
    }
  }).then((res) => {
    return res;
  }).catch((err) => {
    if (err.response.status === 401) {
      store.dispatch(setUserToken(null));
      store.dispatch(userLogin(null, false));
      store.dispatch(toast(err?.response?.data?.message, 'warning'));
    } else {
      store.dispatch(toast(err?.response?.data?.message, 'error'));
    }
    return err;
  });
};