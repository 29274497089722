import { createSelector } from "reselect";

const selectReport = (state) => state.report;

export const getInterestedReport = createSelector(
  [selectReport],
  (report) => report.interestedReport
);

export const getSignedUpReport = createSelector(
  [selectReport],
  (report) => report.signedUpReport
);

export const getUploadedReport = createSelector(
  [selectReport],
  (report) => report.uploadedReport
);