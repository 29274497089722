import { nanoid } from 'nanoid';

const unitsData = [
  {
    id: nanoid(),
    unit: '1 Square Feet',
    conversion: '0.092903 square meter'
  },
  {
    id: nanoid(),
    unit: '1 Acre',
    conversion: '43560 square feet'
  },
  {
    id: nanoid(),
    unit: '1 Acre',
    conversion: '40 Gunta'
  },
  {
    id: nanoid(),
    unit: '1 Gunta',
    conversion: '1089 square Feet'
  },
  {
    id: nanoid(),
    unit: '1 Square Centimeters',
    conversion: '0.00107639 square feet'
  },
  {
    id: nanoid(),
    unit: '1 Square Inch',
    conversion: '0.0069444 square feet'
  },
  {
    id: nanoid(),
    unit: '1 Square Kilometer',
    conversion: '247.1 acres'
  },
  {
    id: nanoid(),
    unit: '1 Square Meter',
    conversion: '10.76391042 square feet'
  },
  {
    id: nanoid(),
    unit: '1 Square Mile',
    conversion: '640 acres'
  },
  {
    id: nanoid(),
    unit: '1 Square Yard',
    conversion: '9 square feet'
  },
  {
    id: nanoid(),
    unit: '1 Acre',
    conversion: '4840 square yard'
  },
  {
    id: nanoid(),
    unit: '1 Hectare',
    conversion: '10000 square  meter'
  },
  {
    id: nanoid(),
    unit: '1 Acre',
    conversion: '100 square meter'
  }
];

const landMeasurement = [
  {
    id: nanoid(),
    unit: '1 Bigha',
    conversion: 'Pucca 3025 sq yd',
    states: 'Bihar & in some parts of UP, Punjab &  Haryana',
    remarks: '1 Pucca Bigha = 165 ft x 165 ft.'
  },
  {
    id: nanoid(),
    unit: '1 Bigha',
    conversion: '968 sq yd',
    states: 'Bigha size used in some parts of Himachal Pradesh & Uttarakhand.',
    remarks: ''
  },
  {
    id: nanoid(),
    unit: '1 Bigha',
    conversion: '900 sq yd',
    states: 'Bigha size used in some parts of Himachal Pradesh &  Uttarakhand.',
    remarks: ''
  },
  {
    id: nanoid(),
    unit: '1 Bigha',
    conversion: 'Kachha 1008.33 sq yd',
    states: 'Some parts of Punjab, Haryana, UP. Kachha Bigha is one-third of Pucca Bigha.',
    remarks: 'It is where settlement is not done.'
  },
  {
    id: nanoid(),
    unit: '1 Biswa',
    conversion: 'Pucca 151.25 sq yd',
    states: 'It’s mostly used in upper parts of UP, Punjab, Haryana',
    remarks: '1/20 Bigha (Pucca)'
  },
  {
    id: nanoid(),
    unit: '1 Biswa',
    conversion: '48.4 sq yd',
    states: 'Some parts of Himachal Pradesh &  Uttarakhand',
    remarks: '1/20 of Bigha'
  },
  {
    id: nanoid(),
    unit: '1 Biswa',
    conversion: '45 sq yd',
    states: 'Some parts of Himachal Pradesh &  Uttarakhand',
    remarks: '1/20 of Bigha'
  },
  {
    id: nanoid(),
    unit: '1 Biswa (Kaccha)',
    conversion: '50.417 sq yd',
    states: 'Lower parts of Punjab, Haryana, U.P',
    remarks: '1/20 of Bigha  (Kaccha)'
  },
  {
    id: nanoid(),
    unit: '1 Biswansi',
    conversion: '1/20 Biswa',
    states: 'Used in UP, Punjab, Haryana, Himachal Pradesh & Uttarakhand.',
    remarks: '1 Biswa has 20 Biswansis. Each state has a different value for Biswansis depending on the value of Biswa/ Bigha used.'
  },
  {
    id: nanoid(),
    unit: '1 Killa',
    conversion: '4840 sq yd',
    states: 'Used in some parts of Haryana & Punjab',
    remarks: 'An Acre is also known as Killa'
  },
  {
    id: nanoid(),
    unit: '1 Ghumaon',
    conversion: '4840 sq yd',
    states: 'In some parts of Himachal Pradesh, Haryana & Punjab.',
    remarks: 'An Acre is also known as Ghumaon'
  },
  {
    id: nanoid(),
    unit: '1 Kanal',
    conversion: '5445 sq ft',
    states: 'Unit used in Haryana, Punjab, Himachal Pradesh & Jammu, Kashmir & Ladakh',
    remarks: '8 Kanals is 1 acre'
  },
  {
    id: nanoid(),
    unit: '1 Sarsahi (Square Karam)',
    conversion: '30.25 sq ft',
    states: 'Used in Punjab, Haryana & Himachal Pradesh',
    remarks: 'Measured as 1 karam x 1 karam (66in X 66in). 1 Marla has 9 Sarsahies'
  }
];

const landRegistration = [
  {
    id: nanoid(),
    state: 'Rajasthan',
    dlcName: 'DLC rate',
    link: 'http://epanjiyan.nic.in/dlcdistrict.aspx'
  },
  {
    id: nanoid(),
    state: 'Maharashtra',
    dlcName: 'Ready reckoner rate',
    link: 'http://www.igrmaharashtra.gov.in/eASR/frmMap.aspx'
  },
  {
    id: nanoid(),
    state: 'Haryana, Punjab',
    dlcName: 'Collector rate',
    link: 'https://jamabandi.nic.in/HARIS/Collector1',
    link2: 'https://revenue.punjab.gov.in/?q=notifications-issued-by-stamp-registration-branch'
  },
  {
    id: nanoid(),
    state: 'Karnataka',
    dlcName: 'Guidance value',
    link: 'https://kaverionline.karnataka.gov.in/KnowYourValuation/KnowYourValuation'
  },
  {
    id: nanoid(),
    state: 'Tamil Nadu',
    dlcName: 'Guideline value',
    link: 'https://www.tn.gov.in/service/dept/92539/3932'
  },
  {
    id: nanoid(),
    state: 'Telangana',
    dlcName: 'Unit rate',
    link: 'https://registration.telangana.gov.in/UnitRateMV/getDistrictList.htm'
  },
  {
    id: nanoid(),
    state: 'Chhattisgarh, Madhya Pradesh',
    dlcName: 'Market value guideline',
    link: 'https://www.cgstate.gov.in/web/registration-stamps',
    link2: 'https://mpigr.gov.in/MarketValueGuideline2020-21.html'
  },
  {
    id: nanoid(),
    state: 'West Bengal',
    dlcName: 'Govt Minimum Rate',
    link: 'https://housing.com/news/west-bengal-stamp-duty-and-registration-charges/'
  },
  {
    id: nanoid(),
    state: 'Kerala',
    dlcName: 'Fair Value',
    link: 'https://igr.kerala.gov.in/index.php/Fairvalue/list_fairvalue'
  },
  {
    id: nanoid(),
    state: 'Delhi, Uttar Pradesh, Uttarakhand',
    dlcName: 'Circle rate',
    link: 'https://eval.delhigovt.nic.in/',
    link2:'https://upavp.in/pages/en/top-menu/public-notices/en-land-rates',
    link3: 'https://www.euttaranchal.com/govt-departments/uttarakhand-stamps-registration-department.php'
  }
];

const stampDuty = [
  {
    id: nanoid(),
    state: 'Assam',
    rate: '8.25%',
    remarks: 'Consideration'
  },
  {
    id: nanoid(),
    state: 'Goa, Daman and Diu',
    rate: '8%',
    remarks: 'Market Value'
  },
  {
    id: nanoid(),
    state: 'Haryana',
    rate: '12.5%',
    remarks: 'Market Value'
  },
  {
    id: nanoid(),
    state: 'Madhya Pradesh',
    rate: '7.5%',
    remarks: 'Market Value'
  },
  {
    id: nanoid(),
    state: 'Chhattisgarh',
    rate: '7.5%',
    remarks: 'Market Value'
  },
  {
    id: nanoid(),
    state: 'Nagaland',
    rate: '7.5%',
    remarks: 'Consideration'
  },
  {
    id: nanoid(),
    state: 'Punjab',
    rate: '6%',
    remarks: '(transfer of immovable property within the Municipal Corporation)'
  },
  {
    id: nanoid(),
    state: 'Tamil Nadu',
    rate: '8%',
    remarks: 'Market Value'
  },
  {
    id: nanoid(),
    state: 'Rajasthan',
    rate: '11%',
    remarks: 'Market Value'
  },
  {
    id: nanoid(),
    state: 'Uttar Pradesh',
    rate: '8%',
    remarks: 'market value or consideration whichever is higher'
  },
  {
    id: nanoid(),
    state: 'Tripura',
    rate: '5%',
    remarks: 'Consideration'
  },
  {
    id: nanoid(),
    state: 'Delhi',
    rate: '3% stamp duty + 5% surcharge',
    remarks: 'as per Delhi Municipal Corporation Act, 1957'
  },
  {
    id: nanoid(),
    state: 'Kerala',
    rate: '8.5%',
    remarks: '(transfer of immovable property within the Municipal Corporation)'
  },
  {
    id: nanoid(),
    state: 'Meghalaya',
    rate: '4.6 % (up to Rs.50,000), 6 % (Rs.50,000 to Rs.90,000), 8% (Rs.90,000 to Rs.1,50,000), 9.9% (above Rs.1,50,000)',
    remarks: 'Consideration'
  },
  {
    id: nanoid(),
    state: 'Bihar',
    rate: '7% + 2% duty',
    remarks: 'on transfer (within municipal limits)'
  },
  {
    id: nanoid(),
    state: 'Jharkhand',
    rate: '7% + 2%',
    remarks: 'On Transfer'
  },
  {
    id: nanoid(),
    state: 'Karnataka',
    rate: '10% , 9%',
    remarks: 'market value (within Bangalore Metropolitan Regional Development Authority); 9%(Corporation or Town Panchayat) of market value'
  },
  {
    id: nanoid(),
    state: 'Maharashtra',
    rate: '10%',
    remarks: 'market value ( Municipal Corporation of Pune, Navi Mumbai)'
  }
];

export { unitsData, landMeasurement, landRegistration, stampDuty };