import { Box } from "@mui/material";

const Between = ({ children,gap,py }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        width: "cover",
      }}
      gap={gap}
      py={py}
    >
      {children}
    </Box>
  );
};
export default Between;
