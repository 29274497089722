import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { ARCHITECT } from '../../constants/iconConstants';
import { architectsData } from './Architects.data';
import './Architects.styles.scss';

const useStyles = makeStyles({
  root: {
    marginTop: 40,
    marginBottom: 50
  }
});

const Architects = () => {
  const classes = useStyles();

  return (
    <div className="architects-container container">
      <img
        src={ARCHITECT.default}
        className="architects-image"
        alt=""
        height="150px"
        width="200px"
      />
      <h1>ARCHITECTS</h1>
      <p>
        An Architect is a person who plans, designs and oversees the construction of buildings. To practice architecture means to 
        provide services in connection with the design of buildings and the space within the site surrounding the buildings that 
        have human occupancy or use as their principal purpose. Etymologically, the term architect derives from the Latin architectus, 
        which derives from the Greek  (arkhi-, chief + tekton, builder), i.e., chief builder.
      </p>
      <p>
        he professional requirements for architects vary from place to place. An architect's decisions affect public safety, and thus 
        the architect must undergo specialized training consisting of advanced education and a practicum (or internship) for practical 
        experience to earn a license to practice architecture. Practical, technical, and academic requirements for becoming an architect 
        vary by jurisdiction, though the formal study of architecture in academic institutions has played a pivotal role in the 
        development of the profession as a whole.
      </p>
      <p>
        The architect, once hired by a client, is responsible for creating a design concept that both meets the requirements of that client 
        and provides a facility suitable to the required use. The architect must meet with, and question, the client in order to ascertain 
        all the requirements (and nuances) of the planned project. 
      </p>
      <p>
        Often the full brief is not entirely clear at the beginning: entailing a degree of risk in the design undertaking. The architect 
        may make early proposals to the client, which may rework the very terms of the brief. The "program" (or brief) is essential to 
        producing a project that meets all the needs of the owner. This then is a guide for the architect in creating the design concept.
      </p>
      <p>
        Design proposal(s) are generally expected to be both imaginative and pragmatic. Depending on the place, time, finance, culture, 
        and available crafts and technology in which the design takes place, the precise extent and nature of these expectations will 
        vary. Foresight is a prerequisite as designing buildings is a very complex and demanding undertaking. Any design concept 
        must at a very early stage in its generation take into account a great number of issues and variables which include qualities 
        of space(s),[14] the end-use and life-cycle of these proposed spaces, connections, relations, and aspects between spaces 
        including how they are put together as well as the impact of proposals on the immediate and wider locality. Selection of 
        appropriate materials and technology must be considered, tested and reviewed at an early stage in the design to ensure 
        there are no setbacks (such as higher-than-expected costs) which may occur later. The site and its environs, as well as 
        the culture and history of the place, will also influence the design. The design must also countenance increasing concerns 
        with environmental sustainability. The architect may introduce (intentionally or not), to greater or lesser degrees, aspects of 
        mathematics and architecture, new or current architectural theory, or references to architectural history.
      </p>
      <p>
        A key part of the design is that the architect often consults with engineers, surveyors and other specialists throughout the design, 
        ensuring that aspects such as the structural supports and air conditioning elements are coordinated in the scheme as a whole. The 
        control and planning of construction costs are also a part of these consultations. Coordination of the different aspects involves a 
        high degree of specialized communication, including advanced computer technology such as BIM (Building Information Modeling), 
        CAD, and cloud-based technologies.
      </p>
      <p>
        At all times in the design, the architect reports back to the client who may have reservations or recommendations, introducing a 
        further variable into the design.
      </p>
      <p>
        Architects deal with local and federal jurisdictions about regulations and building codes. The architect might need to comply with local 
        planning and zoning laws, such as required setbacks, height limitations, parking requirements, transparency requirements (windows), and 
        land use. Some established jurisdictions require adherence to design and historic preservation guidelines. Health and safety risks form 
        a vital part of the current design, and in many jurisdictions, design reports and records are required which include ongoing considerations 
        such as materials and contaminants, waste management and recycling, traffic control and fire safety.
      </p>
      <div className="row table-wrap">
        <div className="col-xl-10 m-auto">
          {architectsData.map(item => (
            <div key={item.id}>
              <h4>{item?.city}</h4>
              <TableContainer component={Paper} className={classes.root}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <img
                          src={item.image.default}
                          alt=""
                          height="100px"
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    {item.data.map(dat => (
                      <TableRow key={dat.id}>
                        <TableCell component="th" scope="row">
                          {dat.label}
                        </TableCell>
                        <TableCell>{dat.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Architects;