import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adminResendOtp } from "../../redux/admin/admin.actions";

export function AdminResendOTPApiCall(phone, toastView, setIsOTPSend) {
  return (dispatch) => {
    dispatch(adminResendOtp(null, true));

    const url = `${URL_CONSTANTS.ADMIN_RESEND_OTP}${phone}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          setIsOTPSend(true);
          dispatch(adminResendOtp(res.data.data, false));
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message, 'error');
        dispatch(adminResendOtp(null, false));
      });
  };
}