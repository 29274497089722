import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { topTenStates } from "../../redux/search/search.actions";

export function TopTenStatesApiCall(searchWord) {
  return (dispatch) => {
    dispatch(topTenStates(null, true));

    const url = `${URL_CONSTANTS.TOP_TEN_STATES}${searchWord}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(topTenStates(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(topTenStates(null, false));
      });
  };
}
