import AdminActionTypes from "./admin.types";

const initialAdminState = {
  adminsByRole: {
    data: null,
    loader: false
  },
  getAdminDetails: {
    data: null,
    loader: false
  },
  getAdminRoles: {
    data: null,
    loader: false
  },
  createAdmin: {
    data: null,
    loader: false
  },
  changeAdminPassword: {
    data: null,
    loader: false
  },
  updateAdminRole: {
    data: null,
    loader: false
  },
  removeAdmin: {
    data: null,
    loader: false
  },
  adminResetPassword: {
    data: null,
    loader: false
  },
  adminResendOtp: {
    data: null,
    loader: false
  },
  adDetails: {
    data: null,
    loader: false
  },
  adChange: {
    data: null,
    loader: false
  }
};

const adminReducer = (state = initialAdminState, action) => {
  switch (action.type) {
    case AdminActionTypes.ADMINS_BY_ROLE:
      return {
        ...state,
        adminsByRole: {
          ...state.adminsByRole,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.GET_ADMIN_DETAILS:
      return {
        ...state,
        getAdminDetails: {
          ...state.getAdminDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.GET_ADMIN_ROLES:
      return {
        ...state,
        getAdminRoles: {
          ...state.getAdminRoles,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.CREATE_ADMIN:
      return {
        ...state,
        createAdmin: {
          ...state.createAdmin,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.CHANGE_ADMIN_PASSWORD:
      return {
        ...state,
        changeAdminPassword: {
          ...state.changeAdminPassword,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.UPDATE_ADMIN_ROLE:
      return {
        ...state,
        updateAdminRole: {
          ...state.updateAdminRole,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.REMOVE_ADMIN:
      return {
        ...state,
        removeAdmin: {
          ...state.removeAdmin,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.ADMIN_RESET_PASSWORD:
      return {
        ...state,
        adminResetPassword: {
          ...state.adminResetPassword,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.ADMIN_RESEND_OTP:
      return {
        ...state,
        adminResendOtp: {
          ...state.adminResendOtp,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.AD_DETAILS:
      return {
        ...state,
        adDetails: {
          ...state.adDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminActionTypes.AD_CHANGE:
      return {
        ...state,
        adChange: {
          ...state.adChange,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default adminReducer;
