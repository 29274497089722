import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TextField from "@material-ui/core/TextField";
import { useToasts } from 'react-toast-notifications';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Loader from '../../components/Loader/Loader.component';
import { LoginApiCall } from '../../apiCalls/Admin/LoginApiCall';
import { AdminResendOTPApiCall } from '../../apiCalls/Admin/AdminResendOtpApiCall';
import { AdminResetPasswordApiCall } from '../../apiCalls/Admin/AdminResetPasswordApiCall';
import { adminResendOtpState, adminResetPasswordState } from '../../redux/admin/admin.selector';
import { adminLoginState } from '../../redux/persistantData/persistantData.selector';
import { phoneNumberValidation } from '../../utils/validations';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import "../Auth/auth.scss";

const useStyles = makeStyles((theme) => ({
  formControlCustom: {
    maxWidth: 500,
  },
  button: {
    minWidth: 500,
    height: 40,
  },
}));

const emailRegex = /\S+@\S+\.\S+/;

const AdminLogin = () => {
  const [values, setValues] = React.useState({
    showPassword: false,
    showResetPassword: false,
    showConfirmResetPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowResetPassword = () => {
    setValues({ ...values, showResetPassword: !values.showResetPassword });
  };
  const handleClickShowConfirmResetPassword = () => {
    setValues({ ...values, showConfirmResetPassword: !values.showConfirmResetPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const classes = useStyles();
  const adminLogin = useSelector(adminLoginState);
  const adminResendOtp = useSelector(adminResendOtpState);
  const adminResetPassword = useSelector(adminResetPasswordState);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsValidEmail] = useState(true);
  const [EmailValidationMessage, setEmailValidationMessage] = useState("");
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsValidPassword] = useState(true);
  const [PasswordValidationMessage, setPasswordValidationMessage] = useState("");
  const [isVisibleForgotPassPage, setIsVisibleForgotPassPage] = useState(false);
  const [isOTPSend, setIsOTPSend] = useState(false);
  const [phoneErr, setPhoneErr] = useState(null);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);
  const [fieldValues, setFieldValues] = useState({
    phone: '',
    otp: '',
    password: ''
  });

  const validateEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (emailRegex.test(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setEmailValidationMessage("Please enter a valid email.");
    }
  };

  const validatePassword = (event) => {
    const password = event.target.value;
    setPassword(password);
    if (password.length >= 6) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
      setPasswordValidationMessage("Min. of 6 characters required.");
    }
  };

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const login = (e) => {
    e.preventDefault();
    let flag = false;
    if (isEmailValid && isPasswordValid) {
      if (email.trim('').length === 0) {
        setEmailValidationMessage("Email is required");
        flag = true;
      }
      if (password.trim('').length === 0) {
        setPasswordValidationMessage("Password is required");
        flag = true;
      }
      if (!flag) {
        let data = {
          email,
          password
        };
        dispatch(LoginApiCall(data, toastView));
      }
    }
  }

  const resetPassword = () => {
    setIsVisibleForgotPassPage(true);
  }

  const sendOtp = () => {
    dispatch(AdminResendOTPApiCall(fieldValues?.phone, toastView, setIsOTPSend));
  }

  const saveNewPass = () => {
    dispatch(AdminResetPasswordApiCall(
      fieldValues?.phone, 
      fieldValues?.otp, 
      fieldValues?.newPassword, 
      toastView, 
      setIsVisibleForgotPassPage
    ));
  }

  const validation = (event, text) => {
    setFieldValues({
      ...fieldValues,
      [text]: event.target.value
    });
    let value = event.target.value;
    if (text === 'phone') {
      setPhoneErr(phoneNumberValidation(value));
    } else if (text === 'password' || text === 'newPassword') {
      if (value.length >= 8) {
        setPasswordValidationMessage(null);
      } else {
        setPasswordValidationMessage("Min. of 8 characters required.");
      }
    } else if (text === 'confirmPassword') {
      if (value === fieldValues.password) {
        setConfirmPasswordErr(null);
      } else {
        setConfirmPasswordErr("Password doesn't match");
      }
    } else if (text === 'confirmNewPassword') {
      if (value === fieldValues?.newPassword) {
        setConfirmPasswordErr(null);
      } else {
        setConfirmPasswordErr("Password doesn't match");
      }
    }
  }

  return (
    <div className="container-fluid login-outer admin-login-outer">
      <Loader visible={adminLogin.loader ||
        adminResendOtp?.loader ||
        adminResetPassword?.loader
      } />
      <h2>Admin</h2>
      <div className="row">
        {isVisibleForgotPassPage ? (
          <div className="m-auto">
          {isOTPSend === true ? (
            <div className="reset-page-outer">
              <h3>Reset Password</h3>
              <p>We've send an OTP. Please check your phone. </p>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 field-wrapper">
                  <TextField
                    fullWidth
                    required
                    value={fieldValues.otp}
                    type="number"
                    id="outlined-basic"
                    label="One Time Password(OTP)"
                    variant="outlined"
                    onChange={(event) => validation(event, 'otp')}
                    className={classes.formControlCustom}
                    size="small"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 field-wrapper">
                  <TextField
                    fullWidth
                    required
                    value={fieldValues?.newPassword}
                    type={values.showResetPassword ? 'text' : 'password'}
                    id="outlined-basic"
                    label="New Password"
                    variant="outlined"
                    error={PasswordValidationMessage}
                    helperText={PasswordValidationMessage}
                    onChange={(event) => validation(event, 'newPassword')}
                    className={classes.formControlCustom1}
                    size="small"
                    InputProps={{
                      endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowResetPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showResetPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 field-wrapper">
                  <TextField
                    fullWidth
                    required
                    value={fieldValues?.confirmNewPassword}
                    type={values.showConfirmResetPassword ? 'text' : 'password'}
                    id="outlined-basic"
                    label="Confirm New Password"
                    variant="outlined"
                    error={confirmPasswordErr}
                    helperText={confirmPasswordErr}
                    onChange={(event) => validation(event, 'confirmNewPassword')}
                    className={classes.formControlCustom1}
                    size="small"
                    InputProps={{
                      endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmResetPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showConfirmResetPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </div>
              </div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={saveNewPass}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="forgot-pass-outer">
              <h3>Forgot Password</h3>
              <p>Reset your password with your mobile number</p>
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <TextField
                    fullWidth
                    required
                    value={fieldValues.phone}
                    type="number"
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    helperText={phoneErr}
                    onChange={(event) => validation(event, 'phone')}
                    className={classes.formControlCustom2}
                    size="small"
                    className="m-auto"
                  />
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={sendOtp}
                  >
                    Send OTP
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        ) : (
          <div className="m-auto">
            <h3>Login</h3>
            <form noValidate autoComplete="off" onSubmit={login}>
              <TextField
                required
                fullWidth
                autoFocus
                value={email}
                id="outlined-basic"
                label="E-mail"
                variant="outlined"
                error={!isEmailValid}
                helperText={!isEmailValid ? EmailValidationMessage : ""}
                className={classes.formControlCustom}
                onChange={validateEmail}
                size="small"
              />
              <TextField
                fullWidth
                required
                value={password}
                type={values.showPassword ? 'text' : 'password'}
                id="outlined-basic"
                label="Password"
                variant="outlined"
                error={!isPasswordValid}
                helperText={!isPasswordValid ? PasswordValidationMessage : ""}
                onChange={validatePassword}
                className={classes.formControlCustom}
                size="small"
                InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
              <div className="auth-actions">
                <p className="resend-otp-label">Forgot Password? <span onClick={resetPassword} >Reset</span></p>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;