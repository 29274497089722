import { nanoid } from 'nanoid';

const propertyValuersData = [
  {
    id: nanoid(),
    city: 'Ahmedabad',
    avg: '3,251',
    growth: '5'
  },
  {
    id: nanoid(),
    city: 'Bangalore',
    avg: '5,495',
    growth: '4'
  },
  {
    id: nanoid(),
    city: 'Chennai',
    avg: '5,308',
    growth: '3'
  },
  {
    id: nanoid(),
    city: 'Hyderabad',
    avg: '5,790',
    growth: '5'
  },
  {
    id: nanoid(),
    city: 'Kolkata',
    avg: '4,251',
    growth: '2'
  },
  {
    id: nanoid(),
    city: 'MMR',
    avg: '9,475',
    growth: 'No change'
  },
  {
    id: nanoid(),
    city: 'NCR',
    avg: '4,337',
    growth: '2'
  },
  {
    id: nanoid(),
    city: 'Pune',
    avg: '5,083',
    growth: '3'
  },
  {
    id: nanoid(),
    city: 'National average',
    avg: '6,234',
    growth: '3'
  }
];

export  { propertyValuersData };