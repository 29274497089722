import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { removeFile } from "../../redux/file/file.actions";

export function RemoveFileApiCall(fileId, toastView) {
  return (dispatch) => {
    dispatch(removeFile(null, true));

    const url = `${URL_CONSTANTS.REMOVE_FILE}${fileId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("File removed successfully", 'success');
          dispatch(removeFile(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(removeFile(null, false));
      });
  };
}