import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import TextField from "@material-ui/core/TextField";
// import { useGoogleLogin } from '@react-oauth/google';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Loader from '../../../components/Loader/Loader.component';
import LoginBanner from "../../../assets/img/login.jpg";
import ForgotPassImg from "../../../assets/img/forgot-pass.jpg";
import OtpImg from "../../../assets/img/otp.jpg";
// import { GOOGLE_SIGN_IN } from '../../../constants/iconConstants';
import { ResendOTPApiCall } from '../../../apiCalls/User/ResendOTPApiCall';
import { UserLoginApiCall } from '../../../apiCalls/User/UserLoginApiCall';
import { ResetPasswordApiCall } from '../../../apiCalls/User/ResetPasswordApiCall';
// import { GoogleSignInApiCall } from '../../../apiCalls/User/GoogleSignInApiCall';
import { savePropertyLink } from '../../../redux/user/user.actions';
import { resendOTPState, getResetPasswordState } from '../../../redux/user/user.selector';
import { userLoginState } from '../../../redux/persistantData/persistantData.selector';
import { phoneNumberValidation } from '../../../utils/validations';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import "../auth.scss";

const useStyles = makeStyles((theme) => ({
  formControlCustom: {
    maxWidth: 500,
  },
  formControlCustom1: {
    maxWidth: 500,
    margin: 'auto'
  },
}));

const emailRegex = /\S+@\S+\.\S+/;

const Login = () => {
  const location = useLocation();
  const [values, setValues] = React.useState({
    showPassword: false,
    showResetPassword: false,
    showConfirmResetPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowResetPassword = () => {
    setValues({ ...values, showResetPassword: !values.showResetPassword });
  };
  const handleClickShowConfirmResetPassword = () => {
    setValues({ ...values, showConfirmResetPassword: !values.showConfirmResetPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  const userLogin = useSelector(userLoginState);
  const getResendOtpState = useSelector(resendOTPState);
  const resetPasswordState = useSelector(getResetPasswordState);
  const { addToast } = useToasts();
  const [isEmailValid, setIsValidEmail] = useState(true);
  const [isVisibleForgotPassPage, setIsVisibleForgotPassPage] = useState(false);
  const [isOTPSend, setIsOTPSend] = useState(false);
  const [EmailValidationMessage, setEmailValidationMessage] = useState("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(null);
  const [fieldValues, setFieldValues] = useState({
    phone: null,
    otp: null,
    password: null
  });
  const [phoneErr, setPhoneErr] = useState(null);
  const [PasswordValidationMessage, setPasswordValidationMessage] = useState(null);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);
  const isEnabled = isEmailValid && password.length > 5;

  useEffect(() => {
    if(location?.state?.link) {
      dispatch(savePropertyLink(location?.state?.link));
    }
  }, []);

  const validateEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (emailRegex.test(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setEmailValidationMessage("Please enter a valid email.");
    }
  };

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const settingDefault = () => {
    setFieldValues({});
    setPasswordValidationMessage(null);
    setEmail('');
    setPassword('');
    setEmailValidationMessage(null);
    setConfirmPasswordErr(null);
    setIsValidEmail(true);
  }

  const classes = useStyles();

  const submit = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      password: password
    };
    dispatch(UserLoginApiCall(data, toastView));
  }

  const resetPassword = () => {
    settingDefault();
    setIsVisibleForgotPassPage(true);
  }

  const sendOtp = () => {
    dispatch(ResendOTPApiCall(fieldValues?.phone, toastView, setIsOTPSend));
  }


  const saveNewPass = () => {
    dispatch(ResetPasswordApiCall(fieldValues?.phone, fieldValues?.otp, fieldValues?.newPassword, toastView, setIsPasswordResetSuccess));
  }

  const validation = (event, text) => {
    setFieldValues({
      ...fieldValues,
      [text]: event.target.value
    });
    let value = event.target.value;
    if (text === 'phone') {
      setPhoneErr(phoneNumberValidation(value));
    } else if (text === 'otp') {
      setOtp(event.target.value);
    } else if (text === 'password' || text === 'newPassword') {
      if (value.length >= 8) {
        setPasswordValidationMessage(null);
      } else {
        setPasswordValidationMessage("Min. of 8 characters required.");
      }
    } else if (text === 'confirmPassword') {
      if (value === fieldValues.password) {
        setConfirmPasswordErr(null);
      } else {
        setConfirmPasswordErr("Password doesn't match");
      }
    } else if (text === 'confirmNewPassword') {
      if (value === fieldValues?.newPassword) {
        setConfirmPasswordErr(null);
      } else {
        setConfirmPasswordErr("Password doesn't match");
      }
    }
  }

  const goToLogin = () => {
    setFieldValues({});
    setIsPasswordResetSuccess(false);
    setIsVisibleForgotPassPage(false);
  }

  // Google sign in
  // const googleSignIn = useGoogleLogin({
  //   onError: (error) => {
      
  //   },
  //   onSuccess: (codeResponse) => {
  //     dispatch(GoogleSignInApiCall(codeResponse.code, toastView));
  //   },
  //   flow: 'auth-code'
  // })

  return (
    <div className="container-fluid login-outer">
      <Loader visible={userLogin.loader || getResendOtpState.loader || resetPasswordState.loader} />
      {/* <div className="row">
        <div className="col-md-6">
          {!isPasswordResetSuccess ? (
            <img src={LoginBanner} alt="Login Banner" style={{ marginTop: 0 }} />
          ) : ('')}
        </div>
      </div> */}
      {isPasswordResetSuccess ? (
        <div className="otp-success-outer container">
          <div className="otp-success-img__wrapper">
            <img src={OtpImg} alt="Login Banner" />
          </div>
          <h2>Awesome!</h2>
          <p>Your password has been reset successfully. Please login</p>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className="submit-btn"
            onClick={goToLogin}
          >
            Login
          </Button>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            {isOTPSend ? (
              <img src={OtpImg} alt="Login Banner" style={{ marginTop: 0 }} />
            ) : isVisibleForgotPassPage ? (
              <img src={ForgotPassImg} alt="Login Banner" style={{ marginTop: 0 }} />
            ) : (
              <img src={LoginBanner} alt="Login Banner" style={{ marginTop: 0 }} />
            )}

          </div>
          {isVisibleForgotPassPage ? (
            <div className="col-md-6">
              {isOTPSend === true ? (
                <div>
                  <p>We've send an OTP. Please check your phone. </p>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={otp}
                        type="number"
                        id="outlined-basic"
                        label="One Time Password(OTP)"
                        variant="outlined"
                        helperText={phoneErr}
                        onChange={(event) => validation(event, 'otp')}
                        className={classes.formControlCustom}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={fieldValues?.newPassword}
                        type={values.showResetPassword ? 'text' : 'password'}
                        id="outlined-basic"
                        label="New Password"
                        variant="outlined"
                        error={PasswordValidationMessage}
                        helperText={PasswordValidationMessage}
                        onChange={(event) => validation(event, 'newPassword')}
                        className={classes.formControlCustom1}
                        size="small"
                        InputProps={{
                          endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowResetPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showResetPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={fieldValues?.confirmNewPassword}
                        type={values.showConfirmResetPassword ? 'text' : 'password'}
                        id="outlined-basic"
                        label="Confirm New Password"
                        variant="outlined"
                        error={confirmPasswordErr}
                        helperText={confirmPasswordErr}
                        onChange={(event) => validation(event, 'confirmNewPassword')}
                        className={classes.formControlCustom1}
                        size="small"
                        InputProps={{
                          endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmResetPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showConfirmResetPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={saveNewPass}
                    className="submit-btn"
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div>
                  <h3>Forgot Password</h3>
                  <p>Reset your password with your mobile number</p>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={fieldValues.phone}
                        type="number"
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        helperText={phoneErr}
                        onChange={(event) => validation(event, 'phone')}
                        className={classes.formControlCustom}
                        size="small"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={sendOtp}
                      >
                        Send OTP
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>

          ) : (
            <div className="col-md-6">
              <h3>Login</h3>
              <p className="signup-link">
                Don't have an account ?
                <Link
                  to={{
                    pathname: "/signup",
                  }}
                >
                  Sign up
                </Link>
              </p>
              <form noValidate autoComplete="off" onSubmit={submit}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  id="outlined-basic"
                  label="E-mail"
                  variant="outlined"
                  value={email}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? EmailValidationMessage : ""}
                  className={classes.formControlCustom}
                  onChange={validateEmail}
                  size="small"
                />
                <TextField
                  fullWidth
                  required
                  value={password}
                  type={values.showPassword ? 'text' : 'password'}
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  className={classes.formControlCustom}
                  size="small"
                  InputProps={{
                    endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                <p className="resend-otp-label">Forgot Password? <span onClick={resetPassword} >Reset</span></p>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isEnabled}
                  className="submit-btn"
                >
                  Login
                </Button>
              </form>

              {/* <p className='or-txt'>OR</p> */}

              {/* Google sign in */}
              {/* <div className='google-sign-in-wrap' onClick={googleSignIn}>
                <img src={GOOGLE_SIGN_IN.default} alt='' />
              </div> */}

            </div>
          )}

        </div>)}
    </div>
  );
};

export default Login;
