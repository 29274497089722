const AdminActionTypes = {
  ADMINS_BY_ROLE: "ADMINS_BY_ROLE",
  GET_ADMIN_DETAILS: "GET_ADMIN_DETAILS",
  GET_ADMIN_ROLES: "GET_ADMIN_ROLES",
  CREATE_ADMIN: "CREATE_ADMIN",
  CHANGE_ADMIN_PASSWORD: "CHANGE_ADMIN_PASSWORD",
  UPDATE_ADMIN_ROLE: "UPDATE_ADMIN_ROLE",
  REMOVE_ADMIN: "REMOVE_ADMIN",
  ADMIN_RESET_PASSWORD: "ADMIN_RESET_PASSWORD",
  ADMIN_RESEND_OTP: "ADMIN_RESEND_OTP",
  AD_DETAILS: 'AD_DETAILS',
  AD_CHANGE: 'AD_CHANGE'
};

export default AdminActionTypes;
