import {
  SERVICES_TITLE,
  SERVICES_FEATURES,
  SERVICES_HOME,
  SERVICES_PHONE
} from '../../constants/iconConstants';
import './OurServices.styles.scss';

const OurServices = () => (
  <div className="our-services-container">
    <h1>Our Services</h1>
    <img
      src={SERVICES_TITLE.default}
      className="service-image"
      alt=""
      width="130px"
      height="130px"
    />
    <p className="sub-head">
      A user friendly digital platform for listing Bank Auction Properties, Properties Buy & Sale other than Bank properties for
    </p>
    <img
      src={SERVICES_PHONE.default}
      className="service-image"
      alt=""
      width="100px"
      height="100px"
    />
    <ul>
      <li>Public Sector Banks</li>
      <li>Private Sector Banks</li>
      <li>Non-Banking Finance Companies (NBFCs)</li>
      <li>Housing Finance Companies (HFCs)</li>
      <li>Asset Reconstruction Companies or Asset Management Companies (ARCs/AMCs)</li>
    </ul>
    <p>
      A market place for show casing properties, upcoming projects, products related to home construction, home loans and more..
    </p>
    <p className="sub-head">
      Arranging Inspection of  Properties for Buyers and assisting in participation of Bidding process
    </p>
    <img
      src={SERVICES_HOME.default}
      className="service-image"
      alt=""
      width="100px"
      height="100px"
    />
    <p>
      Supporting Services for Bank as well as Enforcement Agencies/ Supporting Agencies/ Recovery Agents for
    </p>
    <ul>
      <li>Issue of 60 days notice U/s 13(2)</li>
      <li>Pre-possession survey & Due diligence report</li>
      <li>CMM/DM Application preparation & filing</li>
      <li>Total software solutions for NPA accounts tracking & monitoring system</li>
    </ul>
    <p className="sub-head">Salient Features of Web-portal</p>
    <img
      src={SERVICES_FEATURES.default}
      className="service-image"
      alt=""
      width="110px"
      height="80px"
    />
    <ul>
      <li>Largest database of over  millions of Bank Auction properties as well as Private Properties for Buy & Sale</li>
      <li>Extensive criteria sorting</li>
      <li>Links to get financial support from various Public Sector & Private Sector Banks/Financial Institutions</li>
      <li>Quick disposal of Home Loans by our Principals</li>
      <li>Customized Services for Potential Customers</li>
      <li>Guidelines about home buying and selling</li>
      <li>Personalized alerts for your criteria</li>
      <li>
        Tutorial videos with Social Media platforms, and checklists on the process of purchasing and participating E-Auctions process.
      </li>
      <li>Attractive tariff for listing and advertising/showcasing your properties</li>
      <li>Arranging property inspection</li>
      <li>Videos of properties for virtual selection apart from images</li>
      <li>Software support forSARFAESI NPA Resolution</li>
      <li>PAN India presence of services with authorized channel partners</li>
      <li>Knowledge Hub for new customers/vendors.</li>
      <li>Supporting services of our empaneled legal experts</li>
      <li>State-wise Data base of Allied Business associates</li>
    </ul>
  </div>
);

export default OurServices;