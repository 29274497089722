import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adminPropertySearchAction } from "../../redux/adminProperty/adminProperty.actions";
import { filteredSearchParams } from '../../utils/CustomFunctions';

export function AdminPropertySearchApiCall(params) {
  return (dispatch) => {
    dispatch(adminPropertySearchAction(null, true));

    let url = URL_CONSTANTS.ADMIN_PROPERTY_SEARCH;

    url += filteredSearchParams({
      ...params
    });

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(adminPropertySearchAction(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(adminPropertySearchAction(null, false));
      });
  };
}