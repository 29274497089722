import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import SavedProperties from '../SavedProperties/SavedProperties.component'
import UserProperties from '../UserProperties/UserProperties.component'
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import "./MyProfile.styles.scss";

const MyProfile = () => {

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="my-profile-outer container-fluid">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs-outer">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">My Profile</Typography>
      </Breadcrumbs>
      <TabContext value={value}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="My Uploads" value="1" />
            <Tab label="Saved" value="2" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <UserProperties />
        </TabPanel>
        <TabPanel value="2">
          <SavedProperties />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default MyProfile;
