import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adminPropertyDetails } from "../../redux/adminProperty/adminProperty.actions";

export function AdminPropertyDetailsApiCall(propertyId, toastView) {
  return (dispatch) => {
    dispatch(adminPropertyDetails(null, true));

    const url = `${URL_CONSTANTS.ADMIN_PROPERTY_DETAILS}${propertyId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(adminPropertyDetails(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(adminPropertyDetails(null, false));
      });
  };
}