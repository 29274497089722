import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { createAdmin } from "../../redux/admin/admin.actions";

export function CreateAdminApiCall(data, toastView) {
  return (dispatch) => {
    dispatch(createAdmin(null, true));

    const url = URL_CONSTANTS.CREATE_ADMIN;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("User added successfully", 'success');
          dispatch(createAdmin(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(createAdmin(null, false));
      });
  };
}