import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { showInterest } from "../../redux/user/user.actions";

export function ShowInterestApiCall(propertyId, toastView) {
  return (dispatch) => {
    dispatch(showInterest(null, true));

    const url = `${URL_CONSTANTS.SHOW_INTEREST}${propertyId}`;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Your interest has been recorded. Our team will contact you soon...", 'success');
          dispatch(showInterest(res.data.data, false));
        }
      })
      .catch((error) => {
        toastView("Something went wrong... Please try again after sometime", 'error');
        dispatch(showInterest(null, false));
      });
  };
}