import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Modal from '@material-ui/core/Modal';
import Loader from '../../components/Loader/Loader.component';
import { getTopLocalities, getTopTenCities } from '../../redux/search/search.selector';
import { propertyCategoryDetails } from '../../redux/property/property.selector';
import { resendOTPState } from '../../redux/user/user.selector';
import { userDetailsState } from '../../redux/persistantData/persistantData.selector';
import { topTenCities, topLocalities as topLocalitiesAction } from '../../redux/search/search.actions';
import { PropertyCategoryDetailsApiCall } from '../../apiCalls/Property/PropertyCategoryDetailsApiCall';
import { TopTenCitiesApiCall } from '../../apiCalls/Search/TopTenCitiesApiCall';
import { TopLocalitiesApiCall } from '../../apiCalls/Search/TopLocalitiesApiCall';
import { ResendOTPApiCall } from '../../apiCalls/User/ResendOTPApiCall';
import { VerifyOTPApiCall } from '../../apiCalls/User/VerifyOTPApiCall';
import { UserDetailsApiCall } from '../../apiCalls/User/UserDetailsApiCall';
import { EditProfileApiCall } from '../../apiCalls/User/EditProfileApiCall';
import { normalFieldValidation, phoneNumberValidation } from '../../utils/validations';
import './EditProfile.styles.scss';

const useStyles = makeStyles((theme) => ({
  formControlCustom: {
    maxWidth: 500
  },
  button: {
    height: 40,
    width: '100%',
    maxWidth: 500
  },
  wrapper: {
    position: 'relative'
  },
  fabProgress: {
    color: '#fff',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const EditProfile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addToast } = useToasts();
  const userProfile = useSelector(userDetailsState);
  const topCities = useSelector(getTopTenCities);
  const topLocalities = useSelector(getTopLocalities);
  const [fieldValues, setFieldValues] = useState({
    name: '',
    locality: '',
    city: '',
    phone: '',
    propertyType: '',
    budget: '',
    userType: '',
    otp: null
  });
  const [nameErr, setNameErr] = useState(null);
  const [phoneErr, setPhoneErr] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const categoryDetails = useSelector(propertyCategoryDetails);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const resendOtpData = useSelector(resendOTPState);
  const [otpError, setOtpError] = useState(null);

  useEffect(() => {
    // User details api
    dispatch(UserDetailsApiCall());
    // Property category api
    dispatch(PropertyCategoryDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cities api
  useEffect(() => {
    if(fieldValues.city?.trim('')?.length > 0) dispatch(TopTenCitiesApiCall(fieldValues.city));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues.city]);

  // Localities api
  useEffect(() => {
    if (selectedCity) {
      dispatch(TopLocalitiesApiCall(fieldValues.locality, selectedCity?.city_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues.locality]);

  useEffect(() => {
    if (userProfile.data !== null) {
      let pName = '';
      if (categoryDetails.data?.types?.length > 0) {
        let info = categoryDetails.data?.types;
        for (let i = 0; i < info?.length; i++) {
          if (info[i]?.type_id === userProfile.data?.preferred_property_type) {
            pName = info[i]?.type_name;
          }
        }
      }
      setFieldValues({
        name: userProfile.data?.end_user_name ?? '',
        locality: userProfile.data?.locality_name ?? '',
        city: userProfile.data?.city_name ?? '',
        phone: userProfile.data?.end_user_phone ?? '',
        propertyType: pName,
        budget: userProfile.data?.budget ?? '',
        userType: userProfile.data?.user_type ?? '',
        otp: null
      });
      if(userProfile.data?.city_name) {
        const city = [{
          city_id: userProfile.data?.end_user_city_id,
          city_name: userProfile.data.city_name
        }];
        setSelectedCity(city[0]);
        dispatch(topTenCities(city, false));
      }
      if(userProfile.data?.locality_name) {
        const locality = [{
          locality_name: userProfile.data.locality_name,
          locality_id: userProfile.data?.end_user_locality_id
        }];
        setSelectedLocality(locality[0]);
        dispatch(topLocalitiesAction(locality, false));
      }
    }
  }, [userProfile.data, categoryDetails.data]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const validation = (event, text) => {
    setFieldValues({
      ...fieldValues,
      [text]: event.target.value
    });
    let value = event.target.value;
    if (text === 'name') {
      setNameErr(normalFieldValidation(value, 'Name'));
    } else if (text === 'phone') {
      setPhoneErr(phoneNumberValidation(value));
    } else if (text === 'otp') {
      setOtpError(value?.trim('')?.length === 0 ? 'Otp is required' : null);
    }
  }

  // Verify otp callback
  const verifyOtpCallback = (status) => {
    setOtpLoader(false);
    if(status) {
      setShowOtpInput(false);
      updateProfile();
    } else {
      setOtpError("Invalid otp");
    }
  }

  // Verify otp
  const verifyOtp = () => {
    setOtpLoader(true);
    dispatch(VerifyOTPApiCall(fieldValues.phone, fieldValues.otp, toastView, verifyOtpCallback));
  }

  // Update profile
  const updateProfile = () => {
    setUpdateLoader(true);
    let pId = '';
    if (categoryDetails.data?.types?.length > 0) {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === fieldValues.propertyType) {
          pId = info[i]?.type_id;
        }
      }
    }
    let data = {
      name: fieldValues.name,
      email: userProfile.data?.end_user_email,
      phone: fieldValues.phone,
      city_id: selectedCity?.city_id ?? '',
      locality_id: selectedLocality?.locality_id ?? '',
      property_type_id: pId,
      budget: fieldValues.budget,
      user_type: fieldValues.userType
    };
    if (!selectedLocality?.locality_id) {
      data = {
        ...data,
        locality_name: fieldValues?.locality
      };
    }
    // Update profile api
    dispatch(EditProfileApiCall(data, setUpdateLoader, toastView));
  }

  // Resend otp
  const resendOtp = () => {
    // send otp api
    dispatch(ResendOTPApiCall(fieldValues?.phone, toastView));
  }

  // Update profile
  const submit = (e) => {
    e.preventDefault();
    // if (fieldValues.phone !== userProfile.data?.end_user_phone) {
    //   setShowOtpInput(true);
    //   resendOtp();
    // } else {
    //   updateProfile();
    // }
    updateProfile();
  }

  return (
    <div className='edit-profile-wrap'>
      <Loader visible={userProfile.loader || categoryDetails.loader} />
      <form noValidate autoComplete='off' onSubmit={submit}>
        <div className='container-fluid' style={{ padding: 0 }}>
          <div className='row'>
            {/* User type */}
            <div className='col-12'>
              <FormControl className='mb-1'>
                <FormLabel>I am a</FormLabel>
                <RadioGroup
                  aria-label='I am a'
                  className='flex-row'
                  value={fieldValues.userType}
                  onChange={(event) => validation(event, 'userType')}
                >
                  <FormControlLabel value='Buyer' control={<Radio />} label='Buyer' />
                  <FormControlLabel value='Seller' control={<Radio />} label='Seller' />
                  <FormControlLabel value='Investor' control={<Radio />} label='Investor' />
                  <FormControlLabel value='Agent' control={<Radio />} label='Agent' />
                </RadioGroup>
              </FormControl>
            </div>

            {/* Name */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <TextField
                required
                fullWidth
                autoFocus
                id='outlined-basic'
                label='Name'
                type='text'
                variant='outlined'
                value={fieldValues.name}
                error={nameErr}
                helperText={nameErr}
                className={classes.formControlCustom}
                onChange={(event) => validation(event, 'name')}
                size='small'
              />
            </div>

            {/* Email */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <TextField
                required
                fullWidth
                value={userProfile.data?.end_user_email}
                id='outlined-basic'
                label='E-mail'
                type='email'
                variant='outlined'
                disabled
                className={classes.formControlCustom}
                size='small'
              />
            </div>

            {/* City */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <Autocomplete
                id='city-dropdown'
                freeSolo
                options={topCities.data ?? []}
                value={selectedCity}
                disableClearable
                noOptionsText='No cities'
                getOptionLabel={(option) => option.city_name}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                  setSelectedCity(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='City'
                    className={`${classes.formControlCustom} city-label`}
                    variant='outlined'
                    size='small'
                    onChange={(e) =>
                      setFieldValues({
                        ...fieldValues,
                        city: e.target.value
                      })
                    }
                    helperText='City at which you are looking to buy / sell property'
                  />
                )}
              />
            </div>

            {/* Locality */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <Autocomplete
                id='locality-dropdown'
                freeSolo
                value={selectedLocality}
                options={topLocalities.data ?? []}
                disableClearable
                noOptionsText='No localities'
                getOptionLabel={(option) => option.locality_name}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                  setSelectedLocality(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Locality'
                    className={`${classes.formControlCustom} city-label`}
                    variant='outlined'
                    size='small'
                    onChange={(e) =>
                      setFieldValues({
                        ...fieldValues,
                        locality: e.target.value
                      })
                    }
                    helperText='Locality at which you are looking to buy / sell property'
                  />
                )}
              />
            </div>

            {/* Phone  */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <TextField
                fullWidth
                required
                value={fieldValues.phone}
                type='number'
                id='outlined-basic'
                label='Phone Number'
                variant='outlined'
                error={phoneErr}
                helperText={phoneErr}
                onChange={(event) => validation(event, 'phone')}
                className={classes.formControlCustom}
                size='small'
              />
            </div>

            {/* Property type */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <TextField
                fullWidth
                required
                select
                value={fieldValues.propertyType}
                type='text'
                id='outlined-basic'
                label='Property Type'
                variant='outlined'
                SelectProps={{
                  native: true
                }}
                onChange={(event) => validation(event, 'propertyType')}
                className={classes.formControlCustom}
                size='small'
              >
                <option key='Residential' value='Residential'>
                  Residential
                </option>
                <option key='Commercial' value='Commercial'>
                  Commercial
                </option>
                <option key='Industrial' value='Industrial'>
                  Industrial
                </option>
                <option key='Agricultural' value='Agricultural'>
                  Agricultural
                </option>
              </TextField>
            </div>

            {/* Budget */}
            <div className='col-xl-6 col-lg-6 col-md-12 mb-3'>
              <TextField
                fullWidth
                required
                select
                value={fieldValues.budget}
                type='text'
                id='outlined-basic'
                label='Budget'
                variant='outlined'
                SelectProps={{
                  native: true
                }}
                onChange={(event) => validation(event, 'budget')}
                className={classes.formControlCustom}
                size='small'
              >
                <option key='10 - 25 Lakhs' value='10 - 25 Lakhs'>
                  10 - 25 Lakhs
                </option>
                <option key='25 - 40 Lakhs' value='25 - 40 Lakhs'>
                  25 - 40 Lakhs
                </option>
                <option key='40 - 80 Lakhs' value='40 - 80 Lakhs'>
                  40 - 80 Lakhs
                </option>
                <option key='Above 80 Lakhs' value='Above 80 Lakhs'>
                  Above 80 Lakhs
                </option>
                <option key='Others' value='Others'>
                  Others
                </option>
              </TextField>
            </div>

          </div>

          {/* Update button */}
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.button}
                disabled={
                  fieldValues.userType === '' ||
                  fieldValues.name === '' ||
                  fieldValues.phone === '' ||
                  fieldValues.budget === '' ||
                  fieldValues.propertyType === '' ||
                  nameErr ||
                  phoneErr
                }
              >
                {updateLoader ? <CircularProgress size={20} color='inherit' /> : 'Update'}
              </Button>
            </div>
          </div>

        </div>
      </form>

      {/* Otp modal */}
      <Modal
        open={showOtpInput}
        onClose={() => setShowOtpInput(false)}
      >
        <div className='modal-wrap'>
          <p className='title'>Verify your mobile number</p>

          {/* Phone */}
          <div className='mb-3'>
            <TextField
              fullWidth
              required
              value={fieldValues.phone}
              type='number'
              id='outlined-basic'
              label='Phone Number'
              variant='outlined'
              className={classes.formControlCustom}
              size='small'
              disabled
            />
          </div>

          <div className='mb-1'>
            <TextField
              fullWidth
              required
              value={fieldValues.otp}
              type="number"
              id="outlined-basic"
              label="Otp"
              variant="outlined"
              error={otpError}
              helperText={otpError}
              onChange={(event) => validation(event, 'otp')}
              className={classes.formControlCustom}
              size="small"
            />
          </div>

          {/* Resend otp */}
          <div className='d-flex flex-row justify-content-start resend-otp-wrap'>
            <Button
              type='button'
              variant='text'
              color='primary'
              className={classes.button} 
              onClick={resendOtp}
            >
              {resendOtpData.loader ? <CircularProgress size={20} color='inherit' /> : 'Resend Otp'}
            </Button>
          </div>
          
          <div className='d-flex flex-row'>

            {/* Cancel */}
            <Button
              type='button'
              variant='contained'
              color='default'
              className={classes.button}
              style={{ marginRight: '10px' }}
              onClick={() => setShowOtpInput(false)}
            >
              Cancel
            </Button>

            {/* Verify */}
            <Button
              type='button'
              variant='contained'
              color='primary'
              className={classes.button}
              disabled={fieldValues.otp?.trim('')?.length < 6}
              onClick={verifyOtp}
            >
              {otpLoader ? <CircularProgress size={20} color='inherit' /> : 'Verify'}
            </Button>

          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditProfile;