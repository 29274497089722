
import {
  getRequestHeader
} from "../apiCall";
import {
  URL_CONSTANTS
} from "../../constants/apiConstants";
import {
  propertyOtherAuctionList
} from "../../redux/adminProperty/adminProperty.actions";

export function PropertyOtherAuctionListApiCall(pageNo) {
  return (dispatch) => {
    dispatch(propertyOtherAuctionList(null, true));

    const url = `${URL_CONSTANTS.PROPERTY_OTHER_AUCTION_LIST}${pageNo}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertyOtherAuctionList(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertyOtherAuctionList(null, false));
      });
  };
}