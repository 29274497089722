import { createSelector } from "reselect";

const selectPersistantData = (state) => state.persistantData;

export const adminLoginState = createSelector(
  [selectPersistantData],
  (persistantData) => persistantData.login
);

export const userDetailsState = createSelector(
  [selectPersistantData],
  (persistantData) => persistantData.userDetails
);

export const userLoginState = createSelector(
  [selectPersistantData],
  (persistantData) => persistantData.userLogin
);