import UserActionTypes from './user.types';

export const addEndUser = (data, loader) => ({
  type: UserActionTypes.ADD_END_USER,
  payload: {
    data,
    loader
  }
});

export const resendOTP = (data, loader) => ({
  type: UserActionTypes.RESEND_OTP,
  payload: {
    data,
    loader
  }
});

export const showInterest = (data, loader) => ({
  type: UserActionTypes.SHOW_INTEREST,
  payload: {
    data,
    loader
  }
});

export const verifyOTP = (data, loader) => ({
  type: UserActionTypes.VERIFY_OTP,
  payload: {
    data,
    loader
  }
});

export const resetPassword = (data, loader) => ({
  type: UserActionTypes.RESET_PASSWORD,
  payload: {
    data,
    loader
  }
});

export const propertyDetailsForUser = (data, loader) => ({
  type: UserActionTypes.PROPERTY_DETAILS_FOR_USER,
  payload: {
    data,
    loader
  }
});

export const bankAuctionProperties = (data, loader) => ({
  type: UserActionTypes.BANK_AUCTION_PROPERTIES,
  payload: {
    data,
    loader
  }
});

export const userAdDetails = (data, loader) => ({
  type: UserActionTypes.USER_AD_DETAILS,
  payload: {
    data,
    loader
  }
});

export const savePropertyLink = (data, loader) => ({
  type: UserActionTypes.SAVE_PROPERTY_LINK,
  payload: {
    data,
    loader
  }
});