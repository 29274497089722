import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adminResetPassword } from "../../redux/admin/admin.actions";

export function AdminResetPasswordApiCall(phone, otp, newPassword, toastView, setIsVisibleForgotPassPage) {
  return (dispatch) => {
    dispatch(adminResetPassword(null, true));

    const url = `${URL_CONSTANTS.ADMIN_RESET_PASSWORD}phone=${phone}&otp=${otp}&new_password=${newPassword}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Reset Password Success", 'success');
          setIsVisibleForgotPassPage(false);
          dispatch(adminResetPassword(res.data.data, false));
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message, 'error');
        dispatch(adminResetPassword(null, false));
      });
  };
}