import AdminPropertyActionTypes from "./adminProperty.types";

const initialAdminPropertyState = {
  propertyOtherAuctionList: {
    data: null,
    loader: false
  },
  addProperty: {
    data: null,
    loader: false
  },
  bankAuctionPropertyList: {
    data: null,
    loader: false
  },
  propertyApproval: {
    data: null,
    loader: false
  },
  propertyUnApproval: {
    data: null,
    loader: false
  },
  updateProperty: {
    data: null,
    loader: false
  },
  removeProperty: {
    data: null,
    loader: false
  },
  dashboardSummary: {
    data: null,
    loader: false
  },
  editProperty: {
    data: null,
    loader: false
  },
  adminPropertyDetails: {
    data: null,
    loader: false
  },
  adminPropertySearch: {
    data: null,
    loader: false
  },
  adminBankSearch: {
    data: null,
    loader: false
  },
  propertyAddSuccess: false
};

const AdminPropertyReducer = (state = initialAdminPropertyState, action) => {
  switch(action.type) {
    case AdminPropertyActionTypes.PROPERTY_OTHER_AUCTION_LIST:
      return {
        ...state,
        propertyOtherAuctionList: {
          ...state.propertyOtherAuctionList,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.ADD_PROPERTY:
      return {
        ...state,
        addProperty: {
          ...state.addProperty,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.BANK_AUCTION_PROPERTY_LIST:
      return {
        ...state,
        bankAuctionPropertyList: {
          ...state.bankAuctionPropertyList,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.PROPERTY_APPROVAL:
      return {
        ...state,
        propertyApproval: {
          ...state.propertyApproval,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.PROPERTY_UNAPPROVAL:
      return {
        ...state,
        propertyUnApproval: {
          ...state.propertyUnApproval,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.UPDATE_PROPERTY:
      return {
        ...state,
        updateProperty: {
          ...state.updateProperty,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.REMOVE_PROPERTY:
      return {
        ...state,
        removeProperty: {
          ...state.removeProperty,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.DASHBOARD_SUMMARY:
      return {
        ...state,
        dashboardSummary: {
          ...state.dashboardSummary,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.EDIT_PROPERTY:
      return {
        ...state,
        editProperty: {
          ...state.editProperty,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.ADMIN_PROPERTY_DETAILS:
      return {
        ...state,
        adminPropertyDetails: {
          ...state.adminPropertyDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.ADMIN_PROPERTY_SEARCH:
      return {
        ...state,
        adminPropertySearch: {
          ...state.adminPropertySearch,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.ADMIN_BANK_SEARCH:
      return {
        ...state,
        adminBankSearch: {
          ...state.adminBankSearch,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case AdminPropertyActionTypes.PROPERTY_ADD_SUCCESS:
      return {
        ...state,
        propertyAddSuccess: action.payload
      };
    default:
      return state;
  }
};

export default AdminPropertyReducer;