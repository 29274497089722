import { useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { unitsData, landMeasurement, landRegistration, stampDuty } from './KnowledgeHub.data';
import { KNOWLEDGE, FLOWCHART } from '../../constants/iconConstants';
import './KnowledgeHub.styles.scss';

const useStyles = makeStyles({
  root: {
    marginTop: 40
  },
  head: {
    backgroundColor: 'black'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'white'
  }
});

const KnowledgeHub = () => {
  const sarfaesiActRef = useRef(null);
  const eAuctionRef = useRef(null);
  const duplexRef = useRef(null);
  const pentHouseRef = useRef(null);
  const landMeasurementRef = useRef(null);
  const landRegistrationRef = useRef(null);
  const modelRef = useRef(null);
  const carpetAreaRef = useRef(null);
  const classes = useStyles();

  const handleScroll = (number) => {
    let offsetPosition = 0;
    let offset = 60;
    let elementPosition = 0;
    if(number === 1) {
      elementPosition = sarfaesiActRef.current.getBoundingClientRect().top;
    } else if(number === 2) {
      elementPosition = eAuctionRef.current.getBoundingClientRect().top;
    } else if(number === 3) {
      elementPosition = duplexRef.current.getBoundingClientRect().top;
    } else if(number === 4) {
      elementPosition = pentHouseRef.current.getBoundingClientRect().top;
    } else if(number === 5) {
      elementPosition = landMeasurementRef.current.getBoundingClientRect().top;
    } else if(number === 6) {
      elementPosition = landRegistrationRef.current.getBoundingClientRect().top;
    } else if(number === 7) {
      elementPosition = modelRef.current.getBoundingClientRect().top;
    } else if(number === 8) {
      elementPosition = carpetAreaRef.current.getBoundingClientRect().top;
    }
    offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  return (
    <div className="knowledge-container container">
      <h1>KNOWLEDGE HUB</h1>
      <img
        src={KNOWLEDGE.default}
        className="knowledge-image"
        alt=""
        width="130px"
        height="130px"
      />
      <ol>
        <li onClick={() => handleScroll(1)}>SARFAESI ACT, 2002</li>
        <li onClick={() => handleScroll(2)}>E-Auction</li>
        <li onClick={() => handleScroll(3)}>Duplex House</li>
        <li onClick={() => handleScroll(4)}>Penthouse</li>
        <li onClick={() => handleScroll(5)}>Land Measurement Units in Indian States & UTs</li>
        <li onClick={() => handleScroll(6)}>Land/Property Registration Fee –State-wise</li>
        <li onClick={() => handleScroll(7)}>Model Tenancy Act 2021</li>
        <li onClick={() => handleScroll(8)}>Carpet Area of Flat/Apartment</li>
      </ol>
      <h3 ref={sarfaesiActRef}><span className="h3-subhead">SARFAESI</span> (Securitization and Reconstruction of Financial Assets and Enforcement of Security Interest ) Act , 2002</h3>
      <h4>Background</h4>
      <p>
        Narasimham Committee I and II and Andhyarujina Committee have made suggestions to form new legislation for securitization and empowering banks and 
        financial institutions to gain possession of the securities and to sell them without any intervention of the court here.
      </p>
      <p>
        It enables and empowers the secured creditors to take possession of their Securities, to deal with them without the intervention of the court 
        and also alternatively to authorize any Securitization or Reconstruction Company to acquire financial assets of any Bank or Financial Institution (FI). 
      </p>
      <p>
        The Act has been empowered with the overriding effect over the other legislation and it shall be in addition to and not in derogation of certain legislation.
      </p>
      <p>
        Sarfaesi Act allows secured creditors to take possession of the assets of a borrower who fails to pay dues within 60 days of demanding repayment.
      </p>
      <p>
        Recently Supreme Court of India given verdic that Cooperative Banks on a par with other lenders under Sarfaesi Act.
      </p>
      <h4>Applicability Of SARFAESI Act,2002</h4>
      <p>
        The amendment to this Act is “an act to regulate securitization and reconstruction of financial assets and enforcement of security interest and 
        to provide for a central database of security interests created on property rights, and for matters connected therewith or incidental thereto.” 
      </p>
      <p>The Act deals with the following:</p>
      <ul>
        <li>Registration and regulation of Asset Reconstruction Companies (ARCs) by the Reserve Bank of India.</li>
        <li>Entrusting the Asset Reconstruction Companies to raise funds by issue of security receipts to qualified buyers.</li>
        <li>
          Facilitating the reconstruction of financial assets which are acquired while exercising powers of enforcement of securities 
          or change of management or other powers which are proposed to be conferred on the banks and financial institutions.
        </li>
        <li>
          Presentation of any securitization company or asset reconstruction company registered with the Reserve Bank of India as a public financial institution.
        </li>
        <li>
          Defining ‘security interest’ to be any type of security including mortgage and change on immovable properties given for due repayment of any financial 
          assistance given by any bank or financial institution.
        </li>
        <li>
          Classification of the borrower’s account as a non-performing asset in accordance with the directions given or under guidelines issued by the 
          Reserve Bank of India from time to time.
        </li>
        <li>
          The officers authorized will exercise the rights of a secured creditor in this behalf in accordance with the rules made by the Central Government.
        </li>
        <li>
          An appeal against the action of any bank or financial institution to the concerned Debts Recovery Tribunal and a 
          second appeal to the Appellate Debts Recovery Tribunal.
        </li>
        <li>
          The Central Government may set up or cause to be set up a Central Registry for the purpose of registration of transactions relating to 
          securitization, asset reconstruction and creation of the security interest
        </li>
        <li>
          Application of the proposed legislation initially to banks and financial institutions and empowerment of the Central Government to extend 
          the application of the proposed legislation to non-banking financial companies and other entities.
        </li>
        <li>
          Non-application of the proposed legislation to security interests in agricultural lands, loans less than rupees one lakh and cases where 
          eighty per cent, of the loans, is repaid by the borrower.
        </li>
      </ul>
      <h4>FLOW CHART</h4>
      <img
        src={FLOWCHART.default}
        className="flowchart-image"
        alt=""
      />
      <h3 className="h3-subhead" ref={eAuctionRef}>E-Auction</h3>
      <ul>
        <li>
          eAuctions are online, real-time dynamic negotiations between a buying company and a number of pre-qualified suppliers who compete directly against 
          each other to win the right to supply the specific assets or services or in other words It is a non-conventional method of auction conducted 
          electronically through E-Auction sale software. Banks or Auctioneers are used to sell their properties through online using internet & software technology. 
          In case of property, Banks or DRTs  forward auction is concerned where the buyer bids on the seller's product and the prices of the item are increased by a 
          fixed incremental amount during the auction.  A new way to source  eAuctions are an improved way to negotiate with suppliers in a global marketplace. 
          In the past, many negotiations were conducted face-to-face, via email, and phone calls. Now, with sourcing technology, buyers and suppliers throughout 
          the world can connect to bid and sell products and services competitively.
        </li>
        <li>
          There are many benefits to eAuctions for both buyers and suppliers. Regardless of your company’s industry, it can utilize eAuctions to obtain the 
          best value on a variety of products and services across categories. 
        </li>
        <li>
          Type of eAuctions: Most people are familiar with Forward Auctions (like we know them from eBay and Auction Houses), in which multiple buyers 
          bid on a product or service by offering increasingly higher prices. The most common type of eAuctions however, are a Reverse Auctions; here the 
          opposite takes place. The roles of buyer and seller are swapped, so that multiple sellers compete to earn the buyer’s business by incrementally lowering 
          the price of the product of service. Banks, Financial Institutions &r DRTs  do forward auction where the buyer bids on the seller's product and the prices 
          of the item are increased by a fixed incremental amount during the auction.
        </li>
      </ul>
      <h3 className="h3-subhead" ref={duplexRef}>Duplex House</h3>
      <ul>
        <li>
          Duplexes are always two-storied homes although not all two-storied structures can be classified as duplexes. In the latter, 
          floors are linked through staircases while the former may have independent entries which may or may not be linked. A duplex 
          house is a residential building constructed on two floors. It has a single dining room and a single kitchen. It has a common 
          central wall and consists of two living units, either side-by-side or on two floors, with separate entries. While there are two 
          floors, it is sold together and owned by an individual. It may have separate entry points for both floors.
        </li>
        <li>
          In India, duplexes generally have a kitchen, hall and bedrooms on the lower floor while the upper floor houses the master bedroom. 
          A duplex always has two floors and never three or four floors, in which case it would be called a multiplex. Aduplex has always only 
          two floors not three or four floors.
        </li>
        <li>
          What is the biggest differentiator for duplex units?:- Duplexes have two units which are fully owned by one individual or family 
          and the ownership structure is what demarcates them from other condos, apartments and buildings. 
        </li>
        <li>
          What is the difference between apartment and duplex?:- Apartment units are usually part of a high-rise, unlike a duplex which is 
          essentially a two-storeyed structure, which may have two living units and two different entry points, as well.
        </li>
        <li>
          What is Difference between two-storey house and duplex? :- A duplex house is always a two-storeyed structure but all two-storeyed buildings 
          may not be categorised as duplexes. In duplexes, the floors are connected through a staircase, while two-storeyed properties may be independent 
          of each other and may or may not be connected.
        </li>
        <li>
          What is Difference between duplex and villa? A villa may or may not be a two-storeyed property and is usually larger than a duplex house. 
          Both are used for residential purposes but a villa is considered as a plush purchase, when compared to a duplex. A villa is generally the 
          first choice for those who want total privacy. In fact, the villa concept dates back to the Roman era, when the rich and the famous moved 
          to the countryside during the summers. Today, villas may be independently owned homes in a plot of land but yet are within a gated community. 
          Villas also offer amenities such as a clubhouse, gym, pool, etc., unlike a duplex.
        </li>
        <li>
        	What is Difference between duplex and penthouse:- Unlike a duplex that is a two-storeyed property, a penthouse is always located in the top floor 
          of a multi-storey building or apartment. These units are expensive, as they offer a good view and are generally the only unit on that particular 
          floor design. All the units that were supposed to be on this floor, are consolidated into one.
        </li>
        <li>
          What Difference between duplex and independent house :- The term ‘independent home’ is used interchangeably to refer to villas, duplexes and the like. 
          An independent house may have as many floors as desired but a duplex essentially has two floors.
        </li>
        <li>
          What is better: duplex or flat:- While both kinds of properties have their own pros and cons, a duplex property will come with more responsibilities 
          than a flat. The property would need more maintenance and upkeep and this means more expenses. For flats, while the maintenance would be less and 
          there will be fewer responsibilities, the experience would be limited to the space inside, as you would have to share the open spaces with other society members. 
          Nevertheless, liquidating an apartment property would be easier than selling a duplex property.
        </li>
      </ul>
      <h3 className="h3-subhead" ref={pentHouseRef}>Penthouses</h3>
      <h4>What is Penthouse?</h4>
      <p>
        According to Wikipedia A penthouse[a] is an apartment or unit on the highest floor of an apartment building, condominium, or hotel. Penthouses are typically 
        differentiated from other apartments by luxury features. The term 'penthouse' originally referred, and sometimes still does refer, to a separate smaller 'house' 
        that was constructed on the roof of an apartment building
      </p>
      <p>
        Penthouse is ‘an expensive and comfortable flat or set of rooms at the top of a tall building.  When the concept first became popular, developers followed the 
        term’s original definition. 
      </p>
      <p>
        The concept first gained popularity in major global business districts, where privacy and space increasingly became hard to find amid rising population. 
        Sensing the demand, developers started constructing penthouses on the top floor of apartment buildings and charged a premium for such units. As demand patterns 
        evolved, developers started making changes in the building structure to accommodate more penthouses, which could be built anywhere in the building.
      </p>
      <p>
        Recently, developers use the term penthouse even more freely to define exclusive units in housing projects that have more opulent features than 
        the remaining units, because of their locational advantage and amenities.
      </p>
      <p>
        It will also be correct to say that within the luxury housing market, there is a sub-market that could be categorized as the penthouse market. 
        nvestors in this category, who crave privacy and value uniqueness, seek the best apartment within a grand project and are often willing to shell out 
        a premium, to secure such a fancy unit.
      </p>
      <p>
        As penthouses are not very common in India, developers mostly stick to the standard definition, when they market the product. 
        A penthouse in India is basically a penthouse in the traditional sense.
      </p>
      <h4>Facilities offered in penthouses:</h4>
      <p>
        A penthouse would have an open terrace that is exclusive to the owner. These units also carry opulent indoor fittings. Unlike a regular unit, the ceiling 
        is also much higher in a penthouse and it has a different layout plan and luxury amenities, including gymnasium, swimming pools and sometimes even private elevators.
      </p>
      <p>
        These units are designed specially, to cater to specific types of home buyers, who are willing to pay premium prices for procuring that ultra-luxury space 
        and the state-of-art features associated with it
      </p>
      <h4>Benefits of a penthouse:</h4>
      <p>
        Privacy, terrace space and high ceilings are the typical features of penthouses that differentiate it from regular homes.
      </p>
      <h4>Unobstructed view:</h4>
      <p>
        Penthouses generally provide ample natural light and ventilation and an unobstructed view of the surroundings. These are things that not many may 
        be able to afford in a big city. These units offer more peace and quiet, because of the placement of the units.
      </p>
      <h4>Exclusivity:</h4>
      <p>
        In India, the ownership of a penthouse would be viewed with admiration among your peers. The owner of a penthouse often enjoys a variety of services 
        that others in the same building do not get.
      </p>
      <h4>Recession-proof investment:</h4>
      <p>
        Unlike in western countries, where penthouses are available in large numbers, developers in India have been careful, in launching projects with penthouses. 
        In the presence of limited supply, values of penthouses continue to grow. As long as the supply is lower than the total demand, there is no reason to be 
        worried about value depreciation.
      </p>
      <h3 className="h3-subhead" ref={landMeasurementRef}>
        Land Measurement Units in Indian States & UTs
      </h3>
      <p>
        In India Land measurement are in varieties and if you go from one state to another state you will get to listen different meansurement units it. In India 
        you go North you will come across units like Bigha, Marla,etc and when you will switch to south you will hear Cent, Guntha, Ground, Cents, Acre etc. 
        According to different name of units, sizes also vary from state to state. There are only some units like Square Meters, Hectares Square Feet and
        Square Yards and Acres which are commonly used land measurements units all over India.
      </p>
      <h4>Here are some commonly used Units:</h4>
      <div className="row">
        <div className="col-xl-6">
          <TableContainer component={Paper} className={classes.root}>
            <Table>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell className={classes.title}>Unit of Area</TableCell>
                  <TableCell className={classes.title}>Conversion Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitsData.map((dat) => (
                  <TableRow key={dat.id}>
                    <TableCell component="th" scope="row">
                      {dat.unit}
                    </TableCell>
                    <TableCell>{dat.conversion}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <h3 className="h3-subhead" ref={landMeasurementRef}>Land Measurement Units used in North India</h3>
      <TableContainer component={Paper} className={classes.root}>
        <Table>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.title}>Term of Unit</TableCell>
              <TableCell className={classes.title}>Used Conversion</TableCell>
              <TableCell className={classes.title}>States where applicable</TableCell>
              <TableCell className={classes.title}>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landMeasurement.map((dat) => (
              <TableRow key={dat.id}>
                <TableCell component="th" scope="row">
                  {dat.unit}
                </TableCell>
                <TableCell>{dat.conversion}</TableCell>
                <TableCell>{dat.states}</TableCell>
                <TableCell>{dat.remarks}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h3 className="h3-subhead" ref={landRegistrationRef}>
        Land/Property Registration Fee – State-wise
      </h3>
      <p>
        DLC rate is a term largely used in Rajasthan, DLC rate stands for District Level Committee rate. It is the minimum rate at which the stamp duty is calculated. 
        It is known by other names in the rest of the country as below.
      </p>
      <h4>Other names of DLC rate in India</h4>
      <TableContainer component={Paper} className={classes.root}>
        <Table>
          <TableBody>
            {landRegistration.map((dat) => (
              <TableRow key={dat.id}>
                <TableCell component="th" scope="row">
                  {dat.state}
                </TableCell>
                <TableCell>{dat.dlcName}</TableCell>
                <TableCell>
                  <a href={dat.link} target="_blank" rel="noreferrer">{dat.link}</a>
                  {dat?.link2 && <a href={dat.link2} target="_blank" rel="noreferrer">{dat.link2}</a>}
                  {dat?.link3 && <a href={dat.link3} target="_blank" rel="noreferrer">{dat.link3}</a>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h4>Stamp Duty Rates Applicable in Various States & UTs</h4>
      <p>Stamp duty rates in various States & UTs are  listed  below:</p>
      <TableContainer component={Paper} className={classes.root}>
        <Table>
          <TableBody>
            {stampDuty.map((dat) => (
              <TableRow key={dat.id}>
                <TableCell component="th" scope="row">
                  {dat.state}
                </TableCell>
                <TableCell>{dat.rate}</TableCell>
                <TableCell>{dat.remarks}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h3 className="h3-subhead" ref={modelRef}>Model Tenancy Act 2021</h3>
      <p>
        The Cabinet on June 2 approved the Model Tenancy Act for circulation to the States/Union Territories for adoption. It is aimed at 
        opening up of the vacant housing stock for rental housing purposes and helping bridge the trust deficit that exists between tenants 
        and landlords by clearly delineating their obligations. The Centre has claimed that the MTA act will balance the interest and rights of 
        both the owner and tenant in an accountable and transparent manner.
      </p>
      <p>
        As per HT, the Model Tenancy Act will provide a model for urban and rural properties, as well as a template for residential and commercial 
        properties. Here are the salient features of the act:
      </p>
      <ul>
        <li>
          The government said that the Model Tenancy Act aims to create a vibrant, sustainable and inclusive rental housing market in the country. 
          It will enable creation of adequate rental housing stock for all the income groups thereby addressing the issue of homelessness.
        </li>
        <li>
          The act will enable institutionalization of rental housing by gradually shifting it towards the formal market, it further said.
        </li>
        <li>
          The Model Tenacy Act talks about establishing a rent authority to regulate renting of premises and to protect the interests of landlords and 
          tenants. The proposed authority will also provide speedy adjudication mechanism for resolution of disputes and matters connected therewith or 
          incidental thereto, according to the act.
        </li>
        <li>
          It also puts a cap on the amount of security deposit - usually a bone of contention between landlords and tenants - 
          to a maximum of two months of rent in case of residential premises and six months in case of non-residential premises. Currently, 
          this amount differs from one city to another. For instance, in Delhi, the deposit is usually two-three times the monthly rent, but 
          in Mumbai and Bengaluru, it can be over six times the monthly rent.
        </li>
        <li>
          Another crucial point where the act has provided a mechanism, is vacating the premises. The Model Tenancy Act says that if a landlord has 
          fulfilled all the conditions stated in the rent agreement - giving notice etc - and tenant fails to vacate the premises on the expiration 
          of the period of tenancy or termination of tenancy, the  landlord is entitled to double the monthly rent for two months and four times after that.
        </li>
        <li>
          Every landlord or the property manager may enter the premises let out on rent after serving a notice, in writing or through electronic mode, 
          to the tenant at least twenty four hours before the time of entry under the following circumstances, the act states.
        </li>
        <li>
          It has further described in detail the rights and obligations of both landlord and tenant, and has also dealt with many issues which can lead to 
          disputes between the two parties
        </li>
        <li>
          A written agreement will be required between the owner and tenant for rent and duration of tenancy which is to be submitted to the district's rent authority
        </li>
        <li>
          To increase the rent, the owner must inform the tenant three months before
        </li>
        <li>
          The revision of the rent cannot be arbitrary, under the act. Although there has not been a financial ceiling to determine the value of increased rent, 
          but the owner must notify the tenant three months before revising the rent. A written notice must be given to the tenant three months before the revision of the rent.
        </li>
        <li>
          How much security deposit a tenant must pay?
        </li>
        <li>
          Under the act, a tenant must pay two months of security deposit to rent a residential property. Whereas to rent a commercial property, a tenant 
          must pay six months of security deposit. The Act states that security deposit shall be refunded by the landlord to the tenant on the date of taking 
          over vacant possession of the premises from the tenant after making due deduction of any liability of the tenant.
        </li>
        <li>
          Tenant cannot overstay after the end of tenancy period
        </li>
        <li>
          After the end of the tenancy period as mentioned in the agreement between owner and the tenant, if a tenant refuses to vacate the concerned premises, 
          the owner will be entitled to compensation double the monthly rent for two months. If even after two months of tenancy’s expiration, the tenant doesn’t 
          vacate then under the Tenancy Model Act, the owner will be entitled to compensation four times the monthly rent.
        </li>
        <li>
          Owner can’t withhold essential supply, must make structural repairs
        </li>
        <li>
          Under the act, no landlord can withhold any essential supply to the premises occupied by the tenant, such as electricity/water supply.
        </li>
        <li>
          Unless agreed in the tenancy agreement, the act says, the owner will be responsible for activities like structural repairs except those necessitated by damage 
          caused by the tenant, whitewashing of walls and painting of doors and windows, changing and plumbing pipes when necessary and internal and external electrical 
          wiring and related maintenance when necessary.
        </li>
        <li>
          Up to individual states to implement The Tenancy Model Act, 2021
        </li>
        <li>
          Since it is a Model Act, it is up to the individual states to implement the act. Model laws are not binding on the states.
        </li>
      </ul>
      <h3 className="h3-subhead" ref={carpetAreaRef}>Carpet Area of Flat</h3>
      <p>
        Carpet area is the area enclosed within the walls of your flat. It refers to the area inside your house on which you can actually lay a carpet and 
        physically move around. ... The higher the ratio of carpet area to super built-up area, the more space you get inside your flat. It can be used to 
        spread a carpet inside the house, that is  the net usable area of the apartment or flat. It will include the thickness of the internal wall but excludes 
        balcony or terrace. In Civil engineering language, the distance between inner walls is carpet area. It will also include staircase  if it is inside the 
        apartment, but excludes balcony, lift, lobby, etc..
      </p>
      <h4>How to Calculate Carpet Area?</h4>
      <p>
        To calculate carpet area,  you should know that carpet area is 70 per cent of built-up area. For example assuming that the built-up area is 2,000 sq ft, 
        your carpet area should be 70 per cent of 2,000 sq ft, which is, in this case, is 1400 sq ft. So your carpet area is considered as 1400 sq.ft.
      </p>
      <h4>What is Built-Up Area?</h4>
      <p>
        Built-up area is the carpet area plus the area covered by walls. The built-up area includes balconies, terraces (with or without roof), mezzanine floors and other 
        detachable habitable areas such as servant room, etc. You should also know that walls which are shared with other units are factored in at 50 per cent while other 
        walls are calculated fully.
      </p>
      <h4>How To Calculate Built-Up Area?</h4>
      <p>
        Technically, built-up area = carpet area + areas covered by walls. Normally it is 10-15% higher than the carpet area. This can be understood with an example i.e. 
        areas covered as dry balcony, terraces add up to the 10 per cent of the built-up area while the usable area is just 70 per cent of the built-up area. So, if built 
        up area is 2,000 sq ft, it implies that 30 per cent i.e. the 600 sq ft is not usable while 1400 sq ft is the remaining area that will be used.
      </p>
      <h4>What is Super Built-Up Area?</h4>
      <p>
        Super built-up area is the built-up area in addition to the proportionate share of entrance lobby, corridors, stair cases, lift shafts, lift lobby, generator 
        rooms, club house, security room and any other common areas in the complex. Underground sump, water tanks, walk ways, swimming pool, open sports facilities, 
        weather sheds, inaccessible flower beds, Gym, lofts shall not include in super built-up area.
      </p>
      <h4>How to calculate super Built-Up Area?</h4>
      <p>
        Since super built-up area factors in the common areas such as elevator, veranda/corridor, clubhouse, Gym, Thither etc. developers consider 1.25 as a multiplying 
        factor to calculate super built-up area. This increases the total saleable area by 25 per cent. This percentage is called loading. Few developers tend to quote 
        loading figures while calculating saleable area. For example, if the carpet area is 700 sq ft, the builder adds loading of 30 per cent i.e 210 sq.ft , you have 
        to pay for 910 sq ft, whereas you are using just 700 sq ft area only.
      </p>
    </div>
  );
}

export default KnowledgeHub;