import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { interestedReport } from "../../redux/report/report.actions";

export function InterestedReportApiCall(toastView) {
  return (dispatch) => {
    dispatch(interestedReport(null, true));

    const url = URL_CONSTANTS.INTERESTED_REPORT;

    getRequestHeader(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(interestedReport(res?.data, false));
        }
      })
      .catch((error) => {
        dispatch(interestedReport(null, false));
      });
  };
}