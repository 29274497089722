import { nanoid } from 'nanoid';

export const adsData = [
  {
    id: nanoid(),
    label: 'Homepage Slot #1',
    value: 'Homepage_Slot_1'
  },
  // {
  //   id: nanoid(),
  //   label: 'Homepage Slot #2',
  //   value: 'Homepage_Slot_2'
  // },
  // {
  //   id: nanoid(),
  //   label: 'Homepage Slot #3',
  //   value: 'Homepage_Slot_3'
  // },
  // {
  //   id: nanoid(),
  //   label: 'Homepage Slot #4',
  //   value: 'Homepage_Slot_4'
  // },
  {
    id: nanoid(),
    label: 'Search Results Page Slot #1',
    value: 'Search_Results_Page_Slot_1'
  },
  {
    id: nanoid(),
    label: 'Search Results Page Slot #2',
    value: 'Search_Results_Page_Slot_2'
  },
  {
    id: nanoid(),
    label: 'Search Results Page Slot #3',
    value: 'Search_Results_Page_Slot_3'
  },
  {
    id: nanoid(),
    label: 'Bank Auction Page Slot #1',
    value: 'Bank_Auction_Page_Slot_1'
  },
  {
    id: nanoid(),
    label: 'Bank Auction Page Slot #2',
    value: 'Bank_Auction_Page_Slot_2'
  },
  {
    id: nanoid(),
    label: 'Bank Auction Page Slot #3',
    value: 'Bank_Auction_Page_Slot_3'
  },
  {
    id: nanoid(),
    label: 'Buy/Sale Page Slot #1',
    value: 'Buy_Sale_Page_Slot_1'
  },
  {
    id: nanoid(),
    label: 'Buy/Sale Page Slot #2',
    value: 'Buy_Sale_Page_Slot_2'
  },
  {
    id: nanoid(),
    label: 'Buy/Sale Page Slot #3',
    value: 'Buy_Sale_Page_Slot_3'
  }
];