import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertyDetailsForUser } from "../../redux/user/user.actions";

export function PropertyDetailsForUserApiCall(propertyId) {
  return (dispatch) => {
    dispatch(propertyDetailsForUser(null, true));

    const url = `${URL_CONSTANTS.PROPERTY_DETAILS_FOR_USER}${propertyId}`;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertyDetailsForUser(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertyDetailsForUser(null, false));
      });
  };
}