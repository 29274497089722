import axios from "axios";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { store } from "../../redux/store";

export function UploadFileApiCall(file, onUploadProgress = () => {}) {
  const state = store.getState();
  const authToken = state.token.adminToken;
  let formData = new FormData();

  formData.append("file", file);

  return axios.post(URL_CONSTANTS.UPLOAD_FILE, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${authToken}`,
      "Access-Control-Allow-Origin": "*",
      "Accept": "*/*"
    },
    onUploadProgress
  });
}
