import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from 'react-bootstrap/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { CLOSE_BUTTON } from '../../constants/iconConstants';
import { adminLoginState } from '../../redux/persistantData/persistantData.selector';
import { getTopTenCities, getTopTenStates } from '../../redux/search/search.selector';
import './Modals.styles.scss';
import { GetAllInstitutionApiCall } from "../../apiCalls/Institution/GetAllInstitutionApiCall.js";
import { getAllInstitution } from "../../redux/institution/institution.selector.js";

const AddUserModal = ({
  showAddUserModal,
  setShowAddUserModal,
  addUserValues,
  setAddUserValues,
  addUserFields,
  handleAddUser
}) => {
  const topStates = useSelector(getTopTenStates);
  const topCities = useSelector(getTopTenCities);
  const dispatch = useDispatch();
  const insututeList = useSelector(getAllInstitution);
  const [showPassword, setShowPassword] = useState(false);
  const [isInstitutionEnabled, setIsInstitutionEnabled] = useState(false);
  const adminLoginDetails = useSelector(adminLoginState);
  const [validated, setValidated] = useState(false);
  const [err, setErr] = useState({
    state: null, city: null, mobileErr: null, aadhaarErr: null
  });

  const validateLocation = (name, value) => {
    let stateErr = err?.state;
    let cityErr = err?.city;
    if (name === 'city') {
      if (!value || value?.trim("")?.length === 0) {
        cityErr = "City is required"
      } else {
        cityErr = null;
      }
    } else if (name === 'state') {
      if (!value || value?.trim("")?.length === 0) {
        stateErr = 'State is required';
      } else {
        stateErr = null;
      }
    }
    setErr({
      ...err,
      city: cityErr,
      state: stateErr
    });
  }

  useEffect(() => {
    dispatch(GetAllInstitutionApiCall());
  }, [dispatch]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let stateErr, cityErr, mobErr, aadhaarErr = null;
    if (!addUserValues?.city_name || addUserValues?.city_name?.trim("")?.length === 0) {
      cityErr = "City is required"
    } else {
      cityErr = null;
    }
    if (!addUserValues?.state_name || addUserValues?.state_name?.trim("")?.length === 0) {
      stateErr = 'State is required';
    } else {
      stateErr = null;
    }
    if(addUserValues?.phone?.trim('')?.length !== 10) {
      mobErr = "Invalid Mobile Number";
    }
    if(addUserValues?.aadhaar_no?.trim('')?.length !== 12) {
      aadhaarErr = "Invalid Aadhaar No."
    }
    setErr({
      ...err,
      city: cityErr,
      state: stateErr,
      mobileErr: mobErr,
      aadhaarErr: aadhaarErr
    });
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else if (!stateErr && !cityErr && !mobErr && !aadhaarErr) {
      handleAddUser();
      setValidated(false);
    }
  }

  const closeModal = () => {
    setAddUserValues({});
    setShowAddUserModal(false);
    setValidated(false);
    setErr({
      ...err,
      state: null,
      city: null
    });
  }

  return (
    <Modal
      size="lg"
      show={showAddUserModal}
    >
      <div style={{ margin: 20 }}>
        <Modal.Header>
          <Modal.Title>Add User</Modal.Title>
          <img
            src={CLOSE_BUTTON.default}
            alt=""
            className="button-hover"
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <div className="row d-flex justify-content-between">
              {
                addUserFields.map((user) => (
                  <div
                    key={user.id}
                    className={`mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12 ${user.position === 'right' && 'second-field'}`}
                  >
                    <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12 p-0">
                      <Form.Label
                        htmlFor={`${user.value}-field`}
                        className="form-label"
                      >
                        {user.label}<span className="required-star">*</span>
                      </Form.Label>
                      {user.label === 'City' || user.label === 'State' ? (
                        <Autocomplete
                          freeSolo
                          id="dropdown"
                          className="user-select-menu"
                          options={(
                            user.label === 'State'
                              ? topStates.data
                              : topCities.data
                          ) ?? []}
                          disableClearable
                          noOptionsText={
                            user?.label === 'State'
                              ? "No states"
                              : "No cities"
                          }
                          getOptionLabel={(option) =>
                            user.label === 'State'
                              ? option.state_name
                              : option.city_name
                          }
                          onChange={(event, newValue) => 
                            setAddUserValues({
                              ...addUserValues,
                              [user.value]: user?.label === 'State'
                                ? newValue?.state_id
                                : newValue?.city_id
                            })
                          }
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              error={err[user?.value]}
                              helperText={err[user?.value]}
                              {...params}
                              variant="outlined"
                              onChange={(e) => {
                                setAddUserValues({
                                  ...addUserValues,
                                  [`${user.value}_name`]: e.target.value
                                });
                                validateLocation(user?.value, e.target.value);
                              }}
                            />
                          )}
                        />
                      ) : user.type === 'password' ? (
                        <>
                          <span className="error">Min 6 Characters Required</span>
                          <Form.Control
                            required
                            minLength={user?.minLength}
                            maxLength={user?.maxLength}
                            type={showPassword ? 'text' : 'password'}
                            className="form-control pass-field"
                            id={`${user.value}-field`}
                            value={addUserValues[user.value]}
                            aria-describedby={`${user.value}Help`}
                            onChange={(e) => setAddUserValues({ ...addUserValues, [user.value]: e.target.value })}
                          />
                          {!showPassword ? 
                            <VisibilityOffIcon className="showPass" onClick={() => setShowPassword(true)} /> : 
                            <VisibilityIcon className="showPass" onClick={() => setShowPassword(false)} />
                          }
                        </>
                      ) : (
                        <Form.Control
                          required
                          minLength={user?.minLength}
                          maxLength={user?.maxLength}
                          type={user.type}
                          className="form-control"
                          id={`${user.value}-field`}
                          isInvalid={user?.value === "phone" ? err?.mobileErr 
                            : user?.value === "aadhaar_no" ? err?.aadhaarErr 
                            : user?.value?.trim('')?.length === 0
                          } 
                          value={addUserValues[user.value]}
                          aria-describedby={`${user.value}Help`}
                          onChange={(e) => setAddUserValues({ ...addUserValues, [user.value]: e.target.value })}
                        />
                      )}
                      {(user?.value !== "phone" && user?.value !== "aadhaar_no") && (
                        <Form.Control.Feedback type="invalid">{user.label} is required</Form.Control.Feedback>
                      )}
                      {user?.value === "phone" && <Form.Control.Feedback type="invalid">{err?.mobileErr}</Form.Control.Feedback>}
                      {user?.value === "aadhaar_no" && <Form.Control.Feedback type="invalid">{err?.aadhaarErr}</Form.Control.Feedback>}
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="mb-3">
              <Form.Control
                as="select"
                className="user-select-menu"
                placeholder="User type"
                required
                value={addUserValues?.userType}
                onChange={(e) =>  {
                  setAddUserValues({ ...addUserValues,  userType: e.target.value });
              
                  setIsInstitutionEnabled(e.target.value === "channel partner");
                }}
              >
                <option value="">Select User Type</option>
                {adminLoginDetails.data?.privileges?.add_branch_partners && (
                  <option value="branch partner">Branch Partner</option>
                )}
                {adminLoginDetails.data?.privileges?.add_channel_partners && (
                  <option value="channel partner">Channel Partner</option>
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">User Type is required</Form.Control.Feedback>
            </div>
            <div className="mb-3">
              <Form.Control
                as="select"
                className="user-select-menu"
                placeholder="Institution"
                required
                disabled={!isInstitutionEnabled}
                onChange={(e) => {
                  setAddUserValues({ ...addUserValues, institute: e.target.value })
                  console.log(e.target.value);

                }}
              >
                <option value="">Select Institution</option>
                {insututeList?.map((institute, i) => (
                  <option key={i} value={institute?.institution_id}>
                    {institute?.institution}
                  </option>
                ))}
              </Form.Control>
              {addUserValues.userType === "channel partner" && (
                <Form.Control.Feedback type="invalid">Institution is required</Form.Control.Feedback>
              )}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="address-field" className="form-label">Address<span className="required-star">*</span></Form.Label>
              <Form.Control
                as="textarea"
                required
                type="text"
                className="form-control"
                id="address-field"
                aria-describedby="addressHelp"
                value={addUserValues.address}
                onChange={(e) => setAddUserValues({ ...addUserValues, address: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Address is required</Form.Control.Feedback>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <Button
                variant="dark"
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default AddUserModal;