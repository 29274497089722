import { nanoid } from 'nanoid';
import { PROFILE } from '../../constants/iconConstants';

const architectsData = [
  {
    id: nanoid(),
    city: 'JAIPUR',
    image: PROFILE,
    data: [
      { id: nanoid(), label: 'Name', value: '' },
      { id: nanoid(), label: 'Name of Organization', value: '' },
      { id: nanoid(), label: 'Experience', value: '' },
      { id: nanoid(), label: 'Major works', value: '' },
      { id: nanoid(), label: 'Brief Details', value: '' },
      { id: nanoid(), label: 'Contact No.', value: '' },
      { id: nanoid(), label: 'Email id', value: '' },
      { id: nanoid(), label: 'Website', value: '' }
    ]
  },
  {
    id: nanoid(),
    city: 'DELHI',
    image: PROFILE,
    data: [
      { id: nanoid(), label: 'Name', value: '' },
      { id: nanoid(), label: 'Name of Organization', value: '' },
      { id: nanoid(), label: 'Experience', value: '' },
      { id: nanoid(), label: 'Major works', value: '' },
      { id: nanoid(), label: 'Brief Details', value: '' },
      { id: nanoid(), label: 'Contact No.', value: '' },
      { id: nanoid(), label: 'Email id', value: '' },
      { id: nanoid(), label: 'Website', value: '' }
    ]
  }
];

export { architectsData };