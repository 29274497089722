import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertyApproval } from "../../redux/adminProperty/adminProperty.actions";

export function PropertyApprovalApiCall(propertyId, toastView, approvalSuccess) {
  return (dispatch) => {
    dispatch(propertyApproval(null, true));

    const url = `${URL_CONSTANTS.PROPERTY_APPROVAL}${propertyId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Property Approval Success", 'success');
          dispatch(propertyApproval(res.data.data, false));
          approvalSuccess();
        }
      })
      .catch((error) => {
        dispatch(propertyApproval(null, false));
      });
  };
}