import UserActionTypes from "./user.types";

const initialUserState = {
  addEndUser: {
    data: null,
    loader: false
  },
  resendOTP: {
    data: null,
    loader: false
  },
  showInterest: {
    data: null,
    loader: false
  },
  verifyOTP: {
    data: null,
    loader: false
  },
  resetPassword: {
    data: null,
    loader: false
  },
  propertyDetailsForUser: {
    data: null,
    loader: false
  },
  bankAuctionProperties: {
    data: null,
    loader: false
  },
  userAdDetails: {
    data: null,
    loader: false
  },
  savePropertyLink: {
    data: null,
    loader: false
  }
};

const userReducer = (state = initialUserState, action) => {
  switch(action.type) {
    case UserActionTypes.ADD_END_USER:
      return {
        ...state,
        addEndUser: {
          ...state.addEndUser,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.RESEND_OTP:
      return {
        ...state,
        resendOTP: {
          ...state.resendOTP,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.SHOW_INTEREST:
      return {
        ...state,
        showInterest: {
          ...state.showInterest,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.VERIFY_OTP:
      return {
        ...state,
        verifyOTP: {
          ...state.verifyOTP,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.PROPERTY_DETAILS_FOR_USER:
      return {
        ...state,
        propertyDetailsForUser: {
          ...state.propertyDetailsForUser,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.BANK_AUCTION_PROPERTIES:
      return {
        ...state,
        bankAuctionProperties: {
          ...state.bankAuctionProperties,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.USER_AD_DETAILS:
      return {
        ...state,
        userAdDetails: {
          ...state.userAdDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case UserActionTypes.SAVE_PROPERTY_LINK:
      return {
        ...state,
        savePropertyLink: {
          ...state.savePropertyLink,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default userReducer;