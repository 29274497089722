import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { addProperty, propertyAddSuccess } from "../../redux/adminProperty/adminProperty.actions";

export function AddPropertyApiCall(data, toastView, handleSuccess) {
  return (dispatch) => {
    dispatch(addProperty(null, true));
    dispatch(propertyAddSuccess(false));

    const url = URL_CONSTANTS.ADD_PROPERTY;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView('Property Added', 'success');
          dispatch(propertyAddSuccess(true));
          handleSuccess();
          dispatch(addProperty(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(addProperty(null, false));
        dispatch(propertyAddSuccess(false));
      });
  };
}
