import AdminActionTypes from "./admin.types";

export const adminsByRole = (data, loader) => ({
  type: AdminActionTypes.ADMINS_BY_ROLE,
  payload: {
    data,
    loader
  }
});

export const getAdminDetails = (data, loader) => ({
  type: AdminActionTypes.GET_ADMIN_DETAILS,
  payload: {
    data,
    loader
  }
});

export const getAdminRoles = (data, loader) => ({
  type: AdminActionTypes.GET_ADMIN_ROLES,
  payload: {
    data,
    loader
  }
});

export const createAdmin = (data, loader) => ({
  type: AdminActionTypes.CREATE_ADMIN,
  payload: {
    data,
    loader
  }
});

export const changeAdminPassword = (data, loader) => ({
  type: AdminActionTypes.CHANGE_ADMIN_PASSWORD,
  payload: {
    data,
    loader
  }
});

export const updateAdminRole = (data, loader) => ({
  type: AdminActionTypes.UPDATE_ADMIN_ROLE,
  payload: {
    data,
    loader
  }
});

export const removeAdmin = (data, loader) => ({
  type: AdminActionTypes.REMOVE_ADMIN,
  payload: {
    data,
    loader
  }
});

export const adminResetPassword = (data, loader) => ({
  type: AdminActionTypes.ADMIN_RESET_PASSWORD,
  payload: {
    data,
    loader
  }
});

export const adminResendOtp = (data, loader) => ({
  type: AdminActionTypes.ADMIN_RESEND_OTP,
  payload: {
    data,
    loader
  }
});

export const adDetails = (data, loader) => ({
  type: AdminActionTypes.AD_DETAILS,
  payload: {
    data,
    loader
  }
});

export const adChange = (data, loader) => ({
  type: AdminActionTypes.AD_CHANGE,
  payload: {
    data,
    loader
  }
});
