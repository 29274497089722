import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Modals.styles.scss';

const DeletePropertyModal = ({
  showDeletePropertyModal,
  handleDeletePropertyModalClose,
  handleDeleteProperty
}) => (
  <Modal
    show={showDeletePropertyModal}
    centered
  >
    <Modal.Title
      className="logout-modal-head"
    >
      Are you sure ?
    </Modal.Title>
    <div className="modal-buttons-container">
      <Button
        variant="secondary"
        className="logout-modal-button"
        onClick={handleDeletePropertyModalClose}
      >
        Cancel
      </Button>
      <Button
        variant="danger"
        onClick={handleDeleteProperty}
      >
        Delete
      </Button>
    </div>
  </Modal>
);

export default DeletePropertyModal;