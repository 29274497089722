import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { changeAdminPassword } from "../../redux/admin/admin.actions";

export function ChangeAdminPasswordApiCall(data, toastView) {
  return (dispatch) => {
    dispatch(changeAdminPassword(null, true));

    const url = URL_CONSTANTS.CHANGE_ADMIN_PASSWORD;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Password updated successfully", 'success');
          dispatch(changeAdminPassword(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(changeAdminPassword(null, false));
      });
  };
}