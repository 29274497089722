import './PrivacyPolicy.styles.scss';

const PrivacyPolicy = () => (
    <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>
            Any personal information on our website, either by visiting the “Registration” page or by posting any information, you can 
            be assured that our portal is the only one who will use it. We do not rent, sell, or otherwise distribute any information 
            from our customer database, including e-mail addresses.
        </p>
        <h4>Information Collection and Use</h4>
        <p>
            We give high value to our customers and their privacy. We collect store, use, and share customer information in accordance 
            with this policy. We may store the information you enter on our website, including your account information. We use your 
            personal information to ensure efficient processing of your order, to communicate with you, and to improve our website, 
            services, and products.  Sometimes send offers to select groups of customers on behalf of other businesses. While doing so, 
            we may help our partners verify the effectiveness of their offers to our customers. We will not sell or share identifiable 
            personal information to any third party, except in accordance with this privacy policy or unless we are required to do so by law.
        </p>
        <h4>Security</h4>
        <p>
            All personal information necessary to complete your posting is stored in our system is kept on servers that are locked in our 
            secure data center. All information transmitted to our servers through web-portal is encrypted using state-of-the-art encryption 
            technology, facilitated by our Extended Validation SSL certificate. Sensitive and vital private data exchanged between the web 
            portal and its users happens over an SSL secured communication channel and is encrypted and protected with digital signatures. 
            Our Site is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for 
            Users.
        </p>
        <h4>Communications</h4>
        <p>
            We will use your personal information to communicate with you about our web=portal and your data share with us. All related 
            parties must provide an email address to allow communication regarding communication to be exchanged. We may send you a 
            confirmation email after you register with us as well as service-related announcements as necessary You may also submit your 
            email or sign up for our email newsletter and special offers. You can unsubscribe or opt-out of future emails at any time.
        </p>
        <h4>
            Changes to this Privacy Policy
        </h4>
        <p>
            We reserve the right to update or change our Privacy Policy at any time without prior intimation and you should check this 
            Privacy Policy periodically before entering into our web portal. Your continued use of the Service after we post any 
            modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to 
            abide and be bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you 
            either through the email address you have provided us or by placing a prominent notice on our website.
        </p>
        <p>
            When you visit the website, we may collect the following data:
        </p>
        <ul>
            <li>Your IP address.</li>
            <li>Your contact information and email address.</li>
            <li>Other information such as interests and preferences.</li>
            <li>Data profile regarding your online behavior on our website.</li>
        </ul>
        <h4>
            Why We Collect Your Data
        </h4>
        <p>
            We are collecting your data for several reasons:
        </p>
        <ul>
            <li>To better understand your needs.</li>
            <li>To improve our services and products.</li>
            <li>To send you promotional emails containing the information we think you will find interesting.</li>
            <li>To contact you to fill out surveys and participate in other types of market research.</li>
            <li>To customize our website according to your online behavior and personal preferences.</li>
        </ul>
        <h4>
            Safeguarding and Securing the Data
        </h4>
        <p>
            SARFAESIAUCTIONS.COM is committed to securing your data and keeping its confidentiality SARFAESIAUCTIONS.COM has done all in 
            its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, 
            which help us safeguard all the information we collect online.
        </p>
        <h4>Our Cookie Policy</h4>
        <p>
            Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior 
            (analyze web traffic, web pages you spend the most time on, and websites you visit).
        </p>
        <p>
            The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical 
            analysis, the data is completely removed from our systems.
        </p>
        <p>
            Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which 
            pages you find useful and which you do not so that we can provide a better experience for you.
        </p>
        <p>
            If you want to disable cookies, you can do it by accessing the settings of your internet browser. 
        </p>
        <h4>Links to Other Websites</h4>
        <p>
            Our web portal contains links that lead to other related and useful websites. If you click on these links SARFAESIAUCTIONS.COM 
            is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy 
            agreement. Make sure to read the privacy policy documentation of the website you go to from our website.
        </p>
        <h4>Restricting the Collection of your Personal Data</h4>
        <p>
            At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:
        </p>
        <p>
            When you are filling the forms on the website, make sure to check if there is a box that you can leave unchecked, if you don't 
            want to disclose your personal information.
        </p>
        <p>
            If you have already agreed to share your information with us, feel free to contact us via email and we will be more than happy 
            to change this for you.
        </p>
        <p>
            SARFAESIAUCTIONS.COM will not lease, sell or distribute your personal information to any third parties, unless we have your 
            permission. We might do so if the law forces us. We will share your personal data when we  communicate with you about offers 
            from SARFAESIAUCTIONS.COM and our channel partners.
        </p>
        <h4>
            Non-Personal Identification Information
        </h4>
        <p>
            We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal 
            identification information may include the browser name, the type of computer, and technical information about Users’ means 
            of connection to our Sites, such as the operating system and the Internet service providers utilized and other similar 
            information.
        </p>
        <h4>
            How we use collected data
        </h4>
        <p>
            SARFAESIAUCTIONS.COM may collect and use Users personal information for the following purposes:
        </p>
        <ul className="alpha-list">
            <li>
                To improve customer service - The information you provide helps us respond to your customer service requests and support 
                needs more efficiently.
            </li>
            <li>
                To personalize user experience - We may use information in the aggregate to understand how our Users as a group use the 
                services and resources provided on our Site.
            </li>
            <li>
                To improve our Site - We may use the feedback you provide to improve our products and services.
            </li>
            <li>
                To process payments - We may use the information Users provide about themselves when placing an order only to provide  
                service to that order. We do not share this information with outside parties except to the extent necessary to provide the 
                service.
            </li>
            <li>
                To run a promotion, contest, survey, or other Site feature - To send Users information they agreed to receive about topics 
                we think will be of interest to them.
            </li>
            <li>
                To send periodic emails - We may use the email address to send User information and updates pertaining to their order. 
                It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our 
                mailing list, they will receive emails that may include company news, updates, related product or service information, 
                etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe 
                instructions at the bottom of each email or the User may contact us via our Site.
            </li>
        </ul>
        <h4>How we protect your Information</h4>
        <p>
            SARFAESIAUCTIONS.COM adopts suitable data collection, storage, and processing practices and security measures to protect 
            against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, 
            transaction information, and data stored on our Site.
        </p>
        <h4>
            Google Ad-sense
        </h4>
        <p>
            Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their 
            visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track 
            personal information about you, such as your name, email address, physical address, etc. You may opt-out of the use of the 
            DART cookie by visiting the Google ad and content network privacy policy at http://www.google.com/privacy_ads.html
        </p>
        <h4>Contact us</h4>
        <p>
            If you have any questions about this Privacy Policy, the practices of this website, or your data shared with this website, 
            please feel to contact us at M/s Sahni Corporation, Gorakhpur, or contact on our email id info@sarfaesiauctions.com & 
            Mob No. 8218291267.
        </p>
    </div>
);

export default PrivacyPolicy;