import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertySearchList } from "../../redux/search/search.actions";

export function PropertySearchListApiCall(
  pageNo,
  propertyTypeId,
  categoryId,
  propertyId,
  cityId,
  localityId,
  minPriceId,
  maxPriceId,
  minAreaId,
  maxAreaId,
  bankInsitute
) {
  return (dispatch) => {
    dispatch(propertySearchList(null, true));

    const requestBody = {
      page_number: pageNo,
      property_type_id: propertyTypeId,
      category_id: categoryId,
      property_id: propertyId,
      city_id: cityId,
      location_id: localityId,
      minPriceId: minPriceId,
      maxPriceId: maxPriceId,
      minimum_area_id: minAreaId,
      maximum_area_id: maxAreaId,
      institution_id: bankInsitute,
    };
    
    const url = URL_CONSTANTS.PROPERTY_SEARCH_LIST;
    postRequestHeader(url, requestBody)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertySearchList(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertySearchList(null, false));
      });
  };
}
