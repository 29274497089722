import "./ContactUs.styles.scss";

const ContactUs = () => {
  return (
    <div className="container-fluid p-4 contact-us-outer">
      <div className="form-outer">
        <div className="row mb-4">
          <div className="col-7 col-lg-7 pr-0 mr-0">{/* Image */}</div>
          <div className="col-7 col-lg-7 pr-0 mr-0">{/* Form */}</div>
        </div>
      </div>

      <div className="address-section-outer">
        {/* <h2 className="heading">HEAD OFFICE & CORPORATE OFFICE</h2>
        <div className="row mb-4 pb-4">
          <div className="address-section col-7 col-lg-7">
            <p>
              T-7, Hide Road, Jain Kunj Extension, Kolkatta - 700088, West
              Bengal, India
            </p>
            <p>Email: support@sarfaesiauctions.com</p>
            <p>Mob # 94383 20265/ 89683 45956</p>
          </div>
        </div> */}
        <div className="row mb-4">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5 pl-0">
            <h2 className="heading">OFFICE</h2>
            <div>
              <div className="address-section pl-3">
                <h2 className="sub-heading">Delhi</h2>
                <p>145/35 Krishnapuri, Road No. 3, Delhi - 110092, India</p>
                <p>Email: info@sarfaesiauctions.com</p>
                <p>Mob # 9318343435, 8218291267</p>
              </div>
            </div>
          </div>

          {/* <div className="col-6 col-md-4 col-lg-4 col-xl-4 pl-0">
            <div className="address-section pl-3">
              <h2 className="sub-heading">Rajasthan</h2>
              <p>Vidyadhar Nagar, Jaipur - 302039, Rajasthan, India</p>
              <p>Email: info@sarfaesiauctions.com</p>
              <p>Mob # 70258 92763</p>
            </div>
          </div> */}

          <div className="col-12 col-md-8 col-lg-6 col-xl-5 pl-0">
            <h2 className="heading">REGISTERED OFFICE</h2>
            <div>
              <div className="address-section pl-3">
                <h2 className="sub-heading">Uttar Pradesh</h2>
                <p>
                  Plot No. 364, Jhangha, Gorakhpur, Uttar Pradesh - 273202, India
                </p>
                <p>Email: info@sarfaesiauctions.com</p>
                <p>Mob # 9990051189</p>
              </div>
            </div>
          </div>
          {/* <div className="col-6 col-md-4 col-lg-4 col-xl-4 pl-0">
            <div className="address-section pl-3">
              <h2 className="sub-heading">Maharashtra</h2>
              <p>
                B-130 Kohinoor Industrial Complex, Opposite Hindalco Industries
                Ltd., MIDC Taloja, Navi Mumbai - 410208, Maharashtra, India
              </p>
              <p>Email: nitesvhvjha@rediffmail.com</p>
              <p>Mob # 91670 47066, 93249 77449</p>
              <div className="mt-3">
                <p>
                  101, Rajgiri Apartment, Moravali Pada. Ambernath, 421501,
                  Mumbai, Maharashtra, India
                </p>
                <p>Email: info@sarfaesiauctions.com</p>
                <p>Mob # 82182 91267</p>
              </div>
            </div>
          </div> */}

          {/* <div className="col-6 col-md-4 col-lg-4 col-xl-4 pl-0">
            <div className="address-section pl-3">
              <h2 className="sub-heading">Tamil Nadu</h2>
              <p>
                Flat No. F1, Sekkiyar Nagar, Kundrathur,Chennai – 600069, Tamil
                Nadu, India
              </p>
              <p>Email: info@sarfaesiauctions.com</p>
              <p>Mob # 90425 80124</p>
            </div>
          </div> */}

          {/* <div className="col-6 col-md-4 col-lg-4 col-xl-4 pl-0">
            <div className="address-section pl-3">
              <h2 className="sub-heading">Kerala</h2>
              <p>
                Vengola , Perumbavoor Purthencruz Road,Perumbavoor,Kerala
                683556, India
              </p>
              <p>Email: info@sarfaesiauctions.com</p>
              <p>Mob # 80757 01939</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
