import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { FavouriteListApiCall } from './FavouriteListApiCall';
import { propertyFavouriteUser } from "../../redux/userProperty/userProperty.actions";

export function PropertyFavouriteUserApiCall(favourite, propertyId, toastView, handleAction) {
  return (dispatch) => {
    dispatch(propertyFavouriteUser(null, true));

    const url = `${URL_CONSTANTS.PROPERTY_FAVOURITE_USER}favourite=${favourite}&property_id=${propertyId}`;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertyFavouriteUser(res.data.data, false));
          dispatch(FavouriteListApiCall(1, 10));
          if(favourite == 0) {
            toastView("Property Removed From Favourites", 'success');
          } else {
            toastView("Thank you for your interest. We will contact you", 'success');
          }
          handleAction(favourite);
        }
      })
      .catch((error) => {
        handleAction(favourite == 0 ? 1 : 0);
        dispatch(propertyFavouriteUser(null, false));
      });
  };
}