import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { liveAuction } from "../../redux/property/property.actions";

export function LiveAuctionApiCall(pageNo, pageSize) {
  return (dispatch) => {
    dispatch(liveAuction(null, true));

    const url = `${URL_CONSTANTS.LIVE_AUCTION}${pageNo}&page_size=${pageSize}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(liveAuction(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(liveAuction(null, false));
      });
  };
}
