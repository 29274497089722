import { nanoid } from 'nanoid';
import { PROFILE } from '../../constants/iconConstants';

const propertyDealersData = [
  {
    id: nanoid(),
    city: 'JAIPUR',
    image: PROFILE,
    data: [
      { id: nanoid(), label: 'Locality', value: '' },
      { id: nanoid(), label: 'State RERA Regn. Number', value: '' },
      { id: nanoid(), label: 'Address for contact', value: '' },
      { id: nanoid(), label: 'Mobile No.', value: '' },
      { id: nanoid(), label: 'Email id', value: '' },
      { id: nanoid(), label: 'Brief Details', value: '' }
    ]
  },
  {
    id: nanoid(),
    city: 'MUMBAI',
    image: PROFILE,
    data: [
      { id: nanoid(), label: 'Locality', value: '' },
      { id: nanoid(), label: 'State RERA Regn. Number', value: '' },
      { id: nanoid(), label: 'Address for contact', value: '' },
      { id: nanoid(), label: 'Mobile No.', value: '' },
      { id: nanoid(), label: 'Email id', value: '' },
      { id: nanoid(), label: 'Brief Details', value: '' }
    ]
  }
];

export { propertyDealersData };