import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchFilter from "../../components/SearchFilter/SearchFilter.component";
import MobileSearchFilter from "../../components/MobileSearchFilter/MobileSearchFilter.component";
import PropertyStats from "../../components/Property-Stats/PropertyStats.component";
import PropertyList from "../../components/Property-List/PropertyList.component";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import Advertisement from "../../components/Ads/Advertisement.component";
import Loader from "../../components/Loader/Loader.component";
import { PropertySummaryApiCall } from "../../apiCalls/Property/PropertySummaryApiCall";
import { LiveAuctionApiCall } from "../../apiCalls/Property/LiveAuctionApiCall";
import { UpcomingAuctionApiCall } from "../../apiCalls/Property/UpcomingAuctionApiCall";
import { OtherSalesPropertiesApiCall } from "../../apiCalls/Property/OtherSalesPropertiesApiCall";
import { BankAuctionPropertiesApiCall } from "../../apiCalls/User/BankAuctionPropertiesApiCall";
import { PropertyCategoryDetailsApiCall } from "../../apiCalls/Property/PropertyCategoryDetailsApiCall";
import { UserAdDetailsApiCall } from "../../apiCalls/User/UserAdDetailsApiCall";
import {
  getLiveAuction,
  getOtherSalesProperties,
  getUpcomingAuction,
} from "../../redux/property/property.selector";
import {
  userAdDetailsState,
  showInterestState,
} from "../../redux/user/user.selector";
import "../../assets/css/global.scss";
import "./HomePage.styles.scss";

//Importing By NewDev
import Center from "../../NewDev/core/Center";
import Filter from "../../NewDev/components/Filter";

const HomePage = () => {
  const dispatch = useDispatch();
  const liveAuctionProperties = useSelector(getLiveAuction);
  const upcomingAuctionProperties = useSelector(getUpcomingAuction);
  const otherSalesProperties = useSelector(getOtherSalesProperties);
  const adDetails = useSelector(userAdDetailsState);
  const showInterest = useSelector(showInterestState);

  useEffect(() => {
    dispatch(PropertySummaryApiCall());
    dispatch(LiveAuctionApiCall(1, 8));
    dispatch(UpcomingAuctionApiCall(1, 8));
    dispatch(OtherSalesPropertiesApiCall(1, 8));
    dispatch(BankAuctionPropertiesApiCall(1));
    dispatch(PropertyCategoryDetailsApiCall());
    dispatch(UserAdDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="home-container container-fluid"
        // style={{
        //   height: "100%",
        //   background: "#f6f6f6",
        //   backgroundImage: `url('https://cdn.pixabay.com/photo/2016/11/18/19/42/architecture-1836607_640.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <Loader visible={showInterest.loader} />
        <Advertisement data={adDetails?.data?.Homepage_Slot_1} />
        <Center>
          <div className="banner-main" style={{ width: "100%" }}>
            <h2 style={{ color: "#1f4590", fontWeight: "bolder" }}>
              Find your desired property just in a click...
            </h2>
            <p style={{ color: "#000" }}>
              India’s online composite property web-portal for auction,
              buy-sale,
              <br /> To-let & SARFAESI NPA Resolutions
            </p>
            <div className="search-filter-main">
              {/* <SearchFilter /> */}

              <Filter />
            </div>
            <div className="mobile-search-filter-main">
              {/* <MobileSearchFilter /> */}
              <Filter />
            </div>
            <PropertyStats />
          </div>
        </Center>
      </div>
      <div className="home-container container-fluid">
        {liveAuctionProperties.data?.data?.length !== 0 && (
          <div className="container-fluid mt-1 auction-main">
            <h2 class="m-auto text-center">
              Live Auction
              <span className="float-right">
                <Link
                  to={{
                    pathname: "bank-auction-properties",
                    state: {
                      title: "Live Auction Properties",
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  View all <NavigateNextIcon />
                </Link>
              </span>
            </h2>
            <PropertyList
              section="live-auction"
              id="live"
              data={liveAuctionProperties.data?.data}
            />
          </div>
        )}

        {upcomingAuctionProperties.data?.data?.length !== 0 && (
          <div className="container-fluid mt-2 pt-5 auction-main">
            <h2 class="m-auto text-center">
              Upcoming Auction
              <span className="float-right">
                <Link
                  to={{
                    pathname: "bank-auction-properties",
                    state: {
                      title: "Upcoming Auction Properties",
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  View all <NavigateNextIcon />
                </Link>
              </span>
            </h2>
            <PropertyList
              section="upcoming-auction"
              id="upcoming"
              data={upcomingAuctionProperties.data?.data}
            />
          </div>
        )}

        {otherSalesProperties.data?.data?.length !== 0 && (
          <div className="container-fluid mt-2 pt-5 auction-main">
            <h2 class="m-auto text-center">
              Buy-Sale Properties
              <span className="float-right">
                <Link
                  to={{
                    pathname: "buy-sale",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  View all <NavigateNextIcon />
                </Link>
              </span>
            </h2>
            <PropertyList
              section="buy-sale"
              id="buysale"
              data={otherSalesProperties.data?.data}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default HomePage;
