import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { userLogin } from "../../redux/persistantData/persistantData.actions";
import { setUserToken } from '../../redux/token/token.actions';

export function GoogleSignInApiCall(code, toastView) {
  return (dispatch) => {
    dispatch(userLogin(null, true));

    const url = `${URL_CONSTANTS.GOOGLE_SIGN_IN}${code}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(setUserToken(res?.data?.data?.token));
          dispatch(userLogin(res.data.data, false));
          toastView('Login Successful', 'success');
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message, 'error');
        dispatch(userLogin(null, false));
      });
  };
}