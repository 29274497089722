import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { removeProperty } from "../../redux/adminProperty/adminProperty.actions";
import { BankAuctionPropertyListApiCall } from './BankAuctionPropertyListApiCall';
import { PropertyOtherAuctionListApiCall } from './PropertyOtherAuctionListApiCall';

export function RemovePropertyApiCall(propertyId, toastView, deleteSuccess) {
  return (dispatch) => {
    dispatch(removeProperty(null, true));

    const url = `${URL_CONSTANTS.REMOVE_PROPERTY}${propertyId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Property deletion successful", 'success');
          dispatch(removeProperty(res.data.data, false));
          deleteSuccess(propertyId);
        }
      })
      .catch((error) => {
        dispatch(removeProperty(null, false));
      });
  };
}