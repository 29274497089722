import './AboutUs.styles.scss';
import Banner from '../../assets/img/about-us.jpg';
import React, { useRef } from 'react';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const AboutUs = () => {

    const ourCompanyRef = useRef(null);
    const executeScroll = () => scrollToRef(ourCompanyRef);

    return (
        <div className="container-fluid p-0">
            <div className="about-us-main">
                {/* <div className="container-fluid">
                    <div className="row about-us-banner">
                        <div className="col-lg-5 banner-content-wrapper">
                            <h2 className="heading">about Us</h2>
                            <p>
                                To be the first choice for our consumers and partners in their journey of discovering, renting, buying, selling and financing a home. We do that with data, design, technology, and above all the passion of our people, while delivering value to our shareholders.
                        </p>
                            <Button variant="contained" color="primary" onClick={executeScroll}>
                                Learn More
                        </Button>
                        </div>
                        <div className="col-lg-7 banner-wrapper">
                            <img src={Banner} alt="banner" />
                        </div>
                    </div>
                </div> */}
                <div ref={ourCompanyRef} className="container">
                    <div className="about">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="heading">our company</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-6 desc-banner-wrapper">
                                        <img src={Banner} alt="banner" />
                                    </div>
                                    <div className="col-lg-6">
                                        <p>
                                        SARFAESIAUCTIONS.COM is a venture of M/s Sahni Corporation, Delhi India, and has its own branches in Mumbai, Chennai, Kochi, Jaipur, and Gorakhpur apart from authorized channel partners & a team of business associates with proven track records of achieving grated targets in all major cities. SARFAESIAUCTIONS.COM is India’s  online composite property web portal for auction, buy-sale, To-let & SARFAESI NPA Resolutions through software application for legalized activities as per guidelines laid down by RBI & other statutory bodies. We provide a digital platform-cum-market place with globally accessible to all genuine buyers of property of any nature, in addition to To-let services to tenants and landlords and SARFAESI Act resolution to empaneled enforcement agencies for resolution of NPA, home loan financial institutions like all PSU & Private banks, NBFCs/HFCs/AMCs. Its Directors & stakeholders have vast experience in the relevant field & other long working experience with various organizations. This portal will help all such institutions to eliminate their unsold assets as the portal gives both SARFAESI resolutions to the sale of property under one roof.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <p>
                                This is a composite property portal that offers user-friendly property advertising services to builders, house owners who are looking to showcase their properties for auction sale or open market sale, to lease out or rental clients can post data on an android smartphone with the user-friendly app including videos, photographs, layout plans & location on the map. Portal also offers buyers looking to buy or rent out their property for residential & business use. An actual site visit for inspection of the property or virtual visit as per their discretion is facilitated at the convenience of the buyer. We have a vast database/ databank of verified buyers & vendors consisting of complete details of property dealers, builders, contractors, allied service providers, etc... From all over the country. It provides a common place for Individuals, agents, and builders to buy-sale or rent out their properties. SARFAESIAUCTIONS.COM will act as an open digital platform for all realty buy sell customers, property agents, dealers, accessory business associates, banks, NBFCs & HFCs to display their property with all configurations worldwide and country-wise, pan India state-wise, city-wise, prime location-wise to showcase their property to reach out to all buyers on the android smartphone by notifications by email, SMS, etc. Its platform serves solutions for Real Estate Agents, Builders & Developers, Contractors, Interior decorators, Architecture, Consultants & Advisor, Vaastu Consultants, Building Material Dealer, etc. Finding the right property requires a lot of time and effort and it would not be convenient if all the properties suit your requirement/needs. To explore more & suitable properties to l match your expectations is now just a click away.
                                </p>
                                <p>
                                  As per Post covid-19 revelation, a huge NPA of Public Sector Banks has been piled up and Covide-19 and Supreme Court verdict on interest and NPA declaration by Banks has further deteriorated the scenario
                                  and it endangers the banking sector mainly PSU banks where NPA ratio is extraordinarily soared recent years.
                                </p>
                                <p>
                                  With new I.T. start-ups in India, we intend to extend our footprint beyond India's boundaries to neighboring countries and the Middle East countries very soon. We act as a one-stop shop for all home services such as new home searchers, resale customers, rental property owners & paying guest service providers together to tap a very highly fragmented brokerage market. The Non -Resident Indians who are spread in various countries and looking for investing in the Indian Real Estate market or need to sell their ancestor's property, can use this platform for all their needs & demands hassle-free.
                                </p>
                                <p>
                                  We emerge out of the need to home search process, enable synchronized access of verified property information, free of fake listings and synchronized free site visits with. Up-to-date and authentic information provided on the web portal is revolutionary. Our other value-added services involve legal and loan guidance support as we have tie-up with leading Banks for special rates of interest to our listed members.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="heading">mission</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>
                              The features embedded in this portal other than existing realty portals are; it will equip with SARFAESI Act 2002 enabled resolution tools like 30 days’ notice, 60 days’ notice, SP Application, DM Application, Sale Notice, E-auction notice, invoice generation, using WhatsApp & SMS to be generated through online customized modules for chasing default borrowers and make them felt of their consequences arising out of NPA crisis and CIBIL records adversely. Apart from this, providing one platform for local To-let service providers (rental office space, home & shops) enabling both vendor and customers to display their requirement and get customers handy. To sow the seeds of par-excellence services with a customer-centric approach and reap the trust of worldwide clients. Through our portal buyers can rejoice to deliver trustworthy experiences that you cherish for life long and the ultimate satisfaction of vendors & customers is our utmost priority.
                            </p>
                            <p>
                              Our mission is to provide user-friendly solutions to each vendor and customers by all available technologies and cater to changing technologies from time to time with minimum cost. The strength of our portal will be to general strength and authenticated database of PAN India basis for nationalized and private banks, Non-Banking Finance Companies, Housing Finance Companies which is about 50 in numbers at present. The data bank will cover data of genuine buyers, sellers, to-let service agencies, resolution agents, supporting agents & recovery agents, detective agencies, property dealers, real estate builders, valuation agents, etc. Our mission is to touch the horizon where our capabilities may successfully meet the requirements of our clients, that too with ultimate transparency and cost-effectiveness with user-friendly technology available.
                            </p>
                            <p>
                              To be the first choice for our consumers and partners in their journey of discovering, renting, buying, selling, and financing a home. We do that with data, design, technology, and above all the passion of our people while delivering value to our shareholders.
                            </p>
                            <p>
                              To be the first choice for our consumers and partners in their journey of discovering, renting, buying, selling, and financing a home. We do that with data, design, technology, and above all the passion of our people while delivering value to our shareholders.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="heading">vision</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>
                              Our vision is to become one of the world’s leading service forces in the space of cloud computing, use Artificial Intelligence in financial service sectors, and create a conducive and user-friendly digital platform to the reach of all stakeholders. Our foresight is to transform the world financial sectors through technologies embedded with innovation, foster equality, and zero the gap between digital & physical worlds. We strive for continuous growth through the improvement of our services, processes, and technology. Our core values deliver excellent outcomes to vendors/end-users, open approach to new ideas and diverse perceptiveness, pro-active action, and effective management through skillful communication, adopt high ethical standards & values in the business application, work with passion and full of enthusiasm, develop sustainable culture, values and business practices, precision quality.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;