import React, { useEffect, useState } from "react";
import PropertyCardDetailed from "../../components/Cards/PropertyCardDetailed.component";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./PropertyList.styles.scss";

export default function PropertyList({ id, section, data }) {
  const [splitArr, setSplitArr] = useState([['', '', '', '']]);
  
  useEffect(() => {
    if(data?.length > 0) {
      let temp = [];
      temp.push([...data?.slice(0, 4)]);
      temp.push([...data?.slice(4, 8)]);
      temp.push([...data?.slice(8, 12)]);
      setSplitArr(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div
            id={id}
            class="carousel slide"
            data-ride="carousel"
            data-interval="0"
          >
            {/* <!-- Carousel indicators --> */}
            {splitArr?.length > 3 && (
              <ol class="carousel-indicators">
                <li
                  data-target={'#'+ id}
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target={'#'+ id} data-slide-to="1"></li>
                <li data-target={'#'+ id} data-slide-to="2"></li>
              </ol>
            )}
            {/* <!-- Wrapper for carousel items --> */}
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  {splitArr[0]?.map((item) => (
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12" key={item?.property_id}>
                      <PropertyCardDetailed section={section} data={item} />
                    </div>
                  ))}
                </div>
              </div>
              {splitArr[1]?.length > 0 && (
                <div class="carousel-item">
                  <div class="row">
                    {splitArr[1]?.map((item) => (
                      <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12" key={item?.property_id}>
                        <PropertyCardDetailed section={section} data={item} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {splitArr[2]?.length > 0 && (
                <div class="carousel-item">
                  <div class="row">
                    {splitArr[2]?.map((item) => (
                      <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12" key={item?.property_id}>
                        <PropertyCardDetailed section={section} data={item} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* <!-- Carousel controls --> */}
            {data?.length > 4 && (
              <>
                <a
                  class="carousel-control-prev"
                  href={'#'+ id}
                  data-slide="prev"
                >
                  <ArrowBackIosIcon />
                </a>
                <a
                  class="carousel-control-next"
                  href={'#'+ id}
                  data-slide="next"
                >
                  <ArrowForwardIosIcon />
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
