import PersistantDataTypes from './persistantData.types';

const initialPersistantData = {
  login: {
    data: null,
    loader: false
  },
  userDetails: {
    data: null,
    loader: false
  },
  userLogin: {
    data: null,
    loader: false
  }
};

const persistantDataReducer = (state = initialPersistantData, action) => {
  switch(action.type) {
    case PersistantDataTypes.ADMIN_LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PersistantDataTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PersistantDataTypes.USER_LOGIN:
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default persistantDataReducer;