import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { bankAuctionProperties } from "../../redux/user/user.actions";

export function BankAuctionPropertiesApiCall(pageNo) {
  return (dispatch) => {
    dispatch(bankAuctionProperties(null, pageNo === 1 ? true : false));

    const url = `${URL_CONSTANTS.BANK_AUCTION_PROPERTIES}${pageNo}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(bankAuctionProperties(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(bankAuctionProperties(null, false));
      });
  };
}