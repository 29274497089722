import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { addInstitutionAction } from "../../redux/institution/institution.actions";

export function AddInstitutionApiCall(data, toastView) {
  return (dispatch) => {
    const url = URL_CONSTANTS.ADD_INSTITUTION;
    dispatch(addInstitutionAction(null, true));

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Bank Added", 'success');
          dispatch(addInstitutionAction(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(addInstitutionAction(null, false));
      });
  };
}