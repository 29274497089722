import React, { useState, useEffect } from 'react';
import AdvertisementImg from '../../assets/img/advertise-with-us.jpg';
import './Advertisement.styles.scss';

export default function MiniAdvertisement({ data1, data2, data3 }) {
  const handleClick = (url) => {
    if(url) {
      window.location.href = url;
      window.open(url, '_blank').focus();
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`mini-advertisement-main-outer mt-4 mb-4 ${scrollPosition<110 ? 'adjust-top':''}`}>
      <img 
        src={data1?.image ? `${process.env.REACT_APP_IMAGE_BASE_URL}${data1?.image}` : AdvertisementImg} 
        alt="Advertisement" 
        onClick={() => handleClick(data1?.url)}
      />
      <img
        src={data2?.image ? `${process.env.REACT_APP_IMAGE_BASE_URL}${data2?.image}` : AdvertisementImg} 
        alt="Advertisement" 
        onClick={() => handleClick(data2?.url)}
      />
      <img 
        src={data3?.image ? `${process.env.REACT_APP_IMAGE_BASE_URL}${data3?.image}` : AdvertisementImg} 
        alt="Advertisement" 
        onClick={() => handleClick(data3?.url)}
      />
    </div>
  );
}