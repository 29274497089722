export const conversionList = [
  { label : "Sq.ft", divider : "1", id: '1' },
  { label : "Sq.yard", divider : "9", id: '66' },
  { label : "Sq.meter", divider : "10.76391042", id: '2' },
  { label : "Sq.cm", divider : "0.00107639", id: '62' },
  { label : "Sq.inch", divider : "0.0069444", id: '63' },
  { label : "Sq.mile", divider : "27878400", id: '65' },
  { label : "Acre", divider : "43560", id: '3' },
  { label : "Ankanam", divider : "72", id: '4' },
  { label : "Are", divider : "1076.39", id: '5' },
  { label : "Bigha (Assam)", divider : "14400", id: '6' },
  { label : "Bigha (Bengal)", divider : "14400", id: '7' },
  { label : "Bigha – Pucca (Bihar)", divider : "27225", id: '8' },
  { label : "Bigha – Pucca (UP-I)", divider : "27225", id: '9' },
  { label : "Bigha – Pucca (Rajasthan-I)", divider : "27225", id: '10' },
  { label : "Bigha – Pucca (Punjab-I)", divider : "27225", id: '11' },
  { label : "Bigha – Pucca (Haryana-I)", divider : "27225", id: '12' },
  { label : "Bigha (Gujarat)", divider : "17424", id: '13' },
  { label : "Bigha (Rajasthan-II)", divider : "17424", id: '14' },
  { label : "Bigha (HP-I)", divider : "8712", id: '15' },
  { label : "Bigha (Uttarakhand-I)", divider : "8712", id: '16' },
  { label : "Bigha (HP-II)", divider : "8100", id: '17' },
  { label : "Bigha (Uttarakhand-II)", divider : "8100", id: '18' },
  { label : "Bigha (MP)", divider : "11999.97", id: '19' },
  { label : "Bigha- Kachha (Punjab-II)", divider : "9074.97", id: '20' },
  { label : "Bigha- Kachha (Haryana-II)", divider : "9074.97", id: '21' },
  { label : "Bigha- Kachha (UP-II)", divider : "9074.97", id: '22' },
  { label : "Biswa – Pucca (UP-I)", divider : "1361.25", id: '23' },
  { label : "Biswa – Pucca (Rajasthan-I)", divider : "1361.25", id: '24' },
  { label : "Biswa – Pucca (Punjab-I)", divider : "1361.25", id: '25' },
  { label : "Biswa – Pucca (Haryana-I)", divider : "1361.25", id: '26' },
  { label : "Biswa – Rajasthan II", divider : "871.2", id: '27' },
  { label : "Biswa (HP-I)", divider : "435.6", id: '28' },
  { label : "Biswa (Uttarakhand-I)", divider : "435.6", id: '29' },
  { label : "Biswa (HP-II)", divider : "405", id: '30' },
  { label : "Biswa (Uttarakhand-II)", divider : "405", id: '31' },
  { label : "Biswa (Kaccha) – (Punjab-II)", divider : "453.753", id: '32' },
  { label : "Biswa (Kaccha) – (Haryana-II)", divider : "453.753", id: '33' },
  { label : "Biswa (Kaccha) – (UP-II)", divider : "453.753", id: '34' },
  { label : "Centiare", divider : "10.7639", id: '35' },
  { label : "Cent", divider : "435.6", id: '36' },
  { label : "Chatak", divider : "180", id: '37' },
  { label : "Decimal", divider : "435.6", id: '38' },
  { label : "Dhur (Bihar)", divider : "468.0625", id: '39' },
  { label : "Dhur (Jharkhand)", divider : "68.0625", id: '40' },
  { label : "Dhur (Tripura)", divider : "3.6", id: '41' },
  { label : "Ghumaon", divider : "16560", id: '42' },
  { label : "Ground", divider : "2400", id: '43' },
  { label : "Guntha", divider : "1089", id: '44' },
  { label : "Hectare", divider : "107639.1", id: '45' },
  { label : "Kanal", divider : "5445", id: '46' },
  { label : "Kattha (Assam)", divider : "2880", id: '47' },
  { label : "Kattha (Bengal)", divider : "720", id: '48' },
  { label : "Kattha (Bihar)", divider : "1361.25", id: '49' },
  { label : "Kattha (MP)", divider : "600", id: '50' },
  { label : "Killa", divider : "43560", id: '51' },
  { label : "Kranta", divider : "72", id: '52' },
  { label : "Kuncham", divider : "4356", id: '53' },
  { label : "Lecha", divider : "144", id: '54' },
  { label : "Marla", divider : "272.25", id: '55' },
  { label : "Murabba", divider : "1089000", id: '56' },
  { label : "Nalli", divider : "2160", id: '57' },
  { label : "Perch", divider : "272.25", id: '58' },
  { label : "Rood", divider : "10890", id: '59' },
  { label : "Sarsahi (Square Karam)", divider : "30.25", id: '60' },
  { label : "Shatak", divider : "435.6", id: '61' },
  { label : "Sq.km", divider : "10763676", id: '64' },
  { label : "Biswansi", divider : "67.50", id: '67' },
];