import { createSelector } from "reselect";

const selectFile = (state) => state.file;

export const uploadFileState = createSelector(
  [selectFile],
  (file) => file.uploadFile
);

export const removeFileState = createSelector(
  [selectFile],
  (file) => file.removeFile
);

export const removeAdFileState = createSelector(
  [selectFile],
  (file) => file.removeAdFile
);

export const toast = createSelector(
  [selectFile],
  (file) => file.toast
);