const AdminPropertyActionTypes = {
  PROPERTY_OTHER_AUCTION_LIST: "PROPERTY_OTHER_AUCTION_LIST",
  ADD_PROPERTY: "ADD_PROPERTY",
  BANK_AUCTION_PROPERTY_LIST: "BANK_AUCTION_PROPERTY_LIST",
  PROPERTY_APPROVAL: "PROPERTY_APPROVAL",
  PROPERTY_UNAPPROVAL: "PROPERTY_UNAPPROVAL",
  UPDATE_PROPERTY: "UPDATE_PROPERTY",
  REMOVE_PROPERTY: "REMOVE_PROPERTY",
  DASHBOARD_SUMMARY: "DASHBOARD_SUMMARY",
  EDIT_PROPERTY: "EDIT_PROPERTY",
  ADMIN_PROPERTY_DETAILS: "ADMIN_PROPERTY_DETAILS",
  ADMIN_PROPERTY_SEARCH: "ADMIN_PROPERTY_SEARCH",
  ADMIN_BANK_SEARCH: "ADMIN_BANK_SEARCH",
  PROPERTY_ADD_SUCCESS: "PROPERTY_ADD_SUCCESS"
};

export default AdminPropertyActionTypes;