import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { nanoid } from 'nanoid';
import Calendar from 'react-calendar';
import Button from 'react-bootstrap/Button';
import {
  USERS,
  FILTER,
  WARNING,
  USER
} from '../../../constants/iconConstants';
import Loader from '../../../components/Loader/Loader.component';
import { DashboardSummaryApiCall } from '../../../apiCalls/AdminProperty/DashboardSummaryApiCall';
import { InterestedReportApiCall } from '../../../apiCalls/Report/InterestedReportApiCall';
import { SignedUpReportApiCall } from '../../../apiCalls/Report/SignedUpReportApiCall';
import { UploadedReportApiCall } from '../../../apiCalls/Report/UploadedReportApiCall';
import { dashboardSummary } from '../../../redux/adminProperty/adminProperty.selector';
import { 
  getInterestedReport, 
  getSignedUpReport, 
  getUploadedReport 
} from '../../../redux/report/report.selector';
import { adminLoginState } from '../../../redux/persistantData/persistantData.selector';
import {
  interestedReport,
  signedUpReport,
  uploadedReport
} from '../../../redux/report/report.actions';
import '../AdminPage.styles.scss';

const Dashboard = ({ setSelectedTab, expanded }) => {
  const dispatch = useDispatch();
  const dashboardSummaryState = useSelector(dashboardSummary);
  const [date, setDate] = useState(new Date());
  const { addToast } = useToasts();
  const interestedReportState = useSelector(getInterestedReport);
  const uploadedReportState = useSelector(getUploadedReport);
  const signedUpReportState = useSelector(getSignedUpReport);
  const adminLogin = useSelector(adminLoginState);

  const dashboardCardsData = [
    {
      id: nanoid(),
      title: 'Users Count',
      number: dashboardSummaryState.data?.user_count ?? 0,
      subtitle: null,
      icon: FILTER,
      onPress: () => { }
    },
    {
      id: nanoid(),
      title: 'Pending Approval',
      number: dashboardSummaryState.data?.pending_approval ?? 0,
      subtitle: null,
      icon: WARNING,
      onPress: () => { }
    },
    {
      id: nanoid(),
      title: 'Total Properties',
      number: dashboardSummaryState.data?.all_prop_count ?? 0,
      subtitle: null,
      icon: FILTER,
      onPress: () => { }
    },
    {
      id: nanoid(),
      title: 'Live Auction',
      number: dashboardSummaryState.data?.live_auction_count ?? 0,
      subtitle: 'items active in site',
      icon: FILTER,
      onPress: () => { }
    },
    {
      id: nanoid(),
      title: 'Upcoming Auction',
      number: dashboardSummaryState.data?.upcoming_auction_count,
      subtitle: 'items active in site',
      icon: FILTER,
      onPress: () => { }
    },
    {
      id: nanoid(),
      title: 'Bank Auction',
      number: dashboardSummaryState.data?.bank_auction_count ?? 0,
      subtitle: 'items active in site',
      icon: FILTER,
      onPress: () => setSelectedTab('bankAuctionProperty')
    },
    {
      id: nanoid(),
      title: 'Other Properties',
      number: dashboardSummaryState.data?.other_sale_prop_count ?? 0,
      subtitle: 'items active in site',
      icon: FILTER,
      onPress: () => setSelectedTab('property')
    },
    {
      id: nanoid(),
      title: 'Branch Manager',
      number: dashboardSummaryState.data?.branch_partner_count ?? 0,
      subtitle: null,
      icon: USER,
      onPress: () => setSelectedTab('configuration')
    },
    {
      id: nanoid(),
      title: 'Channel Partners',
      number: dashboardSummaryState.data?.channel_partner_count ?? 0,
      subtitle: null,
      icon: USERS,
      onPress: () => setSelectedTab('configuration')
    }
  ];

  useEffect(() => {
    dispatch(DashboardSummaryApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(interestedReportState.data !== null) {
      let anchor = document.createElement("a");
      anchor.href = `data:text/csv;charset=utf-8,${escape(interestedReportState.data)}`;
      anchor.download = 'interestedReport.csv';
      anchor.click();
      dispatch(interestedReport(null, false));
    }
  }, [interestedReportState.data]);

  useEffect(() => {
    if(signedUpReportState.data !== null) {
      let anchor = document.createElement("a");
      anchor.href = `data:text/csv;charset=utf-8,${escape(signedUpReportState.data)}`;
      anchor.download = 'signedUpReport.csv';
      anchor.click();
      // console.log(signedUpReportState.data)
      dispatch(signedUpReport(null, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedUpReportState.data]);

  useEffect(() => {
    if(uploadedReportState.data !== null) {
      let anchor = document.createElement("a");
      anchor.href = `data:text/csv;charset=utf-8,${escape(uploadedReportState.data)}`;
      anchor.download = 'uploadedReport.csv';
      anchor.click();
      dispatch(uploadedReport(null, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedReportState.data]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance });
  }

  const handleReport = (type) => {
    if(type === 'INTERESTED') {
      dispatch(InterestedReportApiCall(toastView));
    } else if(type === 'SIGNEDUP') {

      dispatch(SignedUpReportApiCall(toastView));
      // console.log("Line 164",signedUpReportState)
      
    } else if(type === 'UPLOADED') {
      dispatch(UploadedReportApiCall(toastView));
    }
  }

  return (
    <div
      className={
        `dashboard-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`
      }
    >
      <Loader 
        visible={interestedReportState.loader || 
          signedUpReportState.loader || 
          uploadedReportState.loader
        } 
      />
      <h1 className="page-title">Dashboard</h1>
      <div className="container-fluid">
        <div className="row right-container-row">
          <div className="col-xl-7 col-lg-5 col-md-12 col-sm-12">
            <div className="row">
              {
                dashboardCardsData.map((item) => (
                  <div
                    className="col-xl-5 col-md-12 dashboard-cards"
                    key={item.id}
                    onClick={item?.onPress}
                  >
                    <p className="card-title">{item.title}</p>
                    <div className="card-bottom-wrap">
                      <p className="card-num">{item.number}</p>
                      <img src={item.icon.default} alt="" className="card-icon" />
                    </div>
                    <p
                      className="subtitle"
                    >
                      {item?.subtitle}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
          {adminLogin?.data?.privileges?.is_OH === 1 && (
            <div
              className="col-xl-3 col-lg-5 col-md-12 col-sm-12 d-flex flex-column calendar-container"
            >
              <Button
                variant="secondary"
                className="report-button"
                onClick={() => handleReport('INTERESTED')}
              >
                Interested Users Report
              </Button>
              <Button
                variant="secondary"
                className="report-button"
                onClick={() => handleReport('UPLOADED')}
              >
                Uploaded Users Report
              </Button>
              <Button
                variant="secondary"
                className="report-button"
                onClick={() => handleReport('SIGNEDUP')}
              >
                Signed Up Users Report
              </Button>
              <Calendar
                onChange={setDate}
                value={date}
                className="calendar"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;