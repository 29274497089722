import { Delete } from "@mui/icons-material"
import { IconButton } from "@mui/material"

const IconBtn=({children,onClick})=>{
    return(
        <IconButton sx={{border:"1px solid #ccc",borderRadius:"8px"}} size="large" onClick={onClick}>
            {children}
        </IconButton>
    )
}
export default IconBtn