// These represents old category name and new category name

export const RESIDENTIAL_CATEGORIES = {
  'Independent House': 'House',
  'Villas or Bungalows': 'Villa / Bungalow',
  'Apartments or Flats': 'Flat',
  'Farm House': 'Farm House',
  'Residential Plot': 'Residential Plot',
  'Others': 'Others'
};

export const COMMERCIAL_CATEGORIES = {
  'Assembling Unit': 'Shop',
  'Boutiques': 'Office',
  'Cages': 'Commercial Land',
  'Cinema Halls': 'Land & Building',
  'ColdStorage': 'Showroom',
  'Educational Institutions': 'School / College',
  'Flex Building': 'Resort',
  'Hospitals': 'Hospitals',
  'Hotel': 'Hotel & Restraurant',
  'Industrial Land': 'Warehouse',
  'Manufacturing Units': 'Godown',
  'Nursing Homes': 'Nursing Homes',
  'Pubs': 'Pubs',
  'Complex': 'Complex',
  'Resorts': 'Resorts',
  'Restaurants': 'Restaurants',
  'Shop': 'Shop',
  'Showroom': 'Showroom',
  'Special Purpose': 'Special Purpose',
  'Warehouse / Distribution': 'Warehouse / Distribution',
  'Others': 'Others'
}

export const INDUSTRIAL_CATEGORIES = {
  'Manufacturing Unit': 'Factory Land & Building',
  'Assembling Unit': 'Factory',
  'Warehouse / Distribution': 'Warehouse / Distribution',
  'Showroom': 'Industrial(Scrap)',
  'Flex Building': 'Plant & Machinery',
  'Industrial Land': 'Industrial Land',
  'Special Purpose': 'Special Purpose',
  'Others': 'Others'
}

export const AGRICULTURAL_CATEGORIES = {
  'Open Farm Land': 'Open Farm Land',
  'Open Crop Land': 'Open Crop Land',
  'Land With Trees or Crops': 'Land With Trees or Crops',
  'Perennial Crops': 'Perennial Crops',
  'Organic Farming': 'Organic Farming',
  'Mixed Crops': 'Mixed Crops',
  'Polyhouse': 'Polyhouse',
  'Fish Pond': 'Fish Pond',
  'Poultry Farm': 'Poultry Farm',
  'Others': 'Others'
}

export const ALL_CATEGORIES = {
  ...RESIDENTIAL_CATEGORIES,
  ...INDUSTRIAL_CATEGORIES,
  ...AGRICULTURAL_CATEGORIES,
  ...COMMERCIAL_CATEGORIES
}