import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Modals.styles.scss';

const RemoveAdminModal = ({
  showRemoveAdminModal,
  setShowRemoveAdminModal,
  handleRemoveAdmin
}) => (
  <Modal
    show={showRemoveAdminModal}
    centered
  >
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Modal.Title className="logout-modal-head">
        You are about to Remove Admin... Are you sure?
      </Modal.Title>
      <div className="modal-buttons-container">
        <Button
          variant="secondary"
          className="logout-modal-button"
          onClick={() => setShowRemoveAdminModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleRemoveAdmin}
        >
          Remove
        </Button>
      </div>
    </div>
  </Modal>
);

export default RemoveAdminModal;