import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from "@material-ui/core/Button";
import './BusinessEnquiries.styles.scss';

const BusinessEnquiries = () => (
  <div className="business-enquiries-outer container">
    <h1>Business Enquiries</h1>
    <form noValidate autoComplete="off" className="w-100">
      <div className="row">
        <div className="col-12">
          <FormControl className="mb-1">
            <FormLabel>I am a</FormLabel>
            <RadioGroup aria-label="I am a" className="flex-row">
              <FormControlLabel value="Buyer" control={<Radio />} label="Buyer" />
              <FormControlLabel value="Seller" control={<Radio />} label="Seller" />
              <FormControlLabel value="Agent" control={<Radio />} label="Builder" />
              <FormControlLabel value="Investor" control={<Radio />} label="Investor" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <TextField
            fullWidth
            required
            id="standard-basic"
            label="Your Name" />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <TextField
            fullWidth
            id="standard-basic"
            label="Company Name" />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <TextField
            fullWidth
            id="standard-basic"
            label="Place" />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <TextField
            fullWidth
            required
            id="standard-basic"
            label="Email" />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <TextField
            fullWidth
            required
            id="standard-basic"
            label="Mobile No." />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <TextField
            fullWidth
            id="standard-basic"
            label="Alternative Mobile No." />
        </div>
        <div className="col-12">
          <TextField
            fullWidth
            id="standard-basic"
            multiline
            rows={3}
            label="Subject" />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  </div>
);

export default BusinessEnquiries;