import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { favouriteList } from "../../redux/userProperty/userProperty.actions";

export function FavouriteListApiCall(pageNo, pageSize) {
  return (dispatch) => {
    dispatch(favouriteList(null, true));

    const url = `${URL_CONSTANTS.FAVOURITE_LIST}page_number=${pageNo}&page_size=${pageSize}`;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(favouriteList(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(favouriteList(null, false));
      });
  };
}