import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertyCategoryDetails } from "../../redux/property/property.actions";

export function PropertyCategoryDetailsApiCall() {
  return (dispatch) => {
    dispatch(propertyCategoryDetails(null, true));

    const url = URL_CONSTANTS.PROPERTY_CATEGORY_DETAILS;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          let types = [];
          res.data.data.types.forEach(item => {
            if(item.type_name === "Commercial") {
              let category = [];
              item.category.forEach(cate => {
                if(
                  cate.category_name !== "Nursing Homes" && 
                  cate.category_name !== "Pubs" && 
                  cate.category_name !== "Complex" && 
                  cate.category_name !== "Resorts" &&
                  cate.category_name !== "Restaurants" &&
                  cate.category_name !== "Shop" &&
                  cate.category_name !== "Showroom" &&
                  cate.category_name !== "Special Purpose" &&
                  cate.category_name !== "Warehouse / Distribution"
                ) {
                  category.push(cate);
                }
              });
              types.push({
                type_name: item.type_name,
                type_id: item.type_id,
                category
              });
            } else {
              types.push(item);
            }
          })
          dispatch(propertyCategoryDetails({ auctions: res.data.data.auctions, types }, false));
        }
      })
      .catch((error) => {
        dispatch(propertyCategoryDetails(null, false));
      });
  };
}