import { postRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { UserDetailsApiCall } from './UserDetailsApiCall';

export function EditProfileApiCall(data, setLoader, toastView) {
  return (dispatch) => {
    postRequestHeaderUser(URL_CONSTANTS.EDIT_PROFILE, data)
    .then((res) => {
      if (res.data.status_code === 200) {
        toastView("Profile updated successfully", 'success');
        dispatch(UserDetailsApiCall());
      }
      setLoader(false);
    })
    .catch((error) => {
      setLoader(false);
    });
  }
}