import PersistantDataTypes from './persistantData.types';

export const adminLogin = (data, loader) => ({
  type: PersistantDataTypes.ADMIN_LOGIN,
  payload: {
    data,
    loader
  }
});

export const userDetails = (data, loader) => ({
  type: PersistantDataTypes.USER_DETAILS,
  payload: {
    data,
    loader
  }
});

export const userLogin = (data, loader) => ({
  type: PersistantDataTypes.USER_LOGIN,
  payload: {
    data,
    loader
  }
});