import ReportActionTypes from './report.types';

const initialReportState = {
  interestedReport: {
    data: null,
    loader: false
  },
  signedUpReport: {
    data: null,
    loader: false
  },
  uploadedReport: {
    data: null,
    loader: false
  }
};

const reportReducer = (state = initialReportState, action) => {
  switch(action.type) {
    case ReportActionTypes.INTERESTED_REPORT:
      return {
        ...state,
        interestedReport: {
          ...state.interestedReport,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case ReportActionTypes.SIGNED_UP_REPORT:
      return {
        ...state,
        signedUpReport: {
          ...state.signedUpReport,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case ReportActionTypes.UPLOADED_REPORT:
      return {
        ...state,
        uploadedReport: {
          ...state.uploadedReport,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default reportReducer;