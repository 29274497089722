export const emailValidation = (email) => {
  const emailRegex = /\S+@\S+\.\S+/;
  if (emailRegex.test(email)) {
    return null;
  } else {
    return "Please enter a valid email.";
  }
};

export const normalFieldValidation = (text, field) => {
  if (text?.trim(" ")?.length === 0) {
    return `${field} is required`;
  } else {
    return null;
  }
};

export const phoneNumberValidation = (phone) => {
  var phoneRegex = /^\d{10}$/;
  if (phone.match(phoneRegex)) {
    return null;
  } else {
    return "Invalid Phone Number";
  }
};
