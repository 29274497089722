import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import './NotFoundPage.styles.scss';
import Img from '../../assets/img/404.jpg';

const NotFoundPage = () => (
  <div className="page-not-found__outer">
    <img src={Img} alt="404" />
    <h1 className="not-found">Oops! Sorry, we could not find the page..</h1>
    <Link to='/'>
      <Button
        type="button"
        variant="contained"
        color="primary"
      >
        Back to home
      </Button>
    </Link>
  </div>
);

export default NotFoundPage;