import AdminPropertyActionTypes from "./adminProperty.types";

export const propertyOtherAuctionList = (data, loader) => ({
  type: AdminPropertyActionTypes.PROPERTY_OTHER_AUCTION_LIST,
  payload: {
    data,
    loader
  }
});

export const addProperty = (data, loader) => ({
  type: AdminPropertyActionTypes.ADD_PROPERTY,
  payload: {
    data,
    loader
  }
});

export const bankAuctionPropertyList = (data, loader) => ({
  type: AdminPropertyActionTypes.BANK_AUCTION_PROPERTY_LIST,
  payload: {
    data,
    loader
  }
});

export const propertyApproval = (data, loader) => ({
  type: AdminPropertyActionTypes.PROPERTY_APPROVAL,
  payload: {
    data,
    loader
  }
});

export const propertyUnApproval = (data, loader) => ({
  type: AdminPropertyActionTypes.PROPERTY_UNAPPROVAL,
  payload: {
    data,
    loader
  }
});

export const updateProperty = (data, loader) => ({
  type: AdminPropertyActionTypes.UPDATE_PROPERTY,
  payload: {
    data,
    loader
  }
});

export const removeProperty = (data, loader) => ({
  type: AdminPropertyActionTypes.REMOVE_PROPERTY,
  payload: {
    data,
    loader
  }
});

export const dashboardSummary = (data, loader) => ({
  type: AdminPropertyActionTypes.DASHBOARD_SUMMARY,
  payload: {
    data,
    loader
  }
});

export const editProperty = (data, loader) => ({
  type: AdminPropertyActionTypes.EDIT_PROPERTY,
  payload: {
    data,
    loader
  }
});

export const adminPropertyDetails = (data, loader) => ({
  type: AdminPropertyActionTypes.ADMIN_PROPERTY_DETAILS,
  payload: {
    data,
    loader
  }
});

export const adminPropertySearchAction = (data, loader) => ({
  type: AdminPropertyActionTypes.ADMIN_PROPERTY_SEARCH,
  payload: {
    data,
    loader
  }
});

export const adminBankSearchAction = (data, loader) => ({
  type: AdminPropertyActionTypes.ADMIN_BANK_SEARCH,
  payload: {
    data,
    loader
  }
});

export const propertyAddSuccess = (data) => ({
  type: AdminPropertyActionTypes.PROPERTY_ADD_SUCCESS,
  payload: data
});