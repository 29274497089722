import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { verifyOTP } from "../../redux/user/user.actions";

export function VerifyOTPApiCall(phone, otp, toastView, verifyCallback = () => {}) {
  return (dispatch) => {
    dispatch(verifyOTP(null, true));

    const url = `${URL_CONSTANTS.VERIFY_OTP}phone=${phone}&otp=${otp}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Verification Successful", 'success');
          dispatch(verifyOTP('Account Verified', false));
          verifyCallback(true);
        } else {
          verifyCallback(false);
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message, 'error');
        dispatch(verifyOTP(null, false));
        verifyCallback(false);
      });
  };
}