import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { uploadedReport } from "../../redux/report/report.actions";

export function UploadedReportApiCall(toastView) {
  return (dispatch) => {
    dispatch(uploadedReport(null, true));

    const url = URL_CONSTANTS.UPLOADED_REPORT;

    getRequestHeader(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch(uploadedReport(res.data, false));
        }
      })
      .catch((error) => {
        dispatch(uploadedReport(null, false));
      });
  };
}