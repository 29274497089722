import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { resetPassword } from "../../redux/user/user.actions";

export function ResetPasswordApiCall(phone, otp, password, toastView, setIsPasswordResetSuccess) {
  return (dispatch) => {
    dispatch(resetPassword(null, true));

    const url = `${URL_CONSTANTS.RESET_PASSWORD}phone=${phone}&otp=${otp}&new_password=${password}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Password reset successful", 'success');
          setIsPasswordResetSuccess(true);
          dispatch(resetPassword(res.data.data, false));
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message);
        dispatch(resetPassword(null, false));
      });
  };
}