import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { dashboardSummary } from "../../redux/adminProperty/adminProperty.actions";

export function DashboardSummaryApiCall() {
  return (dispatch) => {
    dispatch(dashboardSummary(null, true));

    const url = URL_CONSTANTS.DASHBOARD_SUMMARY;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(dashboardSummary(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(dashboardSummary(null, false));
      });
  };
}