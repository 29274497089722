import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { addEndUser } from "../../redux/user/user.actions";

export function AddEndUserApiCall(data) {
  return (dispatch) => {
    dispatch(addEndUser(null, true));

    const url = URL_CONSTANTS.ADD_END_USER;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(addEndUser('Success', false));
        }
      })
      .catch((error) => {
        dispatch(addEndUser(null, false));
      });
  };
}