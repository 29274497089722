import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from 'react-bootstrap/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import InputGroup from 'react-bootstrap/InputGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import { CLOSE_BUTTON } from '../../constants/iconConstants';
import Loader from '../Loader/Loader.component';
import useWindowDimension from '../../utils/CustomHooks/useWindowDimension';
import { numberWithCommas } from '../../utils/CustomFunctions';
import { UploadFileApiCall } from '../../apiCalls/File/UploadFileApiCall';
import { RemoveFileApiCall } from '../../apiCalls/File/RemoveFileApiCall';
import { FileUploadUserApiCall } from '../../apiCalls/UserProperty/FileUploadUserApiCall';
import { FileRemoveUserApiCall } from '../../apiCalls/UserProperty/FileRemoveUserApiCall';
import { SearchInstitutionApiCall } from '../../apiCalls/Institution/SearchInstitutionApiCall';
import { AddInstitutionApiCall } from '../../apiCalls/Institution/AddInstitutionApiCall';
import { EditInstitutionApiCall } from '../../apiCalls/Institution/EditInstitutionApiCall';
import { getTopLocalities, getTopTenCities, getTopTenStates } from '../../redux/search/search.selector';
import { propertyAddSuccessState } from '../../redux/adminProperty/adminProperty.selector';
import { searchInstitution, addInstitution, editInstitution } from '../../redux/institution/institution.selector';
import { searchInstitutionAction } from '../../redux/institution/institution.actions';
import { allUnitsState } from '../../redux/property/property.selector';
import { 
  RESIDENTIAL_CATEGORIES,
  AGRICULTURAL_CATEGORIES,
  INDUSTRIAL_CATEGORIES,
  COMMERCIAL_CATEGORIES
} from '../../utils/JsonData/Categories';
import './Modals.styles.scss';

const AddPropertyModal = ({
  isUserUpload,
  showPropertyModal,
  handleAddProperty,
  handleAddPropertyClose,
  selectedTab,
  addPropertyType,
  handleAddPropertyCategoryChange,
  handleAddPropertyTypeChange,
  addPropertyFields,
  bankAuctionPropertyFields,
  addPropertyCategory,
  residential,
  industrial,
  commercial,
  agricultural,
  savePropertyData,
  setSavePropertyData
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToasts();
  const { width } = useWindowDimension();
  const topStates = useSelector(getTopTenStates);
  const topCities = useSelector(getTopTenCities);
  const topLocalities = useSelector(getTopLocalities);
  const searchInstitutionData = useSelector(searchInstitution);
  const addInstitutionData = useSelector(addInstitution);
  const editInstitutionData = useSelector(editInstitution);
  const propertyAddSuccess = useSelector(propertyAddSuccessState);
  const allUnits = useSelector(allUnitsState);
  const [files, setFiles] = useState([]);
  const [editInstitutionVal, setEditInstitutionVal] = useState('');
  const [showEditInstitutionModal, setShowEditInstitutionModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [fileInfos, setFileInfos] = useState([]);
  const [validated, setValidated] = useState(false);
  const [err, setErr] = useState({
    state: null, city: null, locality: null, institution: null
  });
  const [currentSaleNotice, setCurrentSaleNotice] = useState(null);
  const [currentAnnexure, setCurrentAnnexure] = useState(null);
  const [saleNotice, setSaleNotice] = useState(null);
  const [annexure, setAnnexure] = useState(null);
  const [progressSale, setProgressSale] = useState(0);
  const [progressAnnexure, setProgressAnnexure] = useState(0);
  const [filesSale, setFilesSale] = useState(null);
  const [filesAnnexure, setFilesAnnexure] = useState(null);
  const [uploadFile, setuploadFile] = useState(-1);
  const [disableSave, setDisableSave] = useState(false);
  const annexureInput = useRef(null);
  const saleInput = useRef(null);
  const imageInput = useRef(null);

  const closeModal = () => {
    handleAddPropertyClose();
    setCurrentFile(undefined);
    setCurrentAnnexure(null);
    setCurrentSaleNotice(null);
    setFiles([]);
    setFilesAnnexure(null);
    setFilesSale(null);
    setProgress(0);
    setProgressAnnexure(0);
    setProgressSale(0);
    setValidated(false);
    setuploadFile(-1);
    setErr({
      state: null, city: null, locality: null, institution: null
    });
    setTimeout(() => {
      setFileInfos([]);
      setSaleNotice('');
      setAnnexure('');
    }, 2000);
  }

  useEffect(() => {
    if (propertyAddSuccess) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyAddSuccess]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const selectFile = (event) => {
    if (event.target.files.length + fileInfos?.length > 15) {
      toastView("Maximum number of images allowed is 15", 'warning');
    } else {
      if (event.target.files[0]?.size > 10000000) {
        toastView("Maximum size allowed is 10MB", 'warning');
      } else {
        setFiles(event.target.files);
      }
    }
  };
  useEffect(() => {
    if (uploadFile !== -1) {
      setDisableSave(true);
      let currentItem = files[uploadFile];
      setProgress(0);
      setCurrentFile(files[uploadFile]);

      if (location?.pathname?.includes('admin')) {
        UploadFileApiCall(currentItem, (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        })
          .then((response) => {
            setFileInfos([...fileInfos, response.data?.data?.file_id]);
            if (uploadFile === files.length - 1) {
              setuploadFile(-1)
              setCurrentFile(undefined);
              setFiles([]);
            } else {
              setuploadFile(old => old + 1)
            }
          })
          .catch(() => {
            setProgress(0);
            setCurrentFile(undefined);
            setDisableSave(false);
          });
      } else {
        FileUploadUserApiCall(currentItem, (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        })
          .then((response) => {
            setFileInfos([...fileInfos, response.data?.data?.file_id]);

            if (uploadFile === files.length - 1) {
              setuploadFile(-1)
              setCurrentFile(undefined);
              setFiles([]);
            } else {
              setuploadFile(old => old + 1)
            }
          })
          .catch((error) => {
            setDisableSave(false);
            setProgress(0);
            setCurrentFile(undefined);
          });
      }
    } else {
      setDisableSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  const upload = () => {
    if ((files?.length + fileInfos?.length) > 15) {
      toastView("Maximum number of images allowed is 15", 'warning');
    } else {
      if (files.length === 0) {
        toastView("No File Chosen", 'warning');
      } else {
        setuploadFile(0)
      }
    }
  };

  useEffect(() => {
    setFileInfos(savePropertyData?.images ?? []);
    setSaleNotice(savePropertyData?.e_auction_sale_notice ?? null);
    setAnnexure(savePropertyData?.annexurell ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.images, savePropertyData?.annexurell, savePropertyData?.e_auction_sale_notice]);

  const uploadPdf = (name) => {
    if (name === 'sales') {
      setDisableSave(true);
      let currentFile = filesSale[0];
      setProgressSale(0);
      setCurrentSaleNotice(filesSale[0]);

      UploadFileApiCall(currentFile, (event) => {
        setProgressSale(Math.round((100 * event.loaded) / event.total));
      })
        .then((response) => {
          setSaleNotice(response.data?.data?.file_id);
          setCurrentSaleNotice(undefined);
          setFilesSale(undefined);
          setDisableSave(false);
        })
        .catch(() => {
          setProgressSale(0);
          setDisableSave(false);
          setCurrentSaleNotice(undefined);
        });
    } else if (name === 'annexure') {
      setDisableSave(true);
      let currentFile = filesAnnexure[0];
      setProgressAnnexure(0);
      setCurrentAnnexure(filesAnnexure[0]);

      UploadFileApiCall(currentFile, (event) => {
        setProgressAnnexure(Math.round((100 * event.loaded) / event.total));
      })
        .then((response) => {
          setAnnexure(response.data?.data?.file_id);
          setCurrentAnnexure(undefined);
          setFilesAnnexure(undefined);
          setDisableSave(false);
        })
        .catch(() => {
          setProgressAnnexure(0);
          setDisableSave(false);
          setCurrentAnnexure(undefined);
        });
    }
  }

  const selectFileAnnexure = (event) => {
    if (event.target.files[0]?.size > 10000000) {
      toastView("Maximum size allowed is 10MB", 'warning');
    } else {
      setFilesAnnexure(event.target.files);
    }
  }

  const selectFileSaleNotice = (event) => {
    if (event.target.files[0]?.size > 10000000) {
      toastView("Maximum size allowed is 10MB", 'warning');
    } else {
      setFilesSale(event.target.files);
    }
  }

  const removeImage = (fileId) => {
    let temp = [];
    for (let i = 0; i < fileInfos?.length; i++) {
      if (fileInfos[i] !== fileId) {
        temp.push(fileInfos[i]);
      }
    }
    setFileInfos(temp);
    imageInput.current.value = '';
    if (location?.pathname?.includes('admin')) {
      dispatch(RemoveFileApiCall(fileId, toastView));
    } else {
      dispatch(FileRemoveUserApiCall(fileId, toastView));
    }
  }

  const removePdf = (type) => {
    if(type === 'annexure') {
      dispatch(RemoveFileApiCall(annexure, toastView));
      setAnnexure(null);
      annexureInput.current.value = '';
    } else {
      dispatch(RemoveFileApiCall(saleNotice, toastView));
      setSaleNotice(null);
      saleInput.current.value = '';
    }
  }

  const validateLocation = (name, value) => {
    let stateErr = err?.state;
    let localityErr = err?.locality;
    let cityErr = err?.city;
    if (name === 'city') {
      if (!value || value?.trim("")?.length === 0) {
        cityErr = "City is required"
      } else {
        cityErr = null;
      }
    } else if (name === 'state') {
      if (!value || value?.trim("")?.length === 0) {
        stateErr = 'State is required';
      } else {
        stateErr = null;
      }
    } else if (name === 'locality') {
      if (!value || value?.trim("")?.length === 0) {
        localityErr = 'Locality is required';
      } else {
        localityErr = null;
      }
    }
    setErr({
      ...err,
      city: cityErr,
      state: stateErr,
      locality: localityErr
    });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let stateErr, localityErr, cityErr = null;
    if (!savePropertyData?.city_name || savePropertyData?.city_name?.trim("")?.length === 0) {
      cityErr = "City is required"
    } else if (!savePropertyData?.city_id || !savePropertyData?.city_id?.trim("")?.length === 0) {
      cityErr = "City is not selected"
    } else {
      cityErr = null;
    }
    if (!savePropertyData?.state_name || savePropertyData?.state_name?.trim("")?.length === 0) {
      stateErr = 'State is required';
    } else if (!savePropertyData?.state_id || savePropertyData?.state_id?.trim("")?.length === 0) {
      stateErr = "State is not selected"
    } else {
      stateErr = null;
    }
    if (!savePropertyData?.locality_name || savePropertyData?.locality_name?.trim("")?.length === 0) {
      localityErr = 'Locality is required';
    } else {
      localityErr = null;
    }
    let instErr = null;
    if(selectedTab === 'bankAuctionProperty' && !savePropertyData?.institution_id?.length > 0) {
      instErr = "Institution is required"
    }
    setErr({
      ...err,
      city: cityErr,
      state: stateErr,
      locality: localityErr,
      institution: instErr
    });
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      toastView("Fill required fields", 'error');
    } else if (!stateErr && !localityErr && !cityErr && !instErr) {
      handleAddProperty(fileInfos, saleNotice, annexure);
    }
  }

  const institutionSearch = (value) => {
    setSavePropertyData({
      ...savePropertyData,
      institution: value,
      institution_id: ''
    });
    if(value?.length > 0) {
      dispatch(SearchInstitutionApiCall(value));
    }
  }

  const addInstitutionFunc = () => {
    if((!savePropertyData?.institution_id || 
      savePropertyData?.institution_id?.length === 0) && 
      savePropertyData?.institution?.length > 0
    ) {
      let data = {
        institution: savePropertyData?.institution
      }
      dispatch(AddInstitutionApiCall(data, toastView));
      setSavePropertyData({
        ...savePropertyData,
        institution: ''
      });
    }
  }

  const editInstitutionFunc = () => {
    if(savePropertyData?.institution !== editInstitutionVal) {
      let data = {
        institution: editInstitutionVal,
        institution_id: savePropertyData?.institution_id
      }
      dispatch(searchInstitutionAction(null));
      dispatch(EditInstitutionApiCall(data, toastView));
      setSavePropertyData({
        ...savePropertyData,
        institution: '',
        institution_id: ''
      });
    }
    setShowEditInstitutionModal(false);
  }

  return (
    <Modal
      size={isUserUpload ? "xl" : "lg"}
      show={showPropertyModal}
      className={isUserUpload ? 'user-upload-outer' : ''}
    >
      <Loader visible={addInstitutionData?.loader || editInstitutionData?.loader} />
      <div style={{ margin: 20 }}>
        <Modal.Header>
          <Modal.Title>{savePropertyData?.property_id ? 'Edit' : 'Add'} Property</Modal.Title>
          <img
            src={CLOSE_BUTTON.default}
            alt=""
            className="button-hover"
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <div className="mb-3">
              <Form.Group controlId="validationCustom01">
                <p className="config-subhead">Property Type<span className="required-star">*</span></p>
                <Form.Control
                  as="select"
                  className="user-select-menu"
                  placeholder="Property type"
                  value={addPropertyType}
                  onChange={(e) => handleAddPropertyTypeChange(e)}
                  required
                  disabled={savePropertyData?.property_id ? true : false}
                >
                  <option value="" selected>Select Property Type</option>
                  <option value="Residential">Residential</option>
                  <option value="Industrial">Industrial</option>
                  <option value="Agricultural land">Agricultural Land</option>
                  <option value="Commercial">Commercial</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">Property type is required</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="mb-3">
              <Form.Group controlId="validationCustom01">
                <p className="config-subhead">Category<span className="required-star">*</span></p>
                <Form.Control
                  required
                  as="select"
                  className="user-select-menu"
                  placeholder="Select Category"
                  value={addPropertyCategory}
                  onChange={(e) => handleAddPropertyCategoryChange(e)}
                >
                  <option value="" selected>Select Category</option>
                  {
                    addPropertyType === 'Residential' && (
                      residential.map((item) => (
                        <option value={item?.category_name} key={item?.category_id}>
                          {RESIDENTIAL_CATEGORIES[item?.category_name]}
                        </option>
                      ))
                    )
                  }
                  {
                    addPropertyType === 'Industrial' && (
                      industrial.map((item) => (
                        <option value={item?.category_name} key={item?.category_id}>
                          {INDUSTRIAL_CATEGORIES[item?.category_name]}
                        </option>
                      ))
                    )
                  }
                  {
                    addPropertyType === 'Agricultural land' && (
                      agricultural.map((item) => (
                        <option value={item?.category_name} key={item?.category_id}>
                          {AGRICULTURAL_CATEGORIES[item?.category_name]}
                        </option>
                      ))
                    )
                  }
                  {
                    addPropertyType === 'Commercial' && (
                      commercial.map((item) => (
                        <option value={item?.category_name} key={item?.category_id}>
                          {COMMERCIAL_CATEGORIES[item?.category_name]}
                        </option>
                      ))
                    )
                  }
                </Form.Control>
                <Form.Control.Feedback type="invalid">Property category is required</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="row d-flex justify-content-between">
              {
                addPropertyFields.map((property) => (
                  <div
                    key={property.id}
                    className={`mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12 ${property?.position === 'right' && 'second-field'}`}
                  >
                    <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12 p-0">
                      <Form.Group controlId={`validation${property?.value}`}>
                        <Form.Label
                          className="form-label"
                        >
                          {property.label}{property?.required && <span className="required-star">*</span>}
                        </Form.Label>
                        {
                          property?.isBooleanDropdown ? (
                            <Form.Control
                              as="select"
                              className="user-select-menu"
                              value={savePropertyData[property.value]}
                              onChange={(e) => setSavePropertyData({
                                ...savePropertyData,
                                [property.value]: e.target.value
                              })}
                              required={property?.required ? true : false}
                            >
                              <option selected value="">Select</option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </Form.Control>
                          ) : (
                            <>
                              {
                                property?.value === 'state' || property?.value === 'city' || property?.value === 'locality' ? (
                                  <Autocomplete
                                    id="dropdown"
                                    freeSolo
                                    className="user-select-menu"
                                    options={(
                                      property?.value === 'state'
                                        ? topStates.data
                                        : property?.value === 'city'
                                          ? topCities.data
                                          : topLocalities.data
                                    ) ?? []}
                                    disableClearable
                                    noOptionsText={
                                      property?.value === 'state'
                                        ? "No states"
                                        : property?.value === 'city'
                                          ? "No cities"
                                          : "No localities"
                                    }
                                    getOptionLabel={(option) =>
                                      property?.value === 'state'
                                        ? option.state_name
                                        : property?.value === 'city'
                                          ? option.city_name
                                          : option.locality_name
                                    }
                                    onChange={(event, newValue) =>
                                      setSavePropertyData({
                                        ...savePropertyData,
                                        [`${property.value}_id`]: property?.value === 'state'
                                          ? newValue?.state_id
                                          : property?.value === 'city'
                                            ? newValue?.city_id
                                            : newValue?.locality_id,
                                        [`${property.value}_name`]: property?.value === 'state'
                                          ? newValue?.state_name
                                          : property?.value === 'city'
                                            ? newValue?.city_name
                                            : newValue?.locality_name
                                      })
                                    }
                                    value={{
                                      [`${property?.value}_id`]: savePropertyData[`${property?.value}_id`],
                                      [`${property?.value}_name`]: savePropertyData[`${property?.value}_name`]
                                    }}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                      <TextField
                                        error={err[property?.value]}
                                        helperText={err[property?.value]}
                                        {...params}
                                        value={savePropertyData[`${property?.value}_name`]}
                                        variant="outlined"
                                        onChange={(e) => {
                                          setSavePropertyData({
                                            ...savePropertyData,
                                            [`${property.value}_name`]: e.target.value,
                                            [`${property?.value}_id`]: ""
                                          });
                                          validateLocation(property?.value, e.target.value);
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <InputGroup hasValidation>
                                    {
                                      property.value === 'price' && (
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">₹</span>
                                        </div>
                                      )
                                    }
                                    <Form.Control
                                      type={property.type}
                                      required={property?.required}
                                      placeholder={property?.placeholder}
                                      className="form-control"
                                      value={
                                        property.value === "price" 
                                        ? numberWithCommas(savePropertyData[property.value])
                                        : savePropertyData[property.value]
                                      }
                                      aria-describedby={`${property.value}Help`}
                                      onChange={(e) => setSavePropertyData({
                                        ...savePropertyData,
                                        [property.value]: property.value === "price" ? e.target.value?.replace(/\D/g, '') : e.target.value
                                      })}
                                    />
                                    {
                                      property?.isArea && (
                                        <div className="input-group-append">
                                          <Form.Control
                                            as="select"
                                            className="user-select-menu"
                                            placeholder="Select Measurement"
                                            value={savePropertyData[property?.unit]}
                                            onChange={(e) => setSavePropertyData({
                                              ...savePropertyData,
                                              [property?.unit]: e.target.value
                                            })}
                                            defaultValue=""
                                            required={property?.required}
                                          >
                                            <option selected value="">Area Unit</option>
                                            {allUnits?.data?.map((unit) => (
                                              <option value={unit?.unit_id}>{unit?.unit_name}</option>
                                            ))}
                                          </Form.Control>
                                        </div>
                                      )
                                    }
                                    <Form.Control.Feedback type="invalid">{property?.label} is required</Form.Control.Feedback>
                                  </InputGroup>
                                )
                              }
                            </>
                          )
                        }

                      </Form.Group>
                    </div>
                  </div>
                ))
              }
            </div>
            {
              addPropertyType === 'commercial' && (
                <div className="mb-3">
                  <label htmlFor="address-field" className="form-label">Address</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="address-field"
                    aria-describedby="addressHelp"
                    value={savePropertyData.address}
                    onChange={(e) => setSavePropertyData({
                      ...savePropertyData,
                      address: e.target.value
                    })}
                  />
                </div>
              )
            }
            <div className="row d-flex justify-content-between">
              {
                selectedTab === 'bankAuctionProperty' && (
                  bankAuctionPropertyFields?.map((bank) => (
                    <div
                      key={bank.id}
                      className={`mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12 ${bank?.position === 'right' && 'second-field'}`}
                    >
                      <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12 p-0">
                        <Form.Label
                          className="form-label"
                        >
                          {bank.label}{bank?.required && <span className="required-star">*</span>}
                        </Form.Label>
                        {
                          bank?.isDropdown ? (
                            <Form.Control
                              required={bank?.required}
                              as="select"
                              className="user-select-menu"
                              value={savePropertyData[bank.value]?.toLowerCase()}
                              onChange={(e) => setSavePropertyData({
                                ...savePropertyData,
                                [bank.value]: e.target.value
                              })}
                            >
                              <option selected value="">Select</option>
                              {
                                bank?.dropDownData?.map((item) => (
                                  <option
                                    key={item?.id}
                                    value={item?.value}
                                  >
                                    {item?.label}
                                  </option>
                                ))
                              }
                            </Form.Control>
                          ) : bank?.isInstitution ? (
                            <InputGroup style={{ height: '40px', overflow: 'hidden' }}>
                              <Autocomplete
                                id="dropdown"
                                freeSolo
                                className="user-select-menu institution-wrapper"
                                options={searchInstitutionData ?? []}
                                disableClearable
                                noOptionsText={"No Institutions"}
                                getOptionLabel={(option) =>
                                  option.institution
                                }
                                onChange={(event, newValue) => {
                                  setSavePropertyData({
                                    ...savePropertyData,
                                    institution_id: newValue?.institution_id,
                                    institution: newValue?.institution
                                  });
                                  setErr({
                                    ...err,
                                    institution: null
                                  });
                                }}
                                value={{
                                  ['institution_id']: savePropertyData['institution_id'],
                                  ['institution']: savePropertyData['institution']
                                }}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      error={err?.institution}
                                      helperText={err?.institution}
                                      {...params}
                                      className={savePropertyData?.institution_id?.length > 0 || searchInstitutionData?.length === 0 ? "inst-input" : ""}
                                      value={savePropertyData['institution']}
                                      variant="outlined"
                                      onChange={(e) => institutionSearch(e.target.value)}
                                    />
                                  </>
                                )}
                              />
                              {savePropertyData?.institution_id?.length > 0 ? (
                                <Button
                                  className='edit-add'
                                  onClick={() => {
                                    setEditInstitutionVal(savePropertyData?.institution);
                                    setShowEditInstitutionModal(true);
                                  }}
                                >
                                  Edit
                                </Button>
                              ) : searchInstitutionData?.length === 0 ? (
                                <Button
                                  className='edit-add'
                                  onClick={addInstitutionFunc}
                                >
                                  Add
                                </Button>
                              ) : ''}
                            </InputGroup>
                          ) : (
                            <Form.Control
                              required={bank?.required}
                              type={bank.type}
                              placeholder={bank?.placeholder}
                              className="form-control"
                              value={bank?.value === "inspection_details" 
                                ? savePropertyData[bank.value]?.slice(6) + 
                                  savePropertyData[bank.value]?.slice(2,6) + 
                                  savePropertyData[bank.value]?.slice(0,2) 
                                : bank?.value === "emd"
                                ? numberWithCommas(savePropertyData[bank.value])
                                : savePropertyData[bank.value]}
                              aria-describedby={`${bank.value}Help`}
                              onChange={(e) => {
                                setSavePropertyData({
                                  ...savePropertyData,
                                  [bank.value]: bank?.value === "inspection_details" 
                                    ? moment(e.target.valueAsNumber).format("DD-MM-YYYY") 
                                    : bank?.value === "emd"
                                    ? e.target.value?.replace(/\D/g, '')
                                    : e.target.value
                                })
                              }}
                            />
                          )
                        }
                      </div>
                    </div>
                  ))
                )
              }
            </div>
            {
              selectedTab === 'bankAuctionProperty' && (
                <>
                  <Form.Label
                    className="form-label"
                  >
                    Upload English Sale Notice
                  </Form.Label>
                  <div className="mb-3 d-flex flex-row upload-image">
                    <div className="d-flex flex-row file-btn-wrap">
                      {saleNotice && (
                        <div className="pdfStyle">
                          <CancelIcon
                            style={{
                              width: 20,
                              height: 20,
                              fill: "red",
                              marginTop: -7,
                              marginLeft: -4,
                              position: 'absolute'
                            }}
                            onClick={() => removePdf('saleNotice')}
                          />
                          <DescriptionIcon 
                            style={{
                              fontSize: 40,
                            }}
                          />
                        </div>
                      )}
                      <div className="d-flex flex-column">
                        {currentSaleNotice && !saleNotice && (
                          <div className="progress" style={{ width: width > 576 ? '200px' : '100px' }}>
                            <div
                              className="progress-bar progress-bar-info progress-bar-striped"
                              role="progressbar"
                              aria-valuenow={progressSale}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: progressSale + "%" }}
                            >
                              {progressSale}%
                            </div>
                          </div>
                        )}

                        <label className="btn btn-default">
                          <input type="file" className='file-input' onChange={selectFileSaleNotice} ref={saleInput} />
                        </label>
                      </div>

                      <div className="d-flex flex-row align-items-center">
                        <button
                          className="btn btn-success"
                          disabled={!filesSale || saleNotice}
                          onClick={() => uploadPdf('sales')}
                          type="button"
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                  <Form.Label
                    className="form-label"
                  >
                    Upload Annexure II
                  </Form.Label>
                  <div className="mb-3 d-flex flex-row upload-image">
                    <div className="d-flex flex-row file-btn-wrap">
                      {annexure && (
                        <div className="pdfStyle">
                          <CancelIcon
                            style={{
                              width: 20,
                              height: 20,
                              fill: "red",
                              marginTop: -7,
                              marginLeft: -4,
                              position: 'absolute'    
                            }}
                            onClick={() => removePdf('annexure')}
                          />
                          <DescriptionIcon 
                            style={{
                              fontSize: 40,
                            }}
                          />
                        </div>
                      )}
                      <div className="d-flex flex-column">
                        {currentAnnexure && !annexure && (
                          <div className="progress" style={{ width: width > 576 ? '200px' : '100px' }}>
                            <div
                              className="progress-bar progress-bar-info progress-bar-striped"
                              role="progressbar"
                              aria-valuenow={progressAnnexure}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: progressAnnexure + "%" }}
                            >
                              {progressAnnexure}%
                            </div>
                          </div>
                        )}

                        <label className="btn btn-default">
                          <input type="file" className='file-input' onChange={selectFileAnnexure} ref={annexureInput} />
                        </label>
                      </div>

                      <div className="d-flex flex-row align-items-center">
                        <button
                          className="btn btn-success"
                          disabled={!filesAnnexure || annexure}
                          onClick={() => uploadPdf('annexure')}
                          type="button"
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            {
              addPropertyType?.length > 0 && (
                <>
                  <Form.Label
                    className="form-label"
                  >
                    Images{'  '}<span style={{ fontSize: 12, color: 'red' }}>(jpg / jpeg / png), Max size: 10MB, Max No: 15</span>
                  </Form.Label>
                  <div className="mb-3 d-flex flex-column upload-image">
                    <div className="d-flex flex-row file-btn-wrap">
                      <div className="d-flex flex-column">
                        {currentFile && (
                          <div className="progress" style={{ width: width > 576 ? '200px' : '100px' }}>
                            <div
                              className="progress-bar progress-bar-info progress-bar-striped"
                              role="progressbar"
                              aria-valuenow={progress}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: progress + "%" }}
                            >
                              {progress}%
                            </div>
                          </div>
                        )}

                        <label className="btn btn-default">
                          <input type="file" className='file-input-image' accept="image/*" multiple onChange={selectFile} ref={imageInput} />
                        </label>
                      </div>

                      <div className="d-flex flex-row align-items-center">
                        <button
                          className="btn btn-success"
                          disabled={!files}
                          onClick={upload}
                          type="button"
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                    <div className="card">
                      <ul className="list-group list-group-flush d-flex flex-row images-list">
                        {fileInfos &&
                          fileInfos.map((file, index) => (
                            <li className="list-group-item" key={index}>
                              <CancelIcon
                                style={{
                                  width: 20,
                                  height: 20,
                                  position: "absolute",
                                  fill: "red",
                                  top: 3,
                                  right: 13
                                }}
                                onClick={() => removeImage(file)}
                              />
                              <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}${file}`} width="50px" height="50px" />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </>
              )
            }
            <Button
              variant="dark"
              type="submit"
              disabled={disableSave}
            >
              Save
            </Button>
          </Form>
          <Modal
            size={"md"}
            show={showEditInstitutionModal}
            centered
          >
            <Modal.Header>
              <Modal.Title>Edit Institution</Modal.Title>
              <img
                src={CLOSE_BUTTON.default}
                alt=""
                className="button-hover"
                onClick={() => setShowEditInstitutionModal(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <div style={{ height: '40px' }}>
                <TextField
                  value={editInstitutionVal}
                  onChangeCapture={(e) => setEditInstitutionVal(e.target.value)}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </div>
              <Button
                style={{ marginTop: '50px', float: 'right' }}
                onClick={editInstitutionFunc}
              >
                Edit
              </Button>
            </Modal.Body>
          </Modal>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default AddPropertyModal;