import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropertyCardDetailed from "../../components/Cards/PropertyCardDetailed.component";
import Loader from '../../components/Loader/Loader.component';
import { FavouriteListApiCall } from '../../apiCalls/UserProperty/FavouriteListApiCall';
import { showInterestState } from '../../redux/user/user.selector';
import { favouriteList } from '../../redux/userProperty/userProperty.selector';
import NoDataIMG from '../../assets/img/no-data1.jpg';
import "./SavedProperties.styles.scss";

const SavedProperties = () => {
  const dispatch = useDispatch();
  const [infiniteFavouriteList, setInfiniteFasvouriteList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const favourites = useSelector(favouriteList);
  const showInterest = useSelector(showInterestState);

  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (favourites.loader) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageNo < totalPages) {
          setPageNo(old => old + 1)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [pageNo, favourites.loader, totalPages]);

  useEffect(() => {
    if (favourites.data?.data?.length > 0) {
      if (pageNo === 1) {
        setInfiniteFasvouriteList(favourites.data?.data);
        setTotalPages(favourites.data?.total_page_count);
      } else {
        setInfiniteFasvouriteList(old => [...old, ...favourites.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourites.data]);

  useEffect(() => {
    dispatch(FavouriteListApiCall(pageNo, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  return (
    <div className="container">
      <Loader visible={favourites.loader || showInterest.loader} />
      {infiniteFavouriteList?.length > 0 ? (
        <div className="row">
          {infiniteFavouriteList?.map((fav, index) => (
            infiniteFavouriteList?.length === index + 1 ? (
              <div
                className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mt-3 mb-3"
                ref={lastElementRef}
              >
                <PropertyCardDetailed
                  section={fav?.is_bankauction == 1 ? "bank-auction" : "buy-sale"}
                  data={fav}
                />
              </div>
            ) : (
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 mt-3 mb-3">
                <PropertyCardDetailed
                  section={fav?.is_bankauction == 1 ? "bank-auction" : "buy-sale"}
                  data={fav}
                />
              </div>
            )
          ))}
        </div>
      ) : (
        <div className="no-fav__outer">
          <img src={NoDataIMG} alt="No - Data" />
          <h1>No Favourites Yet !!</h1>
          <p>Once you favourite a property, you'll see them here.</p>
        </div>
      )}
    </div>
  );
}

export default SavedProperties;
