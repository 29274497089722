import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { upcomingAuction } from "../../redux/property/property.actions";

export function UpcomingAuctionApiCall(pageNo, pageSize) {
  return (dispatch) => {
    dispatch(upcomingAuction(null, true));

    const url = `${URL_CONSTANTS.UPCOMING_AUCTION}${pageNo}&page_size=${pageSize}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(upcomingAuction(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(upcomingAuction(null, false));
      });
  };
}
