import ContentLoader from 'react-content-loader';

const InsideLoader = () => (
  <ContentLoader width="93%" height="95%">
    <rect x="20" y="20" rx="4" ry="3" width="100%" height="200" />
    <rect x="20" y="240" rx="4" ry="4" width="40%" height="10" />
    <rect x="60%" y="240" rx="4" ry="4" width="40%" height="10" />
    <rect x="20" y="260" rx="4" ry="4" width="40%" height="10" />
    <rect x="60%" y="260" rx="4" ry="4" width="40%" height="10" />
    <rect x="20" y="280" rx="4" ry="4" width="40%" height="10" />
    <rect x="60%" y="280" rx="4" ry="4" width="40%" height="10" />
    <rect x="20" y="320" rx="4" ry="4" width="100%" height="10" />
    <rect x="20" y="340" rx="4" ry="4" width="100%" height="10" />
    <rect x="20" y="360" rx="4" ry="4" width="100%" height="10" />
    <rect x="20" y="380" rx="4" ry="4" width="100%" height="10" />
  </ContentLoader>
);

export default InsideLoader;