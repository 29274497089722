import * as React from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Between from "./Between";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
];

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const PrettoSlider = styled(Slider)({
  color: "#000",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#000",
    fontWeight: "bold",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function Range() {
  const dimensions = useWindowDimensions();

  return (
    <>
      {dimensions.width>900 ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ m: 5 }} />
          <Between gap={"35px"}>
            <Box width={"100%"}>
              <Between>
                <Typography gutterBottom>Price</Typography>
                <Typography gutterBottom>upto 25Lac</Typography>
              </Between>
              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={25}
                step={5}
                min={5}
                max={100}
              />
            </Box>

            <Box width={"100%"}>
              <Between>
                <Typography gutterBottom>Min Area (sq.ft)</Typography>
                <Typography gutterBottom>1,000sq.ft</Typography>
              </Between>
              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={6000}
                step={5}
                min={100}
                max={10000}
              />
            </Box>

            <Box width={"100%"}>
              <Between>
                <Typography gutterBottom>Max Area (sq.ft)</Typography>
                <Typography gutterBottom>10,000sq.ft</Typography>
              </Between>

              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={6000}
                step={5}
                min={100}
                max={10000}
              />
            </Box>
          </Between>
          <Box sx={{ m: 2 }} />
        </Box>
      ) : (
        <>
         <Box width={"100%"}>
              <Between>
                <Typography gutterBottom>Price</Typography>
                <Typography gutterBottom>upto 25Lac</Typography>
              </Between>
              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={25}
                step={5}
                min={5}
                max={100}
              />
            </Box>
            <Box width={"100%"}>
              <Between>
                <Typography gutterBottom>Min Area (sq.ft)</Typography>
                <Typography gutterBottom>10,000+ sq.ft</Typography>
              </Between>
              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={6000}
                step={5}
                min={100}
                max={10000}
              />
            </Box>

            <Box width={"100%"}>
              <Between>
                <Typography gutterBottom>Max Area (sq.ft)</Typography>
                <Typography gutterBottom>10,000sq.ft</Typography>
              </Between>

              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={6000}
                step={5}
                min={100}
                max={10000}
              />
            </Box>
        </>
      )}
    </>
  );
}
