export const URL_CONSTANTS = {
  // Admin
  ADMIN_LOGIN: `${process.env.REACT_APP_API_BASE_URL}admin/login`,
  GET_ADMINS_BY_ROLE: `${process.env.REACT_APP_API_BASE_URL}admin/admin_by_role?role_id=`,
  GET_ADMIN_DETAILS: `${process.env.REACT_APP_API_BASE_URL}admin/admin_details?admin_id=`,
  GET_ADMIN_ROLES: `${process.env.REACT_APP_API_BASE_URL}admin/roles`,
  CREATE_ADMIN: `${process.env.REACT_APP_API_BASE_URL}admin/create`,
  CHANGE_ADMIN_PASSWORD: `${process.env.REACT_APP_API_BASE_URL}admin/change_password`,
  UPDATE_ADMIN_ROLE: `${process.env.REACT_APP_API_BASE_URL}admin/update/role`,
  REMOVE_ADMIN: `${process.env.REACT_APP_API_BASE_URL}admin/remove?admin_user_id=`,
  ADMIN_RESEND_OTP: `${process.env.REACT_APP_API_BASE_URL}admin/resend/otp?phone=`,
  ADMIN_RESET_PASSWORD: `${process.env.REACT_APP_API_BASE_URL}admin/reset/password?`,
  AD_DETAILS: `${process.env.REACT_APP_API_BASE_URL}admin/ads/details`,
  AD_CHANGE: `${process.env.REACT_APP_API_BASE_URL}admin/ads/change`,

  // Admin Property
  ADD_PROPERTY: `${process.env.REACT_APP_API_BASE_URL}admin/property/add`,
  BANK_AUCTION_PROPERTY_LIST: `${process.env.REACT_APP_API_BASE_URL}admin/property/all/bank/auction?page_number=`,
  PROPERTY_APPROVAL: `${process.env.REACT_APP_API_BASE_URL}admin/property/approve?property_id=`,
  PROPERTY_UNAPPROVAL: `${process.env.REACT_APP_API_BASE_URL}admin/property/unapprove?property_id=`,
  PROPERTY_OTHER_AUCTION_LIST: `${process.env.REACT_APP_API_BASE_URL}admin/property/all/other/properties?page_number=`,
  DASHBOARD_SUMMARY: `${process.env.REACT_APP_API_BASE_URL}admin/property/dashboard/summary`,
  UPDATE_PROPERTY: `${process.env.REACT_APP_API_BASE_URL}admin/property/update`,
  REMOVE_PROPERTY: `${process.env.REACT_APP_API_BASE_URL}admin/property/remove?property_id=`,
  EDIT_PROPERTY: `${process.env.REACT_APP_API_BASE_URL}admin/property/edit/details?property_id=`,
  ADMIN_PROPERTY_DETAILS: `${process.env.REACT_APP_API_BASE_URL}admin/property/details?property_id=`,
  ADMIN_PROPERTY_SEARCH: `${process.env.REACT_APP_API_BASE_URL}admin/property/all/other/properties?`,
  ADMIN_BANK_SEARCH: `${process.env.REACT_APP_API_BASE_URL}admin/property/all/bank/auction?`,
  DOWNLOAD_PROPERTY_TEMPLATE: `${process.env.REACT_APP_API_BASE_URL}admin/property/template`,
  UPLOAD_PROPERTY_FILE: `${process.env.REACT_APP_API_BASE_URL}admin/property/import/excel`,

  // Property
  TOP_TEN_STATES: `${process.env.REACT_APP_API_BASE_URL}property/topten/states?searchword=`,
  TOP_TEN_CITIES: `${process.env.REACT_APP_API_BASE_URL}property/topten/cities?searchword=`,
  TOP_LOCALITIES: `${process.env.REACT_APP_API_BASE_URL}property/topten/localities?searchword=`,
  PROPERTY_SEARCH_LIST: `${process.env.REACT_APP_API_BASE_URL}property/search/result?`,
  PROPERTY_DETAILS: `${process.env.REACT_APP_API_BASE_URL}property/details?property_id=`,
  LIVE_AUCTION: `${process.env.REACT_APP_API_BASE_URL}property/auction/live?page_number=`,
  UPCOMING_AUCTION: `${process.env.REACT_APP_API_BASE_URL}property/auction/upcoming?page_number=`,
  PROPERTY_SUMMARY: `${process.env.REACT_APP_API_BASE_URL}property/summary`,
  OTHER_SALES_PROPERTIES: `${process.env.REACT_APP_API_BASE_URL}property/other/sales?page_number=`,
  PROPERTY_CATEGORY_DETAILS: `${process.env.REACT_APP_API_BASE_URL}property/type/category`,
  ALL_UNITS: `${process.env.REACT_APP_API_BASE_URL}property/all/units`,

  // File
  UPLOAD_FILE: `${process.env.REACT_APP_API_BASE_URL}admin/property/file/add`,
  REMOVE_FILE: `${process.env.REACT_APP_API_BASE_URL}admin/property/file/remove?file_id=`,
  REMOVE_AD_FILE: `${process.env.REACT_APP_API_BASE_URL}admin/ads/remove/file?file_id=`,

  // User
  ADD_END_USER: `${process.env.REACT_APP_API_BASE_URL}user/register`,
  RESEND_OTP: `${process.env.REACT_APP_API_BASE_URL}user/resend/otp?phone=`,
  USER_LOGIN: `${process.env.REACT_APP_API_BASE_URL}user/login`,
  GOOGLE_SIGN_IN: `${process.env.REACT_APP_API_BASE_URL}user/auth/callback?code=`,
  SHOW_INTEREST: `${process.env.REACT_APP_API_BASE_URL}user/property/interest?property_uniqueid=`,
  VERIFY_OTP: `${process.env.REACT_APP_API_BASE_URL}user/verify/otp?`,
  RESET_PASSWORD: `${process.env.REACT_APP_API_BASE_URL}user/reset/password?`,
  USER_DETAILS: `${process.env.REACT_APP_API_BASE_URL}user/details`,
  PROPERTY_DETAILS_FOR_USER: `${process.env.REACT_APP_API_BASE_URL}user/property/details?property_id=`,
  BANK_AUCTION_PROPERTIES: `${process.env.REACT_APP_API_BASE_URL}user/property/bank/auction?page_number=`,
  FAVOURITE_LIST: `${process.env.REACT_APP_API_BASE_URL}user/property/favourite/list?`,
  USER_PROPERTY_ADDING: `${process.env.REACT_APP_API_BASE_URL}user/property/add`,
  PROPERTY_UPLOADED_LIST: `${process.env.REACT_APP_API_BASE_URL}user/property/uploaded/list?`,
  USER_PROPERTY_UPDATE: `${process.env.REACT_APP_API_BASE_URL}user/property/update`,
  FILE_UPLOAD_USER: `${process.env.REACT_APP_API_BASE_URL}user/property/file/add`,
  FILE_REMOVE_USER: `${process.env.REACT_APP_API_BASE_URL}user/property/file/remove?file_id=`,
  PROPERTY_REMOVE_USER: `${process.env.REACT_APP_API_BASE_URL}user/property/remove?property_id=`,
  PROPERTY_FAVOURITE_USER: `${process.env.REACT_APP_API_BASE_URL}user/property/set/favourite?`,
  USER_PROPERTY_EDIT_DETAILS: `${process.env.REACT_APP_API_BASE_URL}user/property/edit/details?property_id=`,
  USER_AD_DETAILS: `${process.env.REACT_APP_API_BASE_URL}user/ads/details`,
  EDIT_PROFILE: `${process.env.REACT_APP_API_BASE_URL}user/edit/details`,

  // Report
  INTERESTED_REPORT: `${process.env.REACT_APP_API_BASE_URL}report/user/interested`,
  SIGNED_UP_REPORT: `${process.env.REACT_APP_API_BASE_URL}report/user/signedup`,
  UPLOADED_REPORT: `${process.env.REACT_APP_API_BASE_URL}report/user/uploaded`,

  // Institution
  SEARCH_INSTITUTION: `${process.env.REACT_APP_API_BASE_URL}admin/institution/topten?`,
  ADD_INSTITUTION: `${process.env.REACT_APP_API_BASE_URL}admin/institution/add`,
  EDIT_INSTITUTION: `${process.env.REACT_APP_API_BASE_URL}admin/institution/edit`,
  ALL_INSTITUTION: `${process.env.REACT_APP_API_BASE_URL}admin/institution/all?`,

  //Filter
  FILTER_ALL_INSTITUTION: `${process.env.REACT_APP_API_BASE_URL}filter/institution`,
  FILTER_ALL_TYPES_PROPERTY: `${process.env.REACT_APP_API_BASE_URL}filter/typesProperty`,
  FILTER_ALL_CATEGORY_BY_ID: `${process.env.REACT_APP_API_BASE_URL}filter/categoryById`,
};
