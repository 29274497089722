import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import { CircularProgress } from '@material-ui/core';
import { DownloadPropertyTemplateApiCall } from '../../apiCalls/AdminProperty/DownloadPropertyTemplateApiCall';
import { UploadPropertyFileApiCall } from '../../apiCalls/AdminProperty/UploadPropertyFileApiCall';
import "./PropertyUpload.styles.scss";
import { Form } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

const PropertyUpload = ({ expanded }) => {
  const [downloadLoader, setDownloadLoader] = useState(null);
  const [propertyType, setPropertyType] = useState('');
  const [progress, setProgress] = useState(0);
  const { addToast } = useToasts();
  const [uploadIssues, setUploadIssues] = useState([]);
  const [uploadFailInfo, setUploadFailInfo] = useState('');

  // Download property template
  const downloadTemplate = (type) => {
    setDownloadLoader(type);
    DownloadPropertyTemplateApiCall(type, setDownloadLoader);
  }

  // Select file
  const selectFile = async(e) => {
    let file = e.target.files[0];
    setUploadIssues([]);
    setUploadFailInfo('');
    UploadPropertyFileApiCall(file, propertyType, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
    .then((response) => {
      if(Array.isArray(response?.data?.data?.failedInfo)) setUploadIssues(response.data.data.failedInfo);
      setProgress(0);
      addToast(`${response.data?.data?.validRowCount} properties imported, ${response.data?.data?.invalidRowCount} properties unable to import`, { appearance: 'success', autoDismiss: true });
      setUploadFailInfo(`${response.data?.data?.validRowCount} properties imported, ${response.data?.data?.invalidRowCount} properties unable to import`);
    }).catch(error => { 
      setProgress(0) 
      addToast('File upload failed', { appearance: 'error', autoDismiss: true });
    })
  }
  
  return (
    <div className={`configuration-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
      <div className="container-fluid">
        <div className="configuration-top-wrap">
          <div className="configuration-top">
            <p className="page-title-config">Property upload</p>
          </div>

          {/* Download templates */}
          <div className='template-wrap'>

            <div className='template-item'>
              <p className='template-item-title'>Commercial property template</p>
              <Button
                variant="contained"
                component="label"
                onClick={() => downloadTemplate('commercial')}
              >
                {downloadLoader === 'commercial' ? <CircularProgress size={15} /> : 'Download'}
              </Button>
            </div>

            <div className='template-item'>
              <p className='template-item-title'>Agriculture property template</p>
              <Button
                variant="contained"
                component="label"
                onClick={() => downloadTemplate('agriculture')}
              >
                {downloadLoader === 'agriculture' ? <CircularProgress size={15} /> : 'Download'}
              </Button>
            </div>

            <div className='template-item'>
              <p className='template-item-title'>Residential property template</p>
              <Button
                variant="contained"
                component="label"
                onClick={() => downloadTemplate('residential')}
              >
                {downloadLoader === 'residential' ? <CircularProgress size={15} /> : 'Download'}
              </Button>
            </div>

            <div className='template-item'>
              <p className='template-item-title'>Industrial property template</p>
              <Button
                variant="contained"
                component="label"
                onClick={() => downloadTemplate('industrial')}
              >
                {downloadLoader === 'industrial' ? <CircularProgress size={15} /> : 'Download'}
              </Button>
            </div>

          </div>

          {/* Upload properties */}
          <p className='upload-prop-title'>Upload properties</p>
          <Form.Control
            as="select"
            className="user-select-menu property-select-menu"
            placeholder="Property type"
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <option selected value=''>Select Property Type</option>
            <option value="Residential">Residential</option>
            <option value="Industrial">Industrial</option>
            <option value="Agricultural land">Agricultural Land</option>
            <option value="Commercial">Commercial</option>
          </Form.Control>

          <div className="upload-btn-wrap">
            <Button
              variant="contained"
              component="label"
              disabled={propertyType === ''}
              style={{ width: '150px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {progress === 0 ? (
                <>
                  Upload File
                  <input
                    type="file"
                    hidden
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={selectFile}
                  />
                </>
              ) : (
                <>{progress} %</>
              )}
            </Button>
          </div>

          {/* Upload issues */}
          {uploadIssues?.length > 0 ? (
            <div className='upload-issue-wrap'>
              <p className='upload-prop-title'>File upload issues</p>
              <div className='issues-wrap'>
                <p className='upload-issue-txt issue-info'>{uploadFailInfo}</p>
                {uploadIssues.map((item, index) => (
                  <div key={`${index.toString()}_Issue`} className='d-flex'>
                    <p className='upload-issue-txt'>Row: {item?.rowNumer},&nbsp;</p>
                    {typeof item?.details === "object" ? (
                      <>
                        <p className='upload-issue-txt'>Issue:&nbsp;</p>
                        {Object.keys(item.details).map(detail => (
                          Array.isArray(item.details[detail]) ? (
                            item.details[detail].length > 0 ? (
                              <p className='upload-issue-txt'>{item.details[detail][0]}&ensp;</p>
                            ) : null
                          ) : (
                            <p className='upload-issue-txt'>Issue: {item.details[detail]}</p>
                          )
                        ))}
                      </>
                    ) : (
                      <p className='upload-issue-txt'>Issue: {item?.details}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : null}

        </div>
      </div>
    </div>
  );
}

export default PropertyUpload;