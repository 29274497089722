import ReportActionTypes from './report.types';

export const interestedReport = (data, loader) => ({
  type: ReportActionTypes.INTERESTED_REPORT,
  payload: {
    data,
    loader
  }
});

export const uploadedReport = (data, loader) => ({
  type: ReportActionTypes.UPLOADED_REPORT,
  payload: {
    data,
    loader
  }
});

export const signedUpReport = (data, loader) => ({
  type: ReportActionTypes.SIGNED_UP_REPORT,
  payload: {
    data,
    loader
  }
});