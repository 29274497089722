import TokenActionTypes from "./token.types";

export const setAdminToken = (data) => ({
  type: TokenActionTypes.ADMIN_TOKEN,
  payload: data 
});

export const setUserToken = (data) => ({
  type: TokenActionTypes.USER_TOKEN,
  payload: data
});