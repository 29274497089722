import "./Shared.styles.scss";
import React from "react";

const Disclaimer = () => {
  return (
    <div className="container-fluid p-0">
      <div className="shared-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading">Disclaimer</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>
                SARFAESIAUCTIONS.COM is an offering PAN India based digital
                platform to list, showcase and offer for sale through the
                auction of all banks, NBFCs/HFCs/AMCs non-movable properties and
                also advertise properties of the seller for a genuine
                customer/buyer/user/tenants coming on its Web-portal owned by
                M/s Sahni Corporation and is not and cannot be a party to or to
                conceal or control in any manner any transactions between the
                seller and the buyer/customer. All the auction properties listed
                or showcased on this Website or Web-portal have been extended
                versions by various Banks/Home Loan Companies/Builders
                (s)/Developers (s)/Landlords who have advertised their products.
                SARFAESIAUCTIONS.COM is only sharing its platform or
                communicating the offers and not selling or rendering any of
                those products or services. We neither warrant nor I make any
                representations concerning offer(s) made on the website.
                SARFAESIAUCTIONS.COM shall neither be responsible nor liable to
                mediate or resolve any disputes or disagreements between the
                Banks/Housing Loan Financial institutions Customer/Buyer and the
                Seller and both Seller and Customer/Buyer/Occupier shall settle
                all such disputes without involving SARFAESIAUCTIONS.COM in any
                manner and cannot be challenged or make party in any legal
                courts of India.
              </p>

              <p>
                By accessing this website, the viewer/user confirms that the
                information including brochures and marketing collaterals on
                this website is solely for informational purposes only and the
                viewer/user has not relied on this information for making any
                booking/purchase in any project of the Company. Nothing on this
                website constitutes advertising, marketing, booking, selling, or
                an offer for sale, or invitation to purchase a unit in any
                project in any manner by the Company.
              </p>

              <p>
                Whatever the information contained in this website is just for
                general information purposes only and the information provided
                by SARFAESIAUCTIONS.COM, various companies or banks carry forward
                versions of information. While we endeavor to keep the
                information up to date and correct, we make no representations
                or warranties of any kind, express or implied, about the
                completeness, accuracy, reliability, suitability, or
                availability with respect to the website or the information,
                products, services, or related graphics contained on the website
                for any purpose. Any reliance you place on such information is
                therefore strictly at your own risk.
              </p>

              <p>
                In no event will we be liable for any loss or damage including
                without limitation, indirect or consequential loss or damage, or
                any loss or damage whatsoever arising from loss of information
                or profits arising out of it, or in connection with, the use of
                this website. Through this website, you can link to other
                websites that are not under the control of SARFAESIAUCTIONS.COM.
                We have no control over the nature, content, and availability of
                those sites. The inclusion of any links does not necessarily
                imply a recommendation or endorse the views expressed within
                them. Every effort is made to keep the website up and running
                smoothly. However, [Business Name] takes no responsibility for,
                and will not be liable for, the website being temporarily
                unavailable due to technical issues beyond our control. We
                further disclaim that -
              </p>

              <ul>
                <li>
                  We indirectly represent some authorized bank recovery and
                  enforcement agents in major cities.
                </li>
                <li>
                  We handle both Bank/NBFCs/HFCs/AMCs Auction sale properties
                  and Direct party/owner sale properties.
                </li>
                <li>
                  Bank/DRT/NCLT selling the property “As is where is” and “As is
                  what is” basis under Section13(4) of the Act read with Rules 8
                  & 9 of the Security interest (Enforcement) Rules, SARFAESI ACT
                  2002 (Securitization and Reconstruction of Financial Assets
                  and Enforcement of Securities Interest Act, 2002 ) for the
                  realization of Bank’s outstanding dues.
                </li>
                <li>
                  The property is being sold on ‘as is where is’ and ‘as is what
                  is basis. The Bank has disclosed only the known encumbrances,
                  statutory liabilities, if any, as above and it is for the
                  purchaser to make their own independent inquires at their own
                  costs before participating in the auction.
                </li>
                <li>
                  Bidders shall hold a valid Digital Signature Certificate at
                  their own cost issued by the competent authority and valid
                  email ID and should register their name/account by login to
                  the website of the aforesaid service provider. They will be
                  provided with a user ID and password by the aforesaid service
                  provider which should be used in the e-auction proceedings.
                </li>
                <li>
                  The EMD and other deposits shall be remitted through NEFT /
                  RTGS to the Bank directly account as specified above and the
                  amount of EMD paid by the interested bidder and no money
                  should give to any third party.
                </li>
                <li>
                  To the best of the knowledge and information of the Authorised
                  Officer, there is no encumbrance on the property. However, the
                  intending bidders should make their own independent inquiries
                  regarding the encumbrances, the title of the property put on
                  auction, and claims/ rights/ dues/ affecting the property,
                  prior to submitting their bid. The e-Auction advertisement
                  does not constitute and will not be deemed to constitute any
                  commitment or any representation of the bank. The property is
                  being sold with all the existing and future encumbrances
                  whether known or unknown to the bank. The Authorised
                  Officer/SARFAESIAUCTIONS.COM shall not be responsible in any
                  way for any third party claims/ rights/dues.
                </li>
                <li>
                  The successful bidder shall deposit 25% of the sale price,
                  after adjusting the EMD already paid, immediately, i.e. on the
                  same day or not later than the next working day, as the case
                  may be, after the acceptance of the offer by the Authorised
                  Officer, failing which the earnest money deposited by the
                  bidder shall be forfeited. The Balance of 75% of the sale
                  price is payable on or before the 15 days of confirmation of
                  the sale of the secured asset or such extended period as may
                  be agreed upon in writing between the Secured Creditor and the
                  e-Auction purchaser
                </li>
                <li>
                  The successful bidder should submit the evidence of EMD
                  deposit like UTR number along with a Request letter for
                  participation in the e-Auction, self-attested copies of (i)
                  Proof of Identification (KYC) like Aadhar Card/ PAN CardVoter
                  ID Card/ Driving Licence/Passport, etc., (ii) Current Address
                  – proof of communication, (iii) PAN Card of the bidder (iv)
                  Valid e-mail ID (v) Contact number(mobile/Landline of the
                  bidder etc., to the Authorised Officer of the particular bank.
                </li>
                <li>
                  It shall be the responsibility of the interested bidders to
                  inspect and satisfy themselves about the property before
                  submission of the bid.
                </li>
                <li>
                  Neither the Authorised Officer/ Bank / SARFAESIAUCTIONS.COM
                  will be held responsible for any Internet Network
                  problem/Power failure/ any other technical lapses/failure
                  etc., in order to ward-off such contingent situation, the
                  interested bidders are requested to ensure that they are
                  technically well equipped with adequate power back-up etc. for
                  successfully participating in the event of e-Auction process.
                </li>
                <li>
                  In case of Stay order from Court cases, if the Borrower gets a
                  stay order from the court against the Auction conducted, then
                  the EMD amount you paid will be refunded at once
                </li>
                <li>
                  Some properties are occupied by the Borrower or Tenant / Lease
                  party. It usually takes 2 to 4 months for this entire process
                  of eviction.
                </li>
                <li>
                  SARFAESIAUCTIONS.COM or the bank is not responsible for any
                  Unexpected delay due to any National disasters or court
                  holiday, transfer of judges, eviction judgment delay.
                </li>

                <li>
                  It is the sole responsibility of the buyer to check all the
                  legal procedures of the document before the auction.
                </li>
                <li>
                  The Bank / SARFAESIAUCTIONS.COM / service provider for
                  e-Auction shall not have any liability towards bidders for any
                  interruption or delay in access to the site irrespective of
                  the causes.
                </li>

                <li>
                  The bidders are required to submit acceptance of the terms &
                  conditions and modalities of e-Auction adopted by the service
                  provider, before participating in the e-Auction.
                </li>

                <li>
                  The bid once submitted by the bidder, cannot be
                  canceled/withdrawn and the bidder shall be bound to buy the
                  property at the final bid price. The failure on the part of
                  the bidder to comply with any of the terms and conditions of
                  e-Auction, mentioned herein will result in forfeiture of the
                  amount paid by the defaulting bidder.
                </li>

                <li>
                  The decision of the Authorised Officer of Bank regarding the
                  declaration of successful bidder shall be final and binding on
                  all the bidders.
                </li>

                <li>
                  The Authorised Officer of Banks shall be at liberty to cancel
                  the e-Auction process/tender at any time, before declaring the
                  successful bidder, without assigning any reason.
                </li>

                <li>
                  The Authorized Officer of Banks has the absolute right to
                  accept or reject any bid or postpone or cancel the sale, as
                  the case may be without assigning any reason whatsoever.
                </li>

                <li>
                  The bid submitted without the EMD shall be summarily rejected.
                  The property shall not be sold below the reserve price.
                </li>

                <li>
                  The conditional bids may be treated as invalid. Please note
                  that after submission of the bid/s, no correspondence
                  regarding any change in the bid shall be entertained. The EMD
                  of the unsuccessful bidder will be refunded to their
                  respective A/c numbers shared with the Bank. The bidders will
                  not be entitled to claim any interest, costs, expenses, and
                  any other charges (if any).
                </li>

                <li>
                  The Authorised Officer of Bank is not bound to accept the
                  highest offer and the Authorised officer of Bank has absolute
                  right to accept or reject any or all offer(s) or
                  adjourn/postpone/cancel the e-Auction without assigning any
                  reason thereof. The sale is subject to confirmation by the
                  secured creditor.{" "}
                </li>

                <li>
                  In case of forfeiture of the amount deposited by the
                  defaulting bidder, he shall neither have a claim on the
                  property nor on any part of the sum for which it may be
                  subsequently sold.
                </li>

                <li>
                  The successful bidder shall bear all the necessary expenses
                  like applicable stamp duties / additional stamp duty/transfer
                  charges, Registration expenses, fees, GST, Pending Eb bills,
                  property tax, water tax, sewage tax, maintenance charges, etc.
                  for transfer of the property in his/her name.
                </li>

                <li>
                  The payment of all statutory/non- statutory dues, taxes,
                  rates, assessments, charges, fees, GST, etc., owing to anybody
                  shall be the sole responsibility of the successful bidder
                  only.
                </li>

                <li>
                  In case of any dispute arises as to the validity of the bid
                  (s), amount of bid, EMD or as to the eligibility of the
                  bidder, authority of the person representing the bidder, the
                  interpretation and decision of the Authorised Officer of Bank
                  shall be final. In such an eventuality, the Bank shall in its
                  sole discretion be entitled to call off the sale and put the
                  property to sale once again on any date and at such time as
                  may be decided by the Bank. For any kind of dispute, bidders
                  are required to contact the concerned Authorized Officer of
                  the concerned bank branch only.
                </li>

                <li>
                  The sale certificate shall be issued after receipt of the
                  entire sale consideration and confirmation of sale by the
                  secured creditor. The sale certificate shall be issued in the
                  name of the successful bidder. No request for change of name
                  in the sale certificate other than the person who submitted
                  the bid / participated in the e-Auction will be entertained.
                  This sale will attract the provisions of sec 194-IA of the
                  Income Tax Act.
                </li>

                <li>
                  All the property ads displayed here are with due consent from
                  the particular banks.
                </li>

                <li>
                  All the photos, videos, and details of the property belong to
                  SARFAESIAUCTIONS.COM. If used without our prior permission
                  will be subjected to legal action.
                </li>

                <li>
                  Bank properties are less than the market price. The price
                  mentioned is not the final price. It can be increased during
                  the auction.
                </li>

                <li>
                  It is the sole responsibility of the buyer to check all the
                  legal procedures of the document before buying the auction
                  property and outright property.
                </li>

                <li>
                  Our Website may contain other proprietary notices and
                  copyright information, the terms of which must be observed and
                  followed.
                </li>

                <li>
                  The information may be changed or updated without notice.
                  SARFAESIAUCTIONS.COM may also make improvements and/or changes
                  in the products and/or the programs described in this
                  information at any time without notice. SARFAESIAUCTIONS.COM
                  expressly disclaims all liability in respect to actions taken
                  or not taken based on any or all the contents of this Website.
                  The Company will in no circumstance be liable for any expense,
                  loss, or damage including, without limitation, indirect or
                  consequential loss or damage, or any expense, loss or damage
                  whatsoever arising from the use of data, arising out of or in
                  connection with the use of this Website. Some links within the
                  Website may lead to other websites, including those operated
                  and maintained by third parties. The Company includes these
                  links solely as a convenience to you, and the presence of such
                  a link does not imply a responsibility for the linked site or
                  an endorsement of the linked site, its operator, its contents,
                  or under RERA.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
