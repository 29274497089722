import { postRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { userPropertyUpdate } from "../../redux/userProperty/userProperty.actions";
import { propertyAddSuccess } from '../../redux/adminProperty/adminProperty.actions';

export function UserPropertyUpdateApiCall(data, toastView) {
  return (dispatch) => {
    dispatch(userPropertyUpdate(null, true));
    dispatch(propertyAddSuccess(false));

    const url = URL_CONSTANTS.USER_PROPERTY_UPDATE;

    postRequestHeaderUser(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView('Property Updated', 'success');
          dispatch(propertyAddSuccess(true));
          dispatch(userPropertyUpdate(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertyAddSuccess(false));
        dispatch(userPropertyUpdate(null, false));
      });
  };
}