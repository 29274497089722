import { getRequestHeaderUser } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { userDetails } from "../../redux/persistantData/persistantData.actions";

export function UserDetailsApiCall() {
  return (dispatch) => {
    dispatch(userDetails(null, true));

    const url = URL_CONSTANTS.USER_DETAILS;

    getRequestHeaderUser(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(userDetails(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(userDetails(null, false));
      });
  };
}