import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { removeAdmin, getAdminDetails } from "../../redux/admin/admin.actions";

export function RemoveAdminApiCall(adminId, toastView) {
  return (dispatch) => {
    dispatch(removeAdmin(null, true));

    const url = `${URL_CONSTANTS.REMOVE_ADMIN}${adminId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Admin has been removed successfully", 'success');
          dispatch(removeAdmin(res.data.data, false));
          dispatch(getAdminDetails(null, false));
        }
      })
      .catch((error) => {
        dispatch(removeAdmin(null, false));
      });
  };
}
