import SearchActionTypes from "./search.types";

export const topTenStates = (data, loader) => ({
  type: SearchActionTypes.TOP_TEN_STATES,
  payload: {
    data,
    loader
  }
});

export const topTenCities = (data, loader) => ({
  type: SearchActionTypes.TOP_TEN_CITIES,
  payload: {
    data: data,
    loader: loader
  }
});

export const topLocalities = (data, loader) => ({
  type: SearchActionTypes.TOP_LOCALITIES,
  payload: {
    data,
    loader
  }
});

export const propertySearchList = (data, loader) => ({
  type: SearchActionTypes.PROPERTY_SEARCH_LIST,
  payload: {
    data,
    loader
  }
});
