import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { searchInstitutionAction } from "../../redux/institution/institution.actions";

export function SearchInstitutionApiCall(searchWord) {
  return (dispatch) => {
    const url = `${URL_CONSTANTS.SEARCH_INSTITUTION}searchword=${searchWord}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(searchInstitutionAction(res.data.data));
        }
      })
      .catch((error) => {
        dispatch(searchInstitutionAction(null));
      });
  };
}