import { nanoid } from 'nanoid';

const addUserFields = [
  {
    id: nanoid(),
    label: 'Name',
    value: 'name',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Email Address',
    value: 'email',
    position: 'right',
    type: 'email',
    required: true
  },
  {
    id: nanoid(),
    label: 'Password',
    value: 'password',
    position: 'left',
    type: 'password',
    required: true,
    minLength: 6
  },
  {
    id: nanoid(),
    label: 'Mobile Number',
    value: 'phone',
    position: 'right',
    type: 'number',
    required: true,
    minLength: 10,
    maxLength: 10
  },
  {
    id: nanoid(),
    label: 'Aadhaar Number',
    value: 'aadhaar_no',
    position: 'left',
    type: 'number',
    required: true,
    minLength: 12,
    maxLength: 12
  },
  {
    id: nanoid(),
    label: 'State',
    value: 'state',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'City',
    value: 'city',
    position: 'left',
    type: 'text',
    required: true
  }
];

const independentHouseFields = [
  {
    id: nanoid(),
    label: 'Borrower Name',
    value: 'title',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Area',
    value: 'area',
    position: 'right',
    type: 'number',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    required: true,
    unit: 'unit'
  },
  {
    id: nanoid(),
    label: 'Facing',
    value: 'face',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: East'
  },
  {
    id: nanoid(),
    label: 'Covered Parking',
    value: 'covered_parking',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Y, two cars'
  },
  {
    id: nanoid(),
    label: 'Other Amenities',
    value: 'other_eminities',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Gym, Theatre'
  },
  {
    id: nanoid(),
    label: 'Piped Gas',
    value: 'piped_gas',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Stats of Furnishing',
    value: 'stats_of_furnishing',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Semi-furnished'
  },
  {
    id: nanoid(),
    label: 'Reserve Price',
    value: 'price',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Purchase Type',
    value: 'purchase_type',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Type of Title',
    value: 'type_of_title',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: JDA Approved/ Society Patta'
  },
  {
    id: nanoid(),
    label: 'Borewell',
    value: 'borewell',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Road',
    value: 'road',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: 30ft'
  },
  {
    id: nanoid(),
    label: 'Floors',
    value: 'floors',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Single'
  },
  {
    id: nanoid(),
    label: 'Gym',
    value: 'gym',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Theatre',
    value: 'theatre',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Servant Room',
    value: 'servant_room',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Address',
    value: 'address',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'RERA Approval',
    value: 'rera_approval',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Registered'
  },
  {
    id: nanoid(),
    label: 'State',
    value: 'state',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'City',
    value: 'city',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Locality',
    value: 'locality',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Contact Person',
    value: 'contact_agent',
    position: 'left',
    type: 'text',
    required: true
  }
];

const flatFields = [
  {
    id: nanoid(),
    label: 'Borrower Name',
    value: 'title',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Area',
    value: 'area',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    required: true,
    unit: 'unit'
  },
  {
    id: nanoid(),
    label: 'Carpet Area',
    value: 'carpet_area',
    position: 'left',
    type: 'number',
    placeholder: 'e.g: 229 Sq.Ft',
    isArea: true,
    unit: 'carpet_area_unit'
  },
  {
    id: nanoid(),
    label: 'BHK',
    value: 'bhk',
    position: 'right',
    type: 'number'
  },
  {
    id: nanoid(),
    label: 'Reserve Price',
    value: 'price',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Facing',
    value: 'face',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: East'
  },
  {
    id: nanoid(),
    label: 'Road',
    value: 'road',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 90 ft'
  },
  {
    id: nanoid(),
    label: 'Balcony',
    value: 'balcony',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Two side'
  },
  {
    id: nanoid(),
    label: 'Parking',
    value: 'parking',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Y, Basement'
  },
  {
    id: nanoid(),
    label: 'Other Amenities',
    value: 'other_eminities',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Gym, Theatre'
  },
  {
    id: nanoid(),
    label: 'Security Guard',
    value: 'security_guard',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Power Back-Up',
    value: 'power_back',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: DG'
  },
  {
    id: nanoid(),
    label: 'Lift',
    value: 'lift',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Piped Gas',
    value: 'piped_gas',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Stats of Furnishing',
    value: 'stats_of_furnishing',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Semi-furnished'
  },
  {
    id: nanoid(),
    label: 'Purchase Type',
    value: 'purchase_type',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: First owner'
  },
  {
    id: nanoid(),
    label: 'Floor',
    value: 'floors',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 10th Floor',
    required: true
  },
  {
    id: nanoid(),
    label: 'Plot Size of Complex',
    value: 'plot_complex_size',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: B 21'
  },
  {
    id: nanoid(),
    label: 'RERA Approval',
    value: 'rera_approval',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Registered'
  },
  {
    id: nanoid(),
    label: 'Building / Complex Name',
    value: 'building_name',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'No. of Rooms',
    value: 'no_of_rooms',
    position: 'right',
    type: 'number'
  },
  {
    id: nanoid(),
    label: 'Bathrooms',
    value: 'bath_rooms',
    position: 'left',
    type: 'text',
    placeholder: '3 Attached'
  },
  {
    id: nanoid(),
    label: 'Servant Room',
    value: 'servant_room',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Flooring',
    value: 'flooring',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Marble'
  },
  {
    id: nanoid(),
    label: 'Address',
    value: 'address',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'State',
    value: 'state',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'City',
    value: 'city',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Locality',
    value: 'locality',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Contact Person',
    value: 'contact_agent',
    position: 'right',
    type: 'text',
    required: true
  }
];

const industrialFields = [
  {
    id: nanoid(),
    label: 'Borrower Name',
    value: 'title',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Reserve Price',
    value: 'price',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Plot Area',
    value: 'area',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    required: true,
    unit: 'unit'
  },
  {
    id: nanoid(),
    label: 'Constructed Area',
    value: 'constructed_area',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    required: true,
    unit: 'area_unit'
  },
  {
    id: nanoid(),
    label: 'Front Road Size',
    value: 'front_road_size',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: 100 ft'
  },
  {
    id: nanoid(),
    label: 'Property Age',
    value: 'property_age',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Facing',
    value: 'face',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Brief Details',
    value: 'brief_details',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Dues of Water / Electricity',
    value: 'water_electricity_due',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Condition',
    value: 'property_condition',
    type: 'text',
    position: 'right',
    placeholder: 'Working Condition',
    required: true
  },
  {
    id: nanoid(),
    label: 'Any Licence Required',
    value: 'any_licence_required',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Pollution Clearance',
    value: 'pollution_clearance',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Required'
  },
  {
    id: nanoid(),
    label: 'Statutory Liability',
    value: 'statutory_liability',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: GST'
  },
  {
    id: nanoid(),
    label: 'Any Other Dues',
    value: 'any_other_dues',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Any Legal Cases With Vendors or Dept.',
    value: 'any_legal_cases',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Other Details',
    value: 'other_details',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Distance from Rly Stn/Bus Stand/Airport',
    value: 'distance_from_station',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'State',
    value: 'state',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'City',
    value: 'city',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Locality',
    value: 'locality',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Contact Person',
    value: 'contact_agent',
    position: 'left',
    type: 'text',
    required: true
  }
];

const agriculturalFields = [
  {
    id: nanoid(),
    label: 'Borrower Name',
    value: 'title',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Area',
    value: 'area',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    required: true,
    unit: 'unit'
  },
  {
    id: nanoid(),
    label: 'Boundary',
    value: 'boundary',
    position: 'left',
    type: 'text',
    required: true,
  },
  {
    id: nanoid(),
    label: 'Crops Taken',
    value: 'crops_taken',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 3 Sugarcane'
  },
  {
    id: nanoid(),
    label: 'Reserve Price',
    value: 'price',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Distance from Rly Stn/Bus Stand/Airport',
    value: 'distance_from_station',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Water Irrigation',
    value: 'water_irrigation',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: Solar motor'
  },
  {
    id: nanoid(),
    label: 'No. of Crops Taken in a Year',
    value: 'crop_per_year',
    position: 'right',
    type: 'number',
    required: true
  },
  {
    id: nanoid(),
    label: 'Brief Details',
    value: 'brief_details',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Approach Road',
    value: 'approach_road',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Any Liability of Bank',
    value: 'bank_liability',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Any Dispute',
    value: 'any_dispute',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Other Details',
    value: 'other_details',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Farm House',
    value: 'farm_house',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Address',
    value: 'address',
    position: 'left',
    type: 'text'
  },
  // {
  //   id: nanoid(),
  //   label: 'Currently Leased Out',
  //   value: 'currently_leased_out',
  //   position: 'right',
  //   type: 'text',
  //   isBooleanDropdown: true
  // },
  // {
  //   id: nanoid(),
  //   label: 'Transaction Type',
  //   value: 'transaction_type',
  //   position: 'left',
  //   type: 'text'
  // },
  // {
  //   id: nanoid(),
  //   label: 'Type of Ownership',
  //   value: 'ownership_type',
  //   position: 'right',
  //   type: 'text'
  // },
  {
    id: nanoid(),
    label: 'State',
    value: 'state',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'City',
    value: 'city',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Locality',
    value: 'locality',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Contact Person',
    value: 'contact_agent',
    position: 'left',
    type: 'text',
    required: true
  }
];

const commercialFields = [
  {
    id: nanoid(),
    label: 'Borrower Name',
    value: 'title',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Area',
    value: 'area',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    required: true,
    unit: 'unit'
  },
  {
    id: nanoid(),
    label: 'Constructed Area',
    value: 'constructed_area',
    position: 'left',
    type: 'number',
    placeholder: 'e.g: 2259 Sq.Ft',
    isArea: true,
    unit: 'area_unit'
  },
  {
    id: nanoid(),
    label: 'Distance from Rly Stn/Bus Stand/Airport',
    value: 'distance_from_station',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Reserve Price',
    value: 'price',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Floors',
    value: 'floors',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Property Age',
    value: 'property_age',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Front Road Size',
    value: 'front_road_size',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: 100 ft'
  },
  {
    id: nanoid(),
    label: 'Facing',
    value: 'face',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: North'
  },
  {
    id: nanoid(),
    label: 'Brief Details',
    value: 'brief_details',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Dues of Water / Electricity',
    value: 'water_electricity_due',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Condition',
    value: 'property_condition',
    position: 'right',
    type: 'text',
    placeholder: 'e.g: Working Condition'
  },
  {
    id: nanoid(),
    label: 'Statutory Liability',
    value: 'statutory_liability',
    position: 'left',
    type: 'text',
    placeholder: 'e.g: GST'
  },
  {
    id: nanoid(),
    label: 'Any Other Dues',
    value: 'any_other_dues',
    position: 'right',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Any Legal Cases With Vendors or Dept.',
    value: 'any_legal_cases',
    position: 'left',
    type: 'text',
    isBooleanDropdown: true
  },
  {
    id: nanoid(),
    label: 'Address',
    value: 'address',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Age of Construction',
    value: 'construction_age',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Other Details',
    value: 'other_details',
    position: 'right',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'State',
    value: 'state',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'City',
    value: 'city',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Locality',
    value: 'locality',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Conatct Agent',
    value: 'contact_agent',
    position: 'right',
    type: 'text'
  }
];

const bankAuctionPropertyFields = [
  {
    id: nanoid(),
    label: 'Institution',
    value: 'institution',
    position: 'left',
    type: 'text',
    required: true,
    isInstitution: true
  },
  {
    id: nanoid(),
    label: 'Institution Branch',
    value: 'institution_branch',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Auction Details',
    value: 'auction_details',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Property Type',
    value: 'property_type',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'EMD Date',
    value: 'property_schedule',
    position: 'left',
    type: 'text'
  },
  {
    id: nanoid(),
    label: 'Inspection Date',
    value: 'inspection_details',
    position: 'right',
    type: 'date'
  },
  {
    id: nanoid(),
    label: 'Property Address',
    value: 'lot_details',
    position: 'left',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'EMD Amount',
    value: 'emd',
    position: 'right',
    type: 'text',
    required: true
  },
  {
    id: nanoid(),
    label: 'Minimum Increment',
    value: 'minimum_increment',
    position: 'left',
    type: 'number',
    required: true
  },
  {
    id: nanoid(),
    label: 'Possession',
    value: 'possession',
    position: 'right',
    type: 'text',
    isDropdown: true,
    required: true,
    dropDownData: [
      {
        id: nanoid(),
        value: 'symbolic',
        label: 'Symbolic'
      },
      {
        id: nanoid(),
        value: 'physical',
        label: 'Physical'
      }
    ]
  },
  {
    id: nanoid(),
    label: 'Auction Date',
    value: 'auction_start_date',
    position: 'left',
    type: 'date'
  },
  {
    id: nanoid(),
    label: 'Auction Time',
    value: 'auction_start_time',
    position: 'right',
    type: 'time'
  }
];

const userPrivileges = [
  {
    id: nanoid(),
    name: 'Approve Properties',
    value: 'approve_properties'
  },
  {
    id: nanoid(),
    name: 'Add Channel Partners',
    value: 'add_channel_partners'
  },
  {
    id: nanoid(),
    name: 'Remove Channel Partners',
    value: 'remove_channel_partners'
  },
  {
    id: nanoid(),
    name: 'Add Branch Partners',
    value: 'add_branch_partners'
  },
  {
    id: nanoid(),
    name: 'Remove Branch Partners',
    value: 'remove_branch_partners'
  },
  {
    id: nanoid(),
    name: 'Upload Mode',
    value: 'external_user'
  }
];

export {
  addUserFields,
  independentHouseFields,
  industrialFields,
  flatFields,
  commercialFields,
  agriculturalFields,
  bankAuctionPropertyFields,
  userPrivileges
};