import TokenActionTypes from "./token.types";

const initialTokenState = {
  adminToken: null,
  userToken: null
};

const tokenReducer = (state = initialTokenState, action) => {
  switch(action.type) {
    case TokenActionTypes.ADMIN_TOKEN:
      return {
        ...state,
        adminToken: action.payload
      };
    case TokenActionTypes.USER_TOKEN:
      return {
        ...state,
        userToken: action.payload
      };
    default:
      return state;
  }
};

export default tokenReducer;