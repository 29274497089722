export const WALLET = require('../assets/Svg/wallet.svg');
export const LEFT_ARROW = require('../assets/Svg/leftArrow.svg');
export const ACCOUNT_SETTINGS = require('../assets/Svg/accountSettings.svg');
export const SETTINGS = require('../assets/Svg/settings.svg');
export const PROPERTY = require('../assets/Svg/property.svg');
export const LOGOUT = require('../assets/Svg/logout.svg');
export const HOME = require('../assets/Svg/home.svg');
export const FILTER = require('../assets/Svg/filter.svg');
export const USERS = require('../assets/Svg/users.svg');
export const WARNING = require('../assets/Svg/warning.svg');
export const USER = require('../assets/Svg/user.svg');
export const ADD_USER = require('../assets/Svg/addUser.svg');
export const SAVE_BUTTON = require('../assets/Svg/saveButton.svg');
export const CLOSE_BUTTON = require('../assets/Svg/closeButton.svg');
export const DELETE_ACCOUNT = require('../assets/Svg/deleteAccount.svg');
export const AVATAR = require('../assets/Svg/avatar.svg');
export const ADD_PROPERTY = require('../assets/Svg/addProperty.svg');
export const ADD_BANK_AUCTION_PROPERTY = require('../assets/Svg/addBankAuctionProperty.svg');
export const INSTAGRAM_ICON = require('../assets/img/Instagram_icon.png');
export const FACEBOOK_ICON = require('../assets/img/Facebook_icon.png');
export const LINKEDIN_ICON = require('../assets/img/linkedin.png');
export const WHATSAPP_ICON = require('../assets/img/whatsapp.png');
export const YOUTUBE_ICON = require('../assets/img/youtube.png');

//OUR SERVICES PAGE
export const SERVICES_TITLE = require('../assets/img/servicesTitle.jpg');
export const SERVICES_HOME = require('../assets/img/servicesHome.png');
export const SERVICES_PHONE = require('../assets/img/servicesPhone.png');
export const SERVICES_FEATURES = require('../assets/img/servicesFeatures.png');

//Knowledge hub
export const KNOWLEDGE = require('../assets/img/knowledge.png');
export const FLOWCHART = require('../assets/img/flowChart.jpg');

//Vastu
export const VASTU = require('../assets/img/vastu.jpg');
export const PROFILE = require('../assets/img/avatar.jpg')

//Property Lawyers
export const PROPERTY_LAWYERS = require('../assets/img/propertyLawyers.png');

//Property Dealers
export const PROPERTY_DEALERS = require('../assets/img/propertyDealers.jpg');

//Architect
export const ARCHITECT = require('../assets/img/architect.png');

//BUSINESS ASSOCIATES
export const BUSINESS_ASSOCIATES = require('../assets/img/businessAssociates.png');

//Property Valuers
export const RUPEE = require('../assets/img/rupee.png');
export const VALUERS = require('../assets/img/valuers.png');

export const GOOGLE_SIGN_IN = require('../assets/img/google_sign_in.png');