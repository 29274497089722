import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { updateAdminRole } from "../../redux/admin/admin.actions";
import { GetAdminDetailsApiCall } from './GetAdminDetailsApiCall';

export function UpdateAdminRoleApiCall(data, toastView, adminId) {
  return (dispatch) => {
    dispatch(updateAdminRole(null, true));

    const url = URL_CONSTANTS.UPDATE_ADMIN_ROLE;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Admin role has been updated", 'success');
          dispatch(updateAdminRole(res.data.data, false));
          dispatch(GetAdminDetailsApiCall(adminId));
        }
      })
      .catch((error) => {
        dispatch(updateAdminRole(null, false));
      });
  };
}