import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { removeAdFile } from "../../redux/file/file.actions";

export function RemoveAdFileApiCall(fileId, toastView, handleRemoveSuccess = () => {}) {
  return (dispatch) => {
    dispatch(removeAdFile(null, true));

    const url = `${URL_CONSTANTS.REMOVE_AD_FILE}${fileId}`;

    getRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("File removed successfully", 'success');
          dispatch(removeAdFile(res.data.data, false));
          handleRemoveSuccess();
        }
      })
      .catch((error) => {
        dispatch(removeAdFile(null, false));
      });
  };
}