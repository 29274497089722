import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import Form from 'react-bootstrap/Form';
import Loader from '../../../components/Loader/Loader.component';
import { adsData } from './AdsData';
import { AdDetailsApiCall } from '../../../apiCalls/Admin/AdDetailsApiCall';
import { AdChangeApiCall } from '../../../apiCalls/Admin/AdChangeApiCall';
import { UploadFileApiCall } from '../../../apiCalls/File/UploadFileApiCall';
import { RemoveAdFileApiCall } from '../../../apiCalls/File/RemoveAdFileApiCall';
import { adDetailsState, adChangeState } from '../../../redux/admin/admin.selector';
import { removeAdFileState } from '../../../redux/file/file.selector';
import './AdvertisementConfig.styles.scss';

const Advertisement = () => {
  const dispatch = useDispatch();
  const adDetails = useSelector(adDetailsState);
  const [adsInfo, setAdsInfo] = useState([]);
  const { addToast } = useToasts();
  const adChange = useSelector(adChangeState);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const removeStatus = useSelector(removeAdFileState);
  let deletingItem = null;

  useEffect(() => {
    dispatch(AdDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(adDetails?.data) {
      adsData.forEach(item => {
        item.url = adDetails?.data[item.value]?.url;
        item.ad_id = adDetails?.data[item.value]?.ad_id;
        item.file_id = adDetails?.data[item.value]?.image;
        return item;
      });
    }
    setAdsInfo([...adsData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adDetails?.data]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const handleUrlChange = (e, item) => {
    let temp = [...adsInfo];
    temp.forEach(dat => {
      if(dat?.ad_id === item?.ad_id) {
        dat.url = e.target.value;
      }
      return dat;
    });
    setAdsInfo(temp);
  }

  const selectFile = (e, item) => {
    let file = e.target.files[0];
    setFileUploadLoader(true);
    UploadFileApiCall(file).then((res) => {
      let temp = [...adsInfo];
      temp.forEach(dat => {
        if(dat?.ad_id === item?.ad_id) {
          dat.file_id = res?.data?.data?.file_id; 
        }
        return dat;
      });
      setAdsInfo(temp);
      setFileUploadLoader(false);
      toastView("Image Upload Success", 'success');
    })
    .catch((error) => {
      setFileUploadLoader(false);
      toastView(error?.response?.data?.message, 'error');
    });
  }

  const handleRemoveSuccess = () => {
    const data = {
      ad_id: deletingItem?.ad_id,
      file_id: null,
      url: deletingItem?.url ?? ''
    };
    dispatch(AdChangeApiCall(data));
    let temp = [...adsInfo];
    temp.forEach(item => {
      if(item?.ad_id === deletingItem?.ad_id) {
        item.file_id = null;
      }
      return item;
    });
    deletingItem = null;
    setAdsInfo(temp);
  }

  const handleRemoveImage = (item) => {
    deletingItem = item;
    dispatch(RemoveAdFileApiCall(item?.file_id, toastView, handleRemoveSuccess));
  }

  const handleSubmit = (e, item) => {
    e.preventDefault();
    if(item?.file_id?.trim('')?.length > 0 || item?.url?.trim('')?.length === 0) {
      const data = {
        ad_id: item?.ad_id,
        file_id: item?.file_id ?? '',
        url: item?.url ?? ''
      };
      dispatch(AdChangeApiCall(data, toastView));
    } else {
      toastView("Image is required", 'error');
    }
  }

  return (
    <div className="ads-config-wrapper">
      <Loader visible={
        adDetails.loader || 
        adChange.loader || 
        fileUploadLoader ||
        removeStatus.loader
      } />
      <h3>Advertisement Config</h3>
      {adsInfo.map((ad) => (
        <div className="ads-upload-section mt-4" key={ad.id}>
          <h4>{ad.label}</h4>
          <div className="row">
            <div className="col-12 col-md-3">
              <Button
                variant="contained"
                component="label"
                disabled={ad?.file_id ? true : false}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => selectFile(e, ad)}
                />
              </Button>
            </div>
            <div className="col-12 col-md-9">
              <Form.Control
                type="text"
                placeholder="Website URL"
                className="form-control"
                id={ad.value}
                value={ad?.url}
                onChange={(e) => handleUrlChange(e, ad)}
              />
            </div>
          </div>
          <div className="bottom-wrapper">
            {ad?.file_id ? (
              <>
                <DeleteIcon className="deleteIcon" onClick={() => handleRemoveImage(ad)} />
                <img className="adImage" src={`${process.env.REACT_APP_IMAGE_BASE_URL}${ad?.file_id}`} alt="" />
              </>
            ) : (
              <div className="adImage adEmptyImage" />
            )}
            <Button
              variant="contained"
              color="primary"
              className="submit-button"
              onClick={(e) => handleSubmit(e, ad)}
            >
              Submit
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Advertisement;