import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CLOSE_BUTTON } from '../../constants/iconConstants';
import { PropertyApprovalApiCall } from '../../apiCalls/AdminProperty/PropertyApprovalApiCall';
import { PropertyUnApprovalApiCall } from '../../apiCalls/AdminProperty/PropertyUnApprovalApiCall';
import ImageCarousal from "../../components/ImageCarousal/ImageCarousal.component";
import { 
  commercialFields, 
  agriculturalFields, 
  independentHouseFields, 
  flatFields, 
  industrialFields,
  bankAuctionPropertyFields 
} from '../../pages/AdminPage/AdminData';
import { getCategoryByPropertyType } from '../../utils/CustomFunctions';
import './Modals.styles.scss';

const ViewPropertyModal = ({
  showViewPropertyModal,
  selectedPropertyView,
  handleViewPropertyModalClose,
  setSelectedPropertyView,
  approvalSuccess
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  let flag = false;

  const toastView = (message, appearance) => {
    if (appearance === 'success') {
      if (flag) {
        setSelectedPropertyView({
          ...selectedPropertyView,
          is_approved: "1"
        });
      } else {
        setSelectedPropertyView({
          ...selectedPropertyView,
          is_approved: "0"
        });
      }
    }
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const handleApprove = (propertyId) => {
    flag = true;
    dispatch(PropertyApprovalApiCall(propertyId, toastView, approvalSuccess));
  }

  const handleUnapprove = (propertyId) => {
    flag = false;
    dispatch(PropertyUnApprovalApiCall(propertyId, toastView, approvalSuccess));
  }

  return (
    <Modal
      size="lg"
      show={showViewPropertyModal}
    >
      <div style={{ margin: 20 }}>
        <Modal.Header>
          <Modal.Title>
            Property Details
          </Modal.Title>
          <img
            src={CLOSE_BUTTON.default}
            alt=""
            className="button-hover"
            onClick={handleViewPropertyModalClose}
          />
        </Modal.Header>
        <div className="modal-body pt-4 px-5 detail-container">
          <div className="property-image" style={{ marginBottom: 20 }}>
            <ImageCarousal data={selectedPropertyView?.images} />
          </div>
          <div className="d-flex flex-row justify-content-end mb-4">
            {selectedPropertyView?.approval_privilege == 1 && (
              selectedPropertyView?.is_approved == 0 ? (
                <Button
                  variant="success"
                  onClick={() => handleApprove(selectedPropertyView?.property_id)}
                >
                  Approve
                </Button>
              ) : (
                <Button
                  variant="warning"
                  onClick={() => handleUnapprove(selectedPropertyView?.property_id)}
                >
                  Unapprove
                </Button>
              )
            )}
          </div>
          {selectedPropertyView?.property_uniqueid && <p className="property-details-text">
            <b>Property Id</b><span> : {selectedPropertyView?.property_uniqueid}</span>
          </p>}
          {selectedPropertyView?.type_name && <p className="property-details-text">
            <b>Property Type</b><span> : {selectedPropertyView.type_name}</span>
          </p>}
          {selectedPropertyView?.category_name && <p className="property-details-text">
            <b>Category</b><span> : {getCategoryByPropertyType(selectedPropertyView.type_name, selectedPropertyView.category_name)}</span>
          </p>}
          {selectedPropertyView?.property_area && <p className="property-details-text">
            <b>Area</b><span> : {selectedPropertyView.property_area} {selectedPropertyView?.property_area_unit}</span>
          </p>}
          {selectedPropertyView?.industry_area && <p className="property-details-text">
            <b>Constructed Area</b><span> : {selectedPropertyView.industry_area}</span>
          </p>}
          {selectedPropertyView?.commercial_area && <p className="property-details-text">
            <b>Constructed Area</b><span> : {selectedPropertyView.commercial_area}</span>
          </p>}
          {
            selectedPropertyView?.type_name === 'Residential' ? (
              selectedPropertyView?.category_name === 'Apartments or Flats' ? (
                flatFields?.map((item) => (
                  (selectedPropertyView[item?.value] !== undefined || selectedPropertyView[`${item?.value}_name`] !== undefined) && (
                    <p key={item?.id} className="property-details-text">
                      <b>{item.label}</b>
                      <span> :{'  '}
                      {item.isBooleanDropdown ? (
                        selectedPropertyView[item?.value] === 1 ? 'Yes' : 'No'
                      ) : (
                        selectedPropertyView[item?.value] ?? selectedPropertyView[`${item?.value}_name`]
                      )}{' '}
                      {item?.unit && selectedPropertyView[item?.unit]}
                      </span>
                    </p>
                  )
                ))
              ) : (
                independentHouseFields?.map((item) => (
                  (selectedPropertyView[item?.value] !== undefined || selectedPropertyView[`${item?.value}_name`] !== undefined) && (
                    <p key={item?.id} className="property-details-text">
                      <b>{item?.label}</b>
                      <span> :{'  '}
                      {item.isBooleanDropdown ? (
                        selectedPropertyView[item?.value] === 1 ? 'Yes' : 'No'
                      ) : (
                        selectedPropertyView[item?.value] ?? selectedPropertyView[`${item?.value}_name`]
                      )}{' '}
                      {item?.unit && selectedPropertyView[item?.unit]}
                      </span>
                    </p>
                  )
                ))
              )
            ) : selectedPropertyView?.type_name === 'Industrial' ? (
              industrialFields?.map((item) => (
                (selectedPropertyView[item?.value] !== undefined || selectedPropertyView[`${item?.value}_name`] !== undefined) && (
                  <p key={item?.id} className="property-details-text">
                    <b>{item?.label}</b>
                    <span> :{'  '}
                    {item.isBooleanDropdown ? (
                      selectedPropertyView[item?.value] === 1 ? 'Yes' : 'No'
                    ) : (
                      selectedPropertyView[item?.value] ?? selectedPropertyView[`${item?.value}_name`]
                    )}{' '}
                    {item?.unit && selectedPropertyView[item?.unit]}
                    </span>
                  </p>
                )
              ))
            ) : selectedPropertyView?.type_name === 'Commercial' ? (
              commercialFields?.map((item) => (
                (selectedPropertyView[item?.value] !== undefined || selectedPropertyView[`${item?.value}_name`] !== undefined) && (
                  <p key={item?.id} className="property-details-text">
                    <b>{item?.label}</b>
                    <span> :{'  '}
                    {item.isBooleanDropdown ? (
                      selectedPropertyView[item?.value] === 1 ? 'Yes' : 'No'
                    ) : (
                      selectedPropertyView[item?.value] ?? selectedPropertyView[`${item?.value}_name`]
                    )}{' '}
                    {item?.unit && selectedPropertyView[item?.unit]}
                    </span>
                  </p>
                )
              ))
            ) : (
              agriculturalFields?.map((item) => (
                (selectedPropertyView[item?.value] !== undefined || selectedPropertyView[`${item?.value}_name`] !== undefined) && (
                  <p key={item?.id} className="property-details-text">
                    <b>{item?.label}</b>
                    <span> :{'  '}
                    {item.isBooleanDropdown ? (
                      selectedPropertyView[item?.value] === 1 ? 'Yes' : 'No'
                    ) : (
                      selectedPropertyView[item?.value] ?? selectedPropertyView[`${item?.value}_name`]
                    )}{' '}
                    {item?.unit && selectedPropertyView[item?.unit]}
                    </span>
                  </p>
                )
              ))
            )
          }
          {selectedPropertyView?.is_bankauction == 1 && (
            bankAuctionPropertyFields.map(item => (
              selectedPropertyView[item?.value] !== undefined && (
                <p key={item?.id} className="property-details-text">
                  <b>{item?.label}</b>
                  <span> :{'  '}
                  {item.isBooleanDropdown ? (
                    selectedPropertyView[item?.value] === 1 ? 'Yes' : 'No'
                  ) : (
                    selectedPropertyView[item?.value] ?? selectedPropertyView[`${item?.value}_name`]
                  )}{' '}
                  {item?.unit && selectedPropertyView[item?.unit]}
                  </span>
                </p>
              )
            ))
          )}
          {/* {selectedPropertyView?.area && <p className="property-details-text">
            <b>Area</b><span> : {selectedPropertyView.area} {selectedPropertyView?.unit}</span>
          </p>} */}
          {/* {selectedPropertyView?.carpet_area && <p className="property-details-text">
            <b>Carpet Area</b><span> : {selectedPropertyView.carpet_area} {selectedPropertyView?.carpet_area_unit}</span>
          </p>}
          {selectedPropertyView?.plot_area && <p className="property-details-text">
            <b>Plot Area</b><span> : {selectedPropertyView.plot_area}</span>
          </p>}
          {selectedPropertyView?.constructed_area && <p className="property-details-text">
            <b>Constructed Area</b><span> : {selectedPropertyView.constructed_area} {selectedPropertyView?.area_unit}</span>
          </p>} */}
        </div>
      </div>
    </Modal>
  );
}

export default ViewPropertyModal;