import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { getAllInstitutionAction } from "../../redux/institution/institution.actions";

export function GetAllInstitutionApiCall() {
  return (dispatch) => {
    const url = URL_CONSTANTS.FILTER_ALL_INSTITUTION;

    postRequestHeader(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(getAllInstitutionAction(res.data.data));
        }
      })
      .catch((error) => {
        dispatch(getAllInstitutionAction(null));
      });
  };
}
