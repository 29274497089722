import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { setCategoryListAction } from "../../redux/category/category.actions";

export function CategoryById(typeId) {
  return (dispatch) => {
    const url = URL_CONSTANTS.FILTER_ALL_CATEGORY_BY_ID;

    const requestBody = {
      type_id: typeId,
    };

    postRequestHeader(url, requestBody) 
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(setCategoryListAction(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(setCategoryListAction(null, false));
      });
  };
}
