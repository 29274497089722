const UserPropertyActionTypes = {
  USER_PROPERTY_ADDING: 'USER_PROPERTY_ADDING',
  FAVOURITE_LIST: 'FAVOURITE_LIST',
  PROPERTY_UPLOADED_LIST: 'PROPERTY_UPLOADED_LIST',
  USER_PROPERTY_UPDATE: 'USER_PROPERTY_UPDATE',
  FILE_UPLOAD_USER: 'FILE_UPLOAD_USER',
  FILE_REMOVE_USER: 'FILE_REMOVE_USER',
  PROPERTY_REMOVE_USER: 'PROPERTY_REMOVE_USER',
  PROPERTY_FAVOURITE_USER: 'PROPERTY_FAVOURITE_USER',
  USER_PROPERTY_EDIT_DETAILS: 'USER_PROPERTY_EDIT_DETAILS'
};

export default UserPropertyActionTypes;