import { postRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { adChange } from "../../redux/admin/admin.actions";

export function AdChangeApiCall(data, toastView = () => {}) {
  return (dispatch) => {
    dispatch(adChange(null, true));

    const url = URL_CONSTANTS.AD_CHANGE;

    postRequestHeader(url, data)
      .then((res) => {
        if (res.data.status_code === 200) {
          toastView("Ad Updated successfully", 'success');
          dispatch(adChange(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(adChange(null, false));
      });
  };
}