const independentHouseFields = [
  {
    label: 'Address',
    value: 'address',
    isMainSection: true
  },
  {
    label: 'Road',
    value: 'road',
    isMainSection: true
  },
  {
    label: 'Facing',
    value: 'face',
    isMainSection: true
  },
  {
    label: 'Type of Title',
    value: 'type_of_title',
    isMainSection: true
  },
  {
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
    isMainSection: true
  },
  // {
  //   label: 'Bedroom',
  //   value: 'bedroom',
  //   isMainSection: true
  // },
  {
    label: 'Stats of Furnishing',
    value: 'stats_of_furnishing',
    isMainSection: true
  },
  {
    label: 'Covered Parking',
    value: 'covered_parking',
  },
  {
    label: 'Piped Gas',
    value: 'piped_gas',
    isBoolean: true
  },
  {
    label: 'RERA Approval',
    value: 'rera_approval',
  },
  {
    label: 'Borewell',
    value: 'borewell',
    isBoolean: true
  },
  {
    label: 'Gym',
    value: 'gym',
    isBoolean: true
  },
  {
    label: 'Theatre',
    value: 'theatre',
    isBoolean: true
  },
  {
    label: 'Servant Room',
    value: 'servant_room',
    isBoolean: true
  },
  {
    label: 'Other Amenities',
    value: 'other_eminities',
  },
  {
    label: 'Contact Person',
    value: 'contact_agent',
    isPhoneNumber: true
  }
];

const flatFields = [
  {
    label: 'Address',
    value: 'address',
    isMainSection: true
  },
  {
    label: 'Carpet Area',
    value: 'carpet_area',
    isMainSection: true
  },
  {

    label: 'BHK',
    value: 'bhk',
    isMainSection: true
  },
  {
    label: 'Road',
    value: 'road',
    isMainSection: true
  },
  {
    label: 'Facing',
    value: 'face',
    isMainSection: true
  },
  {
    label: 'Stats of Furnishing',
    value: 'stats_of_furnishing',
    isMainSection: true
  },
  {
    label: 'Balcony',
    value: 'balcony',
  },
  {
    label: 'Floor',
    value: 'floors',
  },
  {
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
  },
  {
    label: 'Parking',
    value: 'parking',
  },
  {
    label: 'Security Guard Room',
    value: 'security_guard',
    isBoolean: true
  },
  {
    label: 'Power Back-Up',
    value: 'power_back',
  },
  {
    label: 'Other Amenities',
    value: 'other_eminities',
  },
  {
    label: 'Lift',
    value: 'lift',
    isBoolean: true
  },
  {
    label: 'Piped Gas',
    value: 'piped_gas',
    isBoolean: true
  },
  {
    label: 'Bathrooms',
    value: 'bath_rooms',
  },
  {
    label: 'RERA Approval',
    value: 'rera_approval',
    isBoolean: true
  },
  {
    label: 'Plot Size of Complex',
    value: 'plot_complex_size',
  },
  {
    label: 'Building / Complex Name',
    value: 'building_name',
  },
  {
    label: 'Servant Room',
    value: 'servant_room',
    isBoolean: true
  },
  {
    label: 'Flooring',
    value: 'flooring',
  },
  {
    label: 'Other Amenities',
    value: 'other_eminities',
  },
  {
    label: 'Contact Person',
    value: 'contact_agent',
    isPhoneNumber: true
  }
];

const industrialFields = [
  {
    label: 'Address',
    value: 'address',
    isMainSection: true
  },
  {
    label: 'Constructed Area',
    value: 'industry_area',
    isMainSection: true
  },
  {
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
    isMainSection: true
  },
  {
    label: 'Front Road Size',
    value: 'front_road_size',
    isMainSection: true
  },
  {
    label: 'Age of Property',
    value: 'property_age',
    isMainSection: true
  },
  {
    label: 'Facing',
    value: 'face',
    isMainSection: true
  },
  {
    label: 'Brief Details',
    value: 'brief_details',
    isMainSection: true
  },
  {
    label: 'Dues of Water / Electricity',
    value: 'dues_of_water_or_electricity',
    isBoolean: true
  },
  {
    label: 'Condition',
    value: 'property_condition',
  },
  {
    label: 'Any Licence Required',
    value: 'any_licence_required',
    isBoolean: true
  },
  {
    label: 'Pollution Clearance',
    value: 'pollution_clearance',
  },
  {
    label: 'Statutory Liability',
    value: 'statutory_liability',
  },
  {
    label: 'Any Other Dues',
    value: 'any_other_dues',
    isBoolean: true
  },
  {
    label: 'Any Legal Cases With Vendors or Dept.',
    value: 'any_legal_cases',
    isBoolean: true
  },
  {
    label: 'Other Details',
    value: 'other_details',
  },
  {
    label: 'Contact Person',
    value: 'contact_agent',
    isPhoneNumber: true
  }
];

const agriculturalFields = [
  {
    label: 'Address',
    value: 'address',
    isMainSection: true
  },
  {
    label: 'Crops Taken',
    value: 'crops_taken',
    isMainSection: true
  },
  {
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
    isMainSection: true
  },
  {
    label: 'Water Irrigation',
    value: 'water_irrigation',
    isMainSection: true
  },
  {
    label: 'No. of Crops Taken in a Year',
    value: 'crop_per_year',
    isMainSection: true
  },
  {
    label: 'Boundary',
    value: 'boundary',
    isMainSection: true
  },
  {
    label: 'Brief Details',
    value: 'brief_details',
    isMainSection: true
  },
  {
    label: 'Approach Road',
    value: 'approach_road',
  },
  {
    label: 'Any Liability of Bank',
    value: 'bank_liability',
  },
  {
    label: 'Any Dispute',
    value: 'any_dispute',
    isBoolean: true
  },
  {
    label: 'Other Details',
    value: 'other_details',
  },
  {
    label: 'Farm House',
    value: 'farm_house',
    isBoolean: true
  },
];

const commercialFields = [
  {
    label: 'Address',
    value: 'address',
    isMainSection: true
  },
  {
    label: 'Constructed Area',
    value: 'commercial_area',
    isMainSection: true
  },
  {
    label: 'Distance From Railway Station / Bus stand / Airport',
    value: 'distance_from_station',
    isMainSection: true
  },
  {
    label: 'Front Road Size',
    value: 'front_road_size',
    isMainSection: true
  },
  {
    label: 'Age of Property',
    value: 'property_age',
    isMainSection: true
  },
  {
    label: 'Facing',
    value: 'face',
    isMainSection: true
  },
  {
    label: 'Floor',
    value: 'floors',
    isMainSection: true
  },
  {
    label: 'Dues of Water / Electricity',
    value: 'water_electricity_due',
    isBoolean: true
  },
  {
    label: 'Condition',
    value: 'property_condition',
  },
  {
    label: 'Statutory Liability',
    value: 'statutory_liability',
  },
  {
    label: 'Any Other Dues',
    value: 'any_other_dues',
    isBoolean: true
  },
  {
    label: 'Any Legal Cases With Vendors or Dept.',
    value: 'any_legal_cases',
    isBoolean: true
  },
  {
    label: 'Age of Construction',
    value: 'age_of_construction',
  },
  {
    label: 'Contact Person',
    value: 'contact_agent',
    isPhoneNumber: true
  }
];

const bankAuctionPropertyFields = [
  {
    label: 'Institution',
    value: 'institution',
  },
  {
    label: 'Institution Branch',
    value: 'institution_branch',
  },
  {
    label: 'Auction Details',
    value: 'auction_details',
  },
  {
    label: 'EMD Date',
    value: 'property_schedule',
  },
  {
    label: 'Inspection Date',
    value: 'inspection_details',
  },
  {
    label: 'EMD Amount',
    value: 'emd',
    isCurrency: true
  },
  {
    label: 'Minimum Increment',
    value: 'minimum_increment',
    isCurrency: true
  },
  {
    label: 'Possession',
    value: 'possession',
  },
];

export {
  independentHouseFields,
  industrialFields,
  flatFields,
  commercialFields,
  agriculturalFields,
  bankAuctionPropertyFields,
};