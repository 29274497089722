import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import PropertyCard from "../../components/Cards/PropertyCard.component";
import AddPropertyModal from "../../components/Modals/AddPropertyModal.component";
import Loader from '../../components/Loader/Loader.component';
import {
  independentHouseFields,
  industrialFields,
  flatFields,
  commercialFields,
  agriculturalFields,
} from '../AdminPage/AdminData.js';
import { TopTenCitiesApiCall } from '../../apiCalls/Search/TopTenCitiesApiCall';
import { TopTenStatesApiCall } from '../../apiCalls/Search/TopTenStatesApiCall';
import { TopLocalitiesApiCall } from '../../apiCalls/Search/TopLocalitiesApiCall';
import { UserPropertyUpdateApiCall } from '../../apiCalls/UserProperty/UserPropertyUpdateApiCall';
import { UserPropertyAddingApiCall } from '../../apiCalls/UserProperty/UserPropertyAddingApiCall';
import { PropertyUploadedListApiCall } from '../../apiCalls/UserProperty/PropertyUploadedListApiCall';
import { PropertyRemoveUserApiCall } from '../../apiCalls/UserProperty/PropertyRemoveUserApiCall';
import { PropertyCategoryDetailsApiCall } from '../../apiCalls/Property/PropertyCategoryDetailsApiCall';
import { UserPropertyEditDetailsApiCall } from '../../apiCalls/UserProperty/UserPropertyEditDetails';
import { AllUnitsApiCall } from '../../apiCalls/Property/AllUnitsApiCall';
import { 
  propertyUploadedList, 
  propertyRemoveUser, 
  userPropertyAdding, 
  userPropertyUpdate 
} from '../../redux/userProperty/userProperty.selector';
import { propertyCategoryDetails } from '../../redux/property/property.selector';
import { userPropertyEditDetails } from '../../redux/userProperty/userProperty.selector';
import NoDataIMG from '../../assets/img/no-data1.jpg';
import "./UserProperties.styles.scss";

const UserProperties = () => {
  const dispatch = useDispatch();
  const propertiesList = useSelector(propertyUploadedList);
  const propertyDetails = useSelector(userPropertyEditDetails);
  const propertyRemoveStatus = useSelector(propertyRemoveUser);
  const propertyUpdateStatus = useSelector(userPropertyUpdate);
  const propertyAddingStatus = useSelector(userPropertyAdding);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const categoryDetails = useSelector(propertyCategoryDetails);
  const [residential, setResidential] = useState([]);
  const [industrial, setIndustrial] = useState([]);
  const [agricultural, setAgricultural] = useState([]);
  const [commercial, setCommercial] = useState([]);
  const [addPropertyType, setAddPropertyType] = useState('');
  const [addPropertyCategory, setAddPropertyCategory] = useState('');
  const [addPropertyFields, setAddPropertyFields] = useState([]);
  const [addPropertyErr, setAddPropertyErr] = useState({
    propertyType: null,
    propertyCategory: null
  });
  const [savePropertyData, setSavePropertyData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [infiniteProperties, setInfiniteProperties] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  let type_id = '';
  const { addToast } = useToasts();

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (propertiesList.loader) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageNo < totalPages) {
          setPageNo(old => old + 1)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [pageNo, propertiesList.loader, totalPages]);

  useEffect(() => {
    dispatch(AllUnitsApiCall());
    dispatch(PropertyCategoryDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (propertiesList.data?.data?.length > 0) {
      if (pageNo === 1) {
        setInfiniteProperties(propertiesList.data?.data);
        setTotalPages(propertiesList.data?.total_page_count);
      } else {
        setInfiniteProperties(old => [...old, ...propertiesList.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesList.data]);

  useEffect(() => {
    dispatch(PropertyUploadedListApiCall(pageNo, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  useEffect(() => {
    if(propertyUpdateStatus.data?.data?.property_id) {
      let temp = [];
      temp = infiniteProperties?.map(item => {
        if(item?.property_id === propertyUpdateStatus.data?.data?.property_id) {
          return propertyUpdateStatus.data?.data;
        } else {
          return item;
        }
      });
      setInfiniteProperties(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyUpdateStatus.data]);

  useEffect(() => {
    if (categoryDetails.data?.types?.length > 0) {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === "Residential") {
          setResidential(info[i]?.category);
        } else if (info[i]?.type_name === "Industrial") {
          setIndustrial(info[i]?.category);
        } else if (info[i]?.type_name === "Agricultural land") {
          setAgricultural(info[i]?.category);
        } else if (info[i]?.type_name === "Commercial") {
          setCommercial(info[i]?.category);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetails.data]);

  useEffect(() => {
    if (propertyDetails.data?.property_id && addPropertyType?.length > 0) {
      setSavePropertyData(propertyDetails.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDetails.data]);

  useEffect(() => {
    dispatch(TopTenStatesApiCall(savePropertyData?.state_name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.state_name]);

  useEffect(() => {
    dispatch(TopTenCitiesApiCall(savePropertyData?.city_name, savePropertyData?.state_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.city_name]);

  useEffect(() => {
    dispatch(TopLocalitiesApiCall(savePropertyData?.locality_name, savePropertyData?.city_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.locality_name]);

  const handleSuccess = () => {
    if(pageNo === 1) {
      dispatch(PropertyUploadedListApiCall(pageNo, 10));
    } else {
      setPageNo(1);
    }
  }

  const handleAddPropertyCategoryChange = (e) => {
    setAddPropertyCategory(e.target.value);
    let data = {};
    if (addPropertyType === 'Residential') {
      if (e.target.value === 'Apartments or Flats') {
        for (let i = 0; i < flatFields.length; i++) {
          data[flatFields[i].value] = '';
        }
        setAddPropertyFields(flatFields);
      } else {
        for (let i = 0; i < independentHouseFields.length; i++) {
          data[independentHouseFields[i].value] = '';
        }
        setAddPropertyFields(independentHouseFields);
      }
    }
    setSavePropertyData(data);
  }

  const handleAddProperty = (fileInfos) => {
    let auction_id_normal, category_id;
    for (let i = 0; i < categoryDetails.data?.auctions?.length; i++) {
      if (categoryDetails.data?.auctions[i]?.auction_type_name === 'Normal Properties') {
        auction_id_normal = categoryDetails.data?.auctions[i]?.auction_type_id;
      }
    }
    for (let i = 0; i < categoryDetails.data?.types?.length; i++) {
      if (categoryDetails.data?.types[i]?.type_name === addPropertyType) {
        type_id = categoryDetails.data?.types[i]?.type_id;
        for (let j = 0; j < categoryDetails.data?.types[i]?.category?.length; j++) {
          if (categoryDetails.data?.types[i]?.category[j]?.category_name === addPropertyCategory) {
            category_id = categoryDetails.data?.types[i]?.category[j]?.category_id;
          }
        }
      }
    }
    let data = {
      auction_type_id: auction_id_normal,
      type_id,
      category_id,
      ...savePropertyData,
      image: fileInfos,
      location_name: savePropertyData?.locality_name ?? savePropertyData?.location_name ?? '',
      location_id: savePropertyData?.locality_id ?? savePropertyData?.location_id ?? ''
    };
    if (savePropertyData?.property_id) {
      dispatch(UserPropertyUpdateApiCall(data, toastView));
    } else {
      dispatch(UserPropertyAddingApiCall(data, toastView, handleSuccess));
    }
  }

  const handleAddPropertyTypeChange = (e) => {
    setAddPropertyType(e.target.value);
    let data = {};
    if (e.target.value === "Industrial") {
      for (let i = 0; i < industrialFields.length; i++) {
        data[industrialFields[i].value] = '';
      }
      setAddPropertyFields(industrialFields);
    } else if (e.target.value === "Agricultural land") {
      for (let i = 0; i < agriculturalFields.length; i++) {
        data[agriculturalFields[i].value] = '';
      }
      setAddPropertyFields(agriculturalFields);
    } else if (e.target.value === "Commercial") {
      for (let i = 0; i < commercialFields.length; i++) {
        data[commercialFields[i].value] = '';
      }
      data = {
        ...data,
        address: ''
      };
      setAddPropertyFields(commercialFields);
    } else {
      setAddPropertyFields([]);
    }
    setSavePropertyData(data);
  }

  const handleAddPropertyClose = () => {
    setShow(false);
    setAddPropertyType('');
    setAddPropertyCategory('');
    setAddPropertyFields([]);
    setSavePropertyData({});
  }

  const handleEdit = (item) => {
    dispatch(UserPropertyEditDetailsApiCall(item?.property_id));
    if (item?.type_name === 'Residential') {
      if (item?.category_name === 'Apartments or Flats') {
        setAddPropertyFields(flatFields);
      } else {
        setAddPropertyFields(independentHouseFields);
      }
    } else if (item?.type_name === 'Industrial') {
      setAddPropertyFields(industrialFields);
    } else if (item?.type_name === 'Commercial') {
      setAddPropertyFields(commercialFields);
    } else if (item?.type_name === 'Agricultural land') {
      setAddPropertyFields(agriculturalFields);
    }
    setAddPropertyType(item?.type_name);
    setAddPropertyCategory(item?.category_name);
    handleShow();
  }

  const handleDeleteProperty = (item) => {
    dispatch(PropertyRemoveUserApiCall(item?.property_id, toastView, handleSuccess));
  }

  return (
    <div className="user-properties-outer container-fluid pt-4">
      <Loader visible={(
        propertiesList.loader ||
        propertyAddingStatus.loader ||
        propertyDetails.loader ||
        propertyUpdateStatus.loader ||
        propertyRemoveStatus.loader
      )} />
      <div className="row float-right btn-wrapper">
        <Button variant="contained" color="default" onClick={handleShow}>
          <AddIcon />Post a Property
        </Button>
      </div>
      {infiniteProperties?.length > 0 ? (
        <div className="row mt-4 pt-4 user-property-list-wrapper">
          {infiniteProperties?.map((prop, index) => (
            infiniteProperties.length === index + 1 ? (
              <PropertyCard 
                data={prop} 
                ref={lastElementRef}
                handleEdit={handleEdit} 
                handleDelete={handleDeleteProperty}  
              />
            ) : (
              <PropertyCard 
                data={prop} 
                handleEdit={handleEdit} 
                handleDelete={handleDeleteProperty}  
              />
            )
          ))}
        </div>
      ) : (
        <div className="no-fav__outer">
          <img src={NoDataIMG} alt="No - Data" />
          <h1>No Properties Uploaded !!</h1>
          <p>Once you upload a property, you'll see them here.</p>
        </div>
      )}
      <AddPropertyModal
        isUserUpload={true}
        showPropertyModal={show}
        handleAddProperty={handleAddProperty}
        handleAddPropertyClose={handleAddPropertyClose}
        selectedTab='property'
        addPropertyType={addPropertyType}
        addPropertyCategory={addPropertyCategory}
        handleAddPropertyCategoryChange={handleAddPropertyCategoryChange}
        handleAddPropertyTypeChange={handleAddPropertyTypeChange}
        addPropertyFields={addPropertyFields}
        residential={residential}
        industrial={industrial}
        commercial={commercial}
        agricultural={agricultural}
        savePropertyData={savePropertyData}
        setSavePropertyData={setSavePropertyData}
        addPropertyErr={addPropertyErr}
        setAddPropertyErr={setAddPropertyErr}
      />
    </div>
  );
}

export default UserProperties;
