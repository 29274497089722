import FileActionTypes from './file.types';

export const uploadFile = (data, loader) => ({
  type: FileActionTypes.UPLOAD_FILE,
  payload: {
    data,
    loader
  }
});

export const removeFile = (data, loader) => ({
  type: FileActionTypes.REMOVE_FILE,
  payload: {
    data,
    loader
  }
});

export const removeAdFile = (data, loader) => ({
  type: FileActionTypes.REMOVE_AD_FILE,
  payload: {
    data,
    loader
  }
});

export const toast = (message, appearance) => ({
  type: FileActionTypes.TOAST,
  payload: {
    message,
    appearance
  } 
});