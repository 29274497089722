import { BUSINESS_ASSOCIATES } from '../../constants/iconConstants';
import './BusinessAssociates.styles.scss';

const BusinessAssociates = () => (
  <div className="business-associates-container container">
    <img
      src={BUSINESS_ASSOCIATES.default}
      className="associates-image"
      alt=""
      width="130px"
      height="130px"
    />
    <h1>OUR BUSINESS ASSOCIATES</h1>
    <div className="row">
      <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 m-auto">
        <ul>
          <li>CHARTERED ACCOUNTANTS</li>
          <li>PROPERTY LAWYERS</li>
          <li>PROPERTY VALUERS</li>
          <li>FINANCIERS – BANKS, NBFCs, HFCs for Home Loans</li>
          <li>ALLIED BUSINESS ASSOCIATES</li>
        </ul>
      </div>
    </div>
  </div>
);

export default BusinessAssociates;