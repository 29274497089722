import UserPropertyActionTypes from "./userProperty.types";

export const userPropertyAdding = (data, loader) => ({
  type: UserPropertyActionTypes.USER_PROPERTY_ADDING,
  payload: {
    data,
    loader
  }
});

export const favouriteList = (data, loader) => ({
  type: UserPropertyActionTypes.FAVOURITE_LIST,
  payload: {
    data,
    loader
  }
});

export const propertyUploadedList = (data, loader) => ({
  type: UserPropertyActionTypes.PROPERTY_UPLOADED_LIST,
  payload: {
    data,
    loader
  }
});

export const userPropertyUpdate = (data, loader) => ({
  type: UserPropertyActionTypes.USER_PROPERTY_UPDATE,
  payload: {
    data,
    loader
  }
});

export const fileUploadUser = (data, loader) => ({
  type: UserPropertyActionTypes.FILE_UPLOAD_USER,
  payload: {
    data,
    loader
  }
});

export const fileRemoveUser = (data, loader) => ({
  type: UserPropertyActionTypes.FILE_REMOVE_USER,
  payload: {
    data,
    loader
  }
});

export const propertyRemoveUser = (data, loader) => ({
  type: UserPropertyActionTypes.PROPERTY_REMOVE_USER,
  payload: {
    data,
    loader
  }
});

export const propertyFavouriteUser = (data, loader) => ({
  type: UserPropertyActionTypes.PROPERTY_FAVOURITE_USER,
  payload: {
    data,
    loader
  }
});

export const userPropertyEditDetails = (data, loader) => ({
  type: UserPropertyActionTypes.USER_PROPERTY_EDIT_DETAILS,
  payload: {
    data,
    loader
  }
});