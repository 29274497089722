import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { PROPERTY_DEALERS } from '../../constants/iconConstants';
import { propertyDealersData } from './PropertyDealers.data';
import './PropertyDealers.styles.scss';

const useStyles = makeStyles({
  root: {
    marginTop: 40,
    marginBottom: 50
  }
});

const PropertyDealers = () => {
  const classes = useStyles();
  
  return(
    <div className="property-dealers-container">
      <img
        src={PROPERTY_DEALERS.default}
        className="property-dealer-image"
        alt=""
        height="130px"
      />
      <h2>Property Lawyers in your Area</h2>
      <div className="row">
        <div className="col-xl-10 m-auto">
          {propertyDealersData.map(item => (
            <div key={item.id}>
              <h4>{item?.city}</h4>
              <TableContainer component={Paper} className={classes.root}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <img
                          src={item.image.default}
                          alt=""
                          height="100px"
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    {item.data.map(dat => (
                      <TableRow key={dat.id}>
                        <TableCell component="th" scope="row">
                          {dat.label}
                        </TableCell>
                        <TableCell>{dat.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PropertyDealers;