import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertySummary } from "../../redux/property/property.actions";

export function PropertySummaryApiCall() {
  return (dispatch) => {
    dispatch(propertySummary(null, true));

    const url = URL_CONSTANTS.PROPERTY_SUMMARY;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertySummary(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertySummary(null, false));
      });
  };
}
