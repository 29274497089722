import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getTopTenCities, getTopLocalities } from '../../redux/search/search.selector';
import { TopTenCitiesApiCall } from '../../apiCalls/Search/TopTenCitiesApiCall';
import { TopLocalitiesApiCall } from '../../apiCalls/Search/TopLocalitiesApiCall';
import { PropertySearchListApiCall } from '../../apiCalls/Search/PropertySearchListApiCall';
import { propertyCategoryDetails } from '../../redux/property/property.selector';
import {
  RESIDENTIAL_CATEGORIES,
  INDUSTRIAL_CATEGORIES,
  COMMERCIAL_CATEGORIES,
  AGRICULTURAL_CATEGORIES,
  ALL_CATEGORIES
} from '../../utils/JsonData/Categories';
import './SearchFilter.styles.scss';
import { SearchContext } from '../../App.js';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlCustom: {
    margin: theme.spacing(1),
    minWidth: 185,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

export default function SearchFilter() {
  let location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const topCities = useSelector(getTopTenCities);
  const topLocalities = useSelector(getTopLocalities);
  const categoryDetails = useSelector(propertyCategoryDetails);
  const searchData = useContext(SearchContext).searchData;
  const setSearchData = useContext(SearchContext).setSearchData;
  const setPagenumber = useContext(SearchContext).setSearchPageNumber;
  //advanced
  const [checked, setChecked] = useState(false);
  const mounted = useRef();

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // type
  const [state, setState] = useState({
    type: '',
    subType: '',
    city: '',
    locality: '',
    bedsSelected: '',
    priceSelected: '',
    propertyId: '',
    minAreaSelected: '',
    maxAreaSelected: '',
    cityData: {},
    localityData: {}
  });

  const resetFilter = () => {
    setState({
      type: '',
      subType: '',
      city: '',
      locality: '',
      bedsSelected: '',
      priceSelected: '',
      propertyId: '',
      minAreaSelected: '',
      maxAreaSelected: '',
      cityData: {},
      localityData: {}
    });
    setSearchData({
      type: '',
      subType: '',
      city: '',
      locality: '',
      bedsSelected: '',
      priceSelected: '',
      propertyId: '',
      minAreaSelected: '',
      maxAreaSelected: '',
      cityData: {},
      localityData: {},
      pId: ''
    });
  }

  useEffect(() => {
    setState(searchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      dispatch(TopTenCitiesApiCall(state.city));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.city]);

  useEffect(() => {
    if (state.cityData?.city_id?.trim('')?.length > 0) {
      dispatch(TopLocalitiesApiCall(state.locality, state.cityData?.city_id));
    } else {
      dispatch(TopLocalitiesApiCall(state.locality));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.locality]);

  const onFilterChange = (event) => {
    const name = event.target.name;
    if (name === 'type') {
      setState({
        ...state,
        [name]: event.target.value,
        subType: ''
      });
    } else {
      setState({
        ...state,
        [name]: event.target.value
      });
    }
  };

  //residential
  const [residentialList, setResidentialList] = useState([]);
  const [industrialList, setIndustrialList] = useState([]);
  const [commercialList, setCommercialList] = useState([]);
  const [agriculturalList, setAgriculturalList] = useState([]);

  useEffect(() => {
    if (categoryDetails.data?.types?.length > 0) {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === "Residential") {
          setResidentialList(info[i]?.category);
        } else if (info[i]?.type_name === 'Industrial') {
          setIndustrialList(info[i]?.category);
        } else if (info[i]?.type_name === 'Agricultural land') {
          setAgriculturalList(info[i]?.category);
        } else {
          setCommercialList(info[i]?.category);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetails.data]);

  const searchProperty = () => {
    let pId = '';
    if (categoryDetails.data?.types?.length > 0 && state?.type !== "All Types") {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === state.type) {
          pId = info[i]?.type_id;
        }
      }
    }
    setSearchData({
      ...state,
      pId: pId
    });
    setPagenumber(1)
    if(state.minAreaSelected?.trim('')?.length > 0 ||
      state.maxAreaSelected?.trim('')?.length > 0 ||
      state.subType?.trim('')?.length > 0 ||
      state.propertyId?.trim('')?.length > 0 ||
      state.bedsSelected?.trim('')?.length > 0 ||
      state.priceSelected?.trim('')?.length > 0 ||
      state.cityData?.city_name?.trim('')?.length > 0 ||
      state.localityData?.locality_name?.trim('')?.length > 0 ||
      state.type?.trim('')?.length > 0
    ) {
      dispatch(PropertySearchListApiCall(
        1,
        state.minAreaSelected,
        state.maxAreaSelected,
        pId,
        state.subType,
        state.propertyId,
        state.bedsSelected,
        state.priceSelected,
        state.city?.trim('')?.length > 0 ? (state?.cityData?.city_id || '') : '',
        state.locality?.trim('')?.length > 0 ? (state.localityData?.locality_id || '') : ''
      ));
    }
  }

  return (
    <div className={`search-filter-main-outer m-auto ${location.pathname?.includes("/search-results") ? 'w-100':''}`}>
      <div className="row w-100 search-filter-primary m-auto">
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 form-item custom-border">
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControlCustom}
          >
            <InputLabel htmlFor="type-dropdown">Type of Property</InputLabel>
            <Select
              value={state.type}
              onChange={onFilterChange}
              label="Type"
              inputProps={{
                name: "type",
                id: "type-dropdown",
              }}
            >
              <MenuItem value="All Types">All Types</MenuItem>
              <MenuItem value="Residential">Residential</MenuItem>
              <MenuItem value="Commercial">Commercial</MenuItem>
              <MenuItem value="Industrial">Industrial</MenuItem>
              <MenuItem value="Agricultural land">Agricultural</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 form-item custom-border">
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
          >
            <InputLabel htmlFor="type-dropdown">Category</InputLabel>
            <Select
              value={state.subType}
              onChange={onFilterChange}
              label="Sub-Type"
              inputProps={{
                name: "subType",
                id: "type-dropdown",
              }}
              disabled={state.type === "All Types" || !state.type}
            >
              {state.type === "Residential"
                ? residentialList.map((list) => (
                  <MenuItem value={list.category_id}>{RESIDENTIAL_CATEGORIES[list.category_name]}</MenuItem>
                ))
                : state.type === "Industrial"
                  ? industrialList.map((list) => (
                    <MenuItem value={list.category_id}>{INDUSTRIAL_CATEGORIES[list.category_name]}</MenuItem>
                  ))
                  : state.type === "Commercial"
                    ? commercialList.map((list) => (
                      <MenuItem value={list.category_id}>{COMMERCIAL_CATEGORIES[list.category_name]}</MenuItem>
                    ))
                    : agriculturalList.map((list) => (
                      <MenuItem value={list.category_id}>{AGRICULTURAL_CATEGORIES[list.category_name]}</MenuItem>
                    ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 form-item custom-border">
          <Autocomplete
            freeSolo
            id="city-dropdown"
            options={topCities.data ?? []}
            disableClearable
            noOptionsText="No cities"
            getOptionLabel={(option) => option.city_name}
            onChange={(event, newValue) => 
              setState({
                ...state,
                cityData: newValue
              })
            }
            value={state.cityData}
            style={{ width: 160 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                className="city-label"
                variant="outlined"
                onChange={(e) => setState({
                  ...state,
                  city: e.target.value
                })}
              />
            )}
          />
        </div>
        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 form-item custom-border">
          <Autocomplete
            freeSolo
            id="locality-dropdown"
            options={topLocalities.data ?? []}
            disableClearable
            noOptionsText="No locations"
            value={state.localityData}
            getOptionLabel={(option) => option.locality_name}
            onChange={(event, newValue) => 
              setState({
                ...state,
                localityData: newValue
              })
            }
            style={{ width: 160 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Location"
                className="city-label"
                variant="outlined"
                onChange={(e) => setState({
                  ...state,
                  locality: e.target.value
                })}
              />
            )}
          />
        </div>
        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 search-actions white-space-nowrap mt-1">
          <span className="advanced-toggle nowrap" onClick={handleChange}>
            {checked ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            More
          </span>
          <Link
            className={checked ? 'transform-btn' : ''}
            to={{
              pathname: "search-results",
            }}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary" className="m-2" onClick={searchProperty}>
              Search
            </Button>
          </Link>
        </div>
      </div>

      <Collapse in={checked} collapsedHeight={0}>
        <Paper elevation={20} className={` expanded-main ${classes.paper}`}>
          <div className={"row search-filter-expanded"}>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 form-item">
              <FormControl
                variant="outlined"
                size="small"
                className={classes.formControl}
              >
                <InputLabel htmlFor="beds-dropdown">BHK</InputLabel>
                <Select
                  value={state.bedsSelected}
                  onChange={onFilterChange}
                  label="BHK"
                  inputProps={{
                    name: "bedsSelected",
                    id: "beds-dropdown",
                  }}
                >
                  <MenuItem value='1'>1RK/1BHK</MenuItem>
                  <MenuItem value='2'>2 BHK</MenuItem>
                  <MenuItem value='3'>3 BHK</MenuItem>
                  <MenuItem value='4'>4 BHK</MenuItem>
                  <MenuItem value='5'>5 BHK</MenuItem>
                  <MenuItem value='6'>6 BHK</MenuItem>
                  <MenuItem value='7'>7 BHK</MenuItem>
                  <MenuItem value='8'>8 BHK</MenuItem>
                  <MenuItem value='9'>9 BHK</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 form-item">
              <FormControl
                variant="outlined"
                size="small"
                className={classes.formControl}
              >
                <InputLabel htmlFor="price-dropdown">Price</InputLabel>
                <Select
                  value={state.priceSelected}
                  onChange={onFilterChange}
                  label="Price"
                  inputProps={{
                    name: "priceSelected",
                    id: "price-dropdown",
                  }}
                >
                  {/* <MenuItem value={1}>Upto ₹5 Lac</MenuItem> */}
                  <MenuItem value='1'>Upto ₹10 Lac</MenuItem>
                  <MenuItem value='2'>Upto ₹25 Lac</MenuItem>
                  <MenuItem value='3'>Upto ₹50 Lac</MenuItem>
                  <MenuItem value='4'>Upto ₹75 Lac</MenuItem>
                  <MenuItem value='5'>Upto ₹1 Crore</MenuItem>
                  <MenuItem value='6'>Upto ₹1.5 Crore</MenuItem>
                  <MenuItem value='7'>Upto ₹2.5 Crore</MenuItem>
                  <MenuItem value='8'>Upto ₹5 Crore</MenuItem>
                  <MenuItem value='9'>Above ₹5 Crore</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 form-item m-auto">
              <TextField
                id="propertyId"
                label="Property Id"
                variant="outlined"
                size="small"
                value={state.propertyId}
                onChange={(e) => setState({
                  ...state,
                  propertyId: e.target.value
                })}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 form-item">
              <FormControl
                variant="outlined"
                size="small"
                className={classes.formControlCustom}
              >
                <InputLabel htmlFor="min-area-dropdown">
                  Min. area(Sq. Ft.)
                </InputLabel>
                <Select
                  value={state.minAreaSelected}
                  onChange={onFilterChange}
                  label="Min. area(Sq. Ft.)"
                  inputProps={{
                    name: "minAreaSelected",
                    id: "min-area-dropdown",
                  }}
                >
                  <MenuItem value='1'>100 sq.ft</MenuItem>
                  <MenuItem value='2'>500 sq.ft</MenuItem>
                  <MenuItem value='3'>1000 sq.ft</MenuItem>
                  <MenuItem value='4'>2000 sq.ft</MenuItem>
                  <MenuItem value='5'>3000 sq.ft</MenuItem>
                  <MenuItem value='6'>4000 sq.ft</MenuItem>
                  <MenuItem value='7'>5000 sq.ft</MenuItem>
                  <MenuItem value='8'>7000 sq.ft</MenuItem>
                  <MenuItem value='9'>10000 sq.ft</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 form-item">
              <FormControl
                variant="outlined"
                size="small"
                className={classes.formControlCustom}
              >
                <InputLabel htmlFor="max-area-dropdown">
                  Max. area(Sq. Ft.)
                </InputLabel>
                <Select
                  value={state.maxAreaSelected}
                  onChange={onFilterChange}
                  label="Max. area(Sq. Ft.)"
                  inputProps={{
                    name: "maxAreaSelected",
                    id: "max-area-dropdown",
                  }}
                >
                  <MenuItem value='1'>500 sq.ft</MenuItem>
                  <MenuItem value='2'>1000 sq.ft</MenuItem>
                  <MenuItem value='3'>2000 sq.ft</MenuItem>
                  <MenuItem value='4'>3000 sq.ft</MenuItem>
                  <MenuItem value='5'>4000 sq.ft</MenuItem>
                  <MenuItem value='6'>5000 sq.ft</MenuItem>
                  <MenuItem value='7'>7000 sq.ft</MenuItem>
                  <MenuItem value='8'>10000 sq.ft</MenuItem>
                  <MenuItem value='9'>Above 10000 sq.ft</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xl-1 form-item search-actions">
              <Button variant="contained" color="secondary" className="m-2" id="reset-btn" onClick={resetFilter}>
                Reset
              </Button>
            </div>
          </div>
        </Paper>
      </Collapse>
    </div>
  );
}