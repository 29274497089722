import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/Sidebar/Sidebar.compoenent';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {
  ADD_USER,
  AVATAR,
  ADD_PROPERTY,
  ADD_BANK_AUCTION_PROPERTY
} from '../../constants/iconConstants';
import {
  addUserFields,
  independentHouseFields,
  industrialFields,
  flatFields,
  commercialFields,
  agriculturalFields,
  bankAuctionPropertyFields,
  userPrivileges
} from './AdminData';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getTopTenCities, getTopTenStates, getTopLocalities } from '../../redux/search/search.selector';
import useOutsideClick from '../../utils/CustomHooks/useOutsideClick';
import useWindowDimensions from '../../utils/CustomHooks/useWindowDimension';
import AddPropertyModal from '../../components/Modals/AddPropertyModal.component';
import AddUserModal from '../../components/Modals/AddUserModal.component';
import LogoutModal from '../../components/Modals/LogoutModal.component';
import RemoveAdminModal from '../../components/Modals/RemoveAdminModal.component';
import ViewPropertyModal from '../../components/Modals/ViewPropertyModal.component';
import DeletePropertyModal from '../../components/Modals/DeletePropertyModal.component';
import AdvertisementConfig from "./AdvertisementConfig/AdvertisementConfig.component";
import UserReport from './UserReport/UserReport.component';
import Dashboard from './Dashboard/Dashboard.component';
import Loader from '../../components/Loader/Loader.component';
import PropertyUpload from '../../components/PropertyUpload/PropertyUpload.component';
import { useToasts } from 'react-toast-notifications';
import { PropertyCategoryDetailsApiCall } from '../../apiCalls/Property/PropertyCategoryDetailsApiCall';
import { PropertyOtherAuctionListApiCall } from '../../apiCalls/AdminProperty/PropertyOtherAuctionListApiCall';
import { BankAuctionPropertyListApiCall } from '../../apiCalls/AdminProperty/BankAuctionPropertyListApiCall';
import { SearchInstitutionApiCall } from '../../apiCalls/Institution/SearchInstitutionApiCall';
import { AddPropertyApiCall } from '../../apiCalls/AdminProperty/AddPropertyApiCall';
import { TopTenCitiesApiCall } from '../../apiCalls/Search/TopTenCitiesApiCall';
import { TopTenStatesApiCall } from '../../apiCalls/Search/TopTenStatesApiCall';
import { TopLocalitiesApiCall } from '../../apiCalls/Search/TopLocalitiesApiCall';
import { RemovePropertyApiCall } from '../../apiCalls/AdminProperty/RemovePropertyApiCall';
import { UpdatePropertyApiCall } from '../../apiCalls/AdminProperty/UpdatePropertyApiCall';
import { CreateAdminApiCall } from '../../apiCalls/Admin/CreateAdminApiCall';
import { GetAdminRolesApiCall } from '../../apiCalls/Admin/GetAdminRolesApiCall';
import { GetAdminsByRoleApiCall } from '../../apiCalls/Admin/GetAdminsByRoleApiCall';
import { GetAdminDetailsApiCall } from '../../apiCalls/Admin/GetAdminDetailsApiCall';
import { EditPropertyApiCall } from '../../apiCalls/AdminProperty/EditPropertyApiCall';
import { AllUnitsApiCall } from '../../apiCalls/Property/AllUnitsApiCall';
import { UpdateAdminRoleApiCall } from '../../apiCalls/Admin/UpdateAdminRoleApiCall';
import { AdminPropertyDetailsApiCall } from '../../apiCalls/AdminProperty/AdminPropertyDetailsApiCall';
import { RemoveAdminApiCall } from '../../apiCalls/Admin/RemoveAdminApiCall';
import { AdminPropertySearchApiCall } from '../../apiCalls/AdminProperty/AdminPropertySearchApiCall';
import { AdminBankSearchApiCall } from '../../apiCalls/AdminProperty/AdminBankSearchApiCall';
import { ChangeAdminPasswordApiCall } from '../../apiCalls/Admin/ChangeAdminPasswordApiCall';
import { adminLogin } from '../../redux/persistantData/persistantData.actions';
import { setAdminToken } from '../../redux/token/token.actions';
import { getAdminDetails as adminDetailsAction } from '../../redux/admin/admin.actions';
import { adminBankSearchAction, adminPropertySearchAction } from '../../redux/adminProperty/adminProperty.actions';
import { RESET_STORE } from '../../redux/actionTypes';
import {
  getAdminRoles,
  adminsByRole,
  getAdminDetails,
  createAdmin,
  removeAdmin,
  changeAdminPassword
} from '../../redux/admin/admin.selector';
import { searchInstitution } from '../../redux/institution/institution.selector';
import { adminLoginState } from '../../redux/persistantData/persistantData.selector';
import { propertyCategoryDetails, allUnitsState } from '../../redux/property/property.selector';
import {
  bankAuctionPropertyList,
  getpropertyOtherAuctionList,
  addPropertyState,
  propertyApprovalState,
  propertyUnApprovalState,
  editPropertyState,
  adminPropertyDetailsState,
  adminPropertySearchState,
  adminBankSearchState,
  updateProperty
} from '../../redux/adminProperty/adminProperty.selector';
import { 
  RESIDENTIAL_CATEGORIES,
  COMMERCIAL_CATEGORIES,
  INDUSTRIAL_CATEGORIES,
  AGRICULTURAL_CATEGORIES
} from '../../utils/JsonData/Categories';
import 'react-calendar/dist/Calendar.css';
import './AdminPage.styles.scss';

const AdminPage = () => {
  const dispatch = useDispatch();
  const boxRef = useRef(null);
  const { addToast } = useToasts();
  const topStates = useSelector(getTopTenStates);
  const searchInstitutionData = useSelector(searchInstitution);
  const topCities = useSelector(getTopTenCities);
  const topLocalities = useSelector(getTopLocalities);
  const propertyAddState = useSelector(addPropertyState);
  const addAdminState = useSelector(createAdmin);
  const adminRoles = useSelector(getAdminRoles);
  const adminList = useSelector(adminsByRole);
  const adminDetailsState = useSelector(getAdminDetails);
  const [adminDetails, setAdminDetails] = useState([]);
  const allUnits = useSelector(allUnitsState);
  const approvalStatus = useSelector(propertyApprovalState);
  const unapprovalStatus = useSelector(propertyUnApprovalState);
  const editProperty = useSelector(editPropertyState);
  const propDetails = useSelector(adminPropertyDetailsState);
  const removeAdminStatus = useSelector(removeAdmin);
  const adminPropertySearch = useSelector(adminPropertySearchState);
  const adminBankSearch = useSelector(adminBankSearchState);
  const changeAdminPasswordState = useSelector(changeAdminPassword);
  const adminLoginDetails = useSelector(adminLoginState);
  const updatePropertyStatus = useSelector(updateProperty);
  const [showPass, setShowPass] = useState({
    currPass: false, newPass: false
  });
  const [adminPass, setAdminPass] = useState({
    currentPassword: '', newPassword: ''
  });
  const [showSearchPage, setShowSearchPage] = useState(false);
  const [roleIds, setRoleIds] = useState({});
  const categoryDetails = useSelector(propertyCategoryDetails);
  const [residential, setResidential] = useState([]);
  const [industrial, setIndustrial] = useState([]);
  const [agricultural, setAgricultural] = useState([]);
  const [commercial, setCommercial] = useState([]);
  const propertiesList = useSelector(getpropertyOtherAuctionList);
  const bankAuctionProperties = useSelector(bankAuctionPropertyList);
  // boxOutsideClick will be true on outside click
  const boxOutsideClick = useOutsideClick(boxRef);
  const { height, width } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState('dashboard');
  const [expanded, setExpanded] = useState(width > 768 ? true : false);
  const [userType, setUserType] = useState('');
  const [searchParams, setSearchParams] = useState({
    property_type: '',
    type_id: '',
    category_id: '',
    state_name: '',
    state_id: '',
    city_name: '',
    city_id: '',
    approval_filter_id: 0,
    property_id: '',
    institution_id: '',
    institution: '',
    title: '',
    locality: '',
    locality_id: ''
  });
  const [addPropertyType, setAddPropertyType] = useState('');
  const [addPropertyCategory, setAddPropertyCategory] = useState('');
  const [addPropertyFields, setAddPropertyFields] = useState([]);
  const addPropertyButton = useRef(null);
  const addBankPropertyButton = useRef(null);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showViewPropertyModal, setShowViewPropertyModal] = useState(false);
  const [showDeletePropertyModal, setShowDeletePropertyModal] = useState(false);
  const [addPropertyErr, setAddPropertyErr] = useState({
    propertyType: null,
    propertyCategory: null
  });
  const [addUserValues, setAddUserValues] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    aadhaar_no: '',
    city: '',
    state: '',
    address: '',
    userType: '',
    institute: ''
  });
  const [savePropertyData, setSavePropertyData] = useState({});
  const [selectedPropertyView, setSelectedPropertyView] = useState({});
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [infiniteProperties, setInfiniteProperties] = useState([]);
  const [pageNumber, setPagenumber] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [infinitePropertiesbank, setInfinitePropertiesbank] = useState([]);
  const [pageNumberbank, setPagenumberbank] = useState(1);
  const [totalPagesbank, settotalPagesbank] = useState(0);
  const [infinitePropertiesSearch, setInfinitePropertiesSearch] = useState([]);
  const [pageNumberPropertiesSearch, setPageNumberPropertiesSearch] = useState(1);
  const [totalPagesPropertiesSearch, setTotalPagesPropertiesSearch] = useState(0);
  const [infiniteBankSearch, setInfiniteBankSearch] = useState([]);
  const [pageNumberBankSearch, setPageNumberBankSearch] = useState(1);
  const [totalPagesBankSearch, setTotalPagesBankSearch] = useState(0);
  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState(false);
  const mountedFirst = useRef();
  const mountedSecond = useRef();
  const mountedThird = useRef();
  const mountedFourth = useRef();
  const mountedFifth = useRef();
  const mountedSixth = useRef();
  const mountedSeventh = useRef();
  let type_id = '';

  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (propertiesList.loader) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageNumber < (totalPages)) {
          setPagenumber(old => old + 1)
        }
      }
    })
    if (node) observer.current.observe(node)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, propertiesList.loader, totalPages]);

  useEffect(() => {
    if(adminLoginDetails?.data?.privileges?.external_user === true) {
      setSelectedTab('bankAuctionProperty');
    }
    if (adminLoginDetails?.data?.user_role === "Channel Partner") {
      setSearchParams({
        ...searchParams,
        institution_id: adminLoginDetails?.data?.institution_id,
        institution: adminLoginDetails?.data?.institution_name,
      });
    }
  }, [adminLoginDetails?.data]);

  const observerbank = useRef()
  const lastElementRefbank = useCallback(node => {
    if (bankAuctionProperties.loader) return
    if (observerbank.current) observerbank.current.disconnect()
    observerbank.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageNumberbank < (totalPagesbank)) {
          setPagenumberbank(old => old + 1)
        }
      }
    })
    if (node) observerbank.current.observe(node)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAuctionProperties.loader, pageNumberbank, totalPagesbank]);

  const observerBankSearch = useRef()
  const lastElementRefBankSearch = useCallback(node => {
    if (adminBankSearch.loader) return
    if (observerBankSearch.current) observerBankSearch.current.disconnect()
    observerBankSearch.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageNumberBankSearch < (totalPagesBankSearch)) {
          setPageNumberBankSearch(old => old + 1)
        }
      }
    })
    if (node) observerBankSearch.current.observe(node)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminBankSearch.loader, pageNumberBankSearch, totalPagesBankSearch]);

  const observerSearch = useRef()
  const lastElementRefSearch = useCallback(node => {
    if (adminPropertySearch.loader) return
    if (observerSearch.current) observerSearch.current.disconnect()
    observerSearch.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageNumberPropertiesSearch < (totalPagesPropertiesSearch)) {
          setPageNumberPropertiesSearch(old => old + 1)
        }
      }
    })
    if (node) observerSearch.current.observe(node)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminPropertySearch.loader, pageNumberPropertiesSearch, totalPagesPropertiesSearch]);

  useEffect(() => {
    setAdminDetails(adminDetailsState?.data);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminDetailsState.data]);

  useEffect(() => {
    if (editProperty.data?.property_id) {
      setSavePropertyData(editProperty.data);
      if (selectedTab === 'bankAuctionProperty') {
        addBankPropertyButton?.current?.click();
      } else {
        addPropertyButton?.current?.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProperty.data]);

  useEffect(() => {
    if (propDetails.data?.property_id) {
      setSelectedPropertyView(propDetails.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propDetails.data]);

  useEffect(() => {
    setPagenumberbank(1);
    setPagenumber(1);
    dispatch(PropertyOtherAuctionListApiCall(1));
    if(adminLoginDetails?.data?.user_role === "Channel Partner") {
      handleSubmitChange('bank')
    } else {
      dispatch(BankAuctionPropertyListApiCall(1));
    }
    dispatch(GetAdminRolesApiCall());
    dispatch(adminDetailsAction(null, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    dispatch(PropertyCategoryDetailsApiCall());
    dispatch(AllUnitsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temp = {};
    for (let i = 0; i < adminRoles.data?.length; i++) {
      if (adminRoles.data[i]?.role_name === 'Branch Partner') {
        temp['branchRoleId'] = adminRoles.data[i]?.role_id;
      } else if (adminRoles.data[i]?.role_name === 'Channel Partner') {
        temp['channelRoleId'] = adminRoles.data[i]?.role_id;
      }
    }
    setRoleIds(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminRoles?.data]);

  useEffect(() => {
    if (categoryDetails.data?.types?.length > 0) {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === "Residential") {
          setResidential(info[i]?.category);
        } else if (info[i]?.type_name === "Industrial") {
          setIndustrial(info[i]?.category);
        } else if (info[i]?.type_name === "Agricultural land") {
          setAgricultural(info[i]?.category);
        } else if (info[i]?.type_name === "Commercial") {
          setCommercial(info[i]?.category);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetails.data]);

  useEffect(() => {
    if (boxOutsideClick && expanded) {
      if (width < 768) {
        setExpanded(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxOutsideClick]);

  useEffect(() => {
    if (propertiesList.data?.data?.length > 0) {
      if (pageNumber === 1) {
        setInfiniteProperties(propertiesList.data?.data);
        settotalPages(propertiesList.data?.total_page_count);
      } else {
        setInfiniteProperties(old => [...old, ...propertiesList.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesList]);
  

  useEffect(() => {
    dispatch(PropertyOtherAuctionListApiCall(pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (bankAuctionProperties.data?.data?.length > 0) {
      if (pageNumberbank === 1) {
        setInfinitePropertiesbank(bankAuctionProperties.data?.data);
        settotalPagesbank(bankAuctionProperties.data?.total_page_count);
      } else {
        setInfinitePropertiesbank(old => [...old, ...bankAuctionProperties.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAuctionProperties]);

  useEffect(() => {
    if(adminLoginDetails?.data?.user_role !== "Channel Partner") {
      dispatch(BankAuctionPropertyListApiCall(pageNumberbank));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberbank]);

  useEffect(() => {
    if (adminPropertySearch.data?.data?.length > 0) {
      if (pageNumberPropertiesSearch === 1) {
        setInfinitePropertiesSearch(adminPropertySearch.data?.data);
        setTotalPagesPropertiesSearch(adminPropertySearch.data?.total_page_count);
      } else {
        setInfinitePropertiesSearch(old => [...old, ...adminPropertySearch.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminPropertySearch]);

  useEffect(() => {
    if (!mountedFirst.current) {
      mountedFirst.current = true;
    } else {
      const params = {
        page_number: pageNumberPropertiesSearch,
        type_id,
        category_id: searchParams.category_id,
        state_id: searchParams.state_id,
        city_id: searchParams.city_id,
        approval_filter_id: searchParams.approval_filter_id,
        search_uniqueid: searchParams.property_id,
        locality: searchParams.locality,
        title: searchParams.title
      }
      dispatch(AdminPropertySearchApiCall(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberPropertiesSearch]);

  useEffect(() => {
    if (adminBankSearch.data?.data?.length > 0) {
      if (pageNumberBankSearch === 1) {
        setInfiniteBankSearch(adminBankSearch.data?.data);
        setTotalPagesBankSearch(adminBankSearch.data?.total_page_count);
      } else {
        setInfiniteBankSearch(old => [...old, ...adminBankSearch.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminBankSearch]);

  useEffect(() => {
    if (!mountedSecond.current) {
      mountedSecond.current = true;
    } else {
      const params = {
        page_number: pageNumberBankSearch,
        type_id,
        category_id: searchParams.category_id,
        state_id: searchParams.state_id,
        city_id: searchParams.city_id,
        approval_filter_id: searchParams.approval_filter_id,
        search_uniqueid: searchParams.property_id,
        institution_id: searchParams.institution_id,
        locality: searchParams.locality,
        title: searchParams.title
      }
      dispatch(AdminBankSearchApiCall(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberBankSearch]);

  useEffect(() => {
    if (!mountedThird.current) {
      mountedThird.current = true;
    } else {
      if (selectedTab === 'configuration') {
        dispatch(TopTenStatesApiCall(addUserValues?.state_name));
      } else {
        dispatch(TopTenStatesApiCall(savePropertyData?.state_name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.state_name, addUserValues?.state_name]);

  useEffect(() => {
    if (!mountedFourth.current) {
      mountedFourth.current = true;
    } else {
      if (selectedTab === 'configuration') {
        dispatch(TopTenCitiesApiCall(addUserValues?.city_name, addUserValues?.state));
      } else {
        dispatch(TopTenCitiesApiCall(savePropertyData?.city_name, savePropertyData?.state_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.city_name, addUserValues?.city_name]);

  useEffect(() => {
    if (!mountedFifth.current) {
      mountedFifth.current = true;
    } else {
      dispatch(TopTenStatesApiCall(searchParams.state_name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.state_name]);

  useEffect(() => {
    if (!mountedSixth.current) {
      mountedSixth.current = true;
    } else {
      dispatch(TopTenCitiesApiCall(searchParams.city_name, searchParams.state_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.city_name]);

  useEffect(() => {
    if (!mountedSixth.current) {
      mountedSixth.current = true;
    } else {
      dispatch(TopLocalitiesApiCall(searchParams.locality, searchParams.city_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.locality]);

  useEffect(() => {
    if (!mountedSeventh.current) {
      mountedSeventh.current = true;
    } else {
      dispatch(TopLocalitiesApiCall(savePropertyData?.locality_name, savePropertyData?.city_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePropertyData?.locality_name]);

  useEffect(() => {
    if (userType === 'channel partner') {
      dispatch(GetAdminsByRoleApiCall(roleIds?.channelRoleId));
    } else {
      dispatch(GetAdminsByRoleApiCall(roleIds?.branchRoleId));
    }
    dispatch(adminDetailsAction(null, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, removeAdminStatus?.data, addAdminState]);

  useEffect(() => {
    if(updatePropertyStatus.data?.property_id) {
      let temp = [];
      if (selectedTab === 'bankAuctionProperty') {
        if (showSearchPage) {
          temp = infiniteBankSearch?.map(item => {
            if(item?.property_id === updatePropertyStatus.data?.property_id) {
              return updatePropertyStatus.data;
            } else {
              return item;
            }
          });
          setInfiniteBankSearch(temp);
        } else {
          temp = infinitePropertiesbank?.map(item => {
            if(item?.property_id === updatePropertyStatus.data?.property_id) {
              return updatePropertyStatus.data;
            } else {
              return item;
            }
          });
          setInfinitePropertiesbank(temp);
        }
      } else {
        if (showSearchPage) {
          temp = infinitePropertiesSearch?.map(item => {
            if(item?.property_id === updatePropertyStatus.data?.property_id) {
              return updatePropertyStatus.data;
            } else {
              return item;
            }
          });
          setInfinitePropertiesSearch(temp);
        } else {
          temp = infiniteProperties?.map(item => {
            if(item?.property_id === updatePropertyStatus.data?.property_id) {
              return updatePropertyStatus.data;
            } else {
              return item;
            }
          });
          setInfiniteProperties(temp);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePropertyStatus.data]);

  //Toast View
  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  //User Change in dropdown
  const handleUserChange = (userId) => {
    dispatch(GetAdminDetailsApiCall(userId));
  }

  //Add Admin User
  const handleAddUser = () => {
    const defaultValues = {
      name: '',
      email: '',
      password: '',
      phone: '',
      aadhaar_no: '',
      city: '',
      state: '',
      address: '',
      userType: '',
      institute: ''
    };
    setShowAddUserModal(false);
    let data = {
      ...addUserValues,
      role_id: addUserValues.userType === 'branch partner' ? roleIds?.branchRoleId : roleIds?.channelRoleId
    };
    delete data.userType;
    dispatch(CreateAdminApiCall(data, toastView));
    setAddUserValues(defaultValues);
  }

  //Property type change in add property
  const handleAddPropertyTypeChange = (e) => {
    setAddPropertyType(e.target.value);
    if (e.target.value === "Industrial") {
      setAddPropertyFields(industrialFields);
    } else if (e.target.value === "Agricultural land") {
      setAddPropertyFields(agriculturalFields);
    } else if (e.target.value === "Commercial") {
      setAddPropertyFields(commercialFields);
    } else {
      setAddPropertyFields([]);
    }
  }

  //Category Change in add property
  const handleAddPropertyCategoryChange = (e) => {
    setAddPropertyCategory(e.target.value);
    if (addPropertyType === 'Residential') {
      if (e.target.value === 'Apartments or Flats') {
        setAddPropertyFields(flatFields);
      } else {
        setAddPropertyFields(independentHouseFields);
      }
    }
  }

  //Add Property Modal Close
  const handleAddPropertyClose = () => {
    setShowPropertyModal(false);
    setAddPropertyType('');
    setAddPropertyCategory('');
    setAddPropertyFields([]);
    setSavePropertyData({});
  }

  //View Property
  const handleViewProperty = (propertyId) => {
    dispatch(AdminPropertyDetailsApiCall(propertyId, toastView));
    setShowViewPropertyModal(true);
  }

  //Add property success
  const handleSuccess = () => {
    if (selectedTab === 'bankAuctionProperty') {
      if (showSearchPage) {
        if(pageNumberBankSearch === 1) {
          const params = {
            page_number: pageNumberBankSearch,
            type_id,
            category_id: searchParams.category_id,
            state_id: searchParams.state_id,
            city_id: searchParams.city_id,
            approval_filter_id: searchParams.approval_filter_id,
            search_uniqueid: searchParams.property_id,
            institution_id: searchParams.institution_id,
            locality: searchParams.locality,
            title: searchParams.title
          }
          dispatch(AdminBankSearchApiCall(params));
        } else {
          setPageNumberBankSearch(1);
        }
      } else {
        if(pageNumberbank === 1) {
          dispatch(BankAuctionPropertyListApiCall(pageNumberbank));
        } else {
          setPagenumberbank(1);
        }
      }
    } else {
      if (showSearchPage) {
        if(pageNumberPropertiesSearch === 1) {
          const params = {
            page_number: pageNumberPropertiesSearch,
            type_id,
            category_id: searchParams.category_id,
            state_id: searchParams.state_id,
            city_id: searchParams.city_id,
            approval_filter_id: searchParams.approval_filter_id,
            search_uniqueid: searchParams.property_id,
            locality: searchParams.locality,
            title: searchParams.title
          }
          dispatch(AdminPropertySearchApiCall(params));
        } else {
          setPageNumberPropertiesSearch(1);
        }
      } else {
        if(pageNumber === 1) {
          dispatch(PropertyOtherAuctionListApiCall(pageNumber));
        } else {
          setPagenumber(1);
        }
      }
    }
  }

  //Add Property
  const handleAddProperty = (fileInfos, saleNotice, annexure) => {
    let auction_id_normal, auction_id_bank, category_id;
    for (let i = 0; i < categoryDetails.data?.auctions?.length; i++) {
      if (categoryDetails.data?.auctions[i]?.auction_type_name === 'Normal Properties') {
        auction_id_normal = categoryDetails.data?.auctions[i]?.auction_type_id;
      } else {
        auction_id_bank = categoryDetails.data?.auctions[i]?.auction_type_id;
      }
    }
    for (let i = 0; i < categoryDetails.data?.types?.length; i++) {
      if (categoryDetails.data?.types[i]?.type_name === addPropertyType) {
        type_id = categoryDetails.data?.types[i]?.type_id;
        for (let j = 0; j < categoryDetails.data?.types[i]?.category?.length; j++) {
          if (categoryDetails.data?.types[i]?.category[j]?.category_name === addPropertyCategory) {
            category_id = categoryDetails.data?.types[i]?.category[j]?.category_id;
          }
        }
      }
    }
    let temp = {
      ...savePropertyData
    };
    delete temp?.category_id;
    delete temp?.category_name;
    delete temp?.type_name;
    delete temp?.type_id;
    let data = {
      auction_type_id: selectedTab === 'bankAuctionProperty' ? auction_id_bank : auction_id_normal,
      type_id,
      category_id,
      ...temp,
      image: fileInfos,
      location_name: savePropertyData?.locality_name ?? savePropertyData?.location_name ?? '',
      location_id: savePropertyData?.locality_id ?? savePropertyData?.location_id ?? '',
      e_auction_sale_notice: saleNotice ?? '',
      annexurell: annexure ?? ''
    };
    if (savePropertyData?.property_id) {
      dispatch(UpdatePropertyApiCall(data, toastView));
    } else {
      dispatch(AddPropertyApiCall(data, toastView, handleSuccess));
    }
  }

  //Search function
  const handleSubmitChange = (type) => {
    setInfinitePropertiesSearch([]);
    setInfiniteBankSearch([]);
    setPageNumberBankSearch(1);
    setPageNumberPropertiesSearch(1)
    setTotalPagesBankSearch(0);
    setTotalPagesPropertiesSearch(0);
    let type_id = null;
    for (let i = 0; i < categoryDetails.data?.types?.length; i++) {
      if (categoryDetails.data?.types[i]?.type_name === searchParams.property_type) {
        type_id = categoryDetails.data?.types[i]?.type_id;
      }
    }
    if (type === 'property') {
      const params = {
        page_number: 1,
        type_id,
        category_id: searchParams.category_id,
        state_id: searchParams.state_id,
        city_id: searchParams.city_id,
        approval_filter_id: searchParams.approval_filter_id,
        search_uniqueid: searchParams.property_id,
        locality: searchParams.locality,
        title: searchParams.title
      }
      dispatch(AdminPropertySearchApiCall(params));
    } else {
      const params = {
        page_number: 1,
        type_id,
        category_id: searchParams.category_id,
        state_id: searchParams.state_id,
        city_id: searchParams.city_id,
        approval_filter_id: searchParams.approval_filter_id,
        search_uniqueid: searchParams.property_id,
        institution_id: searchParams.institution_id,
        locality: searchParams.locality,
        title: searchParams.title
      }
      dispatch(AdminBankSearchApiCall(params));
    }
    setShowSearchPage(true);
  }

  //Reset Search
  const handleSearchReset = () => {
    setShowSearchPage(false);
    setPageNumberBankSearch(1);
    setPageNumberPropertiesSearch(1);
    setTotalPagesBankSearch(0);
    setTotalPagesPropertiesSearch(0);
    setInfiniteBankSearch([]);
    setInfinitePropertiesSearch([]);
    setSearchParams({
      property_type: '',
      type_id: '',
      category_id: '',
      state_name: '',
      state_id: '',
      city_name: '',
      city_id: '',
      approval_filter_id: 0,
      property_id: '',
      institution_id: '',
      institution: '',
      title: '',
      locality: '',
      locality_id: ''
    });
    dispatch(adminBankSearchAction(null, false));
    dispatch(adminPropertySearchAction(null, false));
  }

  const deleteSuccess = (propertyId) => {
    if (selectedTab === 'bankAuctionProperty') {
      if (showSearchPage) {
        setInfiniteBankSearch(infiniteBankSearch?.filter(item => item?.property_id !== propertyId));
      } else {
        setInfinitePropertiesbank(infinitePropertiesbank?.filter(item => item?.property_id !== propertyId));
      }
    } else {
      if (showSearchPage) {
        setInfinitePropertiesSearch(infinitePropertiesSearch?.filter(item => item?.property_id !== propertyId));
      } else {
        setInfiniteProperties(infiniteProperties?.filter(item => item?.property_id !== propertyId));
      }
    }
  }

  //Delete Property
  const handleDeleteProperty = () => {
    setShowDeletePropertyModal(false);
    dispatch(RemovePropertyApiCall(selectedDeleteItem?.property_id, toastView, deleteSuccess));
    setSelectedDeleteItem({});
  }

  //Edit Property
  const handlePropertyEdit = (item) => {
    dispatch(EditPropertyApiCall(item?.property_id));
    if (item?.type_name === 'Residential') {
      if (item?.category_name === 'Apartments or Flats') {
        setAddPropertyFields(flatFields);
      } else {
        setAddPropertyFields(independentHouseFields);
      }
    } else if (item?.type_name === 'Industrial') {
      setAddPropertyFields(industrialFields);
    } else if (item?.type_name === 'Commercial') {
      setAddPropertyFields(commercialFields);
    } else if (item?.type_name === 'Agricultural land') {
      setAddPropertyFields(agriculturalFields);
    }
    setAddPropertyType(item?.type_name);
    setAddPropertyCategory(item?.category_name);
  }

  //Logout
  const handleLogout = () => {
    setShowLogoutModal(false);
    dispatch(setAdminToken(null));
    dispatch(adminLogin(null, false));
    dispatch({
      type: RESET_STORE
    });
  }

  //Delete Property Modal Close
  const handleDeletePropertyModalClose = () => {
    setSelectedDeleteItem({});
    setShowDeletePropertyModal(false);
  }

  //View Property Modal Close
  const handeViewPropertyModalClose = () => {
    setSelectedPropertyView({});
    setShowViewPropertyModal(false);
  }

  //Admin Privilege Change
  const handlePrivilegeChange = (e, privilege) => {
    let obj = {
      ...adminDetails
    };
    obj.privileges[privilege.value].enabled = !obj?.privileges[privilege.value]?.enabled;
    setAdminDetails(obj);
  }

  //Update Admin Privilege
  const updatePrivilege = () => {
    let data = {
      admin_user_id: adminDetails?.details[0]?.admin_id,
      privileges: [
        {
          privilege_id: adminDetails?.privileges?.add_branch_partners?.privilege_id,
          enabled: adminDetails?.privileges?.add_branch_partners?.enabled
        },
        {
          privilege_id: adminDetails?.privileges?.add_channel_partners?.privilege_id,
          enabled: adminDetails?.privileges?.add_channel_partners?.enabled
        },
        {
          privilege_id: adminDetails?.privileges?.remove_branch_partners?.privilege_id,
          enabled: adminDetails?.privileges?.remove_branch_partners?.enabled
        },
        {
          privilege_id: adminDetails?.privileges?.remove_channel_partners?.privilege_id,
          enabled: adminDetails?.privileges?.remove_channel_partners?.enabled
        },
        {
          privilege_id: adminDetails?.privileges?.approve_properties?.privilege_id,
          enabled: adminDetails?.privileges?.approve_properties?.enabled
        },
        {
          privilege_id: adminDetails?.privileges?.external_user?.privilege_id,
          enabled: adminDetails?.privileges?.external_user?.enabled
        }
      ]
    };
    dispatch(UpdateAdminRoleApiCall(data, toastView, adminDetails?.details[0]?.admin_id));
  }

  const handleRemoveAdmin = () => {
    setShowRemoveAdminModal(false);
    dispatch(RemoveAdminApiCall(adminDetails?.details[0]?.admin_id, toastView));
  }

  //Update Password
  const updatePassword = () => {
    let data = {
      current_password: adminPass.currentPassword,
      new_password: adminPass.newPassword
    };
    dispatch(ChangeAdminPasswordApiCall(data, toastView));
    setAdminPass({
      currentPassword: '',
      newPassword: ''
    });
  }

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleSearchReset();
  }

  const handleApprovalSuccess = () => {
    if(selectedTab === 'bankAuctionProperty') {
      setPageNumberBankSearch(1);
      const params = {
        page_number: 1,
        type_id,
        category_id: searchParams.category_id,
        state_id: searchParams.state_id,
        city_id: searchParams.city_id,
        approval_filter_id: searchParams.approval_filter_id,
        search_uniqueid: searchParams.property_id,
        institution_id: searchParams.institution_id,
        locality: searchParams.locality,
        title: searchParams.title
      }
      dispatch(AdminBankSearchApiCall(params));
    } else {
      setPageNumberPropertiesSearch(1);
      const params = {
        page_number: 1,
        type_id,
        category_id: searchParams.category_id,
        state_id: searchParams.state_id,
        city_id: searchParams.city_id,
        approval_filter_id: searchParams.approval_filter_id,
        search_uniqueid: searchParams.property_id,
        locality: searchParams.locality,
        title: searchParams.title
      }
      dispatch(AdminPropertySearchApiCall(params));
    }
  }

  const institutionSearch = (value) => {
    if(value?.length > 0) {
      dispatch(SearchInstitutionApiCall(value));
    }
    setSearchParams({
      ...searchParams,
      institution_id: '',
      institution: value
    });
  }

  return (
    <div className="admin-page-container">
      <Loader
        visible={bankAuctionProperties?.loader ||
          propertiesList?.loader ||
          adminRoles?.loader ||
          adminList?.loader ||
          adminDetailsState?.loader ||
          categoryDetails?.loader ||
          allUnits?.loader ||
          propertyAddState?.loader ||
          addAdminState?.loader ||
          editProperty?.loader ||
          propDetails?.loader ||
          removeAdminStatus?.loader ||
          adminPropertySearch?.loader ||
          adminBankSearch?.loader ||
          approvalStatus?.loader ||
          unapprovalStatus?.loader ||
          changeAdminPasswordState?.loader ||
          updatePropertyStatus?.loader
        }
      />
      <aside className="side-bar-wrapper" ref={boxRef}>
        <Sidebar
          handleTabChange={handleTabChange}
          selectedTab={selectedTab}
          expanded={expanded}
          setExpanded={setExpanded}
          setShowLogoutModal={setShowLogoutModal}
        />
      </aside>
      {
        selectedTab === 'dashboard' ? (
          <Dashboard
            setSelectedTab={setSelectedTab}
            expanded={expanded}
          />
        ) : selectedTab === 'advertisement' ? (
          <div className={`property-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
            <div className="container-fluid">
              <AdvertisementConfig />
            </div>
          </div>
        ) : selectedTab === 'property' ? (
          <div className={`property-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
            <div className="container-fluid">
              <div className="configuration-top-wrap">
                <div className="configuration-top">
                  <p className="page-title-config">Property</p>
                  <img
                    src={ADD_PROPERTY.default}
                    alt=""
                    className="add-property button-hover"
                    ref={addPropertyButton}
                    onClick={() => setShowPropertyModal(true)}
                  />
                </div>
                <div className="row pt-4 d-flex filter-wrapper align-items-center">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <p className="config-subhead">Property type</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="Property type"
                      value={searchParams.property_type}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        category_id: '',
                        property_type: e.target.value
                      })}
                    >
                      <option value="">Select Property Type</option>
                      <option value="Residential">Residential</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Agricultural land">Agricultural Land</option>
                      <option value="Commercial">Commercial</option>
                    </Form.Control>
                  </div>

                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Category</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="Select Category"
                      value={searchParams.category_id}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        category_id: e.target.value
                      })}
                    >
                      <option value="">Select Category</option>
                      {
                        searchParams.property_type === 'Residential' && (
                          residential.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {RESIDENTIAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                      {
                        searchParams.property_type === 'Industrial' && (
                          industrial.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {INDUSTRIAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                      {
                        searchParams.property_type === 'Agricultural land' && (
                          agricultural.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {AGRICULTURAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                      {
                        searchParams.property_type === 'Commercial' && (
                          commercial.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {COMMERCIAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                    </Form.Control>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">State</p>
                    <Autocomplete
                      className="user-select-menu"
                      options={topStates.data ?? []}
                      disableClearable
                      noOptionsText="No states"
                      getOptionLabel={(option) => option.state_name}
                      value={{
                        state_id: searchParams.state_id,
                        state_name: searchParams.state_name
                      }}
                      onChange={(event, newValue) =>
                        setSearchParams({
                          ...searchParams,
                          state_id: newValue?.state_id,
                          state_name: newValue?.state_name,
                          city_name: '',
                          city_id: ''
                        })
                      }
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              state_name: e.target.value,
                              city_name: '',
                              city_id: ''
                            })
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">City</p>
                    <Autocomplete
                      className="user-select-menu"
                      options={topCities.data ?? []}
                      disableClearable
                      noOptionsText="No cities"
                      getOptionLabel={(option) => option.city_name}
                      value={{
                        city_id: searchParams.city_id,
                        city_name: searchParams.city_name
                      }}
                      onChange={(event, newValue) =>
                        setSearchParams({
                          ...searchParams,
                          city_id: newValue?.city_id,
                          city_name: newValue?.city_name
                        })
                      }
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              city_name: e.target.value
                            })
                          }
                        />
                      )}
                    />
                  </div>

                  {/* Locality */}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">Locality</p>
                    <Autocomplete
                      className="user-select-menu"
                      options={topLocalities.data ?? []}
                      disableClearable
                      noOptionsText="No cities"
                      getOptionLabel={(option) => option.locality_name}
                      value={{
                        locality_id: searchParams.locality_id,
                        locality_name: searchParams.locality
                      }}
                      onChange={(event, newValue) =>
                        setSearchParams({
                          ...searchParams,
                          locality_id: newValue?.locality_id,
                         locality: newValue?.locality_name
                        })
                      }
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              locality: e.target.value
                            })
                          }
                        />
                      )}
                    />
                  </div>

                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Property Id</p>
                    <Form.Control
                      as="input"
                      value={searchParams.property_id}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        property_id: e.target.value
                      })}
                    />
                  </div>

                  {/* Title */}
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Borrower Name</p>
                    <Form.Control
                      as="input"
                      value={searchParams.title}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        title: e.target.value
                      })}
                    />
                  </div>

                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Approval</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="Select Option"
                      value={searchParams.approval_filter_id}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        approval_filter_id: e.target.value
                      })}
                    >
                      <option value={0}>Select Option</option>
                      <option value={1}>Unapproved</option>
                      <option value={2}>Approved</option>
                    </Form.Control>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary mt-2 mr-3"
                      onClick={() => handleSubmitChange('property')}
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={handleSearchReset}
                    >
                      Reset
                    </button>
                  </div>
                </div>

                {/* /////////////////////////////////////////Property Details List */}

                <div className="row property-list d-flex">
                  {
                    showSearchPage ? (
                      <>
                        <>{
                          infinitePropertiesSearch?.map((filter, index) => (
                            infinitePropertiesSearch.length === index + 1 ?
                              <div
                                ref={lastElementRefSearch}
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  Facing : {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : {filter.price}
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : {filter.city_name}
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewPropertyModal"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deletePropertyModal"
                                      />
                                      <EditIcon
                                        onClick={() => handlePropertyEdit(filter)}
                                        className="editIcon button-hover"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div> :
                              <div
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  Facing : {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : {filter.price}
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : {filter.city_name}
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewPropertyModal"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deletePropertyModal"
                                      />
                                      <EditIcon
                                        onClick={() => handlePropertyEdit(filter)}
                                        className="editIcon button-hover"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                          ))}</>
                        <>
                          {
                            infinitePropertiesSearch?.length === 0 && (
                              <h1 className="no-prop">No Properties Found</h1>
                            )
                          }
                        </>
                      </>
                    ) : (
                      <>
                        <>
                          {infiniteProperties?.map((filter, index) => (
                            infiniteProperties.length === index + 1 ?
                              <div
                                ref={lastElementRef}
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  Facing : {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : {filter.price}
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : {filter.city_name}
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewPropertyModal"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deletePropertyModal"
                                      />
                                      <EditIcon
                                        onClick={() => handlePropertyEdit(filter)}
                                        className="editIcon button-hover"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div> :
                              <div
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  <span>Property Id:</span> {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  <span>Borrower Name :</span> {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  <span>Building / Complex Name :</span> {filter.building_name}
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  <span>Area :</span> {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  <span>BHK :</span> {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  <span>Facing :</span> {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  <span>Reserve Price :</span> {filter.price}
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  <span>Property Age :</span> {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  <span>Stats of Furnishing :</span> {filter.stats_of_furnishing}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  <span>Floors :</span> {filter.floors}
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  <span>Locality :</span> {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  <span>City :</span> {filter.city_name}
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  <span>Created By :</span> {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewPropertyModal"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deletePropertyModal"
                                      />
                                      <EditIcon
                                        onClick={() => handlePropertyEdit(filter)}
                                        className="editIcon button-hover"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                          ))}
                        </>
                        <>
                          {infiniteProperties?.length === 0 && (
                            <h1 className="no-prop">No Properties Found</h1>
                          )}
                        </>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        ) : selectedTab === 'bankAuctionProperty' ? (
          <div className={`property-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
            <div className="container-fluid">
              <div className="configuration-top-wrap">
                <div className="configuration-top">
                  <p className="page-title-config">
                    Bank Auction Property
                  </p>
                  <img
                    src={ADD_BANK_AUCTION_PROPERTY.default}
                    alt=""
                    className="add-bank-property button-hover"
                    ref={addBankPropertyButton}
                    onClick={() => setShowPropertyModal(true)}
                  />
                </div>

                <div className="row pt-4 d-flex flex-row filter-wrapper align-items-center">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <p className="config-subhead">Property type</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="Property type"
                      value={searchParams.property_type}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        category_id: '',
                        property_type: e.target.value
                      })}
                    >
                      <option value="">
                        Select Property Type
                      </option>
                      <option value="Residential">
                        Residential
                      </option>
                      <option value="Industrial">
                        Industrial
                      </option>
                      <option value="Agricultural land">
                        Agricultural Land
                      </option>
                      <option value="Commercial">
                        Commercial
                      </option>
                    </Form.Control>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part">
                    <p className="config-subhead">Category</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="Select Category"
                      value={searchParams.category_id}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        category_id: e.target.value
                      })}
                    >
                      <option value="">Select Category</option>
                      {
                        searchParams.property_type === 'Residential' && (
                          residential.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {RESIDENTIAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                      {
                        searchParams.property_type === 'Industrial' && (
                          industrial.map((item) => (
                            <option value={item?.category_id} key={item?.categroy_id}>
                              {INDUSTRIAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                      {
                        searchParams.property_type === 'Agricultural land' && (
                          agricultural.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {AGRICULTURAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                      {
                        searchParams.property_type === 'Commercial' && (
                          commercial.map((item) => (
                            <option value={item?.category_id} key={item?.category_id}>
                              {COMMERCIAL_CATEGORIES[item?.category_name]}
                            </option>
                          ))
                        )
                      }
                    </Form.Control>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">State</p>
                    <Autocomplete
                      className="user-select-menu"
                      options={topStates.data ?? []}
                      disableClearable
                      noOptionsText="No states"
                      getOptionLabel={(option) => option.state_name}
                      value={{
                        state_id: searchParams.state_id,
                        state_name: searchParams.state_name
                      }}
                      onChange={(event, newValue) =>
                        setSearchParams({
                          ...searchParams,
                          state_id: newValue?.state_id,
                          state_name: newValue?.state_name,
                          city_name: '',
                          city_id: ''
                        })
                      }
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              state_name: e.target.value,
                              city_name: '',
                              city_id: ''
                            })
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">City</p>
                    <Autocomplete
                      className="user-select-menu"
                      options={topCities.data ?? []}
                      disableClearable
                      noOptionsText="No cities"
                      getOptionLabel={(option) => option.city_name}
                      onChange={(event, newValue) =>
                        setSearchParams({
                          ...searchParams,
                          city_id: newValue?.city_id,
                          city_name: newValue?.city_name
                        })
                      }
                      value={{
                        city_id: searchParams.city_id,
                        city_name: searchParams.city_name
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              city_name: e.target.value
                            })
                          }
                        />
                      )}
                    />
                  </div>

                  {/* Locality */}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">Locality</p>
                    <Autocomplete
                      className="user-select-menu"
                      options={topLocalities.data ?? []}
                      disableClearable
                      noOptionsText="No cities"
                      getOptionLabel={(option) => option.locality_name}
                      value={{
                        locality_id: searchParams.locality_id,
                        locality_name: searchParams.locality
                      }}
                      onChange={(event, newValue) =>
                        setSearchParams({
                          ...searchParams,
                          locality_id: newValue?.locality_id,
                         locality: newValue?.locality_name
                        })
                      }
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              locality: e.target.value
                            })
                          }
                        />
                      )}
                    />
                  </div>

                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Property Id</p>
                    <Form.Control
                      as="input"
                      value={searchParams.property_id}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        property_id: e.target.value
                      })}
                    />
                  </div>

                  {/* Title */}
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Borrower Name</p>
                    <Form.Control
                      as="input"
                      value={searchParams.title}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        title: e.target.value
                      })}
                    />
                  </div>

                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-second-part"
                  >
                    <p className="config-subhead">Approval</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="Select Option"
                      value={searchParams.approval_filter_id}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        approval_filter_id: e.target.value
                      })}
                    >
                      <option value={0}>Select Option</option>
                      <option value={1}>Unapproved</option>
                      <option value={2}>Approved</option>
                    </Form.Control>
                  </div>
                  
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 user-third-part">
                    <p className="config-subhead">Institution</p>
                    <Autocomplete
                    id="dropdown"
                    freeSolo
                    className="user-select-menu"
                    options={searchInstitutionData ?? []}
                    disableClearable
                    noOptionsText={"No Institutions"}
                    getOptionLabel={(option) => option.institution}
                    onChange={(event, newValue) =>
                      setSearchParams({
                        ...searchParams,
                        institution_id: newValue?.institution_id,
                        institution: newValue?.institution,
                      })
                    }
                    value={{
                      institution_id:
                        adminLoginDetails?.data?.user_role === "Channel Partner"
                          ? adminLoginDetails?.data?.institution_id
                          : searchParams?.institution_id,
                      institution:
                        adminLoginDetails?.data?.user_role === "Channel Partner"
                          ? adminLoginDetails?.data?.institution_name
                          : searchParams?.institution,
                    }}
                    style={{ width: '100%' }}
                    disabled={adminLoginDetails?.data?.user_role === "Channel Partner"} // Disable if user_role is "Channel Partner"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={
                          adminLoginDetails?.data?.user_role === "Channel Partner"
                            ? adminLoginDetails?.data?.institution_name
                            : searchParams?.institution
                        }
                        variant="outlined"
                        onChange={(e) => institutionSearch(e.target.value)}
                        disabled={adminLoginDetails?.data?.user_role === "Channel Partner"} // Disable the input for "Channel Partner"
                      />
                    )}
                  />
                  </div>

                  <div className="col-xl-4 col-md-8 col-sm-12 user-third-part mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary mr-3 mt-2"
                      onClick={() => handleSubmitChange('bank')}
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={handleSearchReset}
                    >
                      Reset
                    </button>
                  </div>
                </div>



                {/* /////////////////////////////////Bank Property Details List */}
                <div className="row property-list d-flex">
                  {
                    showSearchPage ? (
                      <>
                        <>
                          {infiniteBankSearch?.map((filter, index) => (
                            infiniteBankSearch.length === index + 1 ?
                              <div
                                ref={lastElementRefBankSearch}
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.institution && <p className="property-details-text">
                                  Institution : <b>{filter?.institution}</b>
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  Facing : {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : <b>{filter.price}</b>
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.purchase_type && <p className="property-details-text">
                                  Purchase Type : {filter.purchase_type}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.auction_start_date && <p className="property-details-text">
                                  Auction Date : <b>{filter.auction_start_date}</b>
                                </p>}
                                {filter?.auction_start_time && <p className="property-details-text">
                                  Auction Time : <b>{filter.auction_start_time}</b>
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : <b>{filter.city_name}</b>
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : <b>{filter.created_by}</b>
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row">
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                      />
                                      <EditIcon
                                        className="editIcon button-hover"
                                        onClick={() => handlePropertyEdit(filter)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div> :
                              <div
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.institution && <p className="property-details-text">
                                  Institution : <b>{filter?.institution}</b>
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  Facing : {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : <b>{filter.price}</b>
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.purchase_type && <p className="property-details-text">
                                  Purchase Type : {filter.purchase_type}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.auction_start_date && <p className="property-details-text">
                                  Auction Date : <b>{filter.auction_start_date}</b>
                                </p>}
                                {filter?.auction_start_time && <p className="property-details-text">
                                  Auction Time : <b>{filter.auction_start_time}</b>
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : <b>{filter.city_name}</b>
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row">
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                      />
                                      <EditIcon
                                        className="editIcon button-hover"
                                        onClick={() => handlePropertyEdit(filter)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                          ))}
                        </>
                        <>
                          {infiniteBankSearch?.length === 0 && (
                            <h1 className="no-prop">No Properties Found</h1>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        <>
                          {infinitePropertiesbank?.map((filter, index) => (
                            infinitePropertiesbank.length === index + 1 ?
                              <div
                                ref={lastElementRefbank}
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.institution && <p className="property-details-text">
                                  Institution : <b>{filter?.institution}</b>
                                </p>}
                                {filter?.property_area && <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p>}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.face && <p className="property-details-text">
                                  Facing : {filter.face}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : <b>{filter.price}</b>
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.purchase_type && <p className="property-details-text">
                                  Purchase Type : {filter.purchase_type}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.auction_start_date && <p className="property-details-text">
                                  Auction Date : <b>{filter.auction_start_date}</b>
                                </p>}
                                {filter?.auction_start_time && <p className="property-details-text">
                                  Auction Time : <b>{filter.auction_start_time}</b>
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : <b>{filter.city_name}</b>
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row">
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                      />
                                      <EditIcon
                                        className="editIcon button-hover"
                                        onClick={() => handlePropertyEdit(filter)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div> :
                              <div
                                key={filter?.property_id}
                                className="col-xl-3 col-lg-5 col-md-8 col-sm-12 col-xs-12 property-card"
                              >
                                <img
                                  src={filter?.images[0] ? `${process.env.REACT_APP_IMAGE_BASE_URL}${filter?.images[0]}` : require('../../assets/img/no-file.png').default}
                                  alt=""
                                  className="property-image"
                                  height='200'
                                  width="100%"
                                />
                                {filter?.property_uniqueid && <p className="property-details-text">
                                  Property Id: {filter.property_uniqueid}
                                </p>}
                                {filter?.title && <p className="property-details-text">
                                  Borrower Name : {filter.title}
                                </p>}
                                {filter?.building_name && <p className="property-details-text">
                                  Building / Complex Name : {filter.building_name}
                                </p>}
                                {filter?.institution && <p className="property-details-text">
                                  Institution : <b>{filter?.institution}</b>
                                </p>}
                                {filter?.property_area ? <p className="property-details-text">
                                  Area : {filter.property_area}{' '}{filter?.property_area_unit}
                                </p> : ''}
                                {filter?.bhk && <p className="property-details-text">
                                  BHK : {filter?.bhk}
                                </p>}
                                {filter?.price && <p className="property-details-text">
                                  Reserve Price : <b>{filter.price}</b>
                                </p>}
                                {filter?.property_age && <p className="property-details-text">
                                  Property Age : {filter.property_age}
                                </p>}
                                {filter?.stats_of_furnishing && <p className="property-details-text">
                                  Stats of Furnishing : {filter.stats_of_furnishing}
                                </p>}
                                {filter?.purchase_type && <p className="property-details-text">
                                  Purchase Type : {filter.purchase_type}
                                </p>}
                                {filter?.floors && <p className="property-details-text">
                                  Floors : {filter.floors}
                                </p>}
                                {filter?.auction_start_date && <p className="property-details-text">
                                  Auction Date : <b>{filter.auction_start_date}</b>
                                </p>}
                                {filter?.auction_start_time && <p className="property-details-text">
                                  Auction Time : <b>{filter.auction_start_time}</b>
                                </p>}
                                {filter?.locality_name && <p className="property-details-text">
                                  Locality : {filter.locality_name}
                                </p>}
                                {filter?.city_name && <p className="property-details-text">
                                  City : <b>{filter.city_name}</b>
                                </p>}
                                {filter?.created_by && <p className="property-details-text">
                                  Created By : {filter.created_by}
                                </p>}
                                <div className="propertyCardBottom">
                                  <button
                                    type="button"
                                    onClick={() => handleViewProperty(filter?.property_id)}
                                    className="view-more btn btn-secondary pl-3 pr-3"
                                  >
                                    View more
                                  </button>
                                  {filter?.delete_or_edit === 1 && (
                                    <div className="d-flex flex-row">
                                      <DeleteIcon
                                        onClick={() => {
                                          setSelectedDeleteItem(filter)
                                          setShowDeletePropertyModal(true);
                                        }}
                                        className="deleteIcon button-hover"
                                      />
                                      <EditIcon
                                        className="editIcon button-hover"
                                        onClick={() => handlePropertyEdit(filter)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                          ))}
                        </>
                        <>
                          {infinitePropertiesbank?.length === 0 && (
                            <h1 className="no-prop">No Properties Found</h1>
                          )}
                        </>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        ) : selectedTab === 'property-upload' ? (
          <PropertyUpload expanded={expanded} />
        ) : selectedTab === 'configuration' ? (
          <div className={`configuration-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
            <div className="container-fluid">
              <div className="configuration-top-wrap">
                <div className="configuration-top">
                  <p className="page-title-config">User Previlage</p>
                  {(adminLoginDetails.data?.privileges?.add_branch_partners ||
                    adminLoginDetails.data?.privileges?.add_channel_partners) && (
                      <img
                        src={ADD_USER.default}
                        alt=""
                        className="add-user button-hover"
                        onClick={() => setShowAddUserModal(true)}
                      />
                    )}
                </div>
                <div className="row pt-4 d-flex justify-content-between">
                  <div className="col-xl-5 col-md-6 col-sm-12">
                    <p className="config-subhead">User type</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="User type"
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option selected>Select User Type</option>
                      {adminLoginDetails.data?.privileges?.add_branch_partners && (
                        <option value="branch partner">Branch Partner</option>
                      )}
                      {adminLoginDetails.data?.privileges?.add_channel_partners && (
                        <option value="channel partner">Channel Partner</option>
                      )}
                    </Form.Control>
                  </div>
                  <div className="col-xl-5 col-md-6 col-sm-12 user-second-part">
                    <p className="config-subhead">User</p>
                    <Form.Control
                      as="select"
                      className="user-select-menu"
                      placeholder="User"
                      onChange={(e) => handleUserChange(e.target.value)}
                    >
                      <option selected>Select User</option>
                      {
                        adminList.data?.map((admin) => (
                          <option
                            value={admin?.admin_id}
                            key={admin?.admin_id}
                          >
                            {admin?.admin_name}
                          </option>
                        ))
                      }
                    </Form.Control>
                  </div>
                </div>
              </div>
              {
                adminDetails?.details?.length > 0 && (
                  <div className="privilege-container row d-flex flex-row justify-content-between">
                    <div className="col-md-5 col-sm-12 user-details-container d-flex flex-column">
                      <img src={AVATAR.default} alt="" className="user-avatar" />
                      <p className="user-details-text">
                        {adminDetails?.details[0]?.admin_name}
                      </p>
                      <p className="user-details-text">
                        {adminDetails?.details[0]?.admin_role}
                      </p>
                      <p className="user-details-text">
                        Phone: {adminDetails?.details[0]?.admin_phone}
                      </p>
                      <p className="user-details-text">
                        Email: {adminDetails?.details[0]?.admin_email}
                      </p>
                      <p className="user-details-text">Aadhaar: {adminDetails?.details[0]?.admin_aadhaar_no}</p>
                      <p className="user-details-text">Address: {adminDetails?.details[0]?.admin_address}</p>
                      <p className="user-details-text">
                        City: {adminDetails?.details[0]?.admin_city}
                      </p>
                      <p className="user-details-text">
                        State: {adminDetails?.details[0]?.admin_state}
                      </p>
                      <p className="user-details-text">
                        Institute: {adminDetails?.details[0]?.admin_institute_name}
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      {adminDetails?.privileges !== null && (
                        <p className="privilege-head">Privilege</p>
                      )}
                      <div className="d-flex flex-column">
                        {userPrivileges?.map((privilege) => (
                          adminDetails?.privileges?.[privilege.value] && (
                            <div className="form-check form-check-wrap" key={privilege.id}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={adminDetails?.privileges[privilege.value]?.enabled}
                                onChange={(e) => handlePrivilegeChange(e, privilege)}
                              />
                              <label
                                className="form-check-label check-label-style"
                                htmlFor="approveProperties"
                              >
                                {privilege.name}
                              </label>
                            </div>
                          )
                        ))}
                        {adminDetails?.privileges !== null && (
                          <Button
                            variant="primary"
                            onClick={updatePrivilege}
                            style={{ width: 200 }}
                          >
                            Update Privilege
                          </Button>
                        )}
                        {adminDetails?.delete_user == 1 && (
                          <Button
                            variant="danger"
                            className="mt-3"
                            style={{ width: 200 }}
                            onClick={() => setShowRemoveAdminModal(true)}
                          >
                            Remove Admin
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        ) :selectedTab === 'userReport' ?  (
          <div className={`property-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
            <div className="container-fluid">
              <UserReport />
            </div>
          </div>
        ):<div className={`settings-container ${expanded ? 'tab-expanded-wrap' : 'tab-shrink-wrap'}`}>
        <div className="container-fluid">
          <div className="row settings-wrap">
            <div className="col-12">
              <p className="title">Change Password</p>
              <div className="mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor='current-pass-field' className="form-label">
                    Current Password
                  </label>
                  <input
                    type={showPass.currPass ? "text" : "password"}
                    className="form-control pass-field"
                    id="current-pass-field"
                    aria-describedby="currentPassHelp"
                    value={adminPass.currentPassword}
                    onChange={(e) =>
                      setAdminPass({
                        ...adminPass,
                        currentPassword: e.target.value
                      })
                    }
                  />
                  {!showPass.currPass ? 
                    <VisibilityOffIcon className="showPassAdmin" onClick={() => setShowPass({...showPass, currPass: true})} /> : 
                    <VisibilityIcon className="showPassAdmin" onClick={() => setShowPass({...showPass, currPass: false})} />
                  }
                </div>
              </div>
              <div className="mb-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="new-pass-field" className="form-label">
                    New Password
                  </label>
                  <input
                    type={showPass.newPass ? "text" : "password"}
                    className={
                      `form-control
                      ${adminPass?.newPassword?.length > 0
                      && adminPass?.newPassword?.length < 8 && 'error'} pass-field`}
                    id="new-pass-field"
                    aria-describedby="newPassHelp"
                    value={adminPass.newPassword}
                    onChange={(e) => setAdminPass({
                      ...adminPass,
                      newPassword: e.target.value
                    })}
                  />
                  {!showPass.newPass ? 
                    <VisibilityOffIcon className="showPassAdmin" onClick={() => setShowPass({...showPass, newPass: true})} /> : 
                    <VisibilityIcon className="showPassAdmin" onClick={() => setShowPass({...showPass, newPass: false})} />
                  }
                  {adminPass?.newPassword?.length > 0 && adminPass?.newPassword?.length < 8 && (
                    <span style={{ color: 'red', fontSize: '12px' }}>Min 8 characters required</span>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="btn btn-secondary mt-2 ml-4"
                  onClick={updatePassword}
                  disabled={adminPass?.newPassword?.length < 6}
                >
                  Change Password
                </button>
                <button
                  type="button"
                  className="btn btn-danger mt-2 ml-4"
                  onClick={() => setAdminPass({
                    ...adminPass,
                    newPassword: '',
                    currentPassword: ''
                  })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      {/* Logout modal */}
      <LogoutModal
        showLogoutModal={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
        handleLogout={handleLogout}
      />
      {/* Delete Property Modal */}
      <DeletePropertyModal
        showDeletePropertyModal={showDeletePropertyModal}
        handleDeletePropertyModalClose={handleDeletePropertyModalClose}
        handleDeleteProperty={handleDeleteProperty}
      />
      {/* Add User Modal */}
      <AddUserModal
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        addUserValues={addUserValues}
        setAddUserValues={setAddUserValues}
        addUserFields={addUserFields}
        handleAddUser={handleAddUser}
      />
      {/* Add Property Modal */}
      <AddPropertyModal
        showPropertyModal={showPropertyModal}
        handleAddProperty={handleAddProperty}
        handleAddPropertyClose={handleAddPropertyClose}
        selectedTab={selectedTab}
        addPropertyType={addPropertyType}
        addPropertyCategory={addPropertyCategory}
        handleAddPropertyCategoryChange={handleAddPropertyCategoryChange}
        handleAddPropertyTypeChange={handleAddPropertyTypeChange}
        addPropertyFields={addPropertyFields}
        bankAuctionPropertyFields={bankAuctionPropertyFields}
        residential={residential}
        industrial={industrial}
        commercial={commercial}
        agricultural={agricultural}
        savePropertyData={savePropertyData}
        setSavePropertyData={setSavePropertyData}
        addPropertyErr={addPropertyErr}
        setAddPropertyErr={setAddPropertyErr}
      />
      {/* ViewPropertyModal */}
      <ViewPropertyModal
        setSelectedPropertyView={setSelectedPropertyView}
        showViewPropertyModal={showViewPropertyModal}
        selectedPropertyView={selectedPropertyView}
        handleViewPropertyModalClose={handeViewPropertyModalClose}
        approvalSuccess={handleApprovalSuccess}
      />
      {/* Remove Admin Modal */}
      <RemoveAdminModal
        setShowRemoveAdminModal={setShowRemoveAdminModal}
        handleRemoveAdmin={handleRemoveAdmin}
        showRemoveAdminModal={showRemoveAdminModal}
      />
    </div>
  );
}

export default AdminPage;