import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { resendOTP } from "../../redux/user/user.actions";

export function ResendOTPApiCall(phone, toastView, setIsOTPSend = () => {}) {
  return (dispatch) => {
    dispatch(resendOTP(null, true));

    const url = `${URL_CONSTANTS.RESEND_OTP}${phone}`;

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          if(setIsOTPSend) {
            setIsOTPSend(true);
          }
          toastView("OTP has been sent", 'success');
          dispatch(resendOTP(res.data.data, false));
        } else {
          dispatch(resendOTP(null, false));
        }
      })
      .catch((error) => {
        toastView(error?.response?.data?.message, 'error');
        dispatch(resendOTP(null, false));
      });
  };
}